import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { useStore as useLoginStore } from "frontend/stores/login";
import { UserEntity } from "frontend/interfaces/user";

/**
 * Checks if the user is logged in (as practice) and if so
 * it will not allow the user to go to login-route but redirect
 * him to the calendar
 *
 * @export
 * @param {RouteLocationNormalized} to
 * @returns {(RouteLocationRaw | void)}
 */
export async function navigationGuardSkipLogin(
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | void> {
  const loginStore = useLoginStore();
  if (to.name === "login" && loginStore.isLoggedIn(UserEntity.Practice)) {
    return {
      name: "calendar",
    };
  }
}
