import { defineStore } from "pinia";

import {
  OnLoginBefore,
  OnLoginSuccess,
  OnLogoutAfter,
  OnLogoutBefore,
  OnRequestHasRawResponse,
} from "frontend/events/topics";
import { UserEntity } from "frontend/interfaces/user";
import { activate, isActive } from "shared/utils/bitwise-enum";
import { log, LogLevel } from "shared/utils/logger";
import {
  parseRawLoginStatusResponse,
  requestLoginStatus,
  LoginStatusResponse,
} from "frontend/api/application/request-misc";
import { RESPONSE_HEADER_CSRF_TOKEN } from "frontend/api/common";
import { assertUnreachable } from "shared/utils/typescript-helper";
import { LoginStatus } from "frontend/interfaces/stores/login";
import { routes } from "frontend/api/application";

type CurrentLoginStatus = number;
interface LoginStoreState {
  loginStatus: CurrentLoginStatus;
  mobileToken: string | null;
  csrfToken: string | null;
}

export const id = "login";

export const useStore = defineStore({
  id,
  state(): LoginStoreState {
    return {
      loginStatus: LoginStatus.LoggedOut,
      mobileToken: null,
      csrfToken: null,
    };
  },
  actions: {
    updateLoginStatus(status: LoginStatusResponse) {
      // only handle logout --> login case (for now)
      if (status.current_user.present) {
        if (status.current_user.patient) this.login(UserEntity.Patient);
        if (status.current_user.office) this.login(UserEntity.Practice);
      }
    },
    isLoggedInAny(): boolean {
      return (
        this.isLoggedIn(UserEntity.Patient) ||
        this.isLoggedIn(UserEntity.Practice)
      );
    },
    isLoggedIn(userEntity: UserEntity): boolean {
      switch (userEntity) {
        case UserEntity.Practice:
          return isActive(this.loginStatus, LoginStatus.LoggedInPractice);
        case UserEntity.Patient:
          return isActive(this.loginStatus, LoginStatus.LoggedInPatient);
        default:
          return false;
      }
    },
    logout() {
      OnLogoutBefore.emit({});

      this.loginStatus = LoginStatus.LoggedOut;
      requestLoginStatus(); // refresh CSRF

      OnLogoutAfter.emit({});
    },
    login(userEntity: UserEntity) {
      OnLoginBefore.emit({ userEntity });

      let newFlag: LoginStatus | null = null;
      switch (userEntity) {
        case UserEntity.Practice:
          newFlag = LoginStatus.LoggedInPractice;
          break;
        case UserEntity.Patient:
          newFlag = LoginStatus.LoggedInPatient;
          break;
        default:
          log(LogLevel.Error, "unkown user entity", userEntity);
          newFlag = assertUnreachable(userEntity); // throw typescript error if we missed a case
      }

      if (newFlag) {
        this.loginStatus = activate(this.loginStatus, newFlag);
        OnLoginSuccess.emit({ userEntity });
      }
    },
  },
});

export function setupEvents() {
  // subscribe to request events to refresh XSRF-token
  OnRequestHasRawResponse.subscribe((data) => {
    const response = data.rawResponse;
    if (response.headers && response.headers[RESPONSE_HEADER_CSRF_TOKEN]) {
      useStore().csrfToken = response.headers[RESPONSE_HEADER_CSRF_TOKEN];
    }

    if (data.path == routes.paths.login_status_frontend_misc_path) {
      const loginStatus = parseRawLoginStatusResponse(response.body);
      useStore().updateLoginStatus(loginStatus);
    }
  });
}
