<script lang="ts" setup>
  import { computed, onMounted, ref, toRefs, watch } from "vue";

  import {
    Patient,
    PatientWithAppointments,
  } from "frontend/interfaces/patient";
  import { ErrorDatabase, useErrors } from "frontend/uses/use-errors";
  import { Cache } from "frontend/utils/request-cache";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { Insurance } from "frontend/interfaces/insurance";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";

  const props = withDefaults(
    defineProps<{
      patient: Patient | PatientWithAppointments;
      visualModal?: boolean;
      errors: ErrorDatabase;
    }>(),
    {
      visualModal: false,
      errorDatabase: {},
    }
  );

  const { errorFor } = useErrors(toRefs(props).errors);

  // -------------------------------------------------------------------
  // Insurance selector
  // -------------------------------------------------------------------
  const insurances = ref<Array<Insurance>>([]);
  const currentSelectionI = ref<FormSelectId>(props.patient.insurance_id);
  const { options: optionsI } = useFormSelectOptions<Insurance>(
    currentSelectionI,
    insurances,
    (entry) => ({
      id: entry.id,
      label: entry.name,
    })
  );
  const loadInsurances = async () => {
    insurances.value = await Cache.getCachedInsurances();
    currentSelectionI.value = props.patient.insurance_id;
  };
  onMounted(loadInsurances);
  watch(currentSelectionI, () => {
    if (currentSelectionI.value) {
      props.patient.insurance_id = currentSelectionI.value.toString();
    }
  });
  // -------------------------------------------------------------------

  const patientIsUnsaved = computed(
    () => !!(props.patient as unknown as { unsaved: boolean }).unsaved
  );
</script>

<template>
  <div
    class="edit-patient"
    v-bind:class="{ 'edit-patient--modal': visualModal }"
  >
    <h2>
      Patient <template v-if="patientIsUnsaved">erstellen</template>
      <template v-else>anpassen</template>
    </h2>
    <FormInput
      v-model="patient.first_name"
      label="Vorname"
      v-bind:errors="errorFor('first_name')"
    />
    <FormInput
      v-model="patient.name"
      label="Name"
      v-bind:errors="errorFor('name')"
    />
    <FormDatetime
      v-model="patient.date_of_birth"
      label="Geburtsdatum"
      v-bind:errors="errorFor('date_of_birth')"
      allow-date-input
    />
    <FormInput
      v-model="patient.kvnr"
      label="Versichertennummer"
      v-bind:errors="errorFor('versicherungsnummer')"
    />
    <FormSelect
      v-bind:key="patient.id"
      v-model="currentSelectionI"
      v-bind:options="optionsI"
      label="Versicherung"
      v-bind:errors="errorFor('insurance')"
    />
  </div>
</template>

<style lang="scss" scoped>
  .edit-patient--modal {
    width: 80vw;
    min-height: 80vh;
  }
</style>
