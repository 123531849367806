<script lang="ts" setup>
  import { computed } from "vue";

  import {
    useEventEntryProps,
    dataForEventEntry,
  } from "frontend/uses/use-event-entry";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { useOperator } from "frontend/uses/use-operator";

  import OperatorTag from "frontend/components/OperatorTag.vue";

  const props = defineProps({ ...useEventEntryProps() });

  const eventEntryData = computed(() => {
    if (!props.eventEntry) {
      return null;
    }
    return dataForEventEntry(props.eventEntry);
  });

  const { operator } = useOperator(
    computed(() => props.eventEntry?.operator_id || null)
  );
</script>

<template>
  <div
    class="event-base"
    v-bind:class="{ 'event-base--seen': eventEntry?.seen }"
  >
    <template v-if="eventEntry">
      <div class="event-base__header">
        <span class="header__date">
          {{ formatDate(eventEntry.event_date, DateFormat.DateAndTime) }}
        </span>
        <span
          v-if="eventEntry.user"
          v-tooltip="'Nutzer-ID: ' + eventEntry.user.id"
          class="header__user"
        >
          ausgelöst durch
          <template v-if="eventEntry.as_provider_admin">
            Plattformbetreiber
          </template>
          <template v-else-if="eventEntry.user.is_office_member">
            Praxis
          </template>
          <template v-else>Patient</template>
        </span>
        <span v-if="operator"><OperatorTag v-bind:operator="operator" /></span>
      </div>
      <div class="event-base__title">
        <span v-tooltip="'Anfrage-ID: ' + eventEntry.request_id">{{
          eventEntry.title
        }}</span>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -- sanitized in backend -->
      <div class="event-base__text"><span v-html="eventEntry.text_html" /></div>
      <component
        v-bind:is="eventEntryData.component"
        v-if="eventEntryData?.component"
        v-bind:event-entry="eventEntry"
      ></component>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .event-base {
    background-color: colors.$color_event-entry--background;

    padding: 20px 15px;

    margin: 20px 0;
  }

  .event-base--seen {
    background-color: colors.$color_event-entry--background-seen;
  }

  .event-base__header {
    font-size: 10px;
    font-weight: bold;

    margin-bottom: 15px;
  }

  .event-base__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .event-base__text {
    font-size: 14px;
  }

  .header__date {
    margin-right: 10px;
  }

  .header__user {
    font-weight: normal;
  }
</style>
