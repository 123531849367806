import { routes } from "frontend/api/application";
import { requestGenericSettings } from "frontend/api/application/settings/request-generic-settings";
import { ScheduleActivationRaw } from "frontend/interfaces/schedule-activation";

export const api = requestGenericSettings<ScheduleActivationRaw>({
  endpoints: {
    indexEndpoint: routes.paths.frontend_settings_schedule_activations_path,
    showEndpoint: routes.paths.frontend_settings_schedule_activation_path,
  },
});
