<script lang="ts" setup>
  import { toRefs, ref, watch, computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: null,
    },
  });

  const { modelValue } = toRefs(props);

  const emit = defineEmits(["update:model-value"]);

  const isOpen = ref<boolean>(modelValue.value);

  const toggleAccordion = () => {
    const newValue = !isOpen.value;
    isOpen.value = newValue;
    emit("update:model-value", newValue);
  };

  watch(modelValue, (newValue: boolean | undefined) => {
    isOpen.value = !!newValue;
  });

  const iconClass = computed(() =>
    isOpen.value ? "fe-chevron-up" : "fe-chevron-down"
  );
</script>

<template>
  <div>
    <div class="accordion__header" v-on:click="toggleAccordion">
      <div>
        <slot name="header">{{ header }}</slot>
      </div>
      <i class="accordion__icon fe" v-bind:class="[iconClass]"></i>
    </div>
    <div v-if="isOpen"><slot></slot></div>
  </div>
</template>

<style lang="scss" scoped>
  .accordion__header {
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion__icon {
    font-size: 25px;
  }
</style>
