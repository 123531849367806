import { routes, doRequest } from "frontend/api/application";
import {
  PatientRaw,
  PatientsRaw,
  PatientWithAppointmentsRaw,
} from "frontend/interfaces/patient";
import {
  HttpMethod,
  HttpPayload,
  HttpResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestPatients(query?: string): Promise<{
  patients: Array<PatientRaw>;
}> {
  const params: {
    query?: string;
  } = {};
  if (query) params.query = query;

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_patients_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    return {
      patients: (body as unknown as PatientsRaw).patients,
    };
  } else {
    return {
      patients: [],
    };
  }
}

export async function requestPatient(
  id: PrimaryKey
): Promise<PatientWithAppointmentsRaw | null> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_patient_path,
    { id },
    {}
  );

  if (statusIsSuccess && body) {
    return body.patient as unknown as PatientWithAppointmentsRaw;
  } else {
    return null;
  }
}

export async function requestPatientByExternalId(
  id: string
): Promise<string | null> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.GET,
    routes.paths.by_external_id_frontend_patients_path,
    { external_id: id },
    {}
  );

  if (statusIsSuccess && body) {
    return body.patient_id as unknown as string;
  } else {
    return null;
  }
}

export interface OfficeBanner {
  banner_text_html: string | null;
  banner_submit_label: string | null;
}

export async function requestPatientsCurrent(): Promise<{
  currentPatient: PatientRaw | null;
  office_banner: OfficeBanner | null;
}> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.current_frontend_patients_path,
    {},
    {}
  );

  if (statusIsSuccess) {
    return {
      currentPatient: (
        body as unknown as { current_patient: PatientRaw | null }
      ).current_patient,
      office_banner: (body as unknown as { office_banner: OfficeBanner })
        .office_banner,
    };
  } else {
    return {
      currentPatient: null,
      office_banner: null,
    };
  }
}

interface PatientResponse extends PatientRaw {
  _errors: HttpResponse;
}

export async function addUserToPatient(
  patient_id: string,
  external_pvs_id: string,
  pvs_name: string,
  pvs_system_id: string
): Promise<{
  success: boolean;
  errors: Partial<Record<string, string>>;
}> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.add_user_frontend_patient_path,
    { id: patient_id },
    {
      user: {
        external_id: external_pvs_id,
        external_type: `pvs_system::${pvs_name}::${pvs_system_id}::patient::${patient_id}`,
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should not be null
  const { _errors } = body!;
  return {
    success: statusIsSuccess,
    errors: (_errors || {}) as unknown as Partial<Record<string, string>>,
  };
}

export async function updatePatient(patient: HttpPayload): Promise<{
  success: boolean;
  patient: PatientRaw;
  errors: Partial<Record<string, string>>;
}> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_patient_path,
    { id: patient.id as string },
    patient
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should not be null
  const { _errors, ...temp } = body!.patient as unknown as PatientResponse;
  return {
    success: statusIsSuccess,
    patient: temp,
    errors: (_errors || {}) as unknown as Partial<Record<string, string>>,
  };
}

export async function createPatient(
  patient: HttpPayload,
  user?: HttpPayload
): Promise<{
  success: boolean;
  patient: PatientRaw;
  errors: Partial<Record<string, string>>;
}> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_patients_path,
    {},
    user
      ? {
          patient,
          user,
        }
      : {
          patient,
        }
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- should not be null
  const { _errors, ...temp } = body!.patient as unknown as PatientResponse;
  return {
    success: statusIsSuccess,
    patient: temp,
    errors: (_errors || {}) as unknown as Partial<Record<string, string>>,
  };
}

export async function destroyPatient(
  id: PrimaryKey
): Promise<UnifiedResponse<PatientRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_patient_path,
    { id },
    {}
  );

  return {
    entityRaw: (body as unknown as { entity: PatientRaw }).entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}

export async function mergePatients(
  source_id: PrimaryKey,
  destination_id: PrimaryKey
): Promise<UnifiedResponse<PatientWithAppointmentsRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.merge_frontend_patient_path,
    {
      id: destination_id,
      src_patient_id: source_id,
    },
    {}
  );

  return {
    entityRaw: (body as unknown as { entity: PatientWithAppointmentsRaw })
      .entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}

export async function removePvsFromPatient(id: PrimaryKey): Promise<{
  success: boolean;
  patient: PatientRaw;
  errors: Partial<Record<string, string>>;
}> {
  const {
    patient: { _errors, ...patient },
    statusIsSuccess,
  } = (await doRequest(
    HttpMethod.POST,
    routes.paths.remove_pvs_users_frontend_patient_path,
    { id },
    {}
  )) as HttpResponse & { patient: PatientResponse };

  return {
    success: statusIsSuccess,
    patient,
    errors: (_errors || {}) as unknown as Partial<Record<string, string>>,
  };
}
