<script lang="ts">
  import {
    defineComponent,
    nextTick,
    toRefs,
    ref,
    onMounted,
    watch,
    computed,
  } from "vue";

  import { Location } from "frontend/interfaces/location";
  import {
    Attendance,
    AttendanceNew,
  } from "frontend/interfaces/settings/attendance";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { Cache } from "frontend/utils/request-cache";
  import { today, tomorrow } from "shared/utils/date-utils";
  import { AttendanceConfigs } from "shared/static/enums.ts.erb";
  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import {
    useFormCheckbox,
    useWeekdayFormCheckbox,
  } from "frontend/uses/use-form-checkbox";
  import { PartialBy } from "shared/utils/typescript-helper";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormCheckboxBitwise from "frontend/components/form/FormCheckboxBitwise.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";
  import FormOfficeColor from "frontend/components/form/FormOfficeColor.vue";
  import FormSelectOfficeSchedule from "frontend/components/form/FormSelectOfficeSchedule.vue";

  export default defineComponent({
    components: {
      FormInput,
      FormSelect,
      FormCheckboxBitwise,
      FormCheckboxGroup,
      FormDatetime,
      BaseButton,
      FormSelectOfficePerson,
      FormOfficeColor,
      FormSelectOfficeSchedule,
    },
    props: {
      ...editProps<Attendance>(),
      // indicates if the entityEditable should be synced back to entity on successful save
      useEditSync: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["refresh-needed"],
    setup(props, { emit }) {
      const { entity, useEditSync } = toRefs(props);
      const editComponent = useEditComponent(entity.value);
      const attendanceBeginDate = computed(
        () => editComponent.entityEditable.value.from
      );
      // -----------
      // Location selector
      const locations = ref<Array<Location>>([]);
      const currentSelectionL = ref<FormSelectId>(
        editComponent.entityEditable.value.location_id
      );
      const { options: optionsL } = useFormSelectOptions<Location>(
        currentSelectionL,
        locations,
        (entry) => ({
          id: entry.id,
          label: entry.name,
        })
      );
      const loadLocations = async () => {
        locations.value = await Cache.getCachedLocations();
        currentSelectionL.value =
          editComponent.entityEditable.value.location_id;
      };
      onMounted(loadLocations);

      watch(currentSelectionL, () => {
        if (currentSelectionL.value) {
          editComponent.entityEditable.value.location_id =
            currentSelectionL.value.toString();
        }
      });
      //--------
      // weekdays
      const { options } = useWeekdayFormCheckbox();
      //--------
      // sessions
      const sessions = computed(() => {
        return entity.value._sessions || [];
      });
      const { options: optionsS } = useFormCheckbox(sessions, (entry) => ({
        id: entry.id,
        label: `${entry.appointment_type_name} - ${entry.name}`,
      }));
      watch([useEditSync, entity], () => {
        if (useEditSync.value) {
          editComponent.recreateEditable(entity.value);
          currentSelectionL.value = entity.value.location_id;
        }
      });
      return {
        ...editComponent,
        onAutosaveFor: (key: string, deferSave = false) => {
          const autosaver = editComponent.onAutosaveFor(key);
          return async () => {
            if (deferSave) await nextTick();
            if ((await autosaver()) && useEditSync.value) {
              delete (entity.value as PartialBy<AttendanceNew, "unsaved">)
                .unsaved;
              Object.assign(entity.value, editComponent.entityEditable.value);
            }
          };
        },
        deleteRecord: async () => {
          await editComponent.deleteRecord();
          emit("refresh-needed");
        },
        attendanceBeginDate,
        optionsL,
        currentSelectionL,

        options,
        optionsS,
        today,
        tomorrow,

        // libs
        AttendanceConfigs,
      };
    },
  });
</script>

<template>
  <div class="edit-attendance">
    <h5>{{ entity.name }}</h5>
    <div class="edit-attendance__columns">
      <div>
        <FormInput
          v-model="entityEditable.name"
          label="Name"
          v-bind:errors="errorFor('name')"
          v-bind:animation-status="statusFor('name')"
          v-on="{ autosave: onAutosaveFor('name') }"
        />
        <FormSelect
          v-model="currentSelectionL"
          label="Standort"
          v-bind:options="optionsL"
          v-bind:errors="errorFor('location_id')"
          v-bind:animation-status="statusFor('location_id')"
          v-on="{ autosave: onAutosaveFor('location_id') }"
        />
        <FormSelectOfficeSchedule
          v-model="entityEditable.schedule_id"
          label="Zeitplan"
          v-bind:errors="errorFor('schedule_id')"
          v-on="{ autosave: onAutosaveFor('schedule_id') }"
        />
        <FormSelectOfficePerson
          v-model="entityEditable.person_id"
          label="Mitarbeiter"
          v-bind:errors="errorFor('person_id')"
          v-on="{ autosave: onAutosaveFor('person_id') }"
        />
        <FormCheckboxBitwise
          v-model="entityEditable.configs"
          label="für Privatpatienten"
          v-bind:flag="AttendanceConfigs.private"
          v-on="{ autosave: onAutosaveFor('configs') }"
        />
        <FormCheckboxBitwise
          v-model="entityEditable.configs"
          label="für Kassenpatienten"
          v-bind:flag="AttendanceConfigs.statutory"
          v-on="{ autosave: onAutosaveFor('configs') }"
        />
        <FormCheckboxBitwise
          v-model="entityEditable.configs"
          label="für Praxis buchbar"
          v-bind:flag="AttendanceConfigs.available_for_selection_by_office"
          v-on="{ autosave: onAutosaveFor('configs') }"
        />
        <FormCheckboxBitwise
          v-model="entityEditable.configs"
          label="für Patienten über PraxisApp buchbar"
          v-bind:flag="AttendanceConfigs.available_for_selection_by_patients"
          v-on="{ autosave: onAutosaveFor('configs') }"
        />
        <FormOfficeColor
          v-model="entityEditable.color_id"
          label="Farbe (optional)"
          v-bind:errors="errorFor('color_id')"
          v-bind:animation-status="statusFor(`color_id`)"
          allow-blank
          v-on="{ autosave: onAutosaveFor(`color_id`) }"
        />
      </div>
      <div>
        <FormCheckboxGroup
          v-model="entityEditable.days"
          v-bind:options="options"
          label="Wochentage"
          v-bind:errors="errorFor('days')"
          v-bind:animation-status="statusFor('days')"
          v-on="{ autosave: onAutosaveFor('days') }"
        />
        <FormDatetime
          v-model="entityEditable.from"
          use-utc
          label="Startzeit"
          visual-small
          static-time-picker
          v-bind:base-date="attendanceBeginDate"
          v-bind:errors="errorFor('from')"
          v-bind:animation-status="statusFor('from')"
          v-on="{ autosave: onAutosaveFor('from') }"
        ></FormDatetime>
        <FormDatetime
          v-model="entityEditable.to"
          use-utc
          label="Endzeit"
          visual-small
          static-time-picker
          v-bind:base-date="attendanceBeginDate"
          v-bind:errors="errorFor('to')"
          v-bind:animation-status="statusFor('to')"
          v-on="{ autosave: onAutosaveFor('to') }"
        ></FormDatetime>
      </div>
      <div>
        <FormCheckboxGroup
          v-model="entityEditable.session_ids"
          v-bind:options="optionsS"
          label="Termintypen"
          v-bind:errors="errorFor('session_ids')"
          v-bind:animation-status="statusFor('session_ids')"
          select-all
          v-on="{ autosave: onAutosaveFor('session_ids') }"
        />
      </div>
    </div>
    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .edit-attendance {
    margin-bottom: 100px;
  }

  .edit-location__roles-label {
    @include label.label;
  }

  .edit-attendance__columns {
    display: flex;

    margin-bottom: 25px;

    > div {
      min-width: 350px;

      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
