<script lang="ts">
  import { defineComponent, ref } from "vue";
  import { VueFinalModal, useVfm } from "vue-final-modal";
  import "vue-final-modal/style.css";
  import { v4 as uuid } from "uuid";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";

  export default defineComponent({
    components: { BaseButton, BaseSpinner, VueFinalModal },
    inheritAttrs: false,
    props: {
      hideCancel: {
        type: Boolean,
        default: false,
      },
      hideConfirm: { type: Boolean, default: false },
      loading: {
        type: Boolean,
        default: false,
      },
      confirmLabel: {
        type: String,
        default: "OK",
      },
      cancelLabel: {
        type: String,
        default: "Abbrechen",
      },
    },
    emits: ["confirm", "cancel", "_beforeOpen", "_closed"],
    setup() {
      const { close: closeFromVueFinalModal } = useVfm();

      const modalId = ref<string>(uuid());
      const close = () => {
        closeFromVueFinalModal(modalId.value);
      };

      return {
        modalId,
        close,
      };
    },
  });
</script>

<template>
  <VueFinalModal
    v-bind="$attrs"
    v-bind:esc-to-close="false"
    v-bind:click-to-close="false"
    v-bind:modal-id="modalId"
    class="modal__container"
    content-class="modal__wrapper"
  >
    <div>
      <slot />
    </div>
    <div class="modal__actions">
      <BaseButton
        v-if="!hideCancel"
        class="modal__action"
        v-bind:disabled="loading"
        v-on:submit="$emit('cancel', close)"
        >{{ cancelLabel }}</BaseButton
      >
      <BaseButton
        v-if="!hideConfirm"
        class="modal__action"
        v-bind:disabled="loading"
        visual-action
        v-on:submit="$emit('confirm', close)"
        >{{ confirmLabel }}</BaseButton
      >
    </div>
    <div v-if="loading" class="modal__close">
      <BaseSpinner />
    </div>
    <BaseButton
      v-else
      visual-icon
      class="modal__close"
      v-on:click="$emit('cancel', close)"
    >
      <i class="fe fe-close"></i>
    </BaseButton>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";

  .modal__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .modal__close {
    position: absolute;

    right: 0;
    top: 0;

    border-radius: 0;
    border-top-right-radius: dimensions.$dimension_modal--border-radius;
  }
</style>

<style lang="scss">
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";

  .modal__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__wrapper {
    padding: 20px 30px;

    background: colors.$color_modal--background;
    color: colors.$color_modal--text;

    position: relative;

    border-radius: dimensions.$dimension_modal--border-radius;

    max-height: 90vh;
    overflow-y: auto;
  }
</style>
