import { Calendar } from "frontend/interfaces/calendar";
import { DayViewRaw } from "frontend/interfaces/day-view";
import { parseUnit } from "frontend/parser/parse-unit";
import { parsePerson } from "frontend/parser/parse-person";
import { Person } from "frontend/interfaces/person";
import { header, note } from "frontend/uses/abstract-view/keys-and-labels";
import { useAbstractViewParser } from "frontend/uses/abstract-view/use-abstract-view-parser";
import { useStore as useProfileStore } from "frontend/stores/profile";

export function useDayViewParser(date: Date, dayView: DayViewRaw): Calendar {
  const { sortPersons, filterPersons } = useProfileStore();

  const pivotSelector = (persons: Person[]): Person[] => {
    return filterPersons(sortPersons(persons));
  };

  return useAbstractViewParser<Person>(
    {
      units: dayView.units.map((unitRaw) => parseUnit(unitRaw, date)),
      persons: dayView.persons.map(parsePerson),
      dates: [date],
    },
    {
      calculateColumnPivots: (database, _options) =>
        pivotSelector(database.persons),
      calculateColumnHeader: (person, _database, _options) =>
        header(person.id, person.name),
      calculateColumnNote: (_person, _database, _options) => {
        return dayView.holidays.map((holiday) =>
          note(holiday.id, holiday.name)
        );
      },
      calculateCellAt: (
        person,
        intervalReferenceDate,
        columnIntervalReferenceDate,
        _options
      ) => ({
        person: person,
        interval: intervalReferenceDate,
        columnInterval: columnIntervalReferenceDate,
        key: person.id,
      }),
    },
    {}
  );
}
