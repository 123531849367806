<script lang="ts">
  import { defineComponent } from "vue";

  import { SchedulesSettingsManager } from "frontend/uses/settings/manager/schedules-settings-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new SchedulesSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-schedules">
    <h2>Zeitpläne</h2>
    <BaseHelp>
      <p>
        In diesem Bereich können verschiede Zeitpläne erstellt werden. Jeder
        Zeitplan kann unter „Aktivierung von Zeitplänen“ für gewisse Zeiträume
        aktiviert werden.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
