<template>
  <div class="settings-start">
    <h2>Einstellungen</h2>
    <p>
      Unter dem <strong>Menüpunkten</strong> „Allgemein“ bis „Zeitplan“ können
      Sie den Kalender für Ihre Praxis individuell konfigurieren.
    </p>
    <p>
      Eine Auswahl von bereits konfigurierten „Standardpraxen“ (1 Arzt + 1 MFA)
      und anderen Praxismodellen finden Sie voreingestellt unter dem Menüpunkt
      „Musterpraxen“.
    </p>
    <p>
      Dies Musterpraxen inklusive der Texte können Sie auch als Vorlage
      verwenden, um Ihr eigenes Terminbuchungssystem einzurichten.
    </p>
  </div>
</template>
