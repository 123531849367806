import { CalendarLayer } from "frontend/interfaces/calendar";
import { Interval } from "shared/interfaces/interval";
import { Person } from "frontend/interfaces/person";
import { entriesAttendances } from "frontend/uses/abstract-view/entries/attendances";
import { entriesTmpUnits } from "frontend/uses/abstract-view/entries/tmp-units";
import { entriesUnits } from "frontend/uses/abstract-view/entries/units";
import {
  AbstractViewOptions,
  Database,
  AbstractViewCellPosition,
} from "frontend/uses/abstract-view/use-abstract-view-parser";
import { entriesVisuals } from "frontend/uses/abstract-view/entries/visuals";

export const CONSTANTS = {
  Z_INDICES: {
    HOVERED: 6,
    TMP_UNIT: 5,
    UNIT: 4,
    VISUAL_INDICATORS: 2,
    ATTENDANCE: 1,
  } as const,
} as const;

export function attendances(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarLayer {
  return {
    key: `key--${CONSTANTS.Z_INDICES.ATTENDANCE}`,
    zIndex: CONSTANTS.Z_INDICES.ATTENDANCE,
    entries: entriesAttendances(
      person,
      interval,
      columnInterval,
      cellPosition,
      database,
      options
    ),
  };
}

export function visuals(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarLayer {
  return {
    key: `key--${CONSTANTS.Z_INDICES.VISUAL_INDICATORS}`,
    zIndex: CONSTANTS.Z_INDICES.VISUAL_INDICATORS,
    entries: entriesVisuals(
      person,
      interval,
      columnInterval,
      cellPosition,
      database,
      options
    ),
  };
}

export function units(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarLayer {
  return {
    key: `key--${CONSTANTS.Z_INDICES.UNIT}`,
    zIndex: CONSTANTS.Z_INDICES.UNIT,
    entries: entriesUnits(
      person,
      interval,
      columnInterval,
      cellPosition,
      database,
      options
    ),
  };
}

export function tmpUnits(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarLayer {
  return {
    key: `key--${CONSTANTS.Z_INDICES.TMP_UNIT}`,
    zIndex: CONSTANTS.Z_INDICES.TMP_UNIT,
    entries: entriesTmpUnits(
      person,
      interval,
      columnInterval,
      cellPosition,
      database,
      options
    ),
  };
}
