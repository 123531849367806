<script lang="ts">
  import { computed, defineComponent, onMounted, PropType, ref } from "vue";

  import { Schedule } from "frontend/interfaces/schedule";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { requestSchedule } from "frontend/api/application/request-schedules";
  import { parseSchedule } from "frontend/parser/settings/parse-schedule";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import AttendancesCalendar from "frontend/routes/settings/AttendancesCalendar.vue";
  import AttendancesList from "frontend/routes/settings/AttendancesList.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: {
      BaseButton,
      BaseHelp,
      AttendancesCalendar,
      AttendancesList,
    },
    props: {
      id: {
        type: String as PropType<PrimaryKey>,
        required: true,
      },
    },
    setup(props) {
      const schedule = ref<Schedule | null>(null);
      const loadSchedules = async () => {
        const scheduleRaw = await requestSchedule(props.id);
        if (scheduleRaw) schedule.value = parseSchedule(scheduleRaw.entityRaw);
      };
      onMounted(loadSchedules);

      const attendanceCalendar = ref<boolean>(true);
      const mainSwitchButtons = [
        {
          key: "1",
          label: "Kalender",
          onSubmit: () => {
            attendanceCalendar.value = true;
          },
          isActive: computed(() => attendanceCalendar.value),
        },
        {
          key: "2",
          label: "Liste",
          onSubmit: () => {
            attendanceCalendar.value = false;
          },
          isActive: computed(() => !attendanceCalendar.value),
        },
      ];

      const filterParams = computed(() => ({
        schedule_id: props.id,
      }));

      return {
        schedule,
        attendanceCalendar,
        mainSwitchButtons,
        filterParams,
      };
    },
  });
</script>

<template>
  <div class="settings-schedule">
    <RouterLink
      v-bind:to="{
        name: 'settings-schedules',
      }"
    >
      <i class="fe fe-arrow-left"></i>
    </RouterLink>

    <div v-if="schedule">
      <h2>{{ schedule.name }} bearbeiten</h2>

      <BaseHelp>
        <p>
          Im Rahmen des ausgewählten Zeitplans werde hier Anwesenheiten für
          einzelne Mitarbeiter erstellt. Durch
          <strong>ANKLICKEN</strong> (linke Maustaste) können Sie direkt bei
          einzelnen Mitarbeitern
          <strong>Änderungen bei der Verfügbarkeit</strong>
          vornehmen.
        </p>
        <p>
          Sollte ein Mitarbeiter z.B. krankheitsbedingt ausfallen, könne Sie
          diese unter „Zeitplan/Abwesenheit“ ändern.
        </p>
      </BaseHelp>

      <div class="topbar__attendance-view">
        <BaseButton
          v-for="button in mainSwitchButtons"
          v-bind:key="button.key"
          v-bind:class="{ 'button--inverted': !button.isActive.value }"
          v-on:submit="button.onSubmit()"
        >
          {{ button.label }}
        </BaseButton>
      </div>

      <AttendancesCalendar
        v-if="attendanceCalendar"
        v-bind:filter-params="filterParams"
      />
      <AttendancesList
        v-if="!attendanceCalendar"
        v-bind:filter-params="filterParams"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .topbar__attendance-view {
    text-align: right;
    margin: 20px 0;
  }
</style>
