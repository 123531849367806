export function getMetaTag(name: string): string | null {
  const element = document.querySelector(
    `meta[name="${name}"]`
  ) as HTMLMetaElement | null;
  if (element) {
    return element.getAttribute("content");
  } else {
    return null;
  }
}
