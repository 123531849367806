<script lang="ts" setup>
  import { PropType } from "vue";

  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { Patient } from "frontend/interfaces/patient";

  import PatientSearch from "frontend/components/PatientSearch.vue";
  import PatientProfile from "frontend/components/PatientProfile.vue";

  const props = defineProps({
    ...useAsModalChildProps(),
    selectedPatient: {
      type: Object as PropType<Patient | null>,
      default: null,
    },
  });

  const emit = defineEmits(["update:selectedPatient"]);

  const onPatientSelected = (patient: Patient | null) => {
    emit("update:selectedPatient", patient);
    props.asModalChild?.doClose(true);
  };
</script>

<template>
  <div v-bind:class="{ 'patient-selection--as-modal': asModalChild }">
    <PatientSearch hide-confirm v-on:patient-selected="onPatientSelected" />
    <template v-if="selectedPatient">
      <h5>Ausgewählter Patient</h5>
      <PatientProfile v-bind:patient="selectedPatient" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .patient-selection--as-modal {
    width: 80vw;
    min-height: 80vh;
  }
</style>
