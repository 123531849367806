import { Component, computed, ref } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { api } from "frontend/api/application/settings/request-schedule-activations";
import {
  httpPayloadScheduleActivation,
  newScheduleActivation,
  parseScheduleActivation,
} from "frontend/parser/parse-schedule-activation";
import { ScheduleActivation } from "frontend/interfaces/schedule-activation";
import { Schedule } from "frontend/interfaces/schedule";
import { Cache } from "frontend/utils/request-cache";
import {
  BaseS,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { simpleRow } from "frontend/uses/filter/predefined/flex";
import { simpleCheckboxGroup } from "frontend/uses/filter/predefined/checkbox";
import { PrimaryKey } from "frontend/interfaces/primary-key";

import EditScheduleActivation from "frontend/components/settings/EditScheduleActivation.vue";
import ScheduleActivationComponent from "frontend/components/ShowScheduleActivation.vue";

export class ScheduleActivationsSettingsManager extends SettingsManager<ScheduleActivation> {
  schedules = ref<Schedule[]>([]);
  entityName(): string {
    return "Zeitplan Aktivierung";
  }
  componentForEdit(): Component {
    return EditScheduleActivation;
  }
  craftNewEntity(): ScheduleActivation {
    return newScheduleActivation();
  }
  hookList(): Promise<SettingsListResult<ScheduleActivation>> {
    return genericListPattern(parseScheduleActivation, api.requestIndex);
  }
  hookSave(
    record: ScheduleActivation
  ): Promise<SettingsSaveResult<ScheduleActivation>> {
    return genericSavePattern(
      record,
      parseScheduleActivation,
      httpPayloadScheduleActivation,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: ScheduleActivation): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
  useModal(): boolean {
    return true;
  }
  modalComponent(): Component {
    return ScheduleActivationComponent;
  }
  enableFilter() {
    return true;
  }
  //Filter
  async initFilterOptions(): Promise<void> {
    this.schedules.value = await Cache.getCachedSchedules();
  }
  initFilterDefinitions(): FilterDefinition<ScheduleActivation, BaseS>[] {
    return super.initFilterDefinitions().concat([
      simpleRow([
        simpleCheckboxGroup(
          {
            frontend: (
              entry: ScheduleActivation,
              selectedIDs: Array<PrimaryKey>
            ) =>
              selectedIDs.length === 0 ||
              selectedIDs.indexOf(entry.schedule_id) >= 0,
          },
          {
            label: "Zeitpläne",
            options: computed(() =>
              this.schedules.value.map((p) => ({
                id: p.id,
                label: p.name,
              }))
            ),
          }
        ),
      ]),
    ]);
  }
}
