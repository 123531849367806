import { format } from "date-fns";

import { Interval } from "shared/interfaces/interval";

const CONSTANTS = {
  TIME_FORMAT: {
    AS_LABEL: "HH:mm",
    AS_KEY: "dd.MM HH-mm",
  } as const,
} as const;

export function header(
  key: string,
  label: string
): {
  id: string;
  label: string;
} {
  return {
    id: key,
    label,
  };
}

export function note(
  key: string,
  label: string,
  color = "red",
  widthOffset = 1,
  active = true,
  startsBefore = false,
  endsAfter = false
): {
  id: string;
  label: string;
  color: string;
  widthOffset: number;
  active: boolean;
  startsBefore: boolean;
  endsAfter: boolean;
} {
  return {
    id: key,
    label,
    color,
    widthOffset,
    active,
    startsBefore,
    endsAfter,
  };
}

export function interval(interval: Interval): {
  label: string;
  key: string;
} {
  return {
    label: format(interval.from, CONSTANTS.TIME_FORMAT.AS_LABEL),
    key: `key--${format(interval.from, CONSTANTS.TIME_FORMAT.AS_KEY)}`,
  };
}

export function cell(
  key: string,
  interval: Interval
): {
  key: string;
} {
  return {
    key: `key--${key}--${format(interval.from, CONSTANTS.TIME_FORMAT.AS_KEY)}`,
  };
}
