export interface WizardStep {
  label: string;
  slotName: string;
}

export interface WizardStepRendering extends WizardStep {
  status: WizardStepStatus;
  doSelect: () => void;
  disabled: boolean;
}

export enum WizardStepStatus {
  Normal,
  Active,
  Inactive,
}
