import {
  praxisappBase,
  praxisappPaths,
  applicationBase,
  applicationPaths,
} from "shared/static/paths.ts.erb";
import { pathify } from "shared/utils/pathify";

export const pathTemplates = {
  praxisapp: {
    base: praxisappBase,
    paths: pathify(praxisappBase, praxisappPaths),
  },
  application: {
    base: applicationBase,
    paths: pathify(applicationBase, applicationPaths),
  },
};
