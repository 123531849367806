<script lang="ts">
  import { defineComponent } from "vue";

  import { requestLoginStatus } from "frontend/api/application/request-misc";

  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { BaseButton },
    setup() {
      requestLoginStatus();

      return {
        requestLoginStatus,
      };
    },
  });
</script>

<template>
  <div>
    <h1>Unvollständige Patientendaten</h1>
    <p>
      Bitte aktualisieren Sie Ihre Versicherung in den Einstellungen der App!
    </p>
    <BaseButton
      class="action"
      v-bind:visual-inverted="true"
      v-on:submit="requestLoginStatus"
    >
      weiter zu den Terminen</BaseButton
    >
  </div>
</template>
