<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { requestPatientByExternalId } from "frontend/api/application/request-patients";
  import { router } from "frontend/router";
  import { gdtPatient } from "frontend/parser/parse-patient";

  import PatientMerge from "frontend/components/PatientMerge.vue";

  export default defineComponent({
    components: { PatientMerge },
    props: {
      format: {
        type: String as PropType<string>,
        required: true,
      },
      dateOfBirth: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      firstName: {
        type: String,
        default: null,
      },
      externalId: {
        type: String,
        default: null,
      },
      pvsName: {
        type: String,
        default: null,
      },
      pvsSystemId: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const PatientFromExternalId = async (externalId: string) => {
        const patientId = await requestPatientByExternalId(externalId);
        if (patientId) {
          router.push({ name: "patient-show", params: { id: patientId } });
        }
      };

      PatientFromExternalId(props.externalId);

      const importedPatient = gdtPatient({
        date_of_birth: props.dateOfBirth,
        name: props.name,
        first_name: props.firstName,
        external_id: props.externalId,
        pvs_name: props.pvsName,
        pvs_system_id: props.pvsSystemId,
      });

      return {
        importedPatient,
      };
    },
  });
</script>
<template>
  <div class="plain">
    <PatientMerge
      v-bind:imported-patient="importedPatient"
      v-bind:merge-patient="null"
    />
  </div>
</template>
