import { PathURLTemplate, PathParams } from "shared/utils/pathify";
import {
  HttpHeaders,
  HttpMethod,
  HttpPayload,
  HttpResponse,
} from "frontend/interfaces/http";
import {
  OnRequestHasRawResponse,
  OnRequestImminent,
} from "frontend/events/topics";
import { doRequest, RequestOptions } from "frontend/api/common";

export async function base(
  method: HttpMethod,
  path: PathURLTemplate,
  params: PathParams,
  payload: HttpPayload,
  headers: HttpHeaders,
  options?: RequestOptions
): Promise<HttpResponse> {
  return await doRequest(
    method,
    path,
    params,
    payload,
    headers,
    () => OnRequestImminent.emit({ method, path }),
    (rawResponse) => OnRequestHasRawResponse.emit({ rawResponse, path }),
    false,
    options
  );
}
