import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { useStore } from "frontend/stores/login";
import { UserEntity } from "frontend/interfaces/user";
import { usePatientLogin } from "frontend/uses/use-patient-login";

/**
 * Navigation Guard
 * Checks if the user is authenticated and redirects him if not.
 *
 * @export
 * @param {RouteLocationNormalized} to
 * @returns {(RouteLocationRaw | void)}
 */
export async function navigationGuardRequireAuth(
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | void> {
  const store = useStore();
  if (
    to.meta &&
    to.meta.requiresAuth &&
    !store.isLoggedIn(to.meta.requiresAuth)
  ) {
    if (to.meta.requiresAuth === UserEntity.Patient) {
      // try login
      const { tryRequestLogin } = usePatientLogin();
      if (!(await tryRequestLogin())) {
        return {
          name: "login", // TODO: use dedicated route for failed patient login
        };
      }
    } else {
      return {
        name: "login", // TODO: redirect based on requiresAuth-value to different routes (patient, practice-login)
      };
    }
  }
}
