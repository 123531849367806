import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  AppointmentType,
  AppointmentTypeNew,
  AppointmentTypeRaw,
} from "frontend/interfaces/settings/appointment-type";

export function parseAppointmentType(
  appointmentType: AppointmentTypeRaw
): AppointmentType {
  return {
    ...appointmentType,
    sessions: (appointmentType.sessions || []).map((e) => ({ ...e })),
  };
}

export function httpPayloadAppointmentType(
  appointmentType: AppointmentType
): HttpPayload {
  return {
    ...appointmentType,
    sessions: (appointmentType.sessions || []).map((e) => ({
      ...e,
      roles: JSON.parse(JSON.stringify(e.roles)),
      room_ids: [...e.room_ids],
    })),
  };
}

export function newAppointmentType(): AppointmentTypeNew {
  const result: AppointmentType = {
    id: `MOCK-${uuid()}`,
    name: "",
    configs: 0,
    description_html: "",
    message: "",
    notes: "",
    allow_message: false,
    message_description: "",
    max_booking_count: null,

    age_range_begin_number: 0,
    age_range_begin_unit: "day",
    age_range_end_number: null,
    age_range_end_unit: "day",
    booking_range_begin_number: 0,
    booking_range_begin_unit: "day",
    booking_range_end_number: null,
    booking_range_end_unit: "day",

    billing_perriod_number: null,
    billing_perriod_unit: "day",
    min_distance_to_self_number: null,
    min_distance_to_self_unit: "day",

    order_time_in_minutes: null,

    sessions: [],
  };

  result.sessions.push(newSession(result));

  return { ...result, unsaved: true };
}

export function newSession(appointmentType: AppointmentType) {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    duration: 10,
    position:
      Math.max(0, ...appointmentType.sessions.map((s) => s.position)) + 1,
    color_id: "",
    room_ids: [],
    roles: {},
    unsaved: true,
  };
}
