import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { LocationRaw } from "frontend/interfaces/location";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestLocations(): Promise<
  UnifiedListResponse<LocationRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_locations_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { locations: LocationRaw[] }).locations,
  };
}

export async function updateLocation(
  location: HttpPayload
): Promise<UnifiedResponse<LocationRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_location_path,
    { id: location.id as string },
    location
  );

  return {
    entityRaw: (body as unknown as { location: LocationRaw }).location,
    success: statusIsSuccess,
    errors:
      (body as unknown as { location: { _errors?: ErrorDatabase } }).location
        ._errors || {},
  };
}

export async function createLocation(
  location: HttpPayload
): Promise<UnifiedResponse<LocationRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_locations_path,
    {},
    location
  );

  return {
    entityRaw: (body as unknown as { location: LocationRaw }).location,
    success: statusIsSuccess,
    errors:
      (body as unknown as { location: { _errors?: ErrorDatabase } }).location
        ._errors || {},
  };
}
