<script lang="ts" setup>
  import { computed, nextTick, ref } from "vue";

  type EditingValue = string | null;

  const props = defineProps({
    displayValue: {
      type: String,
      default: null,
    },
  });

  const emit = defineEmits(["valueEdited"]);

  const editing = ref(false);
  const editingValue = ref<EditingValue>(null);
  const inputElement = ref<HTMLElement | null>(null);
  const doStartEditing = async () => {
    if (editing.value) return;

    editing.value = true;
    editingValue.value = props.displayValue;

    await nextTick();
    const input = inputElement.value;
    if (input) input.focus();
  };
  const doStopEditing = () => {
    editing.value = false;
    emit("valueEdited", editingValue.value);
  };

  const inputStyles = computed(() => ({
    width: Math.max(2, (editingValue.value ?? "").length + 1).toString() + "ch",
  }));
</script>

<template>
  <div
    class="inline-editable"
    v-bind:class="{ 'inline-editable--editing': editing }"
    v-on:click.stop="doStartEditing"
  >
    <template v-if="editing"
      ><input
        ref="inputElement"
        v-model="editingValue"
        class="inline-editable__input"
        v-bind:style="inputStyles"
        v-on:blur="doStopEditing"
    /></template>
    <template v-else>
      <template v-if="displayValue">{{ displayValue }}</template>
      <template v-else>&hyphen;</template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .inline-editable {
    display: inline-block;

    cursor: text;

    border: 1px solid colors.$color_inline-editable__border;
    padding: 5px 40px;
  }

  .inline-editable--editing {
    outline: 1px solid colors.$color_inline-editable__outline;
  }

  .inline-editable__input {
    display: inline;

    border: none;
    outline: none;
  }
</style>
