import { routes, doRequest } from "frontend/api/application";
import { HttpMethod, UnifiedListResponse } from "frontend/interfaces/http";
import { PersonRaw } from "frontend/interfaces/p/person";
import { PrimaryKey } from "frontend/interfaces/primary-key";

export async function requestPersons(
  appointment_type_id?: PrimaryKey
): Promise<UnifiedListResponse<PersonRaw>> {
  const params: {
    appointment_type_id?: PrimaryKey;
  } = {};
  if (appointment_type_id) {
    params.appointment_type_id = appointment_type_id;
  }
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_p_persons_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: PersonRaw[] }).entities,
  };
}
