import { computed, ComputedRef, onMounted, Ref, ref, unref, watch } from "vue";

import { inverseEnum } from "frontend/utils/translate-enum";
import { States, StateElement } from "shared/static/state-symbols.ts.erb";

export function useFormSelect<T>(
  initialSelection: FormSelectId,
  options: Ref<Array<FormSelectOption>>,
  actionName: T,
  emit: (event: T, newValue: FormSelectId) => void
): { currentSelection: Ref<FormSelectId> } {
  const currentSelection = ref<FormSelectId>(initialSelection);
  const emitSelection = () => {
    const selection = unref(currentSelection);
    if (selection) {
      // verify that it is a valid option
      for (const option of unref(options) || []) {
        if (option.id === selection) {
          emit(actionName, option.id);
          return;
        }
      }
    }

    // no (valid) option selected
    emit(actionName, null);
  };

  watch(currentSelection, () => {
    emitSelection();
  });
  onMounted(emitSelection);

  return {
    currentSelection,
  };
}

/**
 * Make usage of FormSelect possible with objects
 *
 * @param currentSelection v-model from FormSelect component
 * @param entries entries to use as options
 * @param entryToOption transformer of entries to id-wise unique options
 * @returns
 */
export function useFormSelectOptions<T>(
  currentSelection: Ref<FormSelectId>,
  entries: Ref<Array<T>>,
  entryToOption: (entry: T) => FormSelectOption
): {
  options: ComputedRef<Array<FormSelectOption>>;
  currentOption: ComputedRef<T | null>;
} {
  const options = computed<Array<FormSelectOptionTranslation<T>>>(() => {
    // TODO: maybe check that ids are unique?
    return entries.value.map((entry) => {
      return {
        ...entryToOption(entry),
        original: entry,
      };
    });
  });

  const currentOption = computed<T | null>(() => {
    const currentSelectionUnref = unref(currentSelection);
    if (currentSelectionUnref) {
      for (const option of unref(options)) {
        if (option.id === currentSelectionUnref) {
          return option.original;
        }
      }
    }

    return null;
  });

  return {
    options,
    currentOption,
  };
}

export interface FormSelectOption {
  id: string | number;
  label: string;
}

export type FormSelectId = FormSelectOption["id"] | null;

interface FormSelectOptionTranslation<T> extends FormSelectOption {
  original: T;
}

export function useEnumSelect<Keys extends string, Values extends string>(
  currentSelection: Ref<Keys>,
  selectionEnum: {
    [key in Keys]: Values;
  },
  labelMapping: { [key in Keys]: string }
) {
  const options = useFormSelectOptions(
    currentSelection,
    ref(Object.values(selectionEnum) as Values[]),
    (entry) => {
      const key = inverseEnum(selectionEnum, entry as Values);
      return {
        id: key,
        label: labelMapping[key],
      };
    }
  );

  return {
    ...options,
    currentSelection,
  };
}

export function useStatesFormSelect(): ReturnType<
  typeof useFormSelectOptions<StateElement>
> {
  const states = ref([...States]);

  return useFormSelectOptions(
    ref<FormSelectId | null>(null),
    states,
    (entry) => ({
      id: entry.id,
      label: entry.label,
    })
  );
}
