import { computed, ComputedRef, reactive, ref, Ref, unref } from "vue";

import {
  WizardStep,
  WizardStepRendering,
  WizardStepStatus,
} from "frontend/interfaces/wizard";

type CurrentSelection = Ref<WizardStep | null>;

export function useWizardSteps(
  steps: Ref<Array<WizardStep>>,
  noForwardNavigation: Ref<boolean>,
  noBackwardNavigation: Ref<boolean>
): {
  stepsRendering: ComputedRef<Array<WizardStepRendering>>;
  currentSelection: CurrentSelection;
} {
  const currentSelection: CurrentSelection = ref(null);
  const stepsAsRefs = reactive(unref(steps)); // needed for "isSameStep", see: https://v3.vuejs.org/guide/reactivity.html#proxy-vs-original-identity

  const stepsRendering = computed(() => {
    const result: Array<WizardStepRendering> = [];
    const currentSelectionUnref = unref(currentSelection);

    let seenSelection = false;

    for (const step of unref(stepsAsRefs)) {
      const disableForwardStep = unref(noForwardNavigation) && seenSelection;
      const disableBackwardStep = unref(noBackwardNavigation) && !seenSelection;
      const disableNavigation = disableForwardStep || disableBackwardStep;

      if (currentSelectionUnref && isSameStep(step, currentSelectionUnref))
        seenSelection = true;

      result.push({
        ...step,
        status: calculateStatus(step, currentSelectionUnref, seenSelection),
        doSelect: createSelectorCallback(
          step,
          currentSelection,
          disableNavigation
        ),
        disabled: disableNavigation,
      });
    }
    return result;
  });

  return { stepsRendering, currentSelection };
}

function calculateStatus(
  currentStep: WizardStep,
  currentSelection: WizardStep | null,
  seenSelection: boolean
): WizardStepStatus {
  if (!currentSelection) return WizardStepStatus.Inactive;
  else {
    if (isSameStep(currentStep, currentSelection))
      return WizardStepStatus.Active;
    else if (!seenSelection) return WizardStepStatus.Normal;
    else return WizardStepStatus.Inactive;
  }
}

function createSelectorCallback(
  step: WizardStep,
  currentSelection: CurrentSelection,
  disableSelection: boolean
): () => void {
  return () => {
    if (!disableSelection) currentSelection.value = step;
  };
}

function isSameStep(stepA: WizardStep, stepB: WizardStep): boolean {
  return stepA === stepB;
}
