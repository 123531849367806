import { PropType, Ref, ref } from "vue";

import {
  MinimalEntity,
  SettingsManager,
} from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
import { Errors, useErrors } from "frontend/uses/use-errors";
import { StatusValue, SUCCESS_TIMEOUT } from "frontend/utils/animation-status";
import { useToasts } from "frontend/uses/use-toasts";
import { isIncluded, removeFirstOccurrence } from "frontend/utils/array";
import { FilterBackendParams } from "frontend/uses/filter/filter-definition";
import { ISettingsManager } from "frontend/uses/settings/manager/interface";

export type OnAutosaveFor = (key: string) => () => Promise<boolean>;
export type ErrorFor = (key: string, globalKey?: string) => Errors;
export type StatusFor = (key: string) => StatusValue;

export function useEditComponent<T extends MinimalEntity>(
  entity: T,
  options?: {
    onSaved?: () => void;
    manager?: ISettingsManager<T>;
  }
): {
  entityEditable: Ref<T>;
  saveRecord: () => Promise<boolean>;
  deleteRecord: () => Promise<void>;
  isPending: Ref<boolean>;
  errorFor: ErrorFor;
  statusFor: StatusFor;
  onAutosaveFor: OnAutosaveFor;
  recreateEditable: (entity: T) => void;
} {
  const settingsManager: ISettingsManager<T> =
    options?.manager ?? SettingsManager.inject<T, SettingsManager<T>>();

  const entityEditable = ref<T>(
    settingsManager.createEditable(entity)
  ) as Ref<T>;

  const lastRequestSuccess = ref<boolean | null>(null);
  const lastRequestKeys = ref<Array<string>>([]);
  const { errors, errorFor } = useErrors(null);
  const statusFor = (key: string) => {
    // return StatusValue.Pending;
    if (settingsManager.isPendingR().value) return StatusValue.Pending;
    if (errorFor(key).length > 0) return StatusValue.Failure;
    if (lastRequestSuccess.value && isIncluded(lastRequestKeys.value, key))
      return StatusValue.Success;

    return StatusValue.NoStatus;
  };

  const onAutosaveFor = (key: string) => {
    return async () => {
      lastRequestKeys.value.push(key);

      // remove added key after a defined interval
      setTimeout(() => {
        removeFirstOccurrence(lastRequestKeys.value, key);
      }, SUCCESS_TIMEOUT);

      // save the record
      return !!(await saveRecord());
    };
  };

  const saveRecord = async () => {
    lastRequestSuccess.value = null;
    if (entityEditable.value && settingsManager) {
      const result = await settingsManager.save(entityEditable.value);
      if (result) {
        lastRequestSuccess.value = result.success;
        if (result.entity)
          entityEditable.value = settingsManager.createEditable(result.entity);
        errors.value = result.errors || {};
        if (result.success) useToasts().success("Erfolgreich gespeichert");
        if (result.success && options?.onSaved) options.onSaved();

        return !!result.success;
      }
    }

    return false;
  };

  const deleteRecord = async () => {
    lastRequestSuccess.value = null;
    lastRequestKeys.value = [];
    if (entityEditable.value && settingsManager) {
      if (
        !(
          await useSimpleModal().confirm(
            "Sind Sie sicher, dass Sie den Eintrag löschen wollen?",
            {}
          )
        ).confirm
      )
        return;

      const result = await settingsManager.delete(entityEditable.value);
      if (result && result.success) {
        useToasts().success("Erfolgreich gelöscht");
      }
    }
  };

  return {
    entityEditable,
    saveRecord,
    deleteRecord,
    isPending: settingsManager.isPendingR(),
    errorFor,
    statusFor,
    onAutosaveFor,
    recreateEditable: (entityInner: T) => {
      entityEditable.value = settingsManager.createEditable(entityInner);
    },
  };
}

interface EditProps<T extends MinimalEntity> {
  entity: {
    type: PropType<T>;
    required: true;
  };
}
export function editProps<T extends MinimalEntity>(): EditProps<T> {
  return {
    entity: {
      type: Object as PropType<T>,
      required: true,
    } as const,
  } as const;
}

type SavingMethod<T> = (payload: HttpPayload) => Promise<UnifiedResponse<T>>;
export async function genericSavePattern<RecordRaw, Record>(
  record: Record,
  parser: (recordRaw: RecordRaw) => Record,
  transformer: (record: Record) => HttpPayload,
  creator: SavingMethod<RecordRaw>,
  updator: SavingMethod<RecordRaw>
): Promise<SettingsSaveResult<Record & { just_created: boolean }>> {
  const payload = transformer(record);

  let result = null;
  const create = (record as unknown as { unsaved: boolean }).unsaved;
  if (create) {
    result = await creator(payload);
  } else {
    result = await updator(payload);
  }

  if (result.success) {
    return {
      success: true,
      entity: { ...parser(result.entityRaw), just_created: create },
      errors: result.errors,
    };
  } else {
    return {
      success: false,
      entity: { ...record, just_created: false },
      errors: result.errors,
    };
  }
}

type DeletionMethod = (id: PrimaryKey) => Promise<UnifiedBaseResponse>;
export async function genericDeletePattern<Record>(
  record: Record,
  transformer: (record: Record) => PrimaryKey,
  deletor: DeletionMethod
): Promise<SettingsDeleteResult> {
  if ((record as unknown as { unsaved: boolean }).unsaved) {
    return { success: true, errors: {} };
  }

  const result = await deletor(transformer(record));

  return {
    success: result.success,
    errors: result.errors,
  };
}

type ListMethod<RecordRaw> = (
  filterParams?: FilterBackendParams
) => Promise<UnifiedListResponse<RecordRaw>>;
export async function genericListPattern<RecordRaw, Record>(
  parser: (recordRaw: RecordRaw) => Record,
  listor: ListMethod<RecordRaw>,
  filterParams?: FilterBackendParams
): Promise<SettingsListResult<Record>> {
  const result = await listor(filterParams);

  if (result.success) {
    return {
      success: true,
      errors: result.errors,
      entities: result.entities.map(parser),
    };
  } else {
    return {
      success: false,
      errors: result.errors,
      entities: [],
    };
  }
}
