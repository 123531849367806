import { SettingsProfileEnumCalendarDefaultViews } from "shared/static/model-attributes.ts.erb";
import {
  HttpHeaders,
  HttpMethod,
  HttpPayload,
  HttpResponse,
} from "frontend/interfaces/http";
import { PathURLTemplate, PathParams } from "shared/utils/pathify";
import { pathTemplates } from "shared/static/path-templates";
import { router } from "frontend/router";
import { getMetaTag } from "shared/utils/get-meta-tag";
import { useStore as useLoginStore } from "frontend/stores/login";
import { useToasts } from "frontend/uses/use-toasts";
import { base } from "frontend/api/base";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { RequestOptions, REQUEST_HEADER_CSRF_TOKEN } from "frontend/api/common";

export const RESPONSE_HEADER_OFFICE_STORE = "office-store";
export const RESPONSE_HEADER_PATIENT_STORE = "patient-store";

export interface OfficeStoreHeaders {
  blocked: boolean | null;
  config_status: number | null;
  current_operator_id: PrimaryKey | false | null;
  javascript_version: string;
  calendar_default_view: SettingsProfileEnumCalendarDefaultViews | null;
  feature_flags: Partial<Record<string, boolean>>;
}

export interface PatientStoreHeaders {
  insurance_change_needed: boolean | null;
}

export const routes = pathTemplates.application;

export async function doRequest(
  method: HttpMethod,
  path: PathURLTemplate,
  params: PathParams,
  payload: HttpPayload,
  options?: RequestOptions
): Promise<HttpResponse> {
  const result = await base(
    method,
    path,
    params,
    payload,
    createHeaders(),
    options
  );

  if (result.status == 401) {
    // we got a not-authorized from the backend
    useLoginStore().logout();

    router.push({
      name: "login",
    });
  }

  if (!result.status || result.status >= 500) {
    useToasts().error("Es gab ein unerwartetes Problem mit dem Server");
  }

  return result;
}

function createHeaders(): HttpHeaders {
  return {
    ...getCsrfHeaders(),
  };
}

function getCsrfHeaders(): HttpHeaders {
  const csrfToken = useLoginStore().csrfToken || getMetaTag("csrf-token");

  if (csrfToken) {
    return {
      [REQUEST_HEADER_CSRF_TOKEN]: csrfToken,
    };
  } else {
    return {};
  }
}
