import { defineStore } from "pinia";

import { Unit } from "frontend/interfaces/unit";
import { Attendance } from "frontend/interfaces/settings/attendance";

interface ClipboardStoreState {
  unit: Unit | null;
  attendace: Attendance | null;
}

export const id = "clipboard";

export const useStore = defineStore({
  id,
  state(): ClipboardStoreState {
    return {
      unit: null,
      attendace: null,
    };
  },
  actions: {
    getUnit() {
      return this.unit;
    },
    setUnit(data: Unit) {
      this.unit = data;
    },
    clearUnit() {
      this.unit = null;
    },
    getAttendance() {
      return this.attendace;
    },
    setAttendance(data: Attendance) {
      this.attendace = data;
    },
    clearAttendance() {
      this.attendace = null;
    },
  },
});
