<script lang="ts">
  import { defineComponent } from "vue";

  import { PersonsSettingsManager } from "frontend/uses/settings/manager/persons-settings-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new PersonsSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-persons">
    <h2>Mitarbeiter</h2>

    <BaseHelp>
      <p>
        Vergeben Sie <strong>Namen</strong> für die Mitglieder des Praxisteams,
        (z.B. als Abkürzungen). Diese werden dann als Spalten im Kalender
        dargestellt.
      </p>
      <p>
        Haben Mitarbeiter bestimmte <strong>Rollen/Funktionen</strong> (z.B.
        Blutabnahme, Kardiologie), weisen Sie diese jetzt zu.
      </p>
      <p>
        Wenn Sie möchten, dass Patienten bei der Buchung über die PraxisApp die
        Möglichkeit haben, einen speziellen Arzt auszuwählen, dann fügen Sie den
        Namen des Arztes ein und aktivieren Sie die Option
        <strong>Kann vom Patienten in der PraxisApp ausgewählt werden</strong>.
      </p>
      <p>
        Der Arzt/Mitarbeiter wird dann in der App der Patienten zur Auswahl
        angezeigt.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>

<style lang="scss" scoped>
  .settings-persons__person {
    margin-bottom: 20px;
  }
</style>
