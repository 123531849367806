import { Component, PropType } from "vue";

import { EventEntry } from "frontend/interfaces/event-entry";
import { EVENT_TYPES } from "shared/static/event-types.ts.erb";

import EventPlain from "frontend/components/events/EventPlain.vue";

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- needed to work for webpack + rails-erb-loader, without that it would optimize all imports of event-types.ts.erb and thus not update it
const DUMMY = EVENT_TYPES; //

export function useEventEntryProps() {
  return {
    eventEntry: {
      type: Object as PropType<EventEntry>,
      required: true,
    },
  };
}

export function dataForEventEntry(eventEntry: EventEntry): {
  component: Component;
} {
  switch (eventEntry.event_type) {
    case "login":
    case "appointment_confirm":
      return {
        component: EventPlain,
      };
  }
}
