<script lang="ts">
  import {
    computed,
    defineComponent,
    ref,
    toRefs,
    unref,
    watchEffect,
  } from "vue";
  import { addWeeks, eachDayOfInterval, isValid, parseISO } from "date-fns";

  import { router } from "frontend/router";
  import {
    formatDate,
    DateFormat,
    weekBoundaries,
  } from "shared/utils/date-utils";
  import { useFormCheckbox } from "frontend/uses/use-form-checkbox";
  import {
    AbstractCalendarOptions,
    mainSwitchButtons,
    useAbstractCalendar,
  } from "frontend/uses/use-abstract-calendar";
  import { Cache } from "frontend/utils/request-cache";
  import { Person } from "frontend/interfaces/person";
  import { useWeekView } from "frontend/uses/abstract-view/use-week-view";
  import { useStore as useProfileStore } from "frontend/stores/profile";

  import MiniCalendarAsBox from "frontend/components/MiniCalendarAsBox.vue";
  import AbstractCalendarView from "frontend/routes/calendar/AbstractCalendarView.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";

  export default defineComponent({
    components: {
      MiniCalendarAsBox,
      AbstractCalendarView,
      FormSelectOfficePerson,
    },
    props: {
      day: {
        type: String,
        default: null,
      },
      highlightAppointmentId: {
        type: String,
        default: null,
      },
      person: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const { day, person } = toRefs(props);

      const { sortPersons } = useProfileStore();
      const persons = ref<Person[]>([]);
      const { options: personOptions } = useFormCheckbox(persons, (person) => ({
        id: person.id,
        label: person.name,
      }));

      const computedProperties = {
        dayParsed: computed(() => {
          // try to parse date
          const parsedInitialDate = parseISO(unref(day));
          return isValid(parsedInitialDate) ? parsedInitialDate : null;
        }),

        displayDate: computed(() => {
          const dateUnref = unref(date);
          if (!dateUnref) return "-";
          else {
            const { start, end } = weekBoundaries(dateUnref);
            return `${formatDate(start, DateFormat.CalendarWeek)}, ${formatDate(
              start,
              DateFormat.DateOnly
            )} - ${formatDate(end, DateFormat.DateOnly)}`;
          }
        }),
      };

      const routeToDate = (destination: Date, personID?: string) => {
        router.push({
          name: "calendar-week",
          params: {
            day: formatDate(destination, DateFormat.DateOnlyISO),
          },
          query: {
            person: personID ?? unref(person),
          },
        });
      };
      const routeToPerson = (person: string) => {
        if (date.value) {
          routeToDate(date.value, person);
        }
      };

      // sanitize routing, if we have no persons locally
      // or we have no person in the query
      watchEffect(async () => {
        if (persons.value.length <= 0) {
          persons.value = await Cache.getCachedPersons();
          return;
        }

        if (!props.person) {
          routeToPerson(sortPersons(persons.value)[0].id);
        }
      });

      const {
        calendar,
        date,
        triggerReload: doTriggerReload,
      } = useWeekView(computedProperties.dayParsed, person);
      const options: AbstractCalendarOptions = {
        labelCurrent: "Aktuelle Woche",
        switchButtons: mainSwitchButtons(date),
      };

      return {
        ...computedProperties,

        calendar,
        doTriggerReload,
        options,

        ...useAbstractCalendar(
          date,
          routeToDate,
          (date: Date, directionUp: boolean) =>
            addWeeks(date, directionUp ? 1 : -1)
        ),

        highlightedDays: computed(() =>
          date.value ? eachDayOfInterval(weekBoundaries(date.value)) : []
        ),

        personOptions,
        routeToPerson,
      };
    },
  });
</script>

<template>
  <div class="vue-route__calendar-day-view">
    <AbstractCalendarView
      v-if="calendar"
      v-bind:calendar="calendar"
      v-bind:options="options"
      v-bind:initial-highlight="highlightAppointmentId"
      v-bind:highlighted-days="highlightedDays"
      v-bind:display-date="displayDate"
      context-name="calendar-week-view"
      v-on:refresh-needed="doTriggerReload"
      v-on:navigate:forward="doNavigateForward"
      v-on:navigate:back="doNavigateBack"
      v-on:navigate:current="doNavigateCurrent"
    >
      <template v-slot:topbar>
        <FormSelectOfficePerson
          v-bind:model-value="person"
          visual-small
          visual-inverted
          sort-by-profile
          v-on:update:model-value="routeToPerson"
        />
      </template>
      <template v-slot:sidebar>
        <MiniCalendarAsBox
          v-model="miniCalendarMonth"
          v-bind:selected-days="highlightedDays"
          v-on:date-selected="(dateObj) => doNavigateTo(dateObj.date)"
        />
      </template>
    </AbstractCalendarView>
  </div>
</template>
