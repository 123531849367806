<script lang="ts" setup>
  import { ref } from "vue";
  import { useRouter } from "vue-router";

  import {
    GdtPatient,
    Patient,
    PatientWithAppointments,
  } from "frontend/interfaces/patient";
  import { usePending } from "frontend/uses/use-pending";
  import {
    addUserToPatient,
    mergePatients,
  } from "frontend/api/application/request-patients";
  import { parsePatientWithAppointments } from "frontend/parser/parse-patient";

  import PatientSearch from "frontend/components/PatientSearch.vue";
  import PatientProfile from "frontend/components/PatientProfile.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  const props = defineProps<{
    mergePatient: Patient | null;
    importedPatient: GdtPatient | null;
  }>();
  const emit = defineEmits<{
    (e: "merged:patient", patient: PatientWithAppointments): void;
  }>();

  const destinationPatient = ref<Patient | null>(null);
  const { isPending, doWithPending } = usePending();

  const router = useRouter();

  const doAddUserToPatient = async () =>
    await doWithPending(async () => {
      if (!props.importedPatient || !destinationPatient.value) return;
      const result = await addUserToPatient(
        destinationPatient.value.id,
        props.importedPatient.external_id,
        props.importedPatient.pvs_name,
        props.importedPatient.pvs_system_id
      );
      if (result.success) {
        router.push({
          name: "patient-show",
          params: { id: destinationPatient.value.id },
        });
      }
    });

  const searchExcludedPatient: string[] = [];
  let hidePatientCreation = false;
  if (props.mergePatient !== null) {
    searchExcludedPatient.push(props.mergePatient.id);
    hidePatientCreation = true;
  }

  const doMergePatients = async () =>
    await doWithPending(async () => {
      if (!props.mergePatient || !destinationPatient.value) return;
      const { success, entityRaw: newPatient } = await mergePatients(
        props.mergePatient.id,
        destinationPatient.value.id
      );
      if (success) {
        emit("merged:patient", parsePatientWithAppointments(newPatient));
        router.push({
          name: "patient-show",
          params: {
            id: newPatient.id,
          },
        });
      }
    });
</script>

<template>
  <div class="patient-merge">
    <h2>Patientendatensätze zusammenführen</h2>
    <h3>Quelle</h3>
    <BaseHelp>
      Dieser Datensatz dient als Quelle für das Zusammenführen der Daten.
    </BaseHelp>
    <PatientProfile v-if="importedPatient" v-bind:patient="importedPatient" />
    <PatientProfile v-if="mergePatient" v-bind:patient="mergePatient" />

    <h3>Ziel</h3>

    <template v-if="!destinationPatient">
      <BaseHelp>
        Wählen Sie hier den Datensatz aus, in den der oben genannte
        Patientdatensatz kopiert werden soll. Der Name des Patienten ist
        <strong>vorausgefüllt</strong> und kann geändert werden.
      </BaseHelp>
      <PatientSearch
        v-bind:hide-patient-creation="hidePatientCreation"
        v-bind:imported-patient="importedPatient"
        v-bind:search-query="(mergePatient || importedPatient)?.display_name"
        v-bind:excluded-patients="searchExcludedPatient"
        v-on:patient-selected="(patient) => (destinationPatient = patient)"
      />
    </template>
    <template v-else>
      <BaseHelp>
        Dieser Datensatz dient als Ziel für das Zusammenführen der Daten
      </BaseHelp>
      <PatientProfile v-bind:patient="destinationPatient" />
      <div class="patient-merge__actions">
        <BaseButton
          class="patient-merge__action"
          visual-full-width
          v-on:submit="destinationPatient = null"
          >Anderes Ziel wählen</BaseButton
        >
        <BaseButton
          v-if="mergePatient"
          v-bind:disabled="isPending"
          visual-action
          visual-full-width
          class="patient-merge__action"
          v-on:submit="doMergePatients"
          >Datensätze zusammenführen</BaseButton
        >
        <BaseButton
          v-if="importedPatient"
          v-bind:disabled="isPending"
          visual-action
          visual-full-width
          class="patient-merge__action"
          v-on:submit="doAddUserToPatient"
          >Diesen Patienten mit dem Patienten aus dem PVS verknüpfen</BaseButton
        >
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .patient-merge__actions {
    margin-top: 20px;
  }
  .patient-merge__action {
    margin-bottom: 5px;
  }
</style>
