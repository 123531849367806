import { App, createApp } from "vue";

import { setupRouter } from "frontend/utils/setup-router";
import { setupPinia } from "frontend/utils/setup-pinia";
import { setupModal } from "frontend/utils/setup-modal";
import { setupConsole } from "frontend/utils/setup-console";
import { setupEvents } from "frontend/utils/setup-events";
import { setupTooltips } from "frontend/utils/setup-tooltips";
import { setupContextMenu } from "frontend/utils/setup-context-menu";

import AppRoot from "frontend/AppRoot.vue";

const HOOKS: Array<(app: App<Element>) => void> = [
  setupPinia,
  setupRouter,
  setupModal,
  setupConsole,
  setupEvents,
  setupTooltips,
  setupContextMenu,
];

document.addEventListener("DOMContentLoaded", function () {
  const app = createApp(AppRoot);
  HOOKS.forEach((hook) => hook(app));
  app.mount("#app-root");
});
