import { Modal } from "frontend/utils/modals/modal";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { confirmMoveSuggestion } from "frontend/api/application/request-suggestions";

import AppointmentSuggestionsComponent from "frontend/components/AppointmentSuggestions.vue";

export interface SuggestionsModalData {
  appointmentId: PrimaryKey;
  unitId: PrimaryKey;
  begin: Date;
  beginForPatient: Date;
  notifyPatient: boolean;
  reason: string;
  isLoading: boolean;
}
export class SuggestionsModal extends Modal<
  typeof AppointmentSuggestionsComponent,
  SuggestionsModalData
> {
  protected modalComponent() {
    return AppointmentSuggestionsComponent;
  }

  protected async onClose(
    userConfirmed: boolean,
    closeModal: () => void,
    _forceClose = true
  ): Promise<void> {
    super.onClose(userConfirmed, closeModal, false);

    const data = this.getData();
    if (!data) {
      closeModal();
      return;
    }
    if (userConfirmed) {
      try {
        data.isLoading = true;
        const success = await confirmMoveSuggestion(
          data.appointmentId,
          data.unitId,
          data.notifyPatient,
          data.reason
        );

        // setup success logic in modals
        this.setSuccess(success);

        if (success) {
          closeModal();
        }
      } finally {
        data.isLoading = false;
      }
    } else {
      closeModal();
    }
  }
}
