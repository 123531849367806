<script lang="ts" setup>
  import { PropType } from "vue";

  import { CalendarEntrySettingsAttendance } from "frontend/interfaces/calendar";
  import { AttendancesSettingsManager } from "frontend/uses/settings/manager/attendances-settings-manager";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";

  import EditAttendance from "frontend/components/settings/EditAttendance.vue";

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    entry: {
      type: Object as PropType<CalendarEntrySettingsAttendance>,
      default: null,
    },
    ...useAsModalChildProps(),
  });
  const emit = defineEmits(["refresh-needed"]);
  new AttendancesSettingsManager().provide();

  const onRefreshNeeded = () => {
    props.asModalChild?.doClose(true);
    emit("refresh-needed");
  };
</script>

<template>
  <div>
    <h2>Bearbeiten</h2>
    <EditAttendance
      v-bind:entity="entry.entity"
      use-edit-sync
      v-on:refresh-needed="onRefreshNeeded"
    />
  </div>
</template>
