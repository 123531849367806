import { parseISO } from "date-fns";
import { v4 as uuid } from "uuid";

import {
  Attendance,
  AttendanceNew,
  AttendanceRaw,
} from "frontend/interfaces/attendance";
import {
  formatDate,
  DateFormat,
  today,
  atDefaultStartOf,
  shiftFromLocalToUTC,
  atDefaultEndOf,
} from "shared/utils/date-utils";
import { HttpPayload } from "frontend/interfaces/http";

export function parseAttendance(attendance: AttendanceRaw): Attendance {
  return {
    id: attendance.id,
    name: attendance.name,
    from: parseISO(attendance.from),
    to: parseISO(attendance.to),
    person: attendance.person,
    session_offer_id: attendance.session_offer_id,
    location_id: attendance.location_id,
    schedule_id: attendance.schedule_id,
    person_id: attendance.person_id,
    color: attendance.color,
    configs: attendance.configs,
    days: attendance.days ? attendance.days.map((entry) => entry) : [],
    session_ids: [],
  };
}

export function httpPayloadAttendance(attendance: Attendance): HttpPayload {
  return {
    ...attendance,
    from: formatDate(attendance.from, DateFormat.ISO),
    to: formatDate(attendance.to, DateFormat.ISO),
  };
}

export function newAttendance(): AttendanceNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    from: shiftFromLocalToUTC(atDefaultStartOf(today())),
    to: shiftFromLocalToUTC(atDefaultEndOf(today())),
    person: "",
    session_offer_id: "",
    location_id: "",
    schedule_id: "",
    person_id: "",
    color: null,
    days: [],
    configs: 0,
    session_ids: [],
    unsaved: true,
  };
}
