import { routes, doRequest } from "frontend/api/application";
import { AppointmentRaw } from "frontend/interfaces/appointment";
import {
  HttpMethod,
  HttpPayload,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function createAppointment(
  appointment: HttpPayload
): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_appointments_path,
    {},
    appointment
  );
  return statusIsSuccess;
}

export async function toggleStatusAppointment(
  appointment_id: PrimaryKey,
  status_flags: Record<string, boolean>
): Promise<number> {
  const { body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_appointment_toggle_status_path,
    { appointment_id },
    { appointment: { status_flags } }
  );

  if (body?.appointment && (body.appointment as HttpPayload).status) {
    return (body.appointment as HttpPayload).status as number;
  } else {
    return -1;
  }
}

export async function updateAppointment(
  appointment: HttpPayload
): Promise<UnifiedResponse<AppointmentRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_appointment_path,
    { id: appointment.id as string },
    { appointment: appointment }
  );

  return {
    entityRaw: (body as unknown as { appointment: AppointmentRaw }).appointment,
    success: statusIsSuccess,
    errors:
      (body as unknown as { appointment: { _errors?: ErrorDatabase } })
        .appointment._errors || {},
  };
}
