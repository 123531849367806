import { ComputedRef, Ref } from "vue";

import { useWeekViewParser } from "frontend/uses/abstract-view/use-week-view-parser";
import { WeekViewRaw } from "frontend/interfaces/day-view";
import { Calendar } from "frontend/interfaces/calendar";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { weekBoundaries } from "shared/utils/date-utils";
import { requestWeekView } from "frontend/api/application/request-week-view";
import { useAbstractView } from "frontend/uses/abstract-view/use-abstract-view";

export function useWeekView(
  day: Ref<Date | null>,
  personID: Ref<PrimaryKey | null>,
  options?: {
    searchRef: Ref<PrimaryKey | null>;
  }
): {
  date: ComputedRef<Date | null>;
  calendar: Ref<Calendar | null>;
  triggerReload: () => void;
} {
  return useAbstractView(
    day,
    personID,
    (date: Date, weekView: WeekViewRaw) =>
      useWeekViewParser(date, weekView, personID.value),
    async (date, searchRef, personID) => {
      if (!personID) return null;
      const { start, end } = weekBoundaries(date);
      const { weekView } = await requestWeekView(start, end, personID, null);
      return weekView;
    },
    options
  );
}
