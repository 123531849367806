type TargetValues = string | number | null;

export function stringifyObject<T extends string>(
  obj: Record<T, TargetValues>
): Record<T, string> {
  return Object.fromEntries(
    Object.entries<TargetValues>(obj)
      .map(([key, value]) => [key, value ? value.toString() : null])
      .filter(([_, value]) => !!value)
  ) as unknown as Record<T, string>; // assert type as it is correct
}
