import { App } from "vue";
import { mount, MountResult } from "mount-vue-component";
import { computePosition, flip, shift } from "@floating-ui/dom";

import { PointElement } from "shared/utils/context-menu/point-element";
import { ContextMenuEntry } from "shared/utils/context-menu/interfaces";
import {
  CalendarCell,
  CalendarRelativeLocation,
} from "frontend/interfaces/calendar";

import ContextMenuComponent from "shared/utils/context-menu/components/ContextMenu.vue";

export class ContextMenu {
  public constructor(app: App) {
    this.app = app;
  }

  public setEntries(entries: Array<ContextMenuEntry>): void {
    this.entries = entries;
  }

  public setEventPosition(position: CalendarRelativeLocation | null): void {
    this.eventPosition = position;
  }

  public setEventElement(element: CalendarCell | null): void {
    this.eventElement = element;
  }

  public async show(clientX: number, clientY: number): Promise<void> {
    this.destroy(); // if we are already showing this context-menu somewhere
    this.createVueComponent();
    await this.positionVueComponent(clientX, clientY);
  }

  public isShown(): boolean {
    return !!this.internalComponent;
  }

  public destroy(): void {
    if (this.internalComponent) {
      this.internalComponent.destroy(); // destroy component
      this.internalComponent.el.remove(); // destroy wrapper div
    }

    this.internalComponent = null;
  }

  private app: App;
  private internalComponent: MountResult | null = null;
  private entries: Array<ContextMenuEntry> = [];
  private eventPosition: CalendarRelativeLocation | null = null;
  private eventElement: CalendarCell | null = null;

  private createVueComponent(): void {
    if (this.internalComponent)
      throw new Error("internalComponent already exists");

    // create new component instance
    this.internalComponent = mount(ContextMenuComponent, {
      app: this.app,
      props: {
        entries: this.entries,
        api: {
          doClose: () => this.destroy(),
          eventPosition: this.eventPosition,
          eventElement: this.eventElement,
        },
      },
    });

    // initial styling of new element
    // see: https://floating-ui.com/docs/computePosition#usage
    this.internalComponent.el.style.position = "absolute";
    this.internalComponent.el.style.width = "max-content";
    this.internalComponent.el.style.top = "0";
    this.internalComponent.el.style.left = "0";

    // additional styling
    this.internalComponent.el.style.zIndex = "999"; // TODO: unify z-indices

    // mount wrapper div to body
    document.body.append(this.internalComponent.el);
  }

  private async positionVueComponent(
    clientX: number,
    clientY: number
  ): Promise<void> {
    if (!this.internalComponent)
      throw new Error("internalComponent is not existant");

    // compute position of wrapper
    const position = await computePosition(
      new PointElement(clientX, clientY),
      this.internalComponent.el,
      {
        placement: "bottom-start",
        middleware: [shift(), flip()],
      }
    );

    // adjust styling of wrapper
    Object.assign(this.internalComponent.el.style, {
      position: position.strategy,
      left: `${position.x}px`,
      top: `${position.y}px`,
    });
  }
}
