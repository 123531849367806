<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    toRefs,
  } from "vue";

  import { Color } from "frontend/interfaces/color";
  import { Cache } from "frontend/utils/request-cache";
  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { calculatContrastColorCSS } from "frontend/uses/use-color-picker";
  import { StatusValue } from "frontend/utils/animation-status";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  export default defineComponent({
    components: { AnimationStatus },
    props: {
      modelValue: {
        type: String as PropType<string | null>,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
      allowBlank: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:model-value", "autosave"],
    setup(props, { emit }) {
      const { modelValue } = toRefs(props);
      const availableColors = ref<Array<Color>>([]);
      const loadColors = async () => {
        availableColors.value = await Cache.getCachedColors();
      };
      onMounted(loadColors);
      const currentColor = computed<Color | null>(() => {
        for (const color of availableColors.value) {
          if (color.id === modelValue.value) return color;
        }
        return null;
      });
      const { pairing } = useFormLabelling();
      const doSelectColor = (color: Color) => {
        // if we allow blanks and the clicked color is currently selected --> unselect
        emit(
          "update:model-value",
          props.allowBlank && color.id === modelValue.value ? null : color.id
        );
        emit("autosave");
      };
      return {
        availableColors,
        currentColor,
        doSelectColor,
        pairing,
        //libs
        calculatContrastColorCSS,
      };
    },
  });
</script>

<template>
  <div class="form-office-color">
    <div class="form-office-color__label-and-status">
      <label v-if="label" class="form-office-color__label" v-bind:for="pairing">
        {{ label }}
      </label>
      <AnimationStatus
        v-if="animationStatus !== null"
        class="form-input__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <div v-bind:id="pairing" class="form-office-color__container">
      <div
        v-for="color in availableColors"
        v-bind:key="color.id"
        class="form-office-color__preview"
        v-bind:style="{ 'background-color': color.value }"
        v-bind:title="color.name"
        v-on:click="doSelectColor(color)"
      >
        <i
          v-if="color === currentColor"
          v-bind:style="{ color: calculatContrastColorCSS(color.value) }"
          class="fe fe-checkmark form-office-color__preview-icon--selected"
        ></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .form-office-color__label-and-status {
    display: flex;
  }

  .form-office-color__label {
    @include label.label;

    display: block;
  }

  .form-office-color__container {
    display: flex;

    align-items: center;

    margin-top: 5px;
  }

  .form-office-color__preview {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    margin-right: 15px;

    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .form-office-color__preview-icon--selected {
    font-size: 20px;
  }
</style>
