import { reactive, Ref } from "vue";

import {
  FilterBackendParams,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { FormCheckboxOption } from "frontend/uses/use-form-checkbox";

import FilterCheckboxGroup from "frontend/components/filter/FilterCheckboxGroup.vue";
import FilterCheckbox from "frontend/components/filter/FilterCheckbox.vue";

export function simpleCheckbox<T>(
  filter: {
    frontend?: (entry: T, checked: boolean) => boolean;
    backend?: (checked: boolean) => FilterBackendParams;
  },
  options: { label: string },
  initialChecked = false
): FilterDefinition<T, typeof FilterCheckbox> {
  const { frontend, backend } = filter;

  return {
    component: FilterCheckbox,
    frontendFilter: frontend
      ? (entry, state) => frontend(entry, state.filterChecked)
      : undefined,
    backendFilter: backend
      ? (state) => backend(state.filterChecked)
      : undefined,
    initialFilterState: () =>
      reactive({
        filterChecked: initialChecked,
      }),
    filterOptions: reactive({ label: options.label }),
  };
}

export function simpleCheckboxGroup<T>(
  filter: {
    frontend?: (entry: T, selectedIDs: Array<PrimaryKey>) => boolean;
    backend?: (selectedIDs: Array<PrimaryKey>) => FilterBackendParams;
  },
  options: {
    label: string;
    options:
      | Readonly<Ref<Array<FormCheckboxOption>>>
      | Array<FormCheckboxOption>;
  },
  initialSelectedIDs: Array<PrimaryKey> | Ref<Array<PrimaryKey>> = []
): FilterDefinition<T, typeof FilterCheckboxGroup> {
  const { frontend, backend } = filter;

  return {
    component: FilterCheckboxGroup,
    frontendFilter: frontend
      ? (entry, state) => frontend(entry, state.selectedIDs)
      : undefined,
    backendFilter: backend ? (state) => backend(state.selectedIDs) : undefined,
    initialFilterState: () => reactive({ selectedIDs: initialSelectedIDs }),
    filterOptions: reactive({ ...options }),
  };
}
