import { routes, doRequest } from "frontend/api/application";
import { HttpMethod, UnifiedListResponse } from "frontend/interfaces/http";
import { InsuranceRaw } from "frontend/interfaces/insurance";

export async function requestInsurances(): Promise<
  UnifiedListResponse<InsuranceRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_insurances_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: InsuranceRaw[] }).entities,
  };
}
