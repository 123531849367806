<script lang="ts" setup>
  import { nextTick, PropType } from "vue";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      default: null,
    },
  });

  const emit = defineEmits([
    "content:visibility-change",
    "content:show",
    "content:hide",
  ]);
  const onCheckboxClicked = async (checkboxIsChecked: boolean) => {
    if (checkboxIsChecked) {
      emit("content:show");
    } else {
      emit("content:hide");
    }
    await nextTick();
    emit("content:visibility-change", checkboxIsChecked);
  };
</script>

<template>
  <div class="checkbox-accordion">
    <FormCheckbox
      v-bind:label="label"
      v-bind:model-value="show"
      v-on:update:model-value="onCheckboxClicked"
    />
    <slot v-if="show"></slot>
  </div>
</template>
