import { Ref, ref } from "vue";

import { AppointmentType } from "frontend/interfaces/p/appointment-type";
import { requestAppointmentTypes } from "frontend/api/application/p/request-appointment-types";
import { parseAppointmentType } from "frontend/parser/p/parse-appointment-type";

export function useAppointmentTypes(): {
  appointmentTypes: Ref<Array<AppointmentType>>;
  reloadAppointmentTypes: () => Promise<void>;
} {
  const appointmentTypes = ref<Array<AppointmentType>>([]);

  const reloadAppointmentTypes = async () => {
    const data = await requestAppointmentTypes();
    appointmentTypes.value = data.appointment_types.map(parseAppointmentType);
  };

  return { appointmentTypes, reloadAppointmentTypes };
}
