import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import { PersonRaw, Person, PersonNew } from "frontend/interfaces/person";
import { parseAttendance } from "frontend/parser/parse-attendance";

export function parsePerson(person: PersonRaw): Person {
  return {
    id: person.id,
    name: person.name,
    shown_name: person.shown_name,
    selectable: !!person.selectable,
    attendances: person.attendances
      ? person.attendances.map(parseAttendance)
      : [],
    role_ids: person.role_ids ? person.role_ids.map((entry) => entry) : [],
  };
}

export function httpPayloadPerson(person: Person): HttpPayload {
  return {
    id: person.id,
    name: person.name,
    shown_name: person.shown_name,
    selectable: person.selectable,
    role_ids: person.role_ids.map((entry) => entry),
  };
}

export function newPerson(): PersonNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    shown_name: null,
    selectable: false,
    attendances: [],
    role_ids: [],
    unsaved: true,
  };
}

export function isPerson(personCandidate: unknown): personCandidate is Person {
  const person = personCandidate as Person; // type assertion for check below

  return (
    !!person &&
    typeof person === "object" &&
    typeof person.name === "string" &&
    typeof person.id === "string" &&
    (!person.shown_name || typeof person.shown_name === "string") &&
    typeof person.selectable === "boolean" &&
    Array.isArray(person.attendances) &&
    (!person.role_ids ||
      (Array.isArray(person.role_ids) &&
        person.role_ids.every((ele) => typeof ele === "string")))
  );
}
