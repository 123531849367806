import { compareDesc, parseISO } from "date-fns";
import { v4 as uuid } from "uuid";

import {
  PatientRaw,
  Patient,
  PatientNew,
  PatientWithAppointmentsRaw,
  PatientWithAppointments,
  GdtPatientRaw,
  GdtPatient,
} from "frontend/interfaces/patient";
import { DateFormat, formatDate, today } from "shared/utils/date-utils";
import { HttpPayload } from "frontend/interfaces/http";

export function parsePatient(patientRaw: PatientRaw): Patient {
  return {
    ...patientRaw,
    current_pvs_features: patientRaw.current_pvs_features
      ? [...patientRaw.current_pvs_features]
      : null,
    date_of_birth: parseISO(patientRaw.date_of_birth),
  };
}

export function parsePatientWithAppointments(
  patientWARaw: PatientWithAppointmentsRaw
): PatientWithAppointments {
  const { appointments, ...patient } = patientWARaw;

  return {
    ...parsePatient(patient),
    appointments: appointments
      .map(parseAppointment)
      .sort((a, b) => compareDesc(a.begin, b.begin)),
  };
}

function parseAppointment(
  appointment: PatientWithAppointmentsRaw["appointments"][number]
): PatientWithAppointments["appointments"][number] {
  return {
    ...appointment,
    begin: parseISO(appointment.begin),
  };
}

export function httpPayloadPatient(patient: Patient): HttpPayload {
  return {
    ...patient,
    date_of_birth: patient.date_of_birth
      ? formatDate(patient.date_of_birth, DateFormat.ISO)
      : null,
    versicherungsnummer: patient.kvnr,
  };
}

export function gdtPatient(rawPatient: GdtPatientRaw): GdtPatient {
  // TODO: move to a helper
  const dob = rawPatient.date_of_birth;
  const dob2 = dob.slice(4, 8) + "-" + dob.slice(2, 4) + "-" + dob.slice(0, 2);
  const parsedDob = parseISO(dob2);
  return {
    ...rawPatient,
    kvnr: "",
    insurance_name: "",
    date_of_birth: parsedDob,
    human_age: formatDate(parsedDob, DateFormat.DateOnly),
    display_name: rawPatient.first_name + " " + rawPatient.name,
  };
}

export function newPatient(imported_patient?: GdtPatient | null): PatientNew {
  return {
    id: `MOCK-${uuid()}`,
    name: imported_patient?.name || "",
    first_name: imported_patient?.first_name || "",
    display_name: "",
    date_of_birth: imported_patient?.date_of_birth || today(),
    human_age: "0 Wochen",
    insurance_name: "",
    insurance_id: "",
    kvnr: "",
    is_praxisapp: false,
    current_pvs_id: null,
    current_pvs_features: null,
    is_private_insurance: false,

    unsaved: true,
  };
}

export function duplicatePatient(patient: Patient): Patient {
  return { ...patient };
}
