<script lang="ts">
  import { defineComponent } from "vue";

  import { LocationsSettingsManager } from "frontend/uses/settings/manager/locations-settings-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new LocationsSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-locations">
    <h2>Standort</h2>

    <BaseHelp>
      Geben Sie die Adresse Ihrer Praxis ein. Zukünftig wird es hier möglich
      sein, mehrere Standorte anzulegen.
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>

<style lang="scss" scoped>
  .settings-locations__location {
    margin-bottom: 20px;
  }
</style>
