<script lang="ts">
  import { defineComponent, ref, toRefs, watch } from "vue";

  import { UnifiedResponse } from "frontend/interfaces/http";
  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Pvs } from "frontend/interfaces/settings/pvs";
  import { updateConfiguration } from "frontend/api/application/settings/request-pvs";

  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: {
      FormInput,
    },
    props: {
      ...editProps<Pvs["configurations"][number]>(),
    },
    emits: {
      "update:config": (_config: UnifiedResponse<Pvs>) => true,
    },
    setup(props, { emit }) {
      const isPending = ref<boolean>(false);

      const editComponent = useEditComponent(props.entity, {
        manager: {
          isPendingR: () => isPending,
          createEditable: (record) => ({ ...record }),
          save: async (record) => {
            try {
              isPending.value = true;
              const response = await updateConfiguration(
                record.id,
                record.name,
                record.pvs_executable_path,
                record.pvs_username,
                record.pvs_password
              );

              if (response.success) {
                emit("update:config", response);
              }

              return {
                entity: response.entityRaw.configurations.filter(
                  (configuration) => configuration.id === record.id
                )[0],
                success: response.success,
                errors: response.errors,
              };
            } finally {
              isPending.value = false;
            }
          },
          delete: async () => {
            return;
          },
        },
      });

      watch(toRefs(props).entity, () => {
        editComponent.recreateEditable(props.entity);
      });

      const numberOfInstallationsFor = (
        configuration: Pvs["configurations"][number]
      ) =>
        configuration.access_tokens.filter((element) => !element.intermediate)
          .length;

      const hostnamesFor = (configuration: Pvs["configurations"][number]) =>
        configuration.access_tokens
          .filter((element) => !element.intermediate)
          .map((element) =>
            element.client_info?.hostname
              ? `${element.client_info?.hostname}` +
                (element.client_info?.version
                  ? ` (v${element.client_info?.version})`
                  : "")
              : null
          )
          .filter((element): element is string => typeof element === "string");

      const linkFor = (url: string, os: string, arch: string) => {
        const urlParsed = new URL(url);
        const params = urlParsed.searchParams;
        params.set("os", os);
        params.set("arch", arch);

        return urlParsed.toString();
      };

      return {
        ...editComponent,
        numberOfInstallationsFor,
        hostnamesFor,
        linkFor,
      };
    },
  });
</script>

<template>
  <div class="edit-pvs-configuration">
    <h4 class="headline">{{ entityEditable.name ?? "Konfiguration" }}</h4>
    <div class="configuration__form">
      <div class="configuration__form-row">
        <div class="configuration__form-label">
          Name der Konfiguration <span class="red">*</span>
        </div>
        <div class="configuration__form-input">
          <FormInput
            v-model="entityEditable.name"
            v-bind:errors="errorFor('name')"
            v-bind:animation-status="statusFor('name')"
            v-on="{ autosave: onAutosaveFor('name') }"
          />
        </div>
      </div>
      <div
        v-if="
          entityEditable.configuration_properties &&
          entityEditable.configuration_properties.indexOf(
            'pvs_executable_path'
          ) >= 0
        "
        class="configuration__form-row"
      >
        <div class="configuration__form-label">
          Pfad zum PVS <span class="red">*</span>
        </div>
        <div class="configuration__form-input">
          <FormInput
            v-model="entityEditable.pvs_executable_path"
            v-bind:errors="errorFor('pvs_executable_path')"
            v-bind:animation-status="statusFor('pvs_executable_path')"
            v-on="{ autosave: onAutosaveFor('pvs_executable_path') }"
          />
        </div>
      </div>
      <div
        v-if="
          entityEditable.configuration_properties &&
          entityEditable.configuration_properties.indexOf('pvs_username') >= 0
        "
        class="configuration__form-row"
      >
        <div class="configuration__form-label">
          Nutzername für PVS <span class="red">*</span>
        </div>
        <div class="configuration__form-input">
          <FormInput
            v-model="entityEditable.pvs_username"
            v-bind:errors="errorFor('pvs_username')"
            v-bind:animation-status="statusFor('pvs_username')"
            v-on="{ autosave: onAutosaveFor('pvs_username') }"
          />
        </div>
      </div>
      <div
        v-if="
          entityEditable.configuration_properties &&
          entityEditable.configuration_properties.indexOf('pvs_password') >= 0
        "
        class="configuration__form-row"
      >
        <div class="configuration__form-label">
          Passwort für PVS <span class="red">*</span>
        </div>
        <div class="configuration__form-input">
          <FormInput
            v-model="entityEditable.pvs_password"
            label=""
            type="password"
            v-bind:errors="errorFor('pvs_password')"
            v-bind:animation-status="statusFor('pvs_password')"
            v-on="{ autosave: onAutosaveFor('pvs_password') }"
          />
        </div>
      </div>
    </div>

    <div class="configuration__active-installations">
      Aktive Installationen: {{ numberOfInstallationsFor(entityEditable) }}
      <template v-if="hostnamesFor(entityEditable).length > 0">
        <ul>
          <li
            v-for="hostname in hostnamesFor(entityEditable)"
            v-bind:key="hostname"
          >
            &check; {{ hostname }}
          </li>
        </ul>
      </template>
    </div>

    <div class="buttons">
      <a
        class="button"
        v-bind:href="linkFor(entityEditable.download_url, 'windows', 'x86_64')"
      >
        Download (Windows x86_64)
      </a>
      <a
        class="button"
        v-bind:href="linkFor(entityEditable.download_url, 'linux', 'x86_64')"
      >
        Download (Linux x86_64)
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";

  .edit-pvs-configuration {
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 20px;
    background-color: #f1f1f1;
  }

  .button {
    padding: 7px 20px;
    color: colors.$color_button--text;
    background-color: colors.$color_button--background;
    border: none;
    border-radius: dimensions.$dimension_button--border-radius;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;

    margin-right: 10px;

    &:hover {
      background-color: color.adjust(
        colors.$color_button--background,
        $lightness: -5%
      );
    }
  }

  .headline {
    margin: 0;
  }

  .configuration__form {
    margin: 30px 0;
  }

  .configuration__form-row {
    display: flex;
    flex-flow: row wrap;
  }

  .configuration__form-label {
    width: 200px;
    align-self: center;
  }

  .configuration__form-input {
    flex: 1;
  }

  .buttons {
    text-align: center;
    margin: 30px 0;
  }

  ul {
    list-style: none;
    padding-left: 10px;
  }

  .configuration__active-installations {
    color: #0000ff;
    margin-top: 10px;
    font-size: 14px;
  }

  .red {
    color: #ff0000;
  }
</style>
