import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import {
  httpPayloadAppointmentType,
  newAppointmentType,
  parseAppointmentType,
} from "frontend/parser/settings/parse-appointment-type";
import { AppointmentType } from "frontend/interfaces/settings/appointment-type";
import { api } from "frontend/api/application/settings/request-appointment-types";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { sortAppointmentTypes } from "frontend/api/application/request-appointment-types";
import { useToasts } from "frontend/uses/use-toasts";

import EditAppointmentType from "frontend/components/settings/EditAppointmentType.vue";

export class AppointmentTypesSettingsManager extends SettingsManager<AppointmentType> {
  entityName(): string {
    return "Termintyp";
  }
  componentForEdit(): Component {
    return EditAppointmentType;
  }
  craftNewEntity(): AppointmentType {
    return newAppointmentType();
  }
  hookList(): Promise<SettingsListResult<AppointmentType>> {
    return genericListPattern(parseAppointmentType, api.requestIndex);
  }
  hookSave(
    record: AppointmentType
  ): Promise<SettingsSaveResult<AppointmentType>> {
    return genericSavePattern(
      record,
      parseAppointmentType,
      httpPayloadAppointmentType,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: AppointmentType): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
  enableDragAndDrop() {
    return true;
  }
  async sortEntities(entity_ids: PrimaryKey[]) {
    const result = await sortAppointmentTypes(entity_ids);

    if (result.success) {
      useToasts().success("Erfolgreich gespeichert");
      return true;
    } else {
      useToasts().error("Es gab ein unerwartetes Problem mit dem Server");
      return false;
    }
  }
}
