<script lang="ts" setup>
  import { computed, ref, unref } from "vue";
  import { ModalsContainer } from "vue-final-modal";

  import { ElementProvider } from "frontend/utils/provider";

  import BaseHeader from "frontend/components/base/BaseHeader.vue";

  const component = ref(null);
  const element = computed<Element | null>(() => {
    return (unref(component) || { $el: null }).$el;
  });
  new ElementProvider(
    ElementProvider.provisions().baseHeader,
    element
  ).provide();
</script>

<template>
  <div class="vue-route__index">
    <BaseHeader ref="component" visual-fixed />
    <!-- placeholder for margins at the top of the page -->
    <BaseHeader />
    <main
      class="main"
      v-bind:class="{
        'main--skip-padding': $route.meta.skipPadding,
      }"
    >
      <RouterView />
    </main>
    <ModalsContainer></ModalsContainer>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/variables";

  .vue-route__index {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;

    // visual style
    background-color: colors.$color_main-area--background;
    color: colors.$color_main-area--text;
  }

  .main--skip-padding {
    padding: 0;
  }
</style>

<style lang="scss">
  // TODO: Remove this, as it affects global style
  .router-link-active {
    font-weight: bold;
  }
</style>
