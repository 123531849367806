import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { api } from "frontend/api/application/settings/request-operators";
import {
  httpPayloadOperator,
  newOperator,
  parseOperator,
} from "frontend/parser/settings/parse-operator";
import { Operator } from "frontend/interfaces/settings/operator";

import EditOperator from "frontend/components/settings/EditOperator.vue";

export class OperatorsManager extends SettingsManager<Operator> {
  entityName(): string {
    return "Praxisanwender";
  }
  componentForEdit(): Component {
    return EditOperator;
  }
  craftNewEntity(): Operator {
    return newOperator();
  }
  hookList(): Promise<SettingsListResult<Operator>> {
    return genericListPattern(parseOperator, api.requestIndex);
  }
  hookSave(record: Operator): Promise<SettingsSaveResult<Operator>> {
    return genericSavePattern(
      record,
      parseOperator,
      httpPayloadOperator,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: Operator): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
}
