import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { AbsenceRaw } from "frontend/interfaces/settings/absence";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestAbsences(): Promise<AbsenceRaw[]> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_settings_absences_path,
    {},
    {}
  );
  if (statusIsSuccess) {
    return (body as unknown as { absences: AbsenceRaw[] }).absences;
  } else {
    return [];
  }
}

export async function createAbsence(
  absence: HttpPayload
): Promise<UnifiedResponse<AbsenceRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_settings_absences_path,
    {},
    { absence }
  );

  return {
    entityRaw: (body as unknown as { entity: AbsenceRaw }).entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}

export async function previewAbsence(
  absence: HttpPayload
): Promise<UnifiedResponse<AbsenceRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.preview_frontend_settings_absences_path,
    {},
    { absence }
  );

  return {
    entityRaw: (body as unknown as { entity: AbsenceRaw }).entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}
