<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { UnitWithoutAppointment } from "frontend/interfaces/unit";
  import { formatDate, DateFormat } from "shared/utils/date-utils";

  export default defineComponent({
    props: {
      unit: {
        type: Object as PropType<UnitWithoutAppointment>,
        required: true,
      },
    },
    setup() {
      return {
        // libs
        formatDate,
        DateFormat,
      };
    },
  });
</script>

<template>
  <div class="show-simple-unit">
    <div
      class="show-simple-unit__badge"
      v-bind:style="{ 'background-color': unit.effective_color_hex }"
    ></div>
    <div class="show-simple-unit__infos">
      <div>
        <div class="info__label">Von</div>
        <div class="info__value">
          {{ formatDate(unit.from, DateFormat.DateAndTime) }}
        </div>
      </div>
      <div>
        <div class="info__label">Bis</div>
        <div class="info__value">
          {{ formatDate(unit.to, DateFormat.DateAndTime) }}
        </div>
      </div>
      <div
        v-for="participation in unit.participations"
        v-bind:key="participation.id"
      >
        <div class="info__label">Mitarbeiter</div>
        <div class="info__value">
          {{ participation.person_name }}
        </div>
      </div>
      <div v-if="unit.room">
        <div class="info__label">Raum</div>
        <div class="info__value">
          {{ unit.room.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/mixins/label";

  .show-simple-unit {
    display: flex;

    border: 1px solid colors.$color_input--border;
  }

  .show-simple-unit__badge {
    width: 5px;
  }

  .show-simple-unit__infos {
    padding: 0 10px;
    padding-bottom: 5px;
  }

  .info__label {
    @include label.label;
  }
</style>
