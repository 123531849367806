import { RouteLocation, RouteLocationRaw } from "vue-router";

import { SettingsProfileEnumCalendarDefaultViews } from "shared/static/model-attributes.ts.erb";
import { useStore as useProfileStore } from "frontend/stores/profile";
import { today, formatDate, DateFormat } from "shared/utils/date-utils";

export const RedirectCalendar = (_to: RouteLocation): RouteLocationRaw => {
  const profileStore = useProfileStore();
  return {
    name: routeNameByCalendarView(profileStore.profile.calendar_default_view),
  };
};

export const RedirectCalendarDayToday = {
  name: "calendar-day",
  params: {
    day: formatDate(today(), DateFormat.DateOnlyISO),
  },
};

export const RedirectCalendarWeekCurrent = {
  name: "calendar-week",
  params: {
    day: formatDate(today(), DateFormat.DateOnlyISO),
  },
};

function routeNameByCalendarView(
  calendarDefaultView: SettingsProfileEnumCalendarDefaultViews
): string {
  switch (calendarDefaultView) {
    case SettingsProfileEnumCalendarDefaultViews.week:
      return "calendar-week-base";
    case SettingsProfileEnumCalendarDefaultViews.day:
      return "calendar-day-base";
  }
}
