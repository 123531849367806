import {
  getISODay,
  isSameDay,
  isSameMinute,
  isWithinInterval,
  startOfDay,
} from "date-fns";

import { Calendar } from "frontend/interfaces/calendar";
import { WeekViewRaw } from "frontend/interfaces/day-view";
import { parseUnit, parseUnitInterval } from "frontend/parser/parse-unit";
import { parsePerson } from "frontend/parser/parse-person";
import { mergeInterval } from "frontend/utils/base-calendar-utils";
import { header, note } from "frontend/uses/abstract-view/keys-and-labels";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import {
  DateFormat,
  daysBeween,
  formatDate,
  weekBoundaries,
} from "shared/utils/date-utils";
import { useAbstractViewParser } from "frontend/uses/abstract-view/use-abstract-view-parser";
import { useStore as useProfileStore } from "frontend/stores/profile";
import { parseHoliday } from "frontend/parser/settings/parse-holiday";

export function useWeekViewParser(
  date: Date,
  weekView: WeekViewRaw,
  personID: PrimaryKey | null
): Calendar {
  const profileStore = useProfileStore();

  const dates = daysBeween(weekBoundaries(date));
  const units = dates
    .map((date) =>
      weekView.units
        .map((unitRaw) => ({
          unitRaw,
          ...parseUnitInterval(unitRaw),
        }))
        .filter(
          ({ from, to }) =>
            isSameDay(date, from) ||
            (isWithinInterval(date, { start: from, end: to }) &&
              !isSameMinute(startOfDay(date), to))
        )
        .map(({ unitRaw }) => parseUnit(unitRaw, date))
    )
    .flat();
  const persons = weekView.persons
    .filter((person) => person.id === personID)
    .map(parsePerson);

  const pivotSelector = (dates: Date[]): Date[] =>
    dates.filter(
      (date: Date) =>
        profileStore.profile.calendar_shown_weekdays.indexOf(getISODay(date)) >=
        0
    );

  return useAbstractViewParser<Date>(
    {
      units,
      dates,
      persons,
    },
    {
      calculateColumnPivots: (database, _options) =>
        pivotSelector(database.dates),
      calculateColumnHeader: (date, _database, _options) =>
        header(
          `day-${formatDate(date, DateFormat.DateOnlyISO)}`,
          formatDate(date, DateFormat.DateOnlyWithoutYearWithWeekday)
        ),
      calculateColumnNote: (date, _database, _options) => {
        const holidays = weekView.holidays
          .map(parseHoliday)
          .filter((item) => isSameDay(item.date, date));
        return holidays.map((holiday) => note(holiday.id, holiday.name));
      },
      calculateCellAt: (
        date,
        intervalReferenceDate,
        columnIntervalReferenceDate,
        database,
        _options
      ) => ({
        person: database.persons[0],
        interval: mergeInterval(date, intervalReferenceDate),
        columnInterval: mergeInterval(date, columnIntervalReferenceDate),
        key: formatDate(date, DateFormat.DateOnlyISO),
      }),
    },
    {}
  );
}
