import { TmpUnit } from "frontend/interfaces/unit";
import { log, LogLevel } from "shared/utils/logger";

type Handle = string;
interface DatabaseRow {
  currentHandle: Handle;
  currentTransientObject: unknown;
}

const DATABASE: Record<string, DatabaseRow> = {};

/**
 *
 * @param category a application-wide unqiue string that identifies the usage for the transience
 * @param uniqueHandleWithinCategory a category-wide unique handle that identifies the context for the transience
 * @param newTransientObject a new variable if the unique handle changed or is not yet available
 */
export function useTransience<T>(
  category: string,
  uniqueHandleWithinCategory: Handle,
  newTransientObject: T
): { transientObject: T } {
  const cat = DATABASE[category] || {
    currentHandle: uniqueHandleWithinCategory,
    currentTransientObject: newTransientObject,
  };

  if (!cat || cat.currentHandle !== uniqueHandleWithinCategory) {
    cat.currentHandle = uniqueHandleWithinCategory;
    cat.currentTransientObject = newTransientObject;
  }

  DATABASE[category] = cat;

  return {
    transientObject: cat.currentTransientObject as T,
  };
}

///
// ONCE stuff
///

interface OnceMapping {
  "abstract-view__clickUnit": TmpUnit | null;
}

const DATABASE_ONCE: Map<unknown, unknown> = new Map();

/**
 * can be used to achieve "one-time-transience"
 * you put something here under a given key
 * and you can retrieve it once (pullOnce).
 * If you try to retrieve it a second time without
 * putting another value first, you will get back undefined
 *
 * Add to OnceMapping to get type-safe typing for keys and values
 * @param key
 * @param value
 */
export function putOnce<Key extends keyof OnceMapping>(
  key: Key,
  value: OnceMapping[Key]
): void {
  log(LogLevel.Debug, "[USE-TRANSIENCE] putOnce", key, value);
  DATABASE_ONCE.set(key, value);
}

/**
 * See putOnce
 * @param key
 */
export function pullOnce<Key extends keyof OnceMapping>(
  key: Key
): OnceMapping[Key] | undefined {
  const result = peekOnce(key);
  DATABASE_ONCE.delete(key);

  log(LogLevel.Debug, "[USE-TRANSIENCE] pullOnce", key, result);
  return result;
}

/**
 * Same as pullOnce but without deleting the key
 * @param key
 */
export function peekOnce<Key extends keyof OnceMapping>(
  key: Key
): OnceMapping[Key] | undefined {
  return DATABASE_ONCE.get(key) as OnceMapping[Key] | undefined;
}
