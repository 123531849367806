import { destroyUnit } from "frontend/api/application/request-unit";
import { Unit } from "frontend/interfaces/unit";
import { Modal } from "frontend/utils/modals/modal";

import DeleteAppointmentComponent from "frontend/components/DeleteAppointment.vue";

export interface DeleteModalData {
  unit: Unit;
  notifyPatientOnDeletion: boolean;
  isLoading: boolean;
  reasonForDeletion: string | null;
}
export class DeleteModal extends Modal<
  typeof DeleteAppointmentComponent,
  DeleteModalData
> {
  protected modalComponent() {
    return DeleteAppointmentComponent;
  }

  protected async onClose(
    userConfirmed: boolean,
    closeModal: () => void,
    _forceClose = true
  ): Promise<void> {
    super.onClose(userConfirmed, closeModal, false);

    const data = this.getData();
    if (!data) {
      closeModal();
      return;
    }

    if (userConfirmed) {
      try {
        data.isLoading = true;

        const { success } = await destroyUnit(
          data.unit.id,
          data.notifyPatientOnDeletion,
          data.reasonForDeletion
        );

        // setup success logic in modals
        this.setSuccess(success);

        if (success) {
          closeModal();
        }
      } finally {
        data.isLoading = false;
      }
    } else {
      closeModal();
    }
  }
}
