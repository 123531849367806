type Key = string;

const SYNCHROS: Partial<Record<Key, Promise<void>>> = {};

export async function synchronized(
  key: Key,
  callback: () => Promise<void>
): Promise<void> {
  if (SYNCHROS[key]) await SYNCHROS[key];

  const promised = executeCallback(key, callback);
  SYNCHROS[key] = promised;
  await promised;
}

async function executeCallback(key: Key, callback: () => void): Promise<void> {
  await callback();

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- legacy implementation, maybe fix upon touching
  delete SYNCHROS[key];
}
