<script lang="ts" setup>
  import { onMounted, PropType, ref, toRefs, watch } from "vue";
  import { parseISO } from "date-fns";

  import { useAttendances } from "frontend/uses/use-attendances";
  import { Person } from "frontend/interfaces/person";
  import { useFormCheckbox } from "frontend/uses/use-form-checkbox";
  import { Cache } from "frontend/utils/request-cache";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { FilterBackendParams } from "frontend/uses/filter/filter-definition";

  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";
  import BaseCalendar from "frontend/components/base/BaseCalendar.vue";

  const props = defineProps({
    filterParams: {
      type: Object as PropType<FilterBackendParams>,
      required: true,
    },
  });
  const { filterParams } = toRefs(props);

  const selectedPersonIDs = ref<PrimaryKey[]>([]);
  const selectedscheduleID = ref<PrimaryKey>(
    filterParams.value.schedule_id as PrimaryKey
  );
  const persons = ref<Person[]>([]);
  const { options } = useFormCheckbox(persons, (person) => ({
    id: person.id,
    label: person.name,
  }));
  onMounted(async () => {
    persons.value = await Cache.getCachedPersons();
    selectedPersonIDs.value = persons.value.map((p) => p.id);
  });

  const { calendar, currentDate, reload } = useAttendances(
    selectedPersonIDs,
    selectedscheduleID,
    filterParams
  );

  watch(filterParams, () => {
    currentDate.value = parseISO(filterParams.value.date);
    reload();
  });
</script>

<template>
  <div class="settings-attendances">
    <div class="topbar__person-selector">
      <FormCheckboxGroup v-model="selectedPersonIDs" v-bind:options="options" />
    </div>
    <BaseCalendar
      v-bind:calendar="calendar"
      v-bind:note-title="!filterParams.schedule_id ? 'Zeitpläne' : ''"
      enable-click
      v-on:refresh-needed="reload"
    />
  </div>
</template>

<style lang="scss" scoped>
  .topbar__person-selector {
    margin-bottom: 10px;
  }
</style>
