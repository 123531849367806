<script lang="ts">
  import { defineComponent } from "vue";

  import { useFormLabelling } from "frontend/uses/use-form-labelling";

  export default defineComponent({
    props: {
      label: {
        type: String,
        default: null,
      },
      text: {
        type: String,
        default: null,
      },
    },
    setup() {
      const { pairing } = useFormLabelling();

      return {
        pairing,
      };
    },
  });
</script>

<template>
  <div class="form-text">
    <label v-if="label" class="form-text__label" v-bind:for="pairing">{{
      label
    }}</label>
    <div v-bind:id="pairing">
      <slot>{{ text }}</slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .form-text__label {
    @include label.label;
  }
</style>
