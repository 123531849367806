import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { ScheduleActivationRaw } from "frontend/interfaces/schedule-activation";
import { AttendanceRaw } from "frontend/interfaces/settings/attendance";
import { FilterBackendParams } from "frontend/uses/filter/filter-definition";

export async function requestAttendances(
  filterParams: FilterBackendParams
): Promise<{
  entities: AttendanceRaw[];
  schedule_activations: ScheduleActivationRaw[];
}> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_attendances_path,
    filterParams,
    {}
  );
  if (statusIsSuccess)
    return body as unknown as {
      entities: AttendanceRaw[];
      schedule_activations: ScheduleActivationRaw[];
    };
  else
    return {
      entities: [],
      schedule_activations: [],
    };
}
