import { computed, reactive, Ref, markRaw } from "vue";
import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  getDate,
  getISODay,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  isSameMinute,
  max,
  min,
  setDate,
  setMonth,
  setYear,
} from "date-fns";
import { v4 as uuid } from "uuid";

import {
  Calendar,
  CalendarConnection,
  CalendarEntrySettingsAttendance,
  CalendarEntryType,
  CalendarHeader,
  CalendarNote,
  CalendarRow,
} from "frontend/interfaces/calendar";
import {
  Attendance,
  AttendanceNew,
} from "frontend/interfaces/settings/attendance";
import { range } from "frontend/utils/array";
import {
  DateFormat,
  formatDate,
  shiftFromUTCToLocal,
  shiftFromLocalToUTC,
  weekBoundaries,
} from "shared/utils/date-utils";
import { log, LogLevel } from "shared/utils/logger";
import {
  heightPixels,
  heightPercentage,
  topOffsetPercentage,
  calculateOpenTimes,
  calculateTimescaleBoundaries,
  calculateTimeSteps,
  dateFromIntervalAndLocation,
  genericEntryOnClick,
} from "frontend/utils/base-calendar-utils";
import { ConstantsSettingsAttendances } from "frontend/utils/constants";
import { Interval } from "shared/interfaces/interval";
import {
  EventPositioner,
  IEventPositioner,
} from "shared/utils/event-positioner";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import {
  httpPayloadAttendance,
  newAttendance,
  parseAttendance,
} from "frontend/parser/settings/parse-attendance";
import { removeIfExists } from "shared/utils/array-utils";
import { ScheduleActivation } from "frontend/interfaces/schedule-activation";
import { note } from "frontend/uses/abstract-view/keys-and-labels";
import {
  ConditionalContextMenuEntry,
  ContextMenuAPI,
} from "shared/utils/context-menu/interfaces";
import { useStore as useClipboardStore } from "frontend/stores/clipboard";
import { api as endpoint } from "frontend/api/application/settings/request-attendances";
import {
  ContextMenuCategory,
  ContextMenuPositions,
} from "shared/utils/context-menu/positions";

import ShowSettingsAttendanceComponent from "frontend/components/ShowSettingsAttendance.vue";
import SettingsAttendance from "frontend/components/base-calendar/BCSettingsAttendance.vue";
import SimpleEntry from "shared/utils/context-menu/components/SimpleEntry.vue";

const Z_INDICES = {
  attendance: 2,
} as const;

interface Database {
  attendances: Array<Attendance>;
  shownDates: Array<Date>;
  scheduleActivations: Array<ScheduleActivation>;
  shownPersonIDs: Array<PrimaryKey>;
  shownScheduleID: PrimaryKey;
  connection: CalendarConnection | null;
  tmpEntry: CalendarEntrySettingsAttendance | null;
}

export function useAttendancesParser(
  attendances: Ref<Array<Attendance>>,
  date: Ref<Date>,
  scheduleActivations: Ref<Array<ScheduleActivation>>,
  shownPersonIDs: Ref<PrimaryKey[]>,
  shownScheduleID: Ref<PrimaryKey>
): {
  calendar: Calendar;
} {
  const database: Database = reactive({
    attendances,
    shownDates: computed(() => {
      const { start } = weekBoundaries(date.value);
      return range(0, 6).map((offset) => addDays(start, offset));
    }),
    scheduleActivations,
    shownPersonIDs,
    shownScheduleID,
    connection: null,
    tmpEntry: null,
  });

  return {
    calendar: reactive({
      columnHeaders: computed(() => otfColumnHeaders(database)),
      columnNotes: computed(() => otfColumnNotes(database)),
      rows: computed(() => otfRows(database)),
      onCalendarInit: (connection: CalendarConnection) => {
        database.connection = connection;
      },
    }),
  };
}

function otfColumnHeaders(database: Database): Array<CalendarHeader> {
  return database.shownDates.map((date, offset) => ({
    id: `day-${offset}`,
    label: database.shownScheduleID
      ? formatDate(date, DateFormat.WeekdayShortOnly)
      : formatDate(date, DateFormat.DateOnlyWithoutYearWithWeekday),
  }));
}

function otfColumnNotes(database: Database): Array<CalendarNote[]> {
  return database.shownDates.map((date) => calculateColumnNote(date, database));
}

function calculateColumnNote(date: Date, database: Database) {
  const { start, end } = weekBoundaries(date);
  return database.scheduleActivations.map((activation, offset) => {
    const from = max([activation.from ?? start, start]);
    const to = min([activation.to ?? end, end]);
    return note(
      `day--${date}--${offset}`,
      activation.schedule_name,
      "green",
      differenceInDays(to, from) + 1,
      isSameDay(date, from),
      activation.from ? isBefore(activation.from, start) : true,
      activation.to ? isAfter(activation.to, end) : true
    );
  });
}

function otfRows(database: Database): Array<CalendarRow> {
  const attendanceGrouping = groupAttendances(database);
  const boundaries = calculateBoundaries(database);

  const timesteps = calculateTimeSteps(
    boundaries.minDate,
    boundaries.maxDate,
    ConstantsSettingsAttendances.TIMESCALE_STEP_SIZE
  );

  return timesteps.map((step) => ({
    key: uuid(),
    label: formatDate(step.from, DateFormat.TimeOnlyWithoutSeconds),
    cells: database.shownDates.map((pivot) => {
      const hom = homgenizeDateTo(pivot);
      const theGroup = attendanceGrouping.get(pivot) as GroupingResult;
      return {
        columnPivot: pivot,
        key: uuid(),
        layers: [
          {
            key: "layer--0",
            zIndex: Z_INDICES.attendance,
            entries: filterAttendancesOfInterval(
              theGroup.attendances,
              pivot,
              step
            ).map((attendance) =>
              attendanceToEntry(attendance, step, theGroup, database)
            ),
          },
        ],
        interval: step,
        onClick: (location) => {
          if (!database.connection) return;
          if (database.shownPersonIDs.length <= 0) return;

          if (database.tmpEntry) {
            if ((database.tmpEntry.entity as AttendanceNew).unsaved)
              removeIfExists(database.attendances, database.tmpEntry.entity);
            database.tmpEntry = null;
          }

          const clickedDate = dateFromIntervalAndLocation(
            {
              from: shiftFromLocalToUTC(hom(step.from)),
              to: shiftFromLocalToUTC(hom(step.to)),
            },
            location,
            ConstantsSettingsAttendances.ROUND_CLICK_TO_NEAREST
          );

          const attendance = newAttendance();
          attendance.from = clickedDate;
          attendance.to = addMinutes(clickedDate, 60);
          attendance.person_id = database.shownPersonIDs[0];
          attendance.schedule_id = database.shownScheduleID;
          attendance.days = [getISODay(clickedDate)];
          database.attendances.push(attendance);

          database.tmpEntry = attendanceToEntry(
            attendance,
            step,
            theGroup,
            database
          );

          genericEntryOnClick(
            ShowSettingsAttendanceComponent,
            database.connection,
            attendance.id
          )(database.tmpEntry, { stopPropagation: () => {} });
        },
        ctxPasteAttendance: ctxPasteAttendance(database, step, pivot, theGroup),
      };
    }),
  }));
}

interface GroupingResult {
  attendances: Array<Attendance>;
  positioner: IEventPositioner;
}

function groupAttendances(database: Database): Map<Date, GroupingResult> {
  const grouping = new Map<Date, GroupingResult>();
  for (const date of database.shownDates) {
    grouping.set(date, {
      attendances: [],
      positioner: new EventPositioner(),
    });

    for (const attendance of database.attendances) {
      if (database.shownPersonIDs.indexOf(attendance.person_id) < 0) continue;
      if (isAttendanceOnDate(attendance, date)) {
        const dateGroup = grouping.get(date);
        if (dateGroup) dateGroup.attendances.push(attendance);
        else
          log(
            LogLevel.Error,
            "could not group attendance",
            grouping,
            date,
            attendance
          );
      }
    }
  }

  return grouping;
}

function isAttendanceOnDate(attendance: Attendance, date: Date): boolean {
  const active_on_day = attendance.active_on_days
    ? attendance.active_on_days.some((day) => isSameDay(day, date))
    : true;
  return attendance.days.indexOf(getISODay(date)) >= 0 && active_on_day;
}

function homgenizeDates(pivot: Date, dates: Array<Date>): Array<Date> {
  return dates.map((x) => homgenizeDateTo(pivot)(x));
}

export function homgenizeDateTo(pivot: Date) {
  return (date: Date, shiftToUTC = false) => {
    let result = date;
    if (shiftToUTC) result = shiftFromUTCToLocal(result);
    result = setYear(result, getYear(pivot));
    result = setMonth(result, getMonth(pivot));
    result = setDate(result, getDate(pivot));

    return result;
  };
}

function filterAttendancesOfInterval(
  attendances: Array<Attendance>,
  pivot: Date,
  interval: Interval
): Array<Attendance> {
  return attendances.filter((attendance) =>
    isAttendanceInInterval(attendance, pivot, interval)
  );
}

function isAttendanceInInterval(
  attendance: Attendance,
  pivot: Date,
  interval: Interval
): boolean {
  const hom = homgenizeDateTo(pivot);
  const atFrom = hom(attendance.from, true);
  const inFrom = hom(interval.from);
  const inTo = hom(interval.to);

  return (
    (isAfter(atFrom, inFrom) || isSameMinute(atFrom, inFrom)) &&
    isBefore(atFrom, inTo)
  );
}

function attendanceToEntry(
  attendance: Attendance,
  interval: Interval,
  groupingResult: GroupingResult,
  database: Database
): CalendarEntrySettingsAttendance {
  const hom = homgenizeDateTo(interval.from);

  return {
    key: attendance.id,
    type: CalendarEntryType.SettingsAttendance,

    label: attendance.name,
    personLabel: attendance.person_name,
    color_id: attendance.color_id,
    hoverOn: attendance.id,

    componentCalendar: () => SettingsAttendance,

    onClick: genericEntryOnClick(
      ShowSettingsAttendanceComponent,
      database.connection,
      attendance.id
    ),

    position: groupingResult.positioner.addEntry({
      from: hom(attendance.from, true),
      to: hom(attendance.to, true),
    }),

    topOffsetPercentage: topOffsetPercentage(
      hom(attendance.from, true),
      hom(interval.from),
      // disable cell position detection
      {
        firstColumn: false,
        firstRow: false,
        lastColumn: false,
        lastRow: false,
      },
      ConstantsSettingsAttendances
    ),
    heightPercentage: heightPercentage(
      attendance,
      ConstantsSettingsAttendances
    ),

    heightPixels: heightPixels(attendance, ConstantsSettingsAttendances),

    entity: attendance,
    ctxCopyAttendance: ctxCopyAttendance(attendance, database),
  };
}

function calculateBoundaries(database: Database): {
  minDate: Date;
  maxDate: Date;
} {
  const pivotDate =
    database.shownDates.length > 0 ? database.shownDates[0] : new Date();
  const openDates = calculateOpenTimes(pivotDate);
  const dates = homgenizeDates(
    pivotDate,
    database.attendances.flatMap((a) => [
      shiftFromUTCToLocal(a.from),
      shiftFromUTCToLocal(a.to),
    ])
  );
  dates.push(openDates.todayOpen);
  dates.push(openDates.todayClose);

  return calculateTimescaleBoundaries(
    pivotDate,
    dates,
    [],
    ConstantsSettingsAttendances,
    {}
  );
}

function ctxCopyAttendance(
  attendance: Attendance,
  database: Database
): ConditionalContextMenuEntry {
  return {
    condition: computed(() => !!database.shownScheduleID),
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.copyAttendance,
      category: ContextMenuCategory.attendanceActions,
      onTrigger: async (api: ContextMenuAPI) => {
        const clipboardStore = useClipboardStore();
        clipboardStore.setAttendance(attendance);
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Kopieren",
      }),
    },
  };
}

function ctxPasteAttendance(
  database: Database,
  step: Interval,
  pivot: Date,
  theGroup: GroupingResult
): ConditionalContextMenuEntry {
  const clipboardStore = useClipboardStore();
  return {
    condition: computed(
      () => !!clipboardStore.getAttendance() && !!database.shownScheduleID
    ),
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.pasteAttendance,
      category: ContextMenuCategory.attendanceActions,
      onTrigger: async (api: ContextMenuAPI) => {
        const storedAttendance = clipboardStore.getAttendance();

        if (!storedAttendance || !api.eventPosition) return;

        const dauer = differenceInMinutes(
          storedAttendance.to,
          storedAttendance.from
        );

        const hom = homgenizeDateTo(pivot);
        const clickedDate = dateFromIntervalAndLocation(
          {
            from: shiftFromLocalToUTC(hom(step.from)),
            to: shiftFromLocalToUTC(hom(step.to)),
          },
          api.eventPosition,
          ConstantsSettingsAttendances.ROUND_CLICK_TO_NEAREST
        );
        const attendance = { ...storedAttendance };
        attendance.id = `MOCK-${uuid()}`;
        attendance.from = clickedDate;
        attendance.to = addMinutes(clickedDate, dauer);
        attendance.days = [getISODay(clickedDate)];
        attendance.schedule_id = database.shownScheduleID;

        const result = await endpoint.requestCreate(
          httpPayloadAttendance(attendance)
        );
        if (result.success) {
          const newAttendance = parseAttendance(result.entityRaw);
          const entry = attendanceToEntry(
            newAttendance,
            step,
            theGroup,
            database
          );
          genericEntryOnClick(
            ShowSettingsAttendanceComponent,
            database.connection,
            newAttendance.id
          )(entry, { stopPropagation: () => {} });

          database.attendances.push(newAttendance);
        }

        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Einfügen",
      }),
    },
  };
}
