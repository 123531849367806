<script lang="ts" setup>
  import { computed, onMounted, PropType, ref } from "vue";

  import { Errors } from "frontend/uses/use-errors";
  import { FormSelectId } from "frontend/uses/use-form-select";
  import { StatusValue } from "frontend/utils/animation-status";
  import { Room } from "frontend/interfaces/room";
  import { Cache } from "frontend/utils/request-cache";

  import FormSelect from "frontend/components/form/FormSelect.vue";

  defineProps({
    modelValue: {
      type: String as PropType<FormSelectId>,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<Errors>,
      default: () => [],
    },
    animationStatus: {
      type: Number as PropType<StatusValue | null>,
      default: null,
    },
    visualSmall: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:modelValue", "autosave"]);

  const rooms = ref<Array<Room>>([]);
  const roomsLoaded = ref<boolean>(false);
  const options = computed(() => {
    return rooms.value.map((room) => ({ id: room.id, label: room.name }));
  });

  const doLoadRooms = async () => {
    rooms.value = await Cache.getCachedRooms();
    roomsLoaded.value = true;
  };
  onMounted(doLoadRooms);

  const onUpdateSelection = (newValue: FormSelectId | null) => {
    emit("update:modelValue", newValue);
  };
</script>

<template>
  <div class="form-select-office-person">
    <template v-if="roomsLoaded">
      <FormSelect
        v-bind:model-value="modelValue"
        v-bind:label="label"
        v-bind:options="options"
        v-bind:errors="errors"
        v-bind:animation-status="animationStatus"
        v-bind:visual-small="visualSmall"
        include-blank
        v-on:autosave="$emit('autosave')"
        v-on:update:model-value="onUpdateSelection"
      />
    </template>
  </div>
</template>
