import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import {
  AppointmentRaw,
  AppointmentsRaw,
} from "frontend/interfaces/p/appointment";
import { PrimaryKey } from "frontend/interfaces/primary-key";

export async function requestAppointments(): Promise<{
  appointments: Array<AppointmentRaw>;
}> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_p_appointments_path,
    {},
    {}
  );

  if (statusIsSuccess) {
    return {
      appointments: (body as unknown as AppointmentsRaw).appointments,
    };
  } else {
    return {
      appointments: [],
    };
  }
}

export async function destroyAppointment(id: PrimaryKey): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_p_appointment_path,
    { id },
    {}
  );

  return statusIsSuccess;
}
