<script lang="ts" setup>
  import { computed } from "vue";

  import { definePropsEntry } from "frontend/utils/base-calendar-utils";
  import { CalendarEntryAttendance } from "frontend/interfaces/calendar";
  import { useColorModification } from "frontend/uses/use-color-modificaiton";

  const props = defineProps(definePropsEntry<CalendarEntryAttendance>());

  const { colorRgba } = useColorModification(
    computed(() => props.entry?.color ?? null),
    0.8
  );
</script>

<template>
  <div
    class="attendance"
    v-bind:style="{ 'background-color': colorRgba ? colorRgba : undefined }"
    v-bind:title="entry.tooltipLabel === null ? undefined : entry.tooltipLabel"
  ></div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .attendance {
    background-color: colors.$color_calendar__attendance--background;
  }
</style>
