export interface SingletonTimeout {
  reset: (callback: () => void, timeInMs: number) => void;
  cancel: () => void;
}

export function useSingletonTimeout(): SingletonTimeout {
  let handle: NodeJS.Timeout | null = null;

  const cancel = () => {
    if (handle) {
      clearTimeout(handle);
      handle = null;
    }
  };

  return {
    reset: (callback: () => void, timeInMs: number) => {
      cancel();
      handle = setTimeout(() => {
        callback();
      }, timeInMs);
    },
    cancel,
  };
}
