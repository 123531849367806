import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  LocationRaw,
  Location,
  LocationNew,
} from "frontend/interfaces/location";

export function parseLocation(location: LocationRaw): Location {
  return { ...location };
}

export function httpPayloadLocation(location: Location): HttpPayload {
  return {
    ...location,
  };
}

export function newLocation(): LocationNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    zip: "",
    city: "",
    street: "",
    unsaved: true,
  };
}
