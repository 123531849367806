import { App, computed, DirectiveBinding, reactive, VNode } from "vue";
import { mount, MountResult } from "mount-vue-component";
import {
  autoUpdate,
  computePosition,
  flip,
  inline,
  shift,
} from "@floating-ui/dom";

import {
  disposableEventListener,
  DisposableEventListener,
} from "frontend/utils/disposable-event-listener";

import BaseTooltip from "frontend/components/base/BaseTooltip.vue";

const TOOLTIP_DELAY_IN_MS = 300;

// class that wraps a single tooltip and takes care
// of the complete lifecycle
export class Tooltip {
  private readonly app: App<Element>;
  private readonly element: TooltipElement;
  private readonly binding: DirectiveBinding<TooltipBinding>;
  private readonly vnode: VNode<unknown, TooltipElement>;
  private tooltipComponent: MountResult | undefined;

  private mouseEnterListener: DisposableEventListener | undefined;
  private mouseLeaveListener: DisposableEventListener | undefined;

  private destroyAutoUpdater: (() => void) | undefined;

  private currentHovertimeout: NodeJS.Timeout | undefined;

  constructor(
    app: App<Element>,
    element: TooltipElement,
    binding: DirectiveBinding<TooltipBinding>,
    vnode: VNode<unknown, TooltipElement>
  ) {
    this.app = app;
    this.element = element;
    this.binding = binding;
    this.vnode = vnode;

    this.setupHoverListener();
  }

  // remove all the leaky stuff
  // (event listeners end so)
  public cleanup() {
    if (this.mouseEnterListener) {
      this.mouseEnterListener.dispose();
      this.mouseEnterListener = undefined;
    }
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener.dispose();
      this.mouseLeaveListener = undefined;
    }
    if (this.destroyAutoUpdater) {
      this.destroyAutoUpdater();
      this.destroyAutoUpdater = undefined;
    }
    if (this.tooltipComponent) {
      this.tooltipComponent.destroy();
      this.tooltipComponent = undefined;
    }
  }

  // setup listeners needed for showing the tooltip
  private setupHoverListener() {
    this.mouseEnterListener = disposableEventListener(
      this.element,
      "mouseenter",
      () => {
        this.hoveringStart();
      }
    );
    this.mouseLeaveListener = disposableEventListener(
      this.element,
      "mouseleave",
      () => {
        this.hoveringStop();
      }
    );
  }

  // ensures that a Vue-component is created
  // for the tooltip
  // currently: BaseTooltip.vue
  // the vue component will be responsible for
  // displaying the tooltip, while this class
  // is responsible for creating and positioning the component
  private ensureTooltipComponent() {
    if (this.tooltipComponent) return;

    const props = reactive({
      tooltipText: computed(() => this.binding.value),
    });

    this.tooltipComponent = mount(BaseTooltip, {
      props,
      app: this.app,
    });

    this.prepareTooltipContainerElement(this.tooltipComponent.el);
  }

  // initial setup of the tooltip container div once it was created
  private prepareTooltipContainerElement(element: HTMLElement) {
    document.body.append(element);

    element.style.display = "none";
    element.style.position = "absolute";
    element.style.zIndex = "999"; // TODO: unify z-indices
    element.style.width = "max-content"; // needed to mitigate ResizeObserver loop
    // see: https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1620002162
  }

  // return the tooltip container in a safe manner
  private ensureAndGetTooltipElement(): HTMLElement {
    this.ensureTooltipComponent();

    if (!this.tooltipComponent) {
      // redundant check for typescript
      throw new Error(
        "this should never happen: tooltipComponent is null after ensuring"
      );
    }

    return this.tooltipComponent.el;
  }

  // sets up the position updating of the tooltip component
  private ensurePositioning() {
    if (this.destroyAutoUpdater) return;

    const placementFn = async () => {
      const { x, y } = await computePosition(
        this.element,
        this.ensureAndGetTooltipElement(),
        {
          placement: "top",
          middleware: [inline(), shift(), flip()],
        }
      );

      Object.assign(this.ensureAndGetTooltipElement().style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    };

    this.destroyAutoUpdater = autoUpdate(
      this.element,
      this.ensureAndGetTooltipElement(),
      placementFn
    );
    placementFn();
  }

  // callback when the reference element that should
  // trigger the tooltip is hovered
  private hoveringStart() {
    this.ensureTimeoutCleared();

    this.currentHovertimeout = setTimeout(() => {
      this.ensureTooltipComponent();
      this.ensurePositioning();

      this.ensureAndGetTooltipElement().style.display = "initial";
    }, TOOLTIP_DELAY_IN_MS);
  }

  // callback when the reference element that triggers
  // the tooltip is no longer hovered
  private hoveringStop() {
    this.ensureTimeoutCleared();

    this.ensureAndGetTooltipElement().style.display = "none";
  }

  // if there is a timeout that will fire showing the tooltip
  // it will be canceld by this method
  // can be used to prevent "double-showing" the component and
  // thus getting the display out of sync with the tooltip class
  private ensureTimeoutCleared() {
    if (!this.currentHovertimeout) return;

    clearTimeout(this.currentHovertimeout);
    this.currentHovertimeout = undefined;
  }
}

export type TooltipElement = HTMLElement;
export type TooltipBinding = string;
export type TooltipStyling = {
  position: "absolute";
  display: "none" | null;
  top: string | null;
  left: string | null;
};
