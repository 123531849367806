<script lang="ts">
  import { defineComponent, onMounted, reactive, ref } from "vue";
  import { getYear } from "date-fns";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { Holiday } from "frontend/interfaces/settings/holiday";
  import {
    requestHolidays,
    selectHolidays,
  } from "frontend/api/application/settings/request-holidays";
  import { HolidaySelectionModal } from "frontend/utils/modals/holiday-selection-modal";
  import { parseHoliday } from "frontend/parser/settings/parse-holiday";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { BaseHelp, BaseButton },
    setup() {
      const holidays = ref<Array<Holiday>>([]);

      const loadHolidays = async () => {
        const data = await requestHolidays("false");
        if (data.entities) holidays.value = data.entities.map(parseHoliday);
      };
      onMounted(loadHolidays);

      const openHolidaysSelection = async () => {
        const data = reactive({
          selectedHolidaysIds: holidays.value.map((h) => h.id),
        });
        await new HolidaySelectionModal()
          .setData(data)
          .onSuccess(async () => {
            await selectHolidays(data.selectedHolidaysIds);
            await loadHolidays();
          })
          .show();
      };

      const isFirstInYear = (holiday: Holiday) => {
        const position = holidays.value.indexOf(holiday);
        if (position === 0) return true;
        else
          return (
            getYear(holidays.value[position - 1].date) !== getYear(holiday.date)
          );
      };
      return {
        DateFormat,
        formatDate,
        getYear,
        holidays,
        openHolidaysSelection,
        isFirstInYear,
      };
    },
  });
</script>

<template>
  <h2>Feiertage</h2>
  <BaseHelp>
    <p>
      Hier können Sie Feiertage für Ihre Praxis auswählen, die bei der
      Terminbuchung automatisch berücksichtigt werden.
    </p>
  </BaseHelp>
  <template v-if="holidays.length > 0">
    <div class="holidays__wrapper">
      <div class="holidays__row holidays--header">
        <div class="holidays__first-cell"></div>
        <div class="holidays__cell">Feiertag</div>
        <div class="holidays__cell">Datum</div>
      </div>
      <div
        v-for="holiday in holidays"
        v-bind:key="holiday.id"
        class="holidays__row"
        v-bind:class="{ 'holidays-new-year': isFirstInYear(holiday) }"
      >
        <div class="holidays__first-cell">
          <div v-if="isFirstInYear(holiday)">{{ getYear(holiday.date) }}</div>
        </div>
        <div class="holidays__cell">{{ holiday.name }}</div>
        <div class="holidays__cell">
          {{ formatDate(holiday.date, DateFormat.DateOnlyLong) }}
        </div>
      </div>
    </div>
    <div class="holiday-action">
      <BaseButton v-on:click="openHolidaysSelection">
        Feiertage ändern
      </BaseButton>
    </div>
  </template>
  <template v-else>
    <div class="holidays-action">
      <BaseButton v-on:click="openHolidaysSelection">
        Feiertage hinzufügen
      </BaseButton>
    </div>
  </template>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .holidays__wrapper {
    margin: 50px 0;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays__row {
    display: flex;
    flex-flow: row wrap;
    border-bottom: solid 1px colors.$color_base-list--separator;

    &:last-of-type {
      border: none;
    }
  }

  .holidays--header {
    background-color: colors.$color_holidays-table-header--background;
  }

  .holidays__cell {
    flex: 1;
    padding: 8px;
  }

  .holidays__first-cell {
    width: 80px;
    padding: 8px;
    border-right: solid 1px colors.$color_base-list--separator;
  }

  .holidays-new-year {
    border-top: 2px solid colors.$color_inline-editable__outline;
  }

  .holidays-action {
    margin: 30px 0;
  }
</style>
