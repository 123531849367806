import { onMounted, reactive, Ref, toRefs } from "vue";

import { Calendar } from "frontend/interfaces/calendar";
import { parseAttendance } from "frontend/parser/settings/parse-attendance";
import { useAttendancesParser } from "frontend/uses/use-attendances-parser";
import { Attendance } from "frontend/interfaces/settings/attendance";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { FilterBackendParams } from "frontend/uses/filter/filter-definition";
import { requestAttendances } from "frontend/api/application/request-attendances";
import { ScheduleActivation } from "frontend/interfaces/schedule-activation";
import { parseScheduleActivation } from "frontend/parser/parse-schedule-activation";

export function useAttendances(
  shownPersonIDs: Ref<PrimaryKey[]>,
  shownScheduleID: Ref<PrimaryKey>,
  filterParams: Ref<FilterBackendParams>,
  initialDate?: Date
): {
  calendar: Calendar;
  currentDate: Ref<Date>;
  reload: () => Promise<void>;
} {
  const database: {
    attendances: Array<Attendance>;
    currentDate: Date;
    scheduleActivations: Array<ScheduleActivation>;
  } = reactive({
    attendances: [],
    currentDate: initialDate || new Date(),
    scheduleActivations: [],
  });

  const reloadAttendances = async () => {
    const data = await requestAttendances(filterParams.value);
    database.attendances = data.entities.map(parseAttendance);
    database.scheduleActivations = data.schedule_activations
      ? data.schedule_activations.map(parseScheduleActivation)
      : [];
  };
  onMounted(() => reloadAttendances());

  const { calendar } = useAttendancesParser(
    toRefs(database).attendances,
    toRefs(database).currentDate,
    toRefs(database).scheduleActivations,
    shownPersonIDs,
    shownScheduleID
  );

  return {
    calendar,
    currentDate: toRefs(database).currentDate,
    reload: reloadAttendances,
  };
}
