import { onMounted, PropType, ref, Ref, watch } from "vue";

import { Unit } from "frontend/interfaces/unit";
import { requestUnit } from "frontend/api/application/request-unit";
import { parseUnit } from "frontend/parser/parse-unit";
import { CalendarEntryUnit } from "frontend/interfaces/calendar";

export function useShowUnit(modelValue: Ref<string | null | undefined>): {
  currentUnit: Ref<Unit | null>;
} {
  const currentUnit = ref<Unit | null>(null);

  const reloadUnit = async () => {
    currentUnit.value = null;

    if (modelValue.value) {
      const rawUnit = await requestUnit(modelValue.value);
      currentUnit.value = parseUnit(rawUnit);
    }
  };
  watch(modelValue, reloadUnit);
  onMounted(reloadUnit);

  return {
    currentUnit,
  };
}

export function defineShowUnitProps() {
  return {
    modelValue: {
      // Id of the unit
      type: String,
      required: true,
    } as const,
    entry: {
      type: Object as PropType<CalendarEntryUnit>,
      default: null,
    } as const,
  } as const;
}
