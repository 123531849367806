import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { Role } from "frontend/interfaces/role";
import {
  httpPayloadRole,
  newRole,
  parseRole,
} from "frontend/parser/parse-role";
import {
  createRole,
  deleteRole,
  requestRoles,
  updateRole,
} from "frontend/api/application/request-roles";

import EditRole from "frontend/components/settings/EditRole.vue";

export class RolesSettingsManager extends SettingsManager<Role> {
  entityName(): string {
    return "Rolle";
  }
  componentForEdit(): Component {
    return EditRole;
  }
  craftNewEntity(): Role {
    return newRole();
  }
  hookList(): Promise<SettingsListResult<Role>> {
    return genericListPattern(parseRole, requestRoles);
  }
  hookSave(record: Role): Promise<SettingsSaveResult<Role>> {
    return genericSavePattern(
      record,
      parseRole,
      httpPayloadRole,
      createRole,
      updateRole
    );
  }
  hookDelete(record: Role): Promise<SettingsDeleteResult> {
    return genericDeletePattern(record, (entity) => entity.id, deleteRole);
  }
}
