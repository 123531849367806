import {
  SearchResult,
  SearchResultRaw,
} from "frontend/interfaces/search-results";

export function parseSearchResults(result: SearchResultRaw): SearchResult {
  return { ...result, info: [...(result.info || [])] };
}

export function buildQuery(rawQuery: string): string {
  return `${rawQuery}`;
}
