import { v4 as uuid } from "uuid";

import { log, LogLevel } from "shared/utils/logger";

type BusSubscriptionIdentifier = number;
type BusSubscriptionCallback<T> = (data: T) => void;

interface BusEvent<T> {
  emit: (data: T) => void;
  subscribe: (
    callback: BusSubscriptionCallback<T>
  ) => BusSubscriptionIdentifier;
  unsubscribe: (identifier: BusSubscriptionIdentifier) => boolean;
  once: (callback: BusSubscriptionCallback<T>) => void;
  or: <U>(other: BusEvent<U>) => BusEvent<T | U>; // helper to receive events on multiple busses
  and: (other: BusEvent<T>) => BusEvent<T>; // helper to emit events on multiple busses
}

export function busEvent<T>(label?: string): BusEvent<T> {
  let subscriptionCounter = 0;
  const subscriptions: { [key: number]: BusSubscriptionCallback<T> } = {};

  const emit = (data: T) => {
    for (const key in subscriptions) {
      if (label) {
        log(LogLevel.Trace, `[${label}]: emit to ${key}`);
      }
      subscriptions[key](data);
    }
  };
  const subscribe = (callback: BusSubscriptionCallback<T>) => {
    const counter = subscriptionCounter;
    subscriptionCounter += 1;

    subscriptions[counter] = callback;

    return counter;
  };
  const unsubscribe = (identifier: BusSubscriptionIdentifier) => {
    if (subscriptions[identifier]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- legacy implementation, maybe fix upon touching
      delete subscriptions[identifier];
      return true;
    } else {
      return false;
    }
  };
  const once = (callback: BusSubscriptionCallback<T>) => {
    const identifier = subscribe((data: T) => {
      unsubscribe(identifier);
      callback(data);
    });
  };
  const or = <U>(other: BusEvent<U>): BusEvent<T | U> => {
    const busEventVariable = busEvent<T | U>(`or-${uuid()}`);

    other.subscribe((data) => busEventVariable.emit(data));
    subscribe((data) => busEventVariable.emit(data));

    return {
      ...busEventVariable,
      emit: (_data) =>
        log(LogLevel.Warn, "[BUS-EVENT] cannot emit on 'or' bus"),
    };
  };

  const and = (other: BusEvent<T>): BusEvent<T> => {
    const busEventVariable = busEvent<T>(`and-${uuid()}`);

    return {
      ...busEventVariable,
      emit: (data) => {
        other.emit(data);
        emit(data);
      },
      subscribe: (_callback) => {
        log(LogLevel.Warn, "[BUS-EVENT] cannot subscribe on 'and' bus");
        return -1; // return dummy variable
      },
    };
  };

  return {
    subscribe,
    once,
    unsubscribe,
    emit,
    or,
    and,
  };
}
