import { v4 as uuid } from "uuid";

import { ColorRaw, Color, ColorNew } from "frontend/interfaces/color";
import { HttpPayload } from "frontend/interfaces/http";

export function parseColor(color: ColorRaw): Color {
  return {
    id: color.id,
    name: color.name,
    value: color.value,
  };
}

export function httpPayloadColor(color: Color): HttpPayload {
  return {
    ...color,
  };
}

export function newColor(): ColorNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    value: "#000000",
    unsaved: true,
  };
}
