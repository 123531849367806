const ContextMenuPositionsRaw = [
  "appointmentStatusPatientArrived",
  "appointmentStatusPatientNotArrived",
  "appointmentStatusDone",
  "cutAppointment",
  "pasteAppointment",
  "deleteAppointment",
  "appointmentSuggestions",
  "openPatientProfile",
  "openPatientInPVS",
  "copyAttendance",
  "pasteAttendance",
] as const;

const ContextMenuCategoryRaw = [
  "appointmentStatus",
  "appointmentActions",
  "appointmentSuggestions",
  "patientActions",
  "attendanceActions",
] as const;

type PositionNames = (typeof ContextMenuPositionsRaw)[number];
type ContextMenuPositions = Record<PositionNames, number>;

type CategoryNames = (typeof ContextMenuCategoryRaw)[number];
type ContextMenuCategory = Record<CategoryNames, number>;

export const ContextMenuPositions = mapContextMenuPositions(
  ContextMenuPositionsRaw
);

export const ContextMenuCategory = mapContextMenuCategory(
  ContextMenuCategoryRaw
);

function mapContextMenuPositions(
  raw: typeof ContextMenuPositionsRaw
): ContextMenuPositions {
  const result: Partial<ContextMenuPositions> = {};

  let counter = 1;
  for (const entry of raw) {
    result[entry] = counter;
    counter += 1;
  }
  return result as ContextMenuPositions;
}

function mapContextMenuCategory(
  raw: typeof ContextMenuCategoryRaw
): ContextMenuCategory {
  const result: Partial<ContextMenuCategory> = {};

  let counter = 1;
  for (const entry of raw) {
    result[entry] = counter;
    counter += 1;
  }
  return result as ContextMenuCategory;
}
