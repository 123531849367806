import {
  PageDefinition,
  PaginationMeta,
  UnifiedListResponseWithPagination,
} from "frontend/interfaces/pagination";
import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { EventEntryRaw } from "frontend/interfaces/event-entry";
import { stringifyObject } from "frontend/utils/stringify-object";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { PathParams } from "shared/utils/pathify";

export async function requestEventEntries(
  pageDefinition: PageDefinition,
  filterParams: PathParams
): Promise<UnifiedListResponseWithPagination<EventEntryRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_event_entries_path,
    { ...stringifyObject(pageDefinition), ...filterParams },
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: EventEntryRaw[] }).entities,
    pagination: (body as unknown as { pagination: PaginationMeta }).pagination,
  };
}

export async function seenEventEntries(
  ids: Array<PrimaryKey> | null,
  filterParams: PathParams,
  pageDefinition?: PageDefinition
): Promise<UnifiedListResponseWithPagination<EventEntryRaw>> {
  const requestBody = {
    ...(ids ? { event_entry_ids: ids } : {}),
    ...(pageDefinition ? { ...stringifyObject(pageDefinition) } : {}),
    ...filterParams,
  };

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.mark_as_seen_frontend_event_entries_path,
    {},
    requestBody
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: EventEntryRaw[] }).entities,
    pagination: (body as unknown as { pagination: PaginationMeta }).pagination,
  };
}
