import { App, Directive } from "vue";

import {
  Tooltip,
  TooltipBinding,
  TooltipElement,
} from "frontend/utils/tooltip";

// store existing tooltips to remove leaky listeners on unmounting
const DATABASE = new Map<HTMLElement, Tooltip>();

export function setupTooltips(app: App<Element>) {
  app.directive("tooltip", {
    mounted(el, binding, vnode) {
      ensureDatabaseClean(el);

      const tooltip = new Tooltip(app, el, binding, vnode);

      // save tooltip so we can release resources once we dont need it anymore
      DATABASE.set(el, tooltip);
    },
    unmounted(el) {
      ensureDatabaseClean(el);
    },
  } as Directive<TooltipElement, TooltipBinding>);
}

// check if a tooltip exists on the given element
// and if so it will get destroyed safely
function ensureDatabaseClean(el: HTMLElement) {
  const tooltip = DATABASE.get(el);
  if (tooltip) tooltip.cleanup();
  DATABASE.delete(el);
}
