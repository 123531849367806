import { formatISO } from "date-fns";

import { routes, doRequest } from "frontend/api/application";
import { WeekViewRaw } from "frontend/interfaces/day-view";
import { HttpMethod } from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";

export async function requestWeekView(
  from: Date,
  to: Date,
  personIDs: PrimaryKey,
  searchRef: PrimaryKey | null
): Promise<{ weekView: WeekViewRaw | null }> {
  const params: { [key: string]: string } = {
    from: formatDate(from),
    to: formatDate(to),
    persons: personIDs,
  };

  if (searchRef) {
    params.search_ref = searchRef;
  }

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_calendar_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    return {
      weekView: body as unknown as WeekViewRaw,
    };
  } else {
    return {
      weekView: null,
    };
  }
}

function formatDate(date: Date): string {
  return formatISO(date, { representation: "date" });
}
