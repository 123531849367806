<script lang="ts">
  import { defineComponent } from "vue";

  import {
    filterEmits,
    filterProps,
    FILTER_EVENT_STATE_CHANGE,
  } from "frontend/uses/filter/use-filter";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  interface FilterState {
    filterChecked: boolean;
  }

  interface FilterOptions {
    label: string;
  }

  export default defineComponent({
    components: { FormCheckbox },
    props: {
      ...filterProps<FilterState, FilterOptions>(),
    },
    emits: [...filterEmits()],
    setup(_, { emit }) {
      return {
        onCheckboxFlip: (filterChecked: boolean) => {
          emit(FILTER_EVENT_STATE_CHANGE, {
            filterChecked,
          });
        },
      };
    },
  });
</script>

<template>
  <div>
    <FormCheckbox
      v-bind:model-value="filterState.filterChecked"
      v-bind:label="filterOptions.label"
      v-on:update:model-value="onCheckboxFlip"
    />
  </div>
</template>
