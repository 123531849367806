import { reactive, Ref } from "vue";

import {
  FilterBackendParams,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { FormSelectOption } from "frontend/uses/use-form-select";

import FilterSelect from "frontend/components/filter/FilterSelect.vue";

export function simpleSelect<T>(
  filter: {
    frontend?: (entry: T, selectedID: PrimaryKey | null) => boolean;
    backend?: (selectedID: PrimaryKey | null) => FilterBackendParams;
  },
  sort: {
    frontend?: (entries: Array<T>, selectedID: PrimaryKey | null) => Array<T>;
  },
  options: {
    label: string;
    options: Readonly<Ref<Array<FormSelectOption>>> | Array<FormSelectOption>;
    includeBlank?: boolean | string;
  },
  initialSelected: PrimaryKey | null = null
): FilterDefinition<T, typeof FilterSelect> {
  const { frontend: frontendFilter, backend: backendFilter } = filter;
  const { frontend: frontendSort } = sort;

  return {
    component: FilterSelect,
    frontendFilter: frontendFilter
      ? (entry, state) => frontendFilter(entry, state.selectedID)
      : undefined,
    backendFilter: backendFilter
      ? (state) => backendFilter(state.selectedID)
      : undefined,
    frontendSort: frontendSort
      ? (entries, state) => frontendSort(entries, state.selectedID)
      : undefined,
    initialFilterState: () =>
      reactive({
        selectedID: initialSelected,
      }),
    filterOptions: reactive({ ...options }),
  };
}
