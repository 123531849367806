import { inject, provide, Ref } from "vue";

export class Provider<T> {
  _name: string;
  _providedEntity: T;

  constructor(name: string, element: T) {
    this._name = name;
    this._providedEntity = element;
  }

  provide() {
    provide(this._name, this._providedEntity);
  }

  static inject<T>(name: string): T | null {
    return inject<T>(name) || null;
  }

  static injectSafe<T>(name: string): T {
    const instance = inject<T>(name);
    if (!instance) {
      throw `No instance for "${name}" was provided. Make sure some (parent) component called .provide on a provider instance.`;
    }
    return instance;
  }
}

const PROVISIONS = {
  baseHeader: "base-header",
};
type ProvidedElement = Ref<Element | null>;
export class ElementProvider extends Provider<ProvidedElement> {
  static provisions() {
    return PROVISIONS;
  }

  static localInjectSafe(name: string) {
    return Provider.injectSafe<ProvidedElement>(name);
  }
}
