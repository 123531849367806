<script lang="ts">
  import { computed, defineComponent, PropType, ref, toRefs } from "vue";

  import { FormCheckboxOption } from "frontend/uses/use-form-checkbox";
  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { isIncluded, removeFirstOccurrence } from "frontend/utils/array";
  import { Errors } from "frontend/uses/use-errors";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  const DEFER_AUTOSAVE_FOR = 250; // ms
  const UPDATE_EVENT_NAME = "update:model-value";

  export default defineComponent({
    components: { AnimationStatus, FormCheckbox },
    props: {
      options: {
        type: Array as PropType<Array<FormCheckboxOption>>,
        default: () => [],
      },
      label: {
        type: String as PropType<string | null>,
        default: null,
      },
      modelValue: {
        type: Array as PropType<Array<string | number>>,
        default: () => [],
      },
      animationStatus: {
        type: Number as PropType<number | null>,
        default: null,
      },
      errors: {
        type: Array as PropType<Errors>,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      displayType: {
        type: String as PropType<"columns" | "rows">,
        default: "column",
      },
      visualSmall: {
        type: Boolean,
        default: false,
      },
      sorted: {
        type: Array as PropType<Array<string>>,
        default: () => [],
      },
      dragAndDrop: {
        type: Boolean,
        default: false,
      },
      inverse: {
        type: Boolean,
        default: false,
      },
      selectAll: {
        type: Boolean,
        default: false,
      },
    },
    emits: [UPDATE_EVENT_NAME, "autosave", "update:sorted"],
    setup(props, { emit }) {
      const { modelValue, options } = toRefs(props);
      const onUpdateModelValue = (
        option: FormCheckboxOption,
        newValue: boolean
      ) => {
        if (newValue) {
          if (!isIncluded(modelValue.value, option.id))
            emit(UPDATE_EVENT_NAME, modelValue.value.concat([option.id]));
          // modelValue.value.push(option.id);
        } else {
          const result = [...modelValue.value];
          removeFirstOccurrence(result, option.id);
          emit(UPDATE_EVENT_NAME, result);
        }
      };
      const selectAllOptions = () => {
        const selectAll = options.value.map((option) => option.id);
        if (isSelectedAll.value)
          emit(UPDATE_EVENT_NAME, props.inverse ? selectAll : []);
        else emit(UPDATE_EVENT_NAME, props.inverse ? [] : selectAll);
      };
      const isSelectedAll = computed(() => {
        return props.inverse
          ? options.value.every((o) => !modelValue.value.includes(o.id))
          : options.value.every((o) => modelValue.value.includes(o.id));
      });
      let currentTimeout: number | null = null;
      const doRegisterAutosave = () => {
        if (currentTimeout) {
          clearTimeout(currentTimeout);
          currentTimeout = null;
        }
        currentTimeout = setTimeout(() => {
          currentTimeout = null;
          emit("autosave");
        }, DEFER_AUTOSAVE_FOR) as unknown as number;
      };

      // ------------------------------------------------
      // DRAG AND DROP
      // ------------------------------------------------
      const dragIndex = ref<number>(-1);
      const dragItem = ref<FormCheckboxOption>();
      const onDrag = (option: FormCheckboxOption) => {
        dragIndex.value = options.value.indexOf(option);
        dragItem.value = option;
      };
      const onDrop = (option: FormCheckboxOption) => {
        if (!dragItem.value) return;
        const dropIndex = options.value.indexOf(option);
        options.value.splice(dragIndex.value, 1);
        options.value.splice(dropIndex, 0, dragItem.value);
        emit(
          "update:sorted",
          options.value.map((o) => o.id)
        );
        doRegisterAutosave();
      };
      return {
        ...useFormLabelling(),
        onUpdateModelValue,
        selectAllOptions,
        isSelectedAll,
        doRegisterAutosave,
        onDrag,
        onDrop,
      };
    },
  });
</script>

<template>
  <div class="fcg" v-bind:class="{ 'fcg--visual-small': visualSmall }">
    <div class="fcg__status-container">
      <label v-if="label" v-bind:for="pairing" class="fcg__label">
        {{ label }}
      </label>
      <AnimationStatus
        v-if="animationStatus !== null"
        class="fcg__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <div
      class="fcg__checkbox-container"
      v-bind:class="[
        { 'fcg__checkbox-container--visual-small': visualSmall },
        `fcg__checkbox-container--${displayType}`,
      ]"
      v-on:dragover.prevent
      v-on:dragenter.prevent
    >
      <div v-if="options.length > 0 && selectAll">
        <FormCheckbox
          v-bind:model-value="isSelectedAll"
          label="Alle"
          v-on:update:model-value="selectAllOptions"
          v-on:autosave="() => doRegisterAutosave()"
        />
      </div>
      <div v-for="option in options" v-bind:id="pairing" v-bind:key="option.id">
        <FormCheckbox
          v-bind:model-value="
            inverse
              ? modelValue.indexOf(option.id) < 0
              : modelValue.indexOf(option.id) >= 0
          "
          v-bind:label="option.label"
          v-bind:disabled="disabled"
          v-bind:visual-small="visualSmall"
          v-bind:draggable="dragAndDrop"
          v-bind:class="{ draggable: dragAndDrop }"
          v-on:dragstart="onDrag(option)"
          v-on:drop.stop="onDrop(option)"
          v-on:update:model-value="
            (newValue) =>
              inverse
                ? onUpdateModelValue(option, !newValue)
                : onUpdateModelValue(option, newValue)
          "
          v-on:autosave="() => doRegisterAutosave()"
        />
      </div>
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="fcg__label__error"
        v-bind:for="pairing"
        >{{ error }}</label
      ></template
    >
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";
  @use "frontend/styles/mixins/drag";

  .fcg__label,
  .fcg__label__error {
    @include label.label;
  }

  .fcg__label__error {
    @include label.label--error;
  }

  .fcg__status-container {
    display: flex;
    align-items: flex-start;
  }

  .fcg__animation-status {
    margin-left: 10px;
    margin-top: 5px;
  }

  .fcg__checkbox-container--rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
      margin-right: 7px;
    }

    &.fcg__checkbox-container--visual-small > div {
      margin-right: 2px;
      flex: 1;
    }
  }
  .draggable {
    @include drag.draggable;
  }
</style>
