import { CalendarConstants } from "frontend/interfaces/calendar";
import { Unit } from "frontend/interfaces/unit";
import { useStore as useProfileStore } from "frontend/stores/profile";
import { calculateTimescaleBoundaries } from "frontend/utils/base-calendar-utils";
import { DateFormat, formatDate } from "shared/utils/date-utils";

export function profiledUnitLabel(unit: Unit): string {
  const profileStore = useProfileStore();

  const result = [];

  if (profileStore.profile.unit_show_patient_name)
    result.push(
      unit.appointment?.patient?.first_name.concat(
        " ",
        unit.appointment?.patient?.name
      )
    );

  if (profileStore.profile.unit_show_appointment_type_name)
    result.push(
      unit.name ?? unit.session?.name ?? unit.appointment?.appointment_type.name
    );

  if (profileStore.profile.unit_show_room) result.push(unit.room?.name);

  if (profileStore.profile.unit_show_internal_office_note)
    result.push(unit.appointment?.internal_office_note);

  if (profileStore.profile.unit_show_patient_message)
    result.push(unit.appointment?.message);

  const filteredResult = result.filter((element) => !!element);
  if (filteredResult.length <= 0 && unit.name) filteredResult.push(unit.name);

  if (unit.belongs_to_search && unit.appointment?.order_time_in_minutes) {
    return `Einbestellzeit ${formatDate(
      unit.appointment.begin_for_patient,
      DateFormat.TimeOnlyWithoutSeconds
    )}`;
  }

  return filteredResult.join(" / ") ?? "";
}

export function profiledCalendarConstants(
  constants: CalendarConstants
): CalendarConstants {
  const profileStore = useProfileStore();

  const baseTime = profileStore.profile.calendar_timescale_in_minutes;

  return {
    ...constants,
    TIMESCALE_STEP_SIZE: baseTime,
    TIMESCALE_VISUAL_INDICATOR_STEP_SIZE: profileStore.profile
      .calendar_show_visual_lines
      ? constants.TIMESCALE_VISUAL_INDICATOR_STEP_SIZE
      : undefined,
  };
}

export function profiledTimescaleBoundariesOptions(): Parameters<
  typeof calculateTimescaleBoundaries
>[4] {
  const profileStore = useProfileStore();

  const result: Parameters<typeof calculateTimescaleBoundaries>[4] = {};

  if (profileStore.profile.calendar_range_begin_local_as_utc) {
    result.overrideMinDate =
      profileStore.profile.calendar_range_begin_local_as_utc;
  }

  if (profileStore.profile.calendar_range_end_local_as_utc) {
    result.overrideMaxDate =
      profileStore.profile.calendar_range_end_local_as_utc;
  }

  return result;
}
