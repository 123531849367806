import { Ref, ref } from "vue";

import { log, LogLevel } from "shared/utils/logger";

export class PendingLogic {
  localIsPending: Ref<boolean>;

  constructor() {
    this.localIsPending = ref<boolean>(false);
  }

  isPending() {
    return this.isPendingR().value;
  }

  isPendingR() {
    return this.localIsPending;
  }

  startPending() {
    this.localIsPending.value = true;
  }

  stopPending() {
    this.localIsPending.value = false;
  }

  async doWithPending<T>(callback: () => Promise<T>): Promise<T | void> {
    try {
      if (this.isPending()) return;
      this.startPending();

      return await callback();
    } catch (error) {
      log(LogLevel.Error, "could not do with pending", error);
    } finally {
      this.stopPending();
    }
  }
}

// suffix "R" indicates "Reactive"
