import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";
import { assertEntryType } from "shared/utils/typescript-helper";

export type SettingsProfileContextName = keyof typeof SETTINGS_PROFILE_CONTEXTS;

export type SettingsProfilePropertyName = Exclude<
  keyof SettingsProfile,
  "id" | "name" | "is_office_default"
>;

interface SettingsProfileContext {
  readonly showControlsFor: (
    propertyName: SettingsProfilePropertyName
  ) => boolean;
  readonly labelFor: (propertyName: SettingsProfilePropertyName) => string;
  readonly showEditButton: boolean;
}

const DEFAULT_VISIBILITY: Readonly<
  Record<SettingsProfilePropertyName, boolean>
> = Object.freeze({
  calendar_timescale_in_minutes: true,
  calendar_show_visual_lines: true,
  calendar_default_view: false,
  calendar_shown_weekdays: false,
  calendar_sorted_persons: false,
  calendar_removed_persons: false,
  calendar_range_begin_local_as_utc: false,
  calendar_range_end_local_as_utc: false,

  unit_show_appointment_type_name: true,
  unit_show_patient_name: true,
  unit_show_room: true,
  unit_show_internal_office_note: true,
  unit_show_patient_message: true,
});

const DEFAULT_LABEL: Readonly<Record<SettingsProfilePropertyName, string>> =
  Object.freeze({
    calendar_timescale_in_minutes: "Schrittlänge (Minuten)",
    calendar_show_visual_lines: "Hilfslinien im Kalender",
    calendar_default_view: "Standardansicht im Kalender",
    calendar_shown_weekdays: "Wochentage in Wochenansicht im Kalender",
    calendar_sorted_persons: "Mitarbeiter in Tagesansicht im Kalender",
    calendar_removed_persons: "Mitarbeiter in Tagesansicht im Kalender",
    calendar_range_begin_local_as_utc: "Kalenderstartzeit",
    calendar_range_end_local_as_utc: "Kalenderendzeit",

    unit_show_appointment_type_name: "Vorstellungsanlass anzeigen",
    unit_show_patient_name: "Patientennamen anzeigen",
    unit_show_room: "Raum anzeigen",
    unit_show_internal_office_note: "Interne Kurznotiz anzeigen",
    unit_show_patient_message: "Patientennachricht anzeigen",
  });

const DEFAULT_CONTEXT: SettingsProfileContext = Object.freeze({
  showControlsFor: (propertyName: SettingsProfilePropertyName) =>
    !!DEFAULT_VISIBILITY[propertyName],
  labelFor: (propertyName: SettingsProfilePropertyName) =>
    DEFAULT_LABEL[propertyName] ?? "Unbekannte Einstellung",
  showEditButton: true,
});

const SETTINGS_PROFILE_CONTEXTS = {
  default: DEFAULT_CONTEXT,
  edit: Object.freeze({
    ...DEFAULT_CONTEXT,
    showControlsFor: () => true,
    showEditButton: false,
  }),
  "calendar-day-view": Object.freeze({
    ...DEFAULT_CONTEXT,
    showControlsFor: (propertyName: SettingsProfilePropertyName) =>
      propertyName === "calendar_removed_persons"
        ? true
        : DEFAULT_CONTEXT.showControlsFor(propertyName),
    labelFor: (propertyName: SettingsProfilePropertyName) =>
      propertyName === "calendar_removed_persons"
        ? "Mitarbeiter"
        : DEFAULT_CONTEXT.labelFor(propertyName),
  }),
  "calendar-week-view": Object.freeze({
    ...DEFAULT_CONTEXT,
    showControlsFor: (propertyName: SettingsProfilePropertyName) =>
      propertyName === "calendar_shown_weekdays"
        ? true
        : DEFAULT_CONTEXT.showControlsFor(propertyName),
    labelFor: (propertyName: SettingsProfilePropertyName) =>
      propertyName === "calendar_shown_weekdays"
        ? "Wochentage"
        : DEFAULT_CONTEXT.labelFor(propertyName),
  }),
};

const SettingsProfileContexts = Object.freeze(
  SETTINGS_PROFILE_CONTEXTS
) as Readonly<Record<SettingsProfileContextName, SettingsProfileContext>>;

export function settingsProfileContextFor(
  contextName: SettingsProfileContextName
): SettingsProfileContext {
  return SettingsProfileContexts[contextName] || DEFAULT_CONTEXT;
}

// ----------------------------------------------------------------------------
// compile time assertions
// if you get an error here,
//   you made something wrong in SETTINGS_PROFILE_CONTEXTS
//   typewise
// ----------------------------------------------------------------------------
assertEntryType<SettingsProfileContext, typeof SETTINGS_PROFILE_CONTEXTS>(
  SETTINGS_PROFILE_CONTEXTS,
  "SETTINGS_PROFILE_CONTEXTS contains an entry that is not of type SettingsProfileContext"
);
// ----------------------------------------------------------------------------
