import {
  HttpMethod,
  HttpPayload,
  HttpResponse,
} from "frontend/interfaces/http";
import { PathURLTemplate, PathParams } from "shared/utils/pathify";
import { pathTemplates } from "shared/static/path-templates";
import { log, LogLevel } from "shared/utils/logger";
import { base } from "frontend/api/base";

export const routes = pathTemplates.praxisapp;

export async function doRequest(
  method: HttpMethod,
  path: PathURLTemplate,
  params: PathParams,
  payload: HttpPayload
): Promise<HttpResponse> {
  log(LogLevel.Info, "[REQUEST] Doing a request to praxisapp backend");
  return await base(method, path, params, payload, {});
}
