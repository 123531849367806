/* rails-erb-loader-dependencies objects/utils/state_symbols.rb */

import { ArrayElement } from "shared/utils/typescript-helper";

export const States = Object.freeze([
  
  Object.freeze({ id: "bw", label: "Baden-Württemberg" }),
  
  Object.freeze({ id: "by", label: "Bayern" }),
  
  Object.freeze({ id: "be", label: "Berlin" }),
  
  Object.freeze({ id: "bb", label: "Brandenburg" }),
  
  Object.freeze({ id: "hb", label: "Bremen" }),
  
  Object.freeze({ id: "hh", label: "Hamburg" }),
  
  Object.freeze({ id: "he", label: "Hessen" }),
  
  Object.freeze({ id: "mv", label: "Mecklenburg-Vorpommern" }),
  
  Object.freeze({ id: "ni", label: "Niedersachsen" }),
  
  Object.freeze({ id: "nw", label: "Nordrhein-Westfalen" }),
  
  Object.freeze({ id: "rp", label: "Rheinland-Pfalz" }),
  
  Object.freeze({ id: "sl", label: "Saarland" }),
  
  Object.freeze({ id: "sn", label: "Sachsen" }),
  
  Object.freeze({ id: "st", label: "Sachsen-Anhalt" }),
  
  Object.freeze({ id: "sh", label: "Schleswig-Holstein" }),
  
  Object.freeze({ id: "th", label: "Thüringen" }),
  
]);
export type StateElement = ArrayElement<typeof States>;
