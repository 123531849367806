import { requestLogin } from "frontend/api/application/request-login";
import { UserEntity } from "frontend/interfaces/user";
import { useStore } from "frontend/stores/login";
import { log, LogLevel } from "shared/utils/logger";

export function usePatientLogin(): {
  tryRequestLogin: () => Promise<boolean>;
} {
  const store = useStore();

  return {
    tryRequestLogin: async () => {
      if (!store.mobileToken) {
        log(LogLevel.Debug, "[USEPATIENTLOGIN] no mobile token found");
        return false;
      }

      const { success } = await requestLogin(store.mobileToken);
      log(
        LogLevel.Debug,
        "[USEPATIENTLOGIN] login request was successful? " + success
      );

      if (success) {
        store.login(UserEntity.Patient);
        return true;
      } else {
        store.mobileToken = null;
        return false;
      }
    },
  };
}
