import { parseISO, max, addMinutes, startOfDay, endOfDay, min } from "date-fns";
import { v4 as uuid } from "uuid";
import { computed, reactive } from "vue";

import {
  Unit,
  UnitRaw,
  TmpUnit,
  UnitWithoutAppointmentRaw,
  UnitAppointmentRaw,
  UnitAppointment,
  UnitWithoutAppointment,
} from "frontend/interfaces/unit";
import { ConstantsDayView } from "frontend/utils/constants";
import { Interval } from "shared/interfaces/interval";
import { HttpPayload } from "frontend/interfaces/http";
import { formatDate, DateFormat } from "shared/utils/date-utils";
import { Patient } from "frontend/interfaces/patient";
import { AppointmentType } from "frontend/interfaces/appointment-type";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { parsePatient } from "frontend/parser/parse-patient";
import { Session } from "frontend/interfaces/session";

function calculateVisual(duration: Interval, displayDate?: Date): Interval {
  const day = displayDate ? displayDate : duration.from;

  return {
    from: max([new Date(duration.from), startOfDay(day)]),
    to: min([
      endOfDay(day),
      max([
        new Date(duration.to),
        addMinutes(duration.from, ConstantsDayView.DISPLAY_DURATION_AT_LEAST),
      ]),
    ]),
  };
}

export function parseUnitInterval(unit: {
  from: string;
  to: string;
}): Interval {
  return {
    from: parseISO(unit.from),
    to: parseISO(unit.to),
  };
}

function parseUnitWithoutAppointment(
  unit: UnitWithoutAppointmentRaw,
  displayDate?: Date
): UnitWithoutAppointment {
  const interval = parseUnitInterval(unit);
  return {
    ...unit,
    ...interval,
    visual: calculateVisual(interval, displayDate),
    participations: unit.participations.map((p) => ({ ...p })),
    ...(unit.room ? { room: { ...unit.room } } : {}),
    ...(unit.session ? { session: { ...unit.session } } : {}),
  };
}

function parseUnitAppointment(
  appointment: UnitAppointmentRaw
): UnitAppointment {
  return {
    ...appointment,
    begin_for_patient: parseISO(appointment.begin_for_patient),
    patient: parsePatient(appointment.patient),
    units: appointment.units.map((app) => parseUnitWithoutAppointment(app)),
  };
}

export function parseUnit(unit: UnitRaw, displayDate?: Date): Unit {
  const { appointment, ...unitWithoutAppointment } = unit;

  return {
    ...(appointment ? { appointment: parseUnitAppointment(appointment) } : {}),
    ...parseUnitWithoutAppointment(unitWithoutAppointment, displayDate),
  };
}

export function unitForQuickCreate(
  unit: TmpUnit,
  patient: Patient,
  appointmentType: AppointmentType,
  orderTime: number
): HttpPayload {
  return {
    appointment: {
      patient_id: patient.id,
      appointment_type_id: appointmentType.id,
      order_time_in_minutes: orderTime,
    },
    participation: {
      person_id: unit.person_id,
    },
    unit: {
      from: formatDate(unit.from, DateFormat.ISO),
      to: formatDate(unit.to, DateFormat.ISO),
      color_id: unit.color_id,
      room_id: unit.room_id,
    },
  };
}

export function unitsForQuickCreate(
  units: TmpUnit[],
  patient: Patient,
  appointmentType: AppointmentType,
  orderTime: number,
  notifyPatient: boolean,
  remindPatient: boolean
): HttpPayload {
  return {
    appointment: {
      patient_id: patient.id,
      appointment_type_id: appointmentType.id,
      order_time_in_minutes: orderTime,
      do_not_remind: !remindPatient,
      do_not_notify: !notifyPatient,
    },
    units: units.map((unit) => ({
      from: formatDate(unit.from, DateFormat.ISO),
      to: formatDate(unit.to, DateFormat.ISO),
      color_id: unit.color_id,
      room_id: unit.room_id,
      participation: {
        person_id: unit.person_id,
      },
      name: unit.name,
    })),
  };
}

export function unitNameOnlyForQuickCreate(unit: TmpUnit): HttpPayload {
  return {
    unit: {
      participations: {
        person_id: unit.person_id,
      },
      name: unit.name,
      from: formatDate(unit.from, DateFormat.ISO),
      to: formatDate(unit.to, DateFormat.ISO),
      color_id: unit.color_id,
      room_id: unit.room_id,
    },
  };
}

export function newClickUnit(
  person_id: PrimaryKey | null,
  from?: Date
): TmpUnit {
  const date = from ?? new Date();

  const result: TmpUnit = reactive({
    key: `MOCK-${uuid()}`,
    from: date,
    to: addMinutes(date, ConstantsDayView.CREATION_DEFAULT_DURATION),
    person_id,
    visual: computed(() => calculateVisual(result)),
    room_id: null,
    color_id: null,
    name: "",
  });
  return result;
}

export function unitFromSession(
  from: Date,
  person_id: PrimaryKey | null,
  session: Session
): TmpUnit {
  const result: TmpUnit = reactive({
    key: `MOCK-${uuid()}`,
    from,
    to: addMinutes(from, session.duration),
    person_id,
    visual: computed(() => calculateVisual(result)),
    room_id: null,
    color_id: session.color_id,
    name: session.name,
  });
  return result;
}
