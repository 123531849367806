import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { useStore } from "frontend/stores/login";
import { log, LogLevel } from "shared/utils/logger";

const PARAM_NAME = "mobileToken";

/**
 * Navigation Guard
 * Checks if the user is authenticated and redirects him if not.
 *
 * @export
 * @param {RouteLocationNormalized} to
 * @returns {(RouteLocationRaw | void)}
 */
export function navigationGuardMobileToken(
  to: RouteLocationNormalized
): RouteLocationRaw | void {
  if (
    to.query &&
    to.query[PARAM_NAME] &&
    typeof to.query[PARAM_NAME] === "string"
  ) {
    log(LogLevel.Debug, "[LOGIN] found mobile token");
    const store = useStore();
    store.mobileToken = to.query[PARAM_NAME] as string;

    const result = {
      ...to,
    };
    result.query = {
      ...result.query,
    };

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- legacy implementation, maybe fix upon touching
    delete result.query[PARAM_NAME];
    return result;
  }
}
