import { Modal } from "frontend/utils/modals/modal";
import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";
import { createSettingsProfile } from "frontend/api/application/request-settings-profiles";
import { httpPayloadSettingsProfile } from "frontend/parser/parse-settings-profile";
import { ErrorDatabase } from "frontend/uses/use-errors";
import { useStore as useProfileStore } from "frontend/stores/profile";

import CreateSettingsProfileComponent from "frontend/components/CreateSettingsProfile.vue";

export interface CreateProfileModalData {
  profile: SettingsProfile;
  errorDatabase: ErrorDatabase;
  editMode?: boolean; // only edit given profile (without creating a new one or persisting the existing one to the backend)
}
export class CreateProfileModal extends Modal<
  typeof CreateSettingsProfileComponent,
  CreateProfileModalData
> {
  protected modalComponent() {
    return CreateSettingsProfileComponent;
  }

  protected confirmLabel(): "anlegen" | "Ok" {
    if (this.getData()?.editMode) return "Ok";

    return "anlegen";
  }

  protected hideCancel(): boolean {
    return !!this.getData()?.editMode;
  }

  protected async onClose(
    userConfirmed: boolean,
    closeModal: () => void,
    _forceClose = true
  ): Promise<void> {
    super.onClose(userConfirmed, closeModal, false);

    const data = this.getData();

    if (data && !data.editMode && userConfirmed) {
      const result = await createSettingsProfile(
        httpPayloadSettingsProfile(data.profile)
      );

      this.setSuccess(result.success);

      if (result.success) {
        closeModal();
        await useProfileStore().fetchProfiles();
      } else data.errorDatabase = result.errors;
    } else {
      closeModal();
    }
  }
}
