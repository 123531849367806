import { computed, ComputedRef, ref, Ref, unref, watch } from "vue";

import { DayViewRaw, WeekViewRaw } from "frontend/interfaces/day-view";
import { Calendar } from "frontend/interfaces/calendar";
import { PrimaryKey } from "frontend/interfaces/primary-key";

export function useAbstractView(
  day: Ref<Date | null>,
  personID: Ref<PrimaryKey | null>,
  calendarParser: (
    date: Date,
    calendarView: DayViewRaw | WeekViewRaw
  ) => Calendar | null,
  calendarView: (
    date: Date,
    searchRef: string | null,
    personIDs: string
  ) => Promise<DayViewRaw | WeekViewRaw | null>,
  options?: {
    searchRef: Ref<PrimaryKey | null>;
  }
): {
  date: ComputedRef<Date | null>;
  calendar: Ref<Calendar | null>;
  triggerReload: () => void;
} {
  const triggerPoint = ref<number>(0);

  // Calendar
  const date: ComputedRef<Date | null> = computed(() => {
    return unref(day);
  });
  const backendData = ref<DayViewRaw | WeekViewRaw | null>(null);

  const calendar = computed(() => {
    const backendDataUnref = unref(backendData);
    const theDate = unref(date);

    if (!backendDataUnref) return null;
    if (!theDate) return null;
    if (!backendDataUnref.persons || backendDataUnref.persons.length <= 0)
      return null;

    return calendarParser(theDate, backendDataUnref);
  });

  const searchRef =
    (options || {}).searchRef ||
    computed(() => {
      return null;
    });

  // Request data as the selected date changes
  // TODO: (maybe) refine this to a universal pattern to be used everywhere in the application for all sorts of requests
  watch(
    [date, searchRef, triggerPoint, personID],
    async (newValues) => {
      const newDate = newValues[0] as Date;
      const newSearchRef = newValues[1] as string | null;
      const newPerson = newValues[3] as string;

      if (!newDate) {
        return;
      }
      backendData.value = await calendarView(newDate, newSearchRef, newPerson);
    },
    { immediate: true }
  );

  const triggerReload = () => {
    triggerPoint.value = triggerPoint.value + 1;
  };

  return {
    date,
    calendar,
    triggerReload,
  };
}
