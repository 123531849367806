import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { requestLoginStatus } from "frontend/api/application/request-misc";

/**
 * Office Blocked Guard
 * Checks if the office of the current user is blocked.
 *
 * @export
 * @param {RouteLocationNormalized} to
 * @returns {(RouteLocationRaw | void)}
 */
export async function navigationGuardRequestLoginStatus(
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | void> {
  if (to.meta?.requestLoginStatus) {
    await requestLoginStatus();
  }
}
