import { parseISO } from "date-fns";
import { v4 as uuid } from "uuid";

import {
  Attendance,
  AttendanceNew,
  AttendanceRaw,
} from "frontend/interfaces/settings/attendance";
import {
  atDefaultStartOf,
  atDefaultEndOf,
  DateFormat,
  formatDate,
  shiftFromLocalToUTC,
  today,
} from "shared/utils/date-utils";
import { HttpPayload } from "frontend/interfaces/http";

export function parseAttendance(attendance: AttendanceRaw): Attendance {
  return {
    ...attendance,
    from: parseISO(attendance.from_local_as_utc),
    to: parseISO(attendance.to_local_as_utc),
    days: [...attendance.days],
    active_on_days: attendance.active_on_days
      ? [...attendance.active_on_days.map((day) => parseISO(day))]
      : null,
    session_ids: [...attendance.session_ids],
    _sessions: [...(attendance._sessions || []).map((entry) => ({ ...entry }))],
  };
}

export function httpPayloadAttendance(attendance: Attendance): HttpPayload {
  return {
    ...attendance,
    from: null,
    to: null,
    from_local_as_utc: formatDate(attendance.from, DateFormat.ISO),
    to_local_as_utc: formatDate(attendance.to, DateFormat.ISO),
    active_on_days: null,
  };
}

export function newAttendance(): AttendanceNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    from: shiftFromLocalToUTC(atDefaultStartOf(today())),
    to: shiftFromLocalToUTC(atDefaultEndOf(today())),
    active_on_days: null,
    person_id: "",
    person_name: "",
    color_id: null,
    location_id: "",
    schedule_id: "",
    days: [],
    configs: 0,
    session_ids: [],
    _sessions: [],
    unsaved: true,
  };
}
