import { routes } from "frontend/api/application";
import { requestGenericSettings } from "frontend/api/application/settings/request-generic-settings";
import { AppointmentTypeRaw } from "frontend/interfaces/settings/appointment-type";

export const api = requestGenericSettings<AppointmentTypeRaw>({
  endpoints: {
    indexEndpoint: routes.paths.frontend_settings_appointment_types_path,
    showEndpoint: routes.paths.frontend_settings_appointment_type_path,
  },
});
