<script lang="ts" setup>
  import { onMounted, ref } from "vue";

  import { useStore as useOfficeStore } from "frontend/stores/office";
  import { Operator } from "frontend/interfaces/operator";
  import { selectOperator } from "frontend/api/application/request-operators";

  import OperatorTag from "frontend/components/OperatorTag.vue";
  import BasePopup from "frontend/components/base/BasePopup.vue";

  const officeStore = useOfficeStore();

  const showSelector = ref<boolean>(false);
  onMounted(() => {
    showSelector.value = false;
  });

  const doSelectOperator = async (operator: Operator) => {
    await selectOperator(operator.id);
  };
</script>

<template>
  <span class="operator-selector">
    <template v-if="officeStore.operatorsEnabled">
      <BasePopup
        v-model="showSelector"
        enable-close
        v-bind:placement="'bottom-start'"
      >
        <div v-on:click.stop="showSelector = !showSelector">
          <OperatorTag
            v-if="officeStore.currentOperator"
            v-bind:operator="officeStore.currentOperator"
          />
          <span
            v-else
            v-tooltip="'Kein Praxisanwender ausgewählt!'"
            class="operator-selecto__no-selection"
            ><i class="fe fe-exclamation--with-triangle"></i
          ></span>
        </div>

        <template v-slot:popup>
          <div class="operator-selector__popup-container">
            <div class="operator-select__description">
              Aktiven
              <RouterLink v-bind:to="{ name: 'settings-operators' }"
                ><u>Praxisanwender</u></RouterLink
              >
              auswählen:
            </div>
            <div
              v-for="operator in officeStore.operators"
              v-bind:key="operator.id"
              class="operator-selector__option"
              v-bind:class="{
                'operator-selector__option--active':
                  operator.id === officeStore.currentOperator?.id,
              }"
              v-on:click="doSelectOperator(operator)"
            >
              <span class="operator-selector__tag">
                <OperatorTag v-bind:operator="operator" />
              </span>
              {{ operator.name }}
            </div>
          </div>
        </template>
      </BasePopup>
    </template>
  </span>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .operator-selector {
    cursor: pointer;
  }

  .operator-selector__popup-container {
    padding: 10px;
    background-color: colors.$color_operator-selector_popup--background;
  }

  .operator-selector__tag {
    display: inline-block;
    min-width: 60px;
    margin-bottom: 3px;
  }

  .operator-selector__option {
    padding: 5px;

    &:hover,
    &.operator-selector__option--active {
      background-color: colors.$color_operator-selector--hover;
    }
  }

  .operator-select__description {
    font-size: 11px;
    padding: 10px;
  }

  .operator-selecto__no-selection {
    display: inline-block;

    font-size: 20px;
    color: colors.$color_operator-tag--no-selection;

    margin-top: 12px;
    margin-left: 5px;
  }
</style>
