<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { formatDate, DateFormat, isSameDate } from "shared/utils/date-utils";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: {
      FormCheckbox,
      BaseSpinner,
      FormInput,
    },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      unitId: {
        type: String,
        required: true,
      },
      begin: {
        type: Date,
        required: true,
      },
      beginForPatient: {
        type: Date,
        required: true,
      },
      notifyPatient: {
        type: Boolean,
        default: true,
      },
      reason: {
        type: String as PropType<string | null>,
        default: null,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:notifyPatient", "update:reason"],
    setup(_, { emit }) {
      return {
        onUpdateNotifyPatient: (newValue: boolean) => {
          emit("update:notifyPatient", newValue);
        },
        onUpdateReason: (newValue: string) => {
          emit("update:reason", newValue);
        },

        //libs
        formatDate,
        DateFormat,

        isSameDate,
      };
    },
  });
</script>

<template>
  <div class="appointment-suggestions">
    <h3>Bestätigung</h3>
    <p>
      Sind Sie sicher, dass Sie den Termin auf
      <strong>
        {{ formatDate(begin, DateFormat.DateOnlyLong) }} um
        {{ formatDate(begin, DateFormat.TimeOnlyWithoutSeconds) }} Uhr</strong
      >
      <span v-if="!isSameDate(begin, beginForPatient)">
        (Einbestellzeit:
        {{ formatDate(beginForPatient, DateFormat.TimeOnlyWithoutSeconds) }}
        Uhr)</span
      ><br />
      verschieben möchten?
    </p>
    <br /><br />
    <FormCheckbox
      label="den Patienten über den verschobenen Termin informieren"
      v-bind:model-value="notifyPatient"
      v-on:update:model-value="onUpdateNotifyPatient"
    />
    <FormInput
      v-if="notifyPatient"
      v-bind:model-value="reason"
      label=" Nachrichtentext an den Patienten (z.B Grund der Verschiebung)"
      type="textarea"
      v-on:update:model-value="onUpdateReason"
    />
    <br /><br />
    <div v-if="isLoading" class="move-appointment__loading">
      <BaseSpinner />
    </div>
  </div>
</template>
