import { addMinutes, differenceInMinutes } from "date-fns";

import {
  PopupAppointmentEntry,
  CalendarEntryTmp,
  CalendarEntryType,
  CalendarRelativeLocation,
} from "frontend/interfaces/calendar";
import { AppointmentType } from "frontend/interfaces/appointment-type";
import { Interval } from "shared/interfaces/interval";
import { Patient } from "frontend/interfaces/patient";
import { Person } from "frontend/interfaces/person";
import { TmpUnit } from "frontend/interfaces/unit";
import {
  newClickUnit,
  unitNameOnlyForQuickCreate,
  unitsForQuickCreate,
} from "frontend/parser/parse-unit";
import {
  dateFromIntervalAndLocation,
  heightPercentage,
  higherOrderIsInInterval,
  topOffsetPercentage,
} from "frontend/utils/base-calendar-utils";
import { ConstantsDayView } from "frontend/utils/constants";
import { createAppointment } from "frontend/api/application/request-appointments";
import { createUnit } from "frontend/api/application/request-unit";
import {
  AbstractViewData,
  AbstractViewCellPosition,
  AbstractViewOptions,
  Database,
} from "frontend/uses/abstract-view/use-abstract-view-parser";
import { profiledCalendarConstants } from "frontend/utils/profile-helper";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { router } from "frontend/router";
import { pullOnce, putOnce } from "frontend/uses/use-transience";

import BCTmpComponent from "frontend/components/base-calendar/BCTmp.vue";

export function entriesTmpUnits(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): Array<CalendarEntryTmp> {
  return [
    database.clickUnit?.person_id === person?.id ? database.clickUnit : null,
  ]
    .filter(
      (candidate): candidate is NonNullable<typeof database.clickUnit> =>
        !!candidate
    )
    .filter(
      higherOrderTmpUnitIsInInterval(interval, columnInterval, cellPosition)
    )
    .map((tmpUnit) =>
      tmpUnitToEntry(
        tmpUnit,
        person,
        interval,
        columnInterval,
        cellPosition,
        database,
        options
      )
    );
}

// can be used as replacement to tmpUnitToEntry
// if there is no database or abstract view involved
// and it is only a PopupAppointment needed
export function tmpUnitToPopupAppointmentEntry(
  tmpUnit: TmpUnit
): PopupAppointmentEntry {
  return {
    key: tmpUnit.key,
    unit: tmpUnit,

    doDestroy: () => {
      return;
    },
    doSubmit: async (
      units: TmpUnit[],
      patient: Patient,
      appointmentType: AppointmentType,
      orderTime: number,
      notifyPatient: boolean,
      remindPatient: boolean
    ) => {
      return await createAppointment(
        unitsForQuickCreate(
          units,
          patient,
          appointmentType,
          orderTime,
          notifyPatient,
          remindPatient
        )
      );
    },
    doSubmitUnit: async () => {
      return await createUnit(unitNameOnlyForQuickCreate(tmpUnit));
    },

    onPersonChange: (newPersonId: PrimaryKey) => {
      tmpUnit.person_id = newPersonId;
    },

    onDateChange: (from: Date, to: Date) => {
      tmpUnit.from = from;
      tmpUnit.to = to;
    },
  };
}

export function tmpUnitToEntry(
  tmpUnit: TmpUnit,
  person: Person | null,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarEntryTmp {
  return {
    key: tmpUnit.key,
    componentCalendar: () => BCTmpComponent,

    topOffsetPercentage: topOffsetPercentage(
      tmpUnit.visual.from,
      interval.from,
      cellPosition,
      profiledCalendarConstants(ConstantsDayView)
    ),
    heightPercentage: heightPercentage(
      tmpUnit.visual,
      profiledCalendarConstants(ConstantsDayView)
    ),

    type: CalendarEntryType.Tmp,
    doDestroy: () => {
      updateClickUnit(
        null,
        person,
        interval,
        columnInterval,
        database,
        options
      );
    },
    doSubmit: async (
      units: TmpUnit[],
      patient: Patient,
      appointmentType: AppointmentType,
      orderTime: number,
      notifyPatient: boolean,
      remindPatient: boolean
    ) => {
      return await createAppointment(
        unitsForQuickCreate(
          units,
          patient,
          appointmentType,
          orderTime,
          notifyPatient,
          remindPatient
        )
      );
    },
    doSubmitUnit: async () => {
      return await createUnit(unitNameOnlyForQuickCreate(tmpUnit));
    },

    onPersonChange: (newPersonId: PrimaryKey) => {
      tmpUnit.person_id = newPersonId;

      if (database.persons?.map((p) => p.id).indexOf(newPersonId) >= 0) return;
      else {
        putOnce("abstract-view__clickUnit", tmpUnit);
        router.push({ query: { person: newPersonId } });
      }
    },

    onDateChange: (from: Date, to: Date) => {
      tmpUnit.from = from;
      tmpUnit.to = to;
    },

    unit: tmpUnit,
  };
}

function higherOrderTmpUnitIsInInterval(
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition
): (tmpUnit: TmpUnit) => boolean {
  return (tmpUnit: TmpUnit) =>
    higherOrderIsInInterval(
      interval,
      columnInterval,
      cellPosition
    )(tmpUnit.from, tmpUnit.to);
}

export function onClickCreateClickUnit(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  database: Database,
  options: AbstractViewOptions
): (location: CalendarRelativeLocation) => void {
  return (location: CalendarRelativeLocation) => {
    const clickedDate = dateFromIntervalAndLocation(
      interval,
      location,
      profiledCalendarConstants(ConstantsDayView).ROUND_CLICK_TO_NEAREST
    );

    const clickUnit = database.clickUnit ?? newClickUnit(person.id);

    const unitDurationInMinutes = differenceInMinutes(
      clickUnit.to,
      clickUnit.from
    );
    clickUnit.from = clickedDate;
    clickUnit.to = addMinutes(clickedDate, unitDurationInMinutes);
    clickUnit.person_id = person.id;

    updateClickUnit(
      clickUnit,
      person,
      interval,
      columnInterval,
      database,
      options
    );
  };
}

function updateClickUnit(
  clickUnit: TmpUnit | null,
  _person: Person | null,
  _interval: Interval,
  _columnInterval: Interval,
  database: Database,
  _options: AbstractViewOptions
): void {
  database.clickUnit = clickUnit;
}

export function setupClickUnit(
  data: AbstractViewData,
  _options: AbstractViewOptions
): TmpUnit | null {
  if (data.persons && data.persons.length > 0)
    return pullOnce("abstract-view__clickUnit") ?? null;
  else return null;
}
