<script lang="ts">
  import {
    computed,
    defineComponent,
    onUnmounted,
    PropType,
    ref,
    toRefs,
    unref,
  } from "vue";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import {
    Droplet,
    Pillar,
    PillarCalendar,
  } from "frontend/interfaces/pillar-calendar";
  import { StyleValue } from "frontend/interfaces/template";
  import { useResponsiveness } from "frontend/uses/use-responsiveness";

  import BaseBox from "frontend/components/base/BaseBox.vue";

  const DEFAULT_NUMBER_OF_PILLARS = 4;
  const PILLAR_MIN_WIDTH_PX = 80;

  export default defineComponent({
    components: { BaseBox },
    props: {
      pillarCalendar: {
        type: Object as PropType<PillarCalendar>,
        required: true,
      },
      label: {
        type: String,
        default: "",
      },
      additionalLabel: {
        type: String as PropType<string | null>,
        default: null,
      },
    },
    emits: ["click:additionalLabel"],
    setup(props) {
      const { pillarCalendar } = toRefs(props);
      // currently index of first shown pillar
      const pillarIndex = ref<number>(0);
      const totalNumberOfPillars = computed<number>(() => {
        return unref(pillarCalendar).pillars.length;
      });
      // make number of shown pillars responsive
      const numberOfPillars = ref<number>(DEFAULT_NUMBER_OF_PILLARS);
      const { element: grid, doDisconnect } = useResponsiveness((info) => {
        setTimeout(() => {
          const newNumberOfPillars = Math.min(
            Math.max(
              1,
              Math.floor(info.contentRect.width / PILLAR_MIN_WIDTH_PX)
            ),
            unref(totalNumberOfPillars)
          );
          numberOfPillars.value = newNumberOfPillars;
          if (
            unref(pillarIndex) + unref(numberOfPillars) >=
            unref(totalNumberOfPillars)
          ) {
            pillarIndex.value = Math.max(
              0,
              unref(totalNumberOfPillars) - newNumberOfPillars
            );
          }
        }, 100);
      });
      onUnmounted(doDisconnect);
      // calculate which pillars to show
      const activePillars = computed<Array<Pillar>>(() => {
        // return unref(pillarCalendar).pillars;
        return unref(pillarCalendar).pillars.slice(
          unref(pillarIndex),
          unref(pillarIndex) + unref(numberOfPillars)
        );
      });
      const numberOfDropletRows = computed<number>(() => {
        return Math.max(
          ...unref(activePillars).map((pillar) => pillar.droplets.length)
        );
      });
      const calculateNumberOfFillers = (droplets: Array<Droplet>) => {
        return Math.max(0, unref(numberOfDropletRows) - droplets.length);
      };
      const stylingGrid = computed<StyleValue>(() => {
        return {
          "grid-template-columns": `auto repeat(${unref(
            numberOfPillars
          )}, 1fr) auto`,
          "grid-template-rows": `auto repeat(${unref(
            numberOfDropletRows
          )}, auto)`,
        };
      });
      const stylingSpacer = computed<StyleValue>(() => {
        return {
          "grid-row": `2 / ${unref(numberOfDropletRows) + 2}`,
        };
      });
      const leftNavigationAvailable = computed<boolean>(() => {
        return unref(pillarIndex) !== 0;
      });
      const rightNavigationAvailable = computed<boolean>(() => {
        return (
          unref(pillarIndex) + unref(numberOfPillars) <
          unref(totalNumberOfPillars)
        );
      });
      const navigate = (direction: number) => {
        const candidatePillarIndex = unref(pillarIndex) + direction;
        pillarIndex.value = Math.max(
          0,
          Math.min(
            candidatePillarIndex,
            unref(totalNumberOfPillars) - unref(numberOfPillars)
          )
        );
      };
      const navigateOneSheet = (direction: number) => {
        navigate(Math.sign(direction) * unref(numberOfPillars));
      };
      return {
        grid,
        stylingGrid,
        stylingSpacer,
        leftNavigationAvailable,
        rightNavigationAvailable,
        navigate,
        navigateOneSheet,
        activePillars,
        numberOfDropletRows,
        calculateNumberOfFillers,
        DateFormat,
        formatDate,
      };
    },
  });
</script>

<template>
  <div>
    <label v-if="label" class="pillar-calendar__label">{{ label }}</label>
    <BaseBox visual-no-heading>
      <div ref="grid" class="pillar-calendar" v-bind:style="stylingGrid">
        <!-- left navigation -->
        <div
          v-if="leftNavigationAvailable"
          class="pillar-calendar__header pillar-navigation pillar-navigation--left pillar-calendar__cell"
        >
          <i class="fe fe-arrow-left" v-on:click="navigate(-1)"></i>
        </div>
        <div v-bind:style="stylingSpacer" class="pillar-calendar__cell"></div>

        <!-- main calendar -->
        <template v-for="pillar in activePillars" v-bind:key="pillar.id">
          <div class="pillar-calendar__header pillar-calendar__cell">
            <div class="pillar-calendar__title">
              {{ formatDate(pillar.day, DateFormat.WeekdayShortOnly) }}
            </div>
            <div class="pillar-calendar__sub-title">
              {{ formatDate(pillar.day, DateFormat.DateOnlyWithoutYear) }}
            </div>
          </div>
          <div
            v-for="(droplet, dropletIndex) in pillar.droplets"
            v-bind:key="droplet.id"
            class="pillar-calendar__cell"
          >
            <div
              class="pillar-calendar__droplet"
              v-bind:class="{
                'pillar-calendar__droplet--first': dropletIndex === 0,
              }"
              v-on:click="droplet.onClick"
            >
              {{ formatDate(droplet.time, DateFormat.TimeOnlyWithoutSeconds) }}
            </div>
          </div>
          <!-- fill with empty spots -->
          <div
            v-for="index in calculateNumberOfFillers(pillar.droplets)"
            v-bind:key="index"
            class="pillar-calendar__cell"
          >
            <div class="pilar-calendar__empty-droplet"></div>
          </div>
        </template>

        <!-- right navigation -->
        <div
          v-if="rightNavigationAvailable"
          class="pillar-calendar__header pillar-navigation pillar-navigation--right pillar-calendar__cell"
        >
          <i class="fe fe-arrow-right" v-on:click="navigate(1)"></i>
        </div>
        <div v-bind:style="stylingSpacer" class="pillar-calendar__cell"></div>
      </div>

      <div
        v-if="!rightNavigationAvailable && additionalLabel"
        class="additional-label__container"
      >
        <div
          class="additional-label"
          v-on:click="$emit('click:additionalLabel', () => navigateOneSheet(1))"
        >
          {{ additionalLabel }}
        </div>
      </div>
    </BaseBox>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";

  .pillar-navigation {
    font-size: 25px;

    cursor: pointer;

    display: flex;
    align-items: center;
  }

  .pillar-calendar {
    display: grid;
    grid-auto-flow: column;
  }

  .pillar-calendar__cell {
    padding: 5px;

    text-align: center;
  }

  .pillar-calendar__droplet {
    display: inline-block;

    font-weight: bold;

    padding: 3px 8px;
    border-radius: dimensions.$dimension_pillar-calendar--droplet-radius;

    background-color: colors.$color_pillar-calendar__droplet--background;
    color: colors.$color_pillar-calendar__droplet--text;

    cursor: pointer;

    &:hover {
      background-color: color.scale(
        colors.$color_pillar-calendar__droplet--background,
        $lightness: -15%
      );
    }
  }

  .pilar-calendar__empty-droplet {
    display: inline-block;
    width: 20px;
    height: 3px;
    background-color: colors.$color_pillar-calendar__droplet-empty--background;
  }

  .pillar-calendar__header {
    border-bottom: 1px solid colors.$color_pillar-calendar__heading-separator;
  }

  .pillar-calendar__title,
  .pillar-calendar__sub-title {
    font-size: 14px;
    line-height: 1.3;
  }
  .pillar-calendar__title {
    font-weight: bold;
  }

  .pillar-calendar__droplet--first {
    margin-top: 15px;
  }

  // style label
  .pillar-calendar__label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 15px;
    display: block;
  }

  .additional-label__container {
    display: flex;
    justify-content: flex-end;

    margin-top: 20px;
  }

  .additional-label {
    cursor: pointer;
    color: colors.$color_link--text;
    font-size: 12px;
    font-weight: bold;
  }
</style>
