import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { OperatorRaw } from "frontend/interfaces/operator";

export async function requestOperators(): Promise<
  UnifiedListResponse<OperatorRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_operators_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: OperatorRaw[] }).entities,
  };
}

export async function selectOperator(
  id: PrimaryKey
): Promise<UnifiedResponse<OperatorRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.select_frontend_operator_path,
    { id },
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entityRaw: (body as unknown as { entity: OperatorRaw }).entity,
  };
}
