<script lang="ts">
  import { defineComponent } from "vue";

  import { RolesSettingsManager } from "frontend/uses/settings/manager/roles-settings-manager";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";

  export default defineComponent({
    components: { BaseHelp, SettingsContainer },
    setup() {
      new RolesSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-persons">
    <h2>Rollen</h2>
    <BaseHelp>
      <p>
        Die einfachsten Rollenzuweisungen bestehen aus
        <strong>Arzt/-ärztin</strong> und <strong>MFA</strong>.
      </p>
      <p>
        <strong>
          Bitte geben Sie hier keine konkreten Mitarbeiternamen ein, sondern
          allgemeine Tätigkeitsfelder in der Praxis.
        </strong>
      </p>
      <p>
        <strong>
          Spezielle Funktionen einzelner Mitarbeiter/innen können erfasst
          werden:
        </strong>
        z.B. einer/m befähigten Arzthelfer/in wird beispielsweise die
        <strong>Rolle „Blutabnahme“</strong> usw. zugewiesen, einem/einer
        Kardiologen/in die Rolle <strong>„Kardiologie“</strong>; einem/r
        Diätassistenten/in die Rolle <strong>„Ernährungsberatung“</strong> usw.
        <br />
        <strong
          >&rarr; Ist diese Funktion eine Voraussetzung für einen bestimmten
          Termintyp, kann dies bei der Terminvergabe automatisch berücksichtigt
          werden!</strong
        >
      </p>
      <p>
        Ein Beispiel wäre die Aufteilung einer Vorsorgeuntersuchung (U) in MFA-
        und Arztzeit. Die Rollen werden dem Patienten in der Terminvergabe
        <strong>nicht angezeigt</strong>, sondern dienen als Voraussetzung für
        die Verfügbarkeit von Terminen im eigenen Kalender. Im nächsten Schritt
        können die Mitarbeiter der Praxis den jeweiligen Rollen zugeordnet
        werden (siehe Rubrik „Mitarbeiter“)
      </p>
    </BaseHelp>
    <SettingsContainer />
  </div>
</template>
