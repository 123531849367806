<script lang="ts">
  import { defineComponent, nextTick, PropType, toRefs, watch } from "vue";
  import { useEditor, EditorContent } from "@tiptap/vue-3";
  /* eslint-disable import/no-named-as-default -- externally caused - no influence*/
  import Document from "@tiptap/extension-document";
  import Text from "@tiptap/extension-text";
  import Paragraph from "@tiptap/extension-paragraph";
  import Bold from "@tiptap/extension-bold";
  import Italic from "@tiptap/extension-italic";
  import Strike from "@tiptap/extension-strike";
  /* eslint-enable import/no-named-as-default -- externally caused - no influence*/

  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { StatusValue } from "frontend/utils/animation-status";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  export default defineComponent({
    components: {
      EditorContent,
      BaseButton,
      AnimationStatus,
    },
    props: {
      modelValue: {
        type: String as PropType<string | null>,
        default: "",
      },
      label: {
        type: String,
        default: "",
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
    },
    emits: ["update:modelValue", "autosave"],
    setup(props, { emit }) {
      const { modelValue } = toRefs(props);
      const { pairing } = useFormLabelling();

      // Tracking of editing
      let valueBeforeEdit: null | typeof modelValue.value = null;
      const doHandleAutosave = async () => {
        await nextTick();

        if (modelValue.value !== valueBeforeEdit) {
          valueBeforeEdit = modelValue.value;
          emit("autosave", valueBeforeEdit, modelValue.value);
        }
      };
      const editor = useEditor({
        content: modelValue.value,
        extensions: [
          Document.configure(),
          Text.configure(),
          Paragraph.configure(),
          Bold.configure(),
          Italic.configure(),
          Strike.configure(),
        ],
        onUpdate: () => {
          const htmlContent = editor.value?.getHTML();
          emit(
            "update:modelValue",
            htmlContent === "<p></p>" ? null : htmlContent
          );
        },
        onFocus: () => {
          valueBeforeEdit = modelValue.value;
        },
        onBlur: doHandleAutosave,
      });

      watch(modelValue, (value) => {
        if (editor.value?.getHTML() !== value) {
          editor.value?.commands.setContent(value, false);
        }
      });

      return {
        pairing,

        doHandleAutosave,

        editor,
      };
    },
  });
</script>

<template>
  <div class="form-richedit">
    <label class="form-richedit__label" v-bind:for="pairing">{{ label }}</label>
    <div v-if="editor" class="form-richedit__menu">
      <div class="form-richedit__actions-compartment">
        <BaseButton
          class="form-richedit__menu-action"
          visual-icon
          v-bind:visual-action="editor.isActive('bold')"
          v-on:submit="
            () => {
              editor?.chain().focus().toggleBold().run();
              doHandleAutosave();
            }
          "
        >
          <strong>B</strong>
        </BaseButton>
        <BaseButton
          class="form-richedit__menu-action"
          v-bind:visual-action="editor.isActive('italic')"
          visual-icon
          v-on:submit="
            () => {
              editor?.chain().focus().toggleItalic().run();
              doHandleAutosave();
            }
          "
        >
          <i>I</i>
        </BaseButton>
        <BaseButton
          class="form-richedit__menu-action"
          v-bind:visual-action="editor.isActive('strike')"
          visual-icon
          v-on:submit="
            () => {
              editor?.chain().focus().toggleStrike().run();
              doHandleAutosave();
            }
          "
        >
          <s>&nbsp;S&nbsp;</s>
        </BaseButton>
      </div>
    </div>
    <EditorContent
      v-bind:id="pairing"
      class="form-richedit__content"
      v-bind:editor="editor"
    />
    <AnimationStatus
      v-if="animationStatus !== null"
      class="form-input__animation-status"
      v-bind:animation-status="animationStatus"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .form-richedit__label {
    @include label.label;
  }

  .form-richedit__actions-compartment {
    .form-richedit__menu-action {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .form-richedit__content {
    margin-top: 10px;
    border: 1px solid #777777; // TODO: unify border colors among application wrt input fields
    padding: 0 10px;

    /* stylelint-disable-next-line selector-class-pattern -- comes from external library */
    :deep(.ProseMirror) {
      outline: none;
      cursor: text;
    }
  }
</style>
