<script lang="ts" setup>
  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  withDefaults(
    defineProps<{
      modelValue?: boolean;
      label?: string | null;
      disabled?: boolean;
      errors?: Errors;
      animationStatus?: StatusValue | null;
    }>(),
    {
      modelValue: false,
      label: null,
      disabled: false,
      errors: () => [],
      animationStatus: null,
    }
  );

  const emit = defineEmits<{
    (e: "update:modelValue", checked: boolean): void;
    (e: "autosave", checkedOld: boolean, checkedNew: boolean): void;
  }>();

  const { pairing } = useFormLabelling();

  const onInputChange = (event: Event) => {
    if (!event.target) return;

    const target = event.target as HTMLInputElement;
    const newStatus = !!target.checked;

    target.checked = !newStatus; // keep state until we get the new model-value

    emit("update:modelValue", newStatus);
    emit("autosave", !newStatus, newStatus);
  };
</script>

<template>
  <div class="form-checkbox">
    <input
      v-bind:id="pairing"
      type="checkbox"
      v-bind:checked="!!modelValue"
      v-bind:disabled="disabled"
      v-on:change="onInputChange"
    />
    <label v-if="label" class="form-checkbox__label" v-bind:for="pairing">{{
      label
    }}</label>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="form-checkbox__label__error"
        v-bind:for="pairing"
        >{{ error }}</label
      ></template
    >
    <AnimationStatus
      v-if="animationStatus !== null"
      class="form-checkbox__animation-status"
      v-bind:animation-status="animationStatus"
      visual-small
    />
    <div class="form-checkbox__slot"><slot></slot></div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .form-checkbox {
    display: flex;
    align-items: center;

    margin: 5px 0;
  }

  .form-checkbox__label,
  .form-checkbox__label__error,
  .form-checkbox__slot {
    @include label.label;

    padding: 0;
  }

  .form-checkbox__label__error {
    @include label.label--error;
  }

  .form-checkbox__label,
  .form-checkbox__label__error,
  .form-checkbox__animation-status,
  .form-checkbox__slot {
    margin-left: 5px;
  }
</style>
