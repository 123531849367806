import { App, ComponentPublicInstance, inject, watch } from "vue";

import { clearArray, removeIfExists } from "shared/utils/array-utils";
import { ContextMenu } from "shared/utils/context-menu/context-menu";
import {
  ContextMenuEntry,
  ContextMenuEntryControl,
} from "shared/utils/context-menu/interfaces";
import {
  CalendarCell,
  CalendarRelativeLocation,
} from "frontend/interfaces/calendar";

export class Manager {
  public static inject(): Manager {
    const manager = inject<Manager>(Manager.PROVIDE_INJECT_KEY);
    if (!manager)
      throw new Error(
        "(Context-)Manager is not injected or method is not called inside setup"
      );
    return manager;
  }

  public constructor(app: App<Element>, component: ComponentPublicInstance) {
    this.app = app;
    this.component = component;

    this.setup();
  }

  public addEntry(
    componentInstanceID: string,
    entry: ContextMenuEntry
  ): ContextMenuEntryControl {
    // only add every entry once
    if (this.entries.indexOf(entry) >= 0)
      throw new Error("entry is already added to context-menu");

    this.entries.push(entry);
    const { destroy: destroyEntryEvents } = this.setupEntryEvents(entry);

    const result = {
      destroy: () => {
        removeIfExists(this.entries, entry);
        removeIfExists(this.controlsFor(componentInstanceID), result);
        destroyEntryEvents();
      },
    };
    this.controlsFor(componentInstanceID).push(result);
    return result;
  }

  public setEventPosition(location: CalendarRelativeLocation | null) {
    this.eventPosition = location;
  }

  public setEventElement(element: CalendarCell) {
    this.eventElement = element;
  }

  public destroyFor(componentInstanceID: string): void {
    const controls = this.controlsFor(componentInstanceID);
    controls.forEach((control) => control.destroy());
    clearArray(controls);
  }

  private static readonly PROVIDE_INJECT_KEY = Symbol("ContextMenu::Manager");

  private app: App<Element>;
  private component: ComponentPublicInstance;
  private currentMenu: ContextMenu | null = null;
  private entries: Array<ContextMenuEntry> = [];
  private detectedEntries: Array<ContextMenuEntry> = [];
  private controlsOfEntries: Partial<
    Record<string, Array<ContextMenuEntryControl>>
  > = {};
  private eventPosition: CalendarRelativeLocation | null = null;
  private eventElement: CalendarCell | null = null;

  private setup() {
    this.setupVueApplication();
    this.setupEvents();
  }

  private setupVueApplication() {
    // provide this manager to every component in vue
    this.app.provide<Manager>(Manager.PROVIDE_INJECT_KEY, this);
  }

  private setupEvents() {
    this.component.$el.addEventListener(
      "click",
      (event: PointerEvent) => {
        // if menu is open --> do not react to click anywhere else
        if (this.currentMenu && this.currentMenu.isShown())
          event.stopPropagation();
        this.cleanupCurrentMenu();
      },
      // catch event in capture phase instead of bubble phase to cancel it before it even hits the surface
      true
    );

    // capture contextmenu and reset detected entries
    // now every entries reference element will also be
    // captured and added to detectedEntries
    // if the bubble-handler than catches the event eventually
    // it will know which entries to display
    this.component.$el.addEventListener(
      "contextmenu",
      () => {
        this.detectedEntries = [];
      },
      true
    );
    this.component.$el.addEventListener("contextmenu", (event: MouseEvent) => {
      this.cleanupCurrentMenu();

      if (this.detectedEntries.length <= 0) return;

      event.preventDefault(); // do not show native context-menu

      this.currentMenu = new ContextMenu(this.app);
      this.currentMenu.setEntries(this.detectedEntries);
      this.currentMenu.setEventPosition(this.eventPosition);
      this.currentMenu.setEventElement(this.eventElement);
      this.currentMenu.show(event.clientX, event.clientY);
    });
  }

  private setupEntryEvents(entry: ContextMenuEntry): { destroy: () => void } {
    const eventListener = (_event: MouseEvent) => {
      this.detectedEntries.push(entry);
    };
    const stopWatcher = watch(
      entry.target,
      (element, prevElement) => {
        if (prevElement) {
          prevElement.removeEventListener("contextmenu", eventListener, true);
        }
        if (element) {
          element.addEventListener("contextmenu", eventListener, true);
        }
      },
      { immediate: true }
    );

    return {
      destroy: () => {
        stopWatcher();
        if (entry.target.value) {
          entry.target.value.removeEventListener(
            "contextmenu",
            eventListener,
            true
          );
        }
      },
    };
  }

  private cleanupCurrentMenu() {
    if (this.currentMenu) {
      this.currentMenu.destroy();
      this.currentMenu = null;
    }
  }

  private controlsFor(
    componentInstanceID: string
  ): Array<ContextMenuEntryControl> {
    const existingEntries = this.controlsOfEntries[componentInstanceID] || [];
    this.controlsOfEntries[componentInstanceID] = existingEntries;
    return existingEntries;
  }
}
