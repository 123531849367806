import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { TemplateRaw } from "frontend/interfaces/template";

export async function requestTemplates(): Promise<{
  templates: Array<TemplateRaw>;
}> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_templates_path,
    {},
    {}
  );

  if (statusIsSuccess) {
    return {
      templates: (body as unknown as { templates: Array<TemplateRaw> })
        .templates,
    };
  } else {
    return {
      templates: [],
    };
  }
}

export async function loadTemplate(
  machine_name: string
): Promise<{ success: boolean }> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- TODO: fix it
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.load_frontend_templates_path,
    {},
    { machine_name }
  );

  return {
    success: statusIsSuccess,
  };
}
