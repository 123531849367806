import { createRouter, createWebHistory } from "vue-router";

import { UserEntity } from "frontend/interfaces/user";
import { navigationGuardRequireAuth } from "frontend/utils/router/navigation-guard-require-auth";
import { navigationGuardRequestLoginStatus } from "frontend/utils/router/navigation-guard-request-login-status";
import { navigationGuardMobileToken } from "frontend/utils/router/navigation-guard-mobile-token";
import { navigationGuardRequireTemplating } from "frontend/utils/router/navigation-guard-require-templating";
import { navigationGuardSkipLogin } from "frontend/utils/router/navigation-guard-skip-login";
import {
  RedirectCalendar,
  RedirectCalendarDayToday,
  RedirectCalendarWeekCurrent,
} from "frontend/utils/router/redirects";

// routes
import Index from "frontend/routes/RouteIndex.vue";
import NotFound from "frontend/routes/RouteNotFound.vue";
import StyleguideIndex from "frontend/routes/styleguide/RouteIndex.vue";
import ReceiverPatientsIndex from "frontend/routes/receiver/patients/RouteIndex.vue";
import DayView from "frontend/routes/calendar/RouteDayView.vue";
import WeekView from "frontend/routes/calendar/RouteWeekView.vue";
import LoginIndex from "frontend/routes/login/RouteIndex.vue";
import AppointmentNew from "frontend/routes/appointment/RouteNew.vue";
import SettingsIndex from "frontend/routes/settings/RouteIndex.vue";
import PatientsShow from "frontend/routes/patients/RouteShow.vue";
import SettingsAppointmentTypes from "frontend/routes/settings/RouteAppointmentTypes.vue";
import SettingsGeneral from "frontend/routes/settings/RouteGeneral.vue";
import SettingsStart from "frontend/routes/settings/RouteStart.vue";
import SettingsColors from "frontend/routes/settings/RouteColors.vue";
import SettingsRoles from "frontend/routes/settings/RouteRoles.vue";
import SettingsLocations from "frontend/routes/settings/RouteLocations.vue";
import SettingsRooms from "frontend/routes/settings/RouteRooms.vue";
import SettingsPersons from "frontend/routes/settings/RoutePersons.vue";
import SettingsReminders from "frontend/routes/settings/RouteReminders.vue";
import SettingsOperators from "frontend/routes/settings/RouteOperators.vue";
import SettingsSettingsProfiles from "frontend/routes/settings/RouteSettingsProfiles.vue";
import SettingsSchedules from "frontend/routes/settings/RouteSchedules.vue";
import SettingsSchedule from "frontend/routes/settings/RouteSchedule.vue";
import SettingsEditSchedule from "frontend/routes/settings/RouteEditSchedule.vue";
import SettingsScheduleActivations from "frontend/routes/settings/RouteScheduleActivations.vue";
import SettingsAbsences from "frontend/routes/settings/RouteAbsences.vue";
import SettingsHolidays from "frontend/routes/settings/RouteHolidays.vue";
import SettingsPvs from "frontend/routes/settings/RoutePvs.vue";
import PatientAppointmentNew from "frontend/routes/patient/appointment/RouteNew.vue";
import PatientAppointmentIndex from "frontend/routes/patient/appointment/RouteIndex.vue";
import NewsIndex from "frontend/routes/news/RouteIndex.vue";
import EventLogIndex from "frontend/routes/event-log/RouteIndex.vue";
import OfficeBlocked from "frontend/routes/errors/RouteOfficeBlocked.vue";
import ApplyTemplate from "frontend/components/ApplyTemplate.vue";
import InsuranceNotification from "frontend/routes/patient/InsuranceNotification.vue";

// how should router-metadata look like?
declare module "vue-router" {
  interface RouteMeta {
    requiresAuth: UserEntity;
    skipPadding?: true;
    requiresUnblockedOffice?: true;
    requestLoginStatus?: true;
    requiresTemplatingAvailable?: true;
    requiresIncompletePatientData?: true;
    requiresCompletePatientData?: true;
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Index,
      children: [
        { path: "/styleguide", component: StyleguideIndex },
        {
          path: "/empfang/patienten/:format",
          component: ReceiverPatientsIndex,
          props: (route) => ({
            ...route.params,
            dateOfBirth: route.query.date_of_birth,
            name: route.query.name,
            firstName: route.query.first_name,
            externalId: route.query.external_id,
            pvsName: route.query.mpb_pvs,
            pvsSystemId: route.query.mpb_pvs_system_id,
          }),
          meta: { requiresAuth: UserEntity.Practice },
        },
        {
          name: "calendar",
          path: "/kalender",
          alias: "/",
          redirect: RedirectCalendar,
          children: [
            {
              path: "tag",
              name: "calendar-day-base",
              redirect: RedirectCalendarDayToday,
              children: [
                {
                  name: "calendar-day",
                  path: ":day",
                  component: DayView,
                  props: (route) => ({
                    ...route.params,
                    highlightAppointmentId: route.query.highlightAppointmentId,
                  }),
                  meta: { requiresAuth: UserEntity.Practice },
                },
              ],
            },
            {
              path: "woche",
              name: "calendar-week-base",
              redirect: RedirectCalendarWeekCurrent,
              children: [
                {
                  name: "calendar-week",
                  path: ":day",
                  component: WeekView,
                  props: (route) => ({
                    ...route.params,
                    person: route.query.person,
                    highlightAppointmentId: route.query.highlightAppointmentId,
                  }),
                  meta: { requiresAuth: UserEntity.Practice },
                },
              ],
            },
          ],
        },
        {
          name: "patient-show",
          path: "patienten/:id",
          component: PatientsShow,
          props: true,
          meta: { requiresAuth: UserEntity.Practice },
        },
        {
          name: "appointment-new",
          path: "/termine/anlegen",
          component: AppointmentNew,
          props: (route) => ({
            patient: route.query.patient,
            appointmentType: route.query.appointmentType,
            appointment: route.query.appointment,
          }),
          meta: {
            requiresAuth: UserEntity.Practice,
            requiresUnblockedOffice: true,
            requestLoginStatus: true,
          },
        },
        { name: "login", path: "/login", component: LoginIndex },
        {
          name: "settings",
          path: "/einstellungen",
          redirect: { name: "settings-start" },
          component: SettingsIndex,
          meta: {
            requiresAuth: UserEntity.Practice,
            skipPadding: true,
            requestLoginStatus: true,
          },
          children: [
            {
              name: "settings-start",
              path: "start",
              component: SettingsStart,
            },
            {
              name: "settings-appointment_types",
              path: "termintypen",
              component: SettingsAppointmentTypes,
            },
            {
              name: "settings-general",
              path: "allgemein",
              component: SettingsGeneral,
            },
            {
              name: "settings-colors",
              path: "farben",
              component: SettingsColors,
            },
            {
              name: "settings-roles",
              path: "rollen",
              component: SettingsRoles,
            },
            {
              name: "settings-locations",
              path: "standort",
              component: SettingsLocations,
            },
            {
              name: "settings-rooms",
              path: "r%C3%A4ume",
              component: SettingsRooms,
            },
            {
              name: "settings-persons",
              path: "mitarbeiter",
              component: SettingsPersons,
            },
            {
              name: "settings-schedules",
              path: "zeitpl%C3%A4ne",
              component: SettingsSchedules,
            },
            {
              name: "settings-schedule-activations",
              path: "zeitplan-aktivierung",
              component: SettingsScheduleActivations,
            },
            {
              name: "settings-schedule",
              path: "zeitplan",
              component: SettingsSchedule,
            },
            {
              name: "settings-edit-schedule",
              path: "edit/zeitplan/:id",
              props: true,
              component: SettingsEditSchedule,
            },
            {
              name: "settings-absences",
              path: "abwesenheit",
              component: SettingsAbsences,
            },
            {
              name: "settings-holidays",
              path: "feiertage",
              component: SettingsHolidays,
            },
            {
              name: "settings-reminders",
              path: "erinnerungen",
              component: SettingsReminders,
            },
            {
              name: "settings-operators",
              path: "praxisanwender",
              component: SettingsOperators,
            },
            {
              name: "settings-settings-profiles",
              path: "einstellungsprofile",
              component: SettingsSettingsProfiles,
            },
            {
              name: "settings-pvs",
              path: "pvs",
              component: SettingsPvs,
            },
            {
              name: "settings-musterpraxen",
              path: "musterpraxen",
              component: ApplyTemplate,
              meta: {
                requiresAuth: UserEntity.Practice,
                requiresTemplatingAvailable: true,
              },
            },
          ],
        },
        {
          name: "news",
          path: "news",
          component: NewsIndex,
        },
        {
          name: "event-log",
          path: "protokoll/:pageNumber?",
          component: EventLogIndex,
          props: true,
          meta: {
            requiresAuth: UserEntity.Practice,
          },
        },
        {
          name: "office-blocked",
          path: "/terminbuchungfreigeben",
          component: OfficeBlocked,
          meta: {
            requiresAuth: UserEntity.Practice,
          },
        },
        {
          name: "patient",
          path: "patient",
          redirect: { name: "patient-appointment" },
          children: [
            {
              name: "notifications-insurance",
              path: "hinweise/versicherung",
              component: InsuranceNotification,
              meta: {
                requiresAuth: UserEntity.Patient,
                requiresIncompletePatientData: true,
              },
            },
            {
              name: "patient-appointment-new",
              path: "termine/anlegen",
              component: PatientAppointmentNew,
              meta: {
                requiresAuth: UserEntity.Patient,
                requiresUnblockedOffice: true,
                requiresCompletePatientData: true,
              },
            },
            {
              name: "patient-appointment",
              path: "termine/uebersicht",
              component: PatientAppointmentIndex,
              meta: {
                requiresAuth: UserEntity.Patient,
                requiresCompletePatientData: true,
              },
            },
          ],
        },
        // 404 - catch-all route
        {
          path: "/:pathMatch(.*)*",
          name: "not-found",
          component: NotFound,
        },
      ],
    },
  ],
});

// add navigation guards
// order is important
// we need to save mobileToken before authentication
router.beforeEach(navigationGuardMobileToken);
router.beforeEach(navigationGuardRequireAuth);
// sends a dummy request to the backend if specified on the route's meta
router.beforeEach(navigationGuardRequestLoginStatus);
router.beforeEach(navigationGuardRequireTemplating);
router.beforeEach(navigationGuardSkipLogin);
