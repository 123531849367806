<script lang="ts" setup>
  import { PropType, toRefs } from "vue";

  import { Schedule } from "frontend/interfaces/schedule";
  import { ErrorDatabase, useErrors } from "frontend/uses/use-errors";

  import FormInput from "frontend/components/form/FormInput.vue";

  const props = defineProps({
    entity: {
      type: Object as PropType<Schedule>,
      required: true,
    },
    visualModal: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object as PropType<ErrorDatabase>,
      required: true,
    },
    globalErrors: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  });
  const { errorFor } = useErrors(toRefs(props).errors);
  const { entity } = toRefs(props);
</script>

<template>
  <div v-bind:class="{ 'schedule--as-modal': visualModal }">
    <template v-if="globalErrors && globalErrors.length > 0">
      <label
        v-for="errorMsg in globalErrors"
        v-bind:key="errorMsg"
        class="label--error"
      >
        {{ errorMsg }}
      </label>
    </template>
    <FormInput
      v-model="entity.name"
      label="Name"
      v-bind:errors="errorFor('name')"
    />
    <FormInput
      v-model="entity.description"
      label="Beschreibung"
      v-bind:errors="errorFor('description')"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";
  .schedule--as-modal {
    width: 50vw;
    min-height: 50vh;
  }
  .label--error {
    @include label.label;
    @include label.label--error;
  }
</style>
