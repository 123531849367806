<script lang="ts">
  import { defineComponent, toRefs, ref, onMounted, watch } from "vue";

  import { Room } from "frontend/interfaces/room";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { Location } from "frontend/interfaces/location";
  import { Cache } from "frontend/utils/request-cache";
  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { FormInput, FormSelect, BaseButton },
    props: {
      ...editProps<Room>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      const editComponent = useEditComponent(entity.value);
      // -----------
      // Location selector
      const locations = ref<Array<Location>>([]);
      const currentSelectionAT = ref<FormSelectId>(
        editComponent.entityEditable.value.location_id
      );
      const { options: optionsAT } = useFormSelectOptions<Location>(
        currentSelectionAT,
        locations,
        (entry) => ({ id: entry.id, label: entry.name })
      );
      const loadLocations = async () => {
        locations.value = await Cache.getCachedLocations();
        currentSelectionAT.value =
          editComponent.entityEditable.value.location_id;
      };
      onMounted(loadLocations);
      watch(currentSelectionAT, () => {
        if (currentSelectionAT.value) {
          editComponent.entityEditable.value.location_id =
            currentSelectionAT.value.toString();
        }
      });
      return { ...editComponent, optionsAT, currentSelectionAT };
    },
  });
</script>

<template>
  <div class="edit-location">
    <h5>{{ entity.name }}</h5>
    <FormInput
      v-model="entityEditable.name"
      label="Raumname"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <FormSelect
      v-model="currentSelectionAT"
      label="Standort"
      v-bind:options="optionsAT"
      v-bind:errors="errorFor('location_id')"
      v-bind:animation-status="statusFor('location_id')"
      v-on="{ autosave: onAutosaveFor('location_id') }"
    />
    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>
