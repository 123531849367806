/* rails-erb-loader-dependencies models/office.rb */

type HexColor = string;

interface DefaultColor {
  name: string;
  value: HexColor;
}

export const DEFAULT_COLORS: Array<DefaultColor> = [
  
  { name: "Farbe 1", value: "#DB5D44"},
  
  { name: "Farbe 2", value: "#CDCE47"},
  
  { name: "Farbe 3", value: "#A1C4CA"},
  
  { name: "Farbe 4", value: "#3C7D8B"},
  
  { name: "Farbe 5", value: "#FBF6BD"},
  
  { name: "Farbe 6", value: "#F2C966"},
  
  { name: "Farbe 7", value: "#EEA154"},
  
  { name: "Farbe 8", value: "#3F526C"},
  
];

