<script lang="ts">
  import { computed, defineComponent } from "vue";

  import { useStore as useOfficeStore } from "frontend/stores/office";
  import { block } from "frontend/api/application/request-office";
  import { useStickyness } from "frontend/uses/use-stickyness";
  import { ElementProvider } from "frontend/utils/provider";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: {
      BaseButton,
      BaseHelp,
    },
    props: {
      visualFixed: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const officeStore = useOfficeStore();
      const blockOffice = () => {
        block();
      };

      // ------------------
      // Stickyness for sidebar
      const headerElement = ElementProvider.localInjectSafe(
        ElementProvider.provisions().baseHeader
      );
      const { targetCSS: sidebarStickynessCSS } = useStickyness(
        headerElement,
        () => 20
      );
      const showTemplatingLink = computed<boolean>(
        () => officeStore.isTemplatingAvailable
      );
      const showPVSLink = computed<boolean>(() =>
        officeStore.hasFeature("pvs_integration")
      );

      return {
        officeStore,
        blockOffice,

        sidebarStickynessCSS,
        showTemplatingLink,
        showPVSLink,
      };
    },
  });
</script>

<template>
  <div class="vue-route__settings--index">
    <nav class="sidebar">
      <div class="back__to-calendar">
        <RouterLink v-bind:to="{ name: 'calendar' }"
          >&larr; zurück zur Kalenderansicht</RouterLink
        >
      </div>
      <div v-bind:style="sidebarStickynessCSS">
        <h3>Einstellungen</h3>
        <ul>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-start' }"
              >Start</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-general' }"
              >Allgemein</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-locations' }"
              >Standort</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-rooms' }"
              >Räume</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-roles' }"
              >Rollen</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-persons' }"
              >Mitarbeiter</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-colors' }"
              >Farben</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-reminders' }"
              >Terminerinnerungen</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-appointment_types' }"
              >Termintypen</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-schedules' }"
              >Praxiszeiten</RouterLink
            >
            <ul
              v-if="
                $route.name === 'settings-schedules' ||
                $route.name === 'settings-schedule-activations' ||
                $route.name === 'settings-schedule' ||
                $route.name === 'settings-edit-schedule' ||
                $route.name === 'settings-absences' ||
                $route.name === 'settings-holidays'
              "
            >
              <li class="nav__li nav__li--sublevel">
                <RouterLink v-bind:to="{ name: 'settings-schedules' }"
                  >Zeitpläne</RouterLink
                >
              </li>
              <li class="nav__li nav__li--sublevel">
                <RouterLink
                  v-bind:to="{ name: 'settings-schedule-activations' }"
                  >Aktivierung von Zeitplänen</RouterLink
                >
              </li>
              <li class="nav__li nav__li--sublevel">
                <RouterLink v-bind:to="{ name: 'settings-schedule' }"
                  >Wochenansicht</RouterLink
                >
              </li>
              <li class="nav__li nav__li--sublevel">
                <RouterLink v-bind:to="{ name: 'settings-absences' }"
                  >Abwesenheit</RouterLink
                >
              </li>
              <li class="nav__li nav__li--sublevel">
                <RouterLink v-bind:to="{ name: 'settings-holidays' }"
                  >Feiertage</RouterLink
                >
              </li>
            </ul>
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-operators' }"
              >Praxisanwender</RouterLink
            >
          </li>
          <li>
            <RouterLink v-bind:to="{ name: 'settings-settings-profiles' }">
              Einstellungsprofile
            </RouterLink>
          </li>
          <li v-if="showPVSLink">
            <RouterLink v-bind:to="{ name: 'settings-pvs' }"
              >PVS-Schnittstelle</RouterLink
            >
          </li>
          <li v-if="showTemplatingLink">
            <RouterLink v-bind:to="{ name: 'settings-musterpraxen' }"
              >Musterpraxen</RouterLink
            >
          </li>
        </ul>

        <div v-if="!officeStore.isBlocked" class="office-blocking">
          <h5>Terminbuchung blockieren</h5>
          <BaseHelp>
            <p class="office-blocking__description">
              Hier können Sie Terminbuchungen blockieren, um Fehlbuchungen
              während der Anpassung der Einstellungen zu verhinden. Die
              Terminbuchung kann jederzeit wieder aktiviert werden.
            </p>
          </BaseHelp>
          <BaseButton visual-small v-on:submit="blockOffice()">
            Terminbuchung blockieren
          </BaseButton>
        </div>
      </div>
    </nav>
    <div class="settings-edit">
      <div class="plain">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/variables";

  .vue-route__settings--index {
    flex: 1;

    display: flex;
    min-height: inherit;
  }

  .sidebar {
    min-height: inherit;
    width: 300px;
    color: colors.$color_sidebar--text;
    background-color: colors.$color_sidebar--background;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;

    box-shadow: variables.$box-shadow_menus;
    z-index: 1;
  }

  nav ul > li {
    margin: 10px 0;
  }

  .nav__li--sublevel {
    margin-left: 20px;
  }

  .settings-edit {
    flex: 1;

    color: colors.$color_settings--text;
    background-color: colors.$color_settings--background;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;
  }

  .office-blocking {
    margin-top: 50px;

    text-align: center;
  }

  .office-blocking__description {
    text-align: initial;
  }

  .back__to-calendar {
    font-size: 14px;
    margin-bottom: 40px;
  }
</style>
