import { readonly, ref, Ref, watch } from "vue";

export function useRecurring(intervalInMS: number): {
  tick: Readonly<Ref<number>>;
} {
  const counter = ref<number>(0);

  const updateCounter = () => {
    counter.value++;
    setTimeout(updateCounter, intervalInMS);
  };
  updateCounter();

  return {
    tick: readonly(counter),
  };
}

export function useRecurringTime(): {
  currentTime: Readonly<Ref<Date>>;
} {
  const time = ref<Date>(new Date());

  watch(useRecurring(1000).tick, () => (time.value = new Date()));
  return {
    currentTime: readonly(time),
  };
}
