import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { useStore as useLoginStore } from "frontend/stores/login";
import { SettingsProfile } from "frontend/interfaces/settings-profile";
import {
  duplicateSettingsProfile,
  newSettingsProfileDefault,
} from "frontend/parser/parse-settings-profile";
import { Cache } from "frontend/utils/request-cache";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { log, LogLevel } from "shared/utils/logger";
import { UserEntity } from "frontend/interfaces/user";
import {
  watchAndSelectOperators,
  watchLoginState,
} from "frontend/stores/profile/watcher";
import {
  OnLoginSuccess,
  OnRequestHasOfficeStoreHeaders,
} from "frontend/events/topics";
import { routes } from "frontend/api/application";
import { Person } from "frontend/interfaces/person";
import { sortArrayByArrangement } from "shared/utils/array-utils";

export const id = "profile";

export interface OperatorProfile {
  operatorID: PrimaryKey;
  profile: SettingsProfile | null;
}

export const useStore = defineStore(id, () => {
  const loginStore = useLoginStore();

  // the actual profile that is selected currently
  const profile = ref<SettingsProfile>(newSettingsProfileDefault());
  const officeProfile = ref<SettingsProfile | null>(null);
  const operatorProfile = ref<OperatorProfile | null>(null);

  // settings profiles that are available
  const settingsProfiles = ref<SettingsProfile[]>([]);
  const fetchProfiles = async () => {
    settingsProfiles.value = await Cache.getCachedSettingsProfiles({
      forceLoad: true,
    });

    const defaultProfile =
      settingsProfiles.value.filter(
        (profile) => profile.is_office_default
      )[0] ?? null;
    if (defaultProfile) provideOfficeProfile(defaultProfile);
  };
  if (loginStore.isLoggedIn(UserEntity.Practice)) fetchProfiles();

  // ---------------------------------------------------------------------------------------------
  // Helper-methods
  // ---------------------------------------------------------------------------------------------
  // calling with no profile --> default profile will be used
  const selectProfile = (selectedProfile?: SettingsProfile) => {
    log(
      LogLevel.Debug,
      `[PROFILE-STORE] select profile ${selectedProfile?.id} / ${selectedProfile?.name}`
    );
    profile.value = duplicateSettingsProfile(
      selectedProfile ?? newSettingsProfileDefault()
    );
  };

  const lookupProfile = (
    profileID: PrimaryKey | null
  ): SettingsProfile | null =>
    settingsProfiles.value.filter((profile) => profile.id === profileID)[0] ??
    null;

  const provideOfficeProfile = (theOfficeProfile: SettingsProfile | null) => {
    if (
      !theOfficeProfile ||
      !officeProfile.value ||
      officeProfile.value.id != theOfficeProfile.id
    ) {
      officeProfile.value = theOfficeProfile;
    }
  };

  const provideOperatorProfile = (theOperatorProfile: OperatorProfile) => {
    if (
      !operatorProfile.value ||
      operatorProfile.value.operatorID !== theOperatorProfile.operatorID ||
      operatorProfile.value.profile?.id !== theOperatorProfile.profile?.id
    )
      operatorProfile.value = theOperatorProfile;
  };

  const provideProfileForOperator = (
    operatorID: PrimaryKey,
    profile: SettingsProfile | null
  ) => {
    if (
      operatorProfile.value &&
      operatorProfile.value.operatorID === operatorID
    ) {
      operatorProfile.value = {
        operatorID,
        profile,
      };
    }
  };

  // sort array of persons according to calendar_sorted_persons of the current profile
  const sortPersons = (persons: Array<Person>) =>
    sortPersonsByProfile(persons, profile.value);

  const filterPersons = (persons: Array<Person>) =>
    persons.filter(
      (p) => profile.value.calendar_removed_persons.indexOf(p.id) < 0
    );

  // ---------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------
  // watcher and state-management
  // ---------------------------------------------------------------------------------------------
  watchAndSelectOperators(
    officeProfile,
    operatorProfile,
    selectProfile,
    lookupProfile,
    provideOperatorProfile
  );
  watchLoginState(
    officeProfile,
    operatorProfile,
    settingsProfiles,
    selectProfile,
    fetchProfiles
  );
  // ---------------------------------------------------------------------------------------------

  return {
    profile,
    officeProfile,
    operatorProfile,

    fetchProfiles,
    selectProfile,
    lookupProfile,
    provideOfficeProfile,
    provideProfileForOperator,
    sortPersonsByProfile,
    sortPersons,
    filterPersons,
    _piniaPluginPersist_onLoad,

    settingsProfiles: computed(() => [
      newSettingsProfileDefault(),
      ...settingsProfiles.value,
    ]),
  };
});

function _piniaPluginPersist_onLoad<T>(
  _property: string,
  loadedValue: Partial<T> | null,
  stateValue: T
): Partial<T> | T | null {
  if (typeof stateValue === "object" && loadedValue && stateValue)
    return Object.assign({}, stateValue, loadedValue);
  else return loadedValue;
}

export function setupEvents() {
  OnRequestHasOfficeStoreHeaders.subscribe(({ headers, path }) => {
    if (
      path !== routes.paths.user_session_path ||
      !headers.calendar_default_view
    )
      return;

    // wait until login is done and the settings profiles are purged
    OnLoginSuccess.once(() => {
      if (headers.calendar_default_view) {
        const store = useStore();
        store.profile.calendar_default_view = headers.calendar_default_view;
      }
    });
  });
}

export function sortPersonsByProfile(
  persons: Array<Person>,
  profile: SettingsProfile
): Array<Person> {
  return sortArrayByArrangement(persons, profile.calendar_sorted_persons);
}
