<script lang="ts">
  import { defineComponent, PropType, Slot } from "vue";

  export default defineComponent({
    props: {
      renderedSlot: {
        type: Function as PropType<Slot>,
        default: () => {
          return;
        },
      },
    },
    setup(props) {
      return () => props.renderedSlot();
    },
  });
</script>
