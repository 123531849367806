<script lang="ts">
  import { computed, defineComponent, onMounted, ref, unref } from "vue";

  import { WizardStep } from "frontend/interfaces/wizard";
  import { useStore } from "frontend/stores/login";
  import {
    OfficeBanner,
    requestPatientsCurrent,
  } from "frontend/api/application/request-patients";
  import { Patient } from "frontend/interfaces/patient";
  import { parsePatient } from "frontend/parser/parse-patient";
  import { Suggestion } from "frontend/interfaces/p/suggestion";
  import { AppointmentType } from "frontend/interfaces/p/appointment-type";
  import { confirmSuggestion } from "frontend/api/application/p/request-suggestions";
  import { router } from "frontend/router";
  import { usePending } from "frontend/uses/use-pending";

  import WizardSteps from "frontend/components/WizardSteps.vue";
  import BaseBox from "frontend/components/base/BaseBox.vue";
  import PatientData from "frontend/components/PatientData.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import PatientCreateAppointment from "frontend/components/PatientCreateAppointment.vue";
  import PatientSummary from "frontend/components/PatientSummary.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormText from "frontend/components/form/FormText.vue";

  export default defineComponent({
    components: {
      WizardSteps,
      BaseBox,
      PatientData,
      BaseButton,
      BaseSpinner,
      PatientCreateAppointment,
      PatientSummary,
      FormInput,
      FormText,
    },
    setup() {
      const loginStore = useStore();
      const steps = ref<WizardStep[]>([
        {
          label: "Infos",
          slotName: "infos",
        },
        {
          label: "Anlegen",
          slotName: "create",
        },
        {
          label: "Überprüfen",
          slotName: "check",
        },
        {
          label: "Buchen",
          slotName: "calendar",
        },
      ]);

      const currentOfficeBanner = ref<OfficeBanner | null>(null);
      const bannerAccepted = ref<boolean>(false);
      const hasBanner = computed(
        () =>
          currentOfficeBanner.value &&
          currentOfficeBanner.value.banner_text_html
      );
      onMounted(() => (bannerAccepted.value = false));

      const currentStepIndex = ref<number>(0);
      const selectStep = computed(() => {
        return unref(steps)[unref(currentStepIndex)];
      });
      const doSelectNextStep = (direction = 1) => {
        currentStepIndex.value = Math.max(
          0,
          (unref(currentStepIndex) + direction) % unref(steps).length
        );
      };
      const currentPatient = ref<Patient | null>(null);
      onMounted(async () => {
        const data = await requestPatientsCurrent();
        if (data.currentPatient) {
          currentPatient.value = parsePatient(data.currentPatient);
        } else {
          currentPatient.value = null;
        }

        currentOfficeBanner.value = data.office_banner;
      });
      const currentSuggestion = ref<Suggestion | null>(null);
      const currentAT = ref<AppointmentType | null>(null);
      const patientMessage = ref<string>("");
      const doSelectSuggestion = (
        appointmentType: AppointmentType,
        suggestion: Suggestion
      ) => {
        currentAT.value = appointmentType;
        currentSuggestion.value = suggestion;
        currentStepIndex.value = 2;
      };
      const {
        isPending: createAppoinmentPending,
        doWithPending: doWithPendingCreateAppointment,
      } = usePending();
      const doCreateAppointment = async () => {
        doWithPendingCreateAppointment(async () => {
          const currentSuggestionUnref = unref(currentSuggestion);
          if (currentSuggestionUnref) {
            const result = await confirmSuggestion(
              currentSuggestionUnref.appointment_id,
              patientMessage.value
            );
            if (result) {
              await router.push({ name: "patient-appointment" });
            }
          }
        });
      };
      return {
        steps,
        selectStep,
        doSelectNextStep,
        doSelectSuggestion,
        currentSuggestion,
        currentAT,
        doCreateAppointment,
        createAppoinmentPending,
        loginStore,
        currentPatient,
        patientMessage,

        currentOfficeBanner,
        bannerAccepted,
        hasBanner,
      };
    },
  });
</script>

<template>
  <div>
    <h2 class="main-heading">Neuen Termin anlegen</h2>
    <keep-alive>
      <WizardSteps
        v-bind:steps="steps"
        v-bind:select-step="selectStep"
        disable-forward-navigation
        disable-backward-navigation
        visual-inverted
      >
        <template v-slot:infos>
          <BaseBox heading="Allgemeine Informationen" visual-light>
            <template v-if="hasBanner && !bannerAccepted">
              <!-- eslint-disable-next-line vue/no-v-html -- field is sanitized in backend -->
              <span v-html="currentOfficeBanner?.banner_text_html"></span>
              <BaseButton
                visual-full-width
                v-on:submit="bannerAccepted = true"
                >{{
                  currentOfficeBanner?.banner_submit_label ?? "Weiter"
                }}</BaseButton
              >
            </template>
            <template v-else>
              <template v-if="currentPatient">
                <PatientData
                  v-if="currentPatient"
                  v-bind:patient="currentPatient"
                />
                <div class="navigation-button__container">
                  <BaseButton visual-full-width v-on:submit="doSelectNextStep">
                    Weiter
                  </BaseButton>
                </div></template
              >
              <template v-else><BaseSpinner /></template>
            </template>
          </BaseBox>
        </template>
        <template v-slot:create>
          <BaseBox heading="Termin anlegen" visual-light>
            <PatientCreateAppointment
              v-if="currentPatient"
              v-bind:patient="currentPatient"
              visual-light
              v-on:selection="doSelectSuggestion"
            />
          </BaseBox>
        </template>
        <template v-slot:check>
          <BaseBox heading="Daten überprüfen" visual-light>
            <PatientSummary
              v-if="currentPatient && currentSuggestion && currentAT"
              v-bind:patient="currentPatient"
              v-bind:suggestion="currentSuggestion"
              v-bind:appointment-type="currentAT"
            />
            <FormText
              v-if="currentAT?.allow_message"
              class="praxis-message"
              label="Nachricht von der Praxis"
            >
              {{ currentAT.message_description }}
            </FormText>

            <FormInput
              v-if="currentAT?.allow_message"
              v-model="patientMessage"
              label="Nachricht schreiben"
              type="textarea"
            />
            <div class="navigation-button__container">
              <BaseButton
                v-bind:disabled="createAppoinmentPending"
                visual-full-width
                v-on:submit="doCreateAppointment"
                >Termin anlegen</BaseButton
              >
            </div>
          </BaseBox>
        </template>
      </WizardSteps>
    </keep-alive>
  </div>
</template>

<style lang="scss" scoped>
  .main-heading {
    text-align: center;
    font-size: 18px;
  }

  .navigation-button__container {
    padding: 15px 0;
    width: 180px;
    margin: 0 auto; // center horizontally
  }

  .praxis-message {
    margin-top: 20px;
  }
</style>
