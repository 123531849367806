import { computed, ComputedRef, ref, Ref } from "vue";

import { identity } from "shared/utils/helper-functions";
import { ArrayElement } from "shared/utils/typescript-helper";

export function useFormCheckbox<T>(
  rawOptions: Ref<Array<T>>,
  translator: (options: T) => FormCheckboxOption
): { options: ComputedRef<Array<FormCheckboxOptionWithOriginal<T>>> } {
  const options = computed(() => {
    return rawOptions.value.map((entry) => ({
      ...translator(entry),
      entry,
    }));
  });
  return { options };
}

export interface FormCheckboxOption {
  id: string | number;
  label: string;
}

export type FormRadioOption = FormCheckboxOption;

interface FormCheckboxOptionWithOriginal<T> extends FormCheckboxOption {
  entry: T;
}

const WEEKDAYS = Object.freeze([
  Object.freeze({ id: 1 as const, label: "Montag" as const }),
  Object.freeze({ id: 2 as const, label: "Dienstag" as const }),
  Object.freeze({ id: 3 as const, label: "Mittwoch" as const }),
  Object.freeze({ id: 4 as const, label: "Donnerstag" as const }),
  Object.freeze({ id: 5 as const, label: "Freitag" as const }),
  Object.freeze({ id: 6 as const, label: "Samstag" as const }),
  Object.freeze({ id: 7 as const, label: "Sonntag" as const }),
]);
type WeekdayElement = ArrayElement<typeof WEEKDAYS>;

export function useWeekdayFormCheckbox(): ReturnType<
  typeof useFormCheckbox<WeekdayElement>
> {
  const weekdays = ref([...WEEKDAYS]);

  return useFormCheckbox<WeekdayElement>(weekdays, identity);
}
