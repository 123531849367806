export interface DisposableEventListener {
  dispose: () => void;
}

export function disposableEventListener<K extends keyof HTMLElementEventMap>(
  target: HTMLElement,
  eventName: K,
  callback: (event: HTMLElementEventMap[K]) => void,
  options?: AddEventListenerOptions
): DisposableEventListener {
  target.addEventListener<K>(eventName, callback, {
    capture: options?.capture,
  });
  return {
    dispose: () => {
      target.removeEventListener(eventName, callback);
    },
  };
}
