import { routes, doRequest } from "frontend/api/praxisapp";
import { HttpMethod } from "frontend/interfaces/http";

type LoginAccessToken = string;

interface RequestLogin {
  success: boolean;
  accessToken: LoginAccessToken | null;
}

export async function requestLogin(
  username: string,
  password: string
): Promise<RequestLogin> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.login,
    {},
    { user: { email: username, password: password } }
  );

  let accessToken = null;
  if (statusIsSuccess && body && body.user) {
    accessToken = (body.user as Record<string, string>).access_token || null;
  }

  return {
    success: statusIsSuccess,
    accessToken,
  };
}
