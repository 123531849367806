import { computed, ref, Ref } from "vue";

import { Cache } from "frontend/utils/request-cache";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { Color } from "frontend/interfaces/color";

export function useColorLookup(color_id: Readonly<Ref<PrimaryKey | null>>): {
  color: Readonly<Ref<Color | null>>;
} {
  const colors = ref<Color[]>([]);

  const loadColors = async () => {
    colors.value = await Cache.getCachedColors();
  };
  loadColors();

  return {
    color: computed(
      () =>
        colors.value.find((candidate) => candidate.id === color_id.value) ??
        null
    ),
  };
}
