import { routes, doRequest } from "frontend/api/application";
import { HttpMethod, UnifiedBaseResponse } from "frontend/interfaces/http";
import {
  AppointmentTypeRaw,
  AppointmentTypesRaw,
} from "frontend/interfaces/appointment-type";
import { PrimaryKey } from "frontend/interfaces/primary-key";

export async function requestAppointmentTypes(patientID?: PrimaryKey): Promise<{
  appointment_types: Array<AppointmentTypeRaw>;
}> {
  const params: {
    patient_id?: PrimaryKey;
  } = {};
  if (patientID) params.patient_id = patientID;

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_appointment_types_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    return {
      appointment_types: (body as unknown as AppointmentTypesRaw)
        .appointment_types,
    };
  } else {
    return {
      appointment_types: [],
    };
  }
}

export async function sortAppointmentTypes(
  appointment_type_ids: Array<PrimaryKey>
): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.sort_frontend_settings_appointment_types_path,
    {},
    { appointment_type_ids }
  );

  return { success: statusIsSuccess, errors: {} };
}
