import { defineStore } from "pinia";
import { ref } from "vue";

import { PatientStoreHeaders } from "frontend/api/application";
import {
  OnLoginBefore,
  OnLogoutAfter,
  OnRequestHasPatientStoreHeaders,
} from "frontend/events/topics";
import { Operator } from "frontend/interfaces/operator";
import { router } from "frontend/router";

interface PatientState {
  insurance_change_needed: boolean;
}

export const id = "patient";

const operators = ref<Operator[]>([]);

export const useStore = defineStore({
  id,
  state: (): PatientState => ({
    insurance_change_needed: false,
  }),
  getters: {
    insuranceIsSelectableByPatient: (state): boolean =>
      !!state.insurance_change_needed,
  },
  actions: {
    parseHeader(header: PatientStoreHeaders) {
      const InsuranceChangeNeeded = !!header.insurance_change_needed;

      if (InsuranceChangeNeeded) this.insuranceInsufficient();
      else this.insuranceSufficient();
    },
    async insuranceInsufficient() {
      this.insurance_change_needed = false;
      if (router.currentRoute.value.meta.requiresCompletePatientData) {
        router.push({ name: "notifications-insurance" });
      }
    },
    async insuranceSufficient() {
      this.insurance_change_needed = true;
      if (router.currentRoute.value.meta.requiresIncompletePatientData) {
        router.push({ name: "patient" });
      }
    },
  },
});

export function setupEvents() {
  OnRequestHasPatientStoreHeaders.subscribe(({ headers }) => {
    useStore().parseHeader(headers);
  });

  OnLogoutAfter.or(OnLoginBefore).subscribe(() => {
    // clear caches on logout
    operators.value = [];
  });
}
