export function isIncluded<T>(arr: Array<T>, element: T): boolean {
  return (arr || []).indexOf(element) >= 0;
}

export function removeFirstOccurrence<T>(arr: Array<T>, element: T): boolean {
  const saneArray = arr || [];
  const index = saneArray.indexOf(element);
  if (index >= 0) {
    saneArray.splice(index, 1);
    return true;
  } else {
    return false;
  }
}

export function range(
  from: number,
  to: number,
  options?: { excludeTo: boolean }
): Array<number> {
  const saneFrom = Math.round(from);
  const saneTo = Math.round(to);

  if (saneTo < saneFrom) return [];
  if (options?.excludeTo && saneTo === saneFrom) return [];

  const length = saneTo - saneFrom + (options?.excludeTo ? 0 : 1);
  return Array.from({ length }, (x, i) => i + saneFrom);
}
