<script lang="ts">
  // -- Inspired from
  // https://codepen.io/Beaugust/pen/DByiE
  // --
  import { defineComponent } from "vue";

  export default defineComponent({
    props: {
      visualInverted: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>

<template>
  <div class="vue-component--base-spinner">
    <div
      class="circle"
      v-bind:class="{ 'circle--inverted': visualInverted }"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .vue-component--base-spinner {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 25%;
  }

  .circle {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid colors.$color_spinner__main--transparent;
    border-top-color: colors.$color_spinner__main;
    animation: spin 1s infinite linear;
  }

  .circle--inverted {
    border-color: colors.$color_spinner__main-inverted--transparent;
    border-top-color: colors.$color_spinner__main-inverted;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
