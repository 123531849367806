import { SimpleModal } from "frontend/uses/simple-modal/variants/simple-modal";

export class AlertModal extends SimpleModal {
  get defaultTitle(): string {
    return "Hinweis";
  }

  get hideCancel() {
    return true;
  }
}
