<script lang="ts" setup>
  import { ResizingPosition } from "frontend/interfaces/resizing";

  import BaseResizeBar from "frontend/components/base/BaseResizeBar.vue";

  export type OnResizing = (
    position: ResizingPosition,
    offset: number,
    stopped: boolean
  ) => void;

  const emit = defineEmits<{
    (e: "resizing", ...args: Parameters<OnResizing>): ReturnType<OnResizing>;
  }>();

  const onResizing = (
    position: ResizingPosition,
    offset: number,
    stopped: boolean
  ) => {
    if (!offset) return;

    emit("resizing", position, -offset, stopped);
  };
  const onResizingStart = onResizing.bind(undefined, "start");
  const onResizingEnd = onResizing.bind(undefined, "end");
</script>

<template>
  <div class="base-resizer">
    <BaseResizeBar
      class="resize-bar resize-bar--upper"
      v-on:resizing="onResizingStart"
    />
    <slot></slot>

    <BaseResizeBar
      class="resize-bar resize-bar--lower"
      v-on:resizing="onResizingEnd"
    />
  </div>
</template>

<style lang="scss" scoped>
  .base-resizer {
    position: relative;
  }

  .resize-bar {
    position: absolute;
    left: 0;
    right: 0;
  }

  .resize-bar--upper {
    top: 0;
  }

  .resize-bar--lower {
    bottom: 0;
  }
</style>
