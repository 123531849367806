import { addMinutes, compareAsc, differenceInMinutes, format } from "date-fns";
import { computed, markRaw, reactive } from "vue";

import {
  CalendarCell,
  CalendarEntry,
  CalendarEntryType,
  CalendarEntryUnit,
  CalendarEntryUnitAbsence,
  CalendarRelativeLocation,
} from "frontend/interfaces/calendar";
import { Person } from "frontend/interfaces/person";
import { ResizingPosition } from "frontend/interfaces/resizing";
import { Unit, UnitParticipation } from "frontend/interfaces/unit";
import { statusToEntry } from "frontend/parser/p/parse-appointment";
import { isPerson } from "frontend/parser/parse-person";
import {
  onUpdateAppointmentInternalOfficeNote,
  onUpdateAppointmentStatus,
} from "frontend/uses/abstract-view/database-mutations";
import {
  AbstractViewCellPosition,
  AbstractViewOptions,
  Database,
} from "frontend/uses/abstract-view/use-abstract-view-parser";
import {
  dateFromIntervalAndLocation,
  genericEntryOnClick,
  height,
  higherOrderIsInInterval,
  topOffsetPercentage,
} from "frontend/utils/base-calendar-utils";
import { ConstantsDayView } from "frontend/utils/constants";
import { MoveModal } from "frontend/utils/modals/move-modal";
import { DeleteModal } from "frontend/utils/modals/delete-modal";
import {
  profiledCalendarConstants,
  profiledUnitLabel,
} from "frontend/utils/profile-helper";
import { Interval } from "shared/interfaces/interval";
import {
  ConditionalContextMenuEntry,
  ContextMenuAPI,
} from "shared/utils/context-menu/interfaces";
import { isActive } from "shared/utils/bitwise-enum";
import { AppointmentStatus } from "shared/static/enums.ts.erb";
import { toggleStatusAppointment } from "frontend/api/application/request-appointments";
import {
  ContextMenuCategory,
  ContextMenuPositions,
} from "shared/utils/context-menu/positions";
import { useStore as useClipboardStore } from "frontend/stores/clipboard";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { router } from "frontend/router";

import BCUnitComponent from "frontend/components/base-calendar/BCUnit.vue";
import BCUnitAbsenceComponent from "frontend/components/base-calendar/BCUnitAbsence.vue";
import ShowUnitComponent from "frontend/components/ShowUnit.vue";
import ShowUnitAbsenceComponent from "frontend/components/ShowUnitAbsence.vue";
import SimpleEntry from "shared/utils/context-menu/components/SimpleEntry.vue";
import LinkEntry from "shared/utils/context-menu/components/LinkEntry.vue";

const CONSTANTS = {
  TIME_FORMATS: {
    TIME_LABEL: "HH:mm",
  } as const,
} as const;

export function entriesUnits(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): Array<CalendarEntryUnit | CalendarEntryUnitAbsence> {
  return database.units
    .filter(higherOrderUnitIsInInterval(interval, columnInterval, cellPosition))
    .filter((unit: Unit) => isUnitPartOfPerson(unit, person))
    .sort((a: Unit, b: Unit) => compareAsc(a.visual.from, b.visual.from))
    .map((unit: Unit) =>
      isUnitAbsence(unit)
        ? absenceToEntry(
            unit,
            person,
            interval,
            columnInterval,
            cellPosition,
            database,
            options
          )
        : unitToEntry(
            unit,
            person,
            interval,
            columnInterval,
            cellPosition,
            database,
            options
          )
    );
}

function isUnitPartOfPerson(unit: Unit, person: Person): boolean {
  return unit.participations.some((participation) =>
    isParticipationPartOfPerson(participation, person)
  );
}

function isParticipationPartOfPerson(
  participation: UnitParticipation,
  person: Person
): boolean {
  return !!participation.person_id && participation.person_id == person?.id;
}

function isUnitAbsence(unit: Unit): boolean {
  return unit.unit_type > 0;
}

function iconsToEntry(unit: Unit): {
  iconSmartphoneTouch: boolean;
  iconHasPraxisApp: boolean;
  iconHasPrivate: boolean;
} {
  return {
    iconSmartphoneTouch: !!unit.created_by_patient,
    iconHasPraxisApp: !!unit.appointment?.patient?.is_praxisapp,
    iconHasPrivate: !!unit.appointment?.patient?.is_private_insurance,
  };
}

function unitToEntry(
  unit: Unit,
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): CalendarEntryUnit {
  const disabled = unit.search && !unit.belongs_to_search;
  const hasClickHandler = !disabled && !unit.belongs_to_search;

  return {
    type: CalendarEntryType.Unit,
    key: unit.id,

    label: profiledUnitLabel(unit),
    color: unit.color ?? unit.session?.color ?? "#000000",
    timeLabel: format(unit.from, CONSTANTS.TIME_FORMATS.TIME_LABEL),
    topOffsetPercentage: topOffsetPercentage(
      unit.visual.from,
      interval.from,
      cellPosition,
      profiledCalendarConstants(ConstantsDayView)
    ),
    roomLabel: unit.room?.name,
    ...height(
      unit.visual,
      columnInterval,
      profiledCalendarConstants(ConstantsDayView)
    ),
    position: database.positionerFor(person).addEntry(unit.visual),
    disabled,
    hoverOn: unit.appointment_id ?? unit.id,
    ...statusToEntry(unit.appointment?.status ?? 0),
    ...iconsToEntry(unit),
    ...ctxToEntry(unit, database),

    onDrop: higherOrderUnitOnDrop(
      unit,
      person.id,
      person.name,
      interval,
      columnInterval,
      database,
      options
    ),
    onResize: higherOrderUnitOnResize(
      unit,
      person,
      interval,
      columnInterval,
      database,
      options
    ),
    onClick: hasClickHandler
      ? genericEntryOnClick(ShowUnitComponent, database.connection, unit.id, {
          "changed:unit:status": onUpdateAppointmentStatus(unit, database),
          "changed:unit:internalOfficeNote":
            onUpdateAppointmentInternalOfficeNote(unit, database),
          "changed:unit:name": (newName: string | null) =>
            (unit.name = newName),
          "changed:unit:color_id": (
            newColorId: string | null,
            colorHex: string | null,
            effectiveColorHex: string
          ) => {
            unit.color_id = newColorId;
            unit.color = colorHex;
            unit.effective_color_hex = effectiveColorHex;
          },
        })
      : undefined,

    componentCalendar: () => BCUnitComponent,
  };
}

function absenceToEntry(
  absence: Unit,
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  _options: AbstractViewOptions
): CalendarEntryUnitAbsence {
  return {
    type: CalendarEntryType.UnitAbsence,
    key: absence.id,

    ...height(
      absence.visual,
      columnInterval,
      profiledCalendarConstants(ConstantsDayView)
    ),
    topOffsetPercentage: topOffsetPercentage(
      absence.visual.from,
      interval.from,
      cellPosition,
      profiledCalendarConstants(ConstantsDayView)
    ),

    position: database.positionerFor(person).addEntry(absence.visual),

    label: absence.name ? absence.name : undefined,

    componentCalendar: () => BCUnitAbsenceComponent,

    onClick: genericEntryOnClick(
      ShowUnitAbsenceComponent,
      database.connection,
      absence.id
    ),
  };
}

function higherOrderUnitIsInInterval(
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition
): (unit: Unit) => boolean {
  return (unit: Unit) =>
    higherOrderIsInInterval(
      interval,
      columnInterval,
      cellPosition
    )(unit.visual.from, unit.visual.to);
}

export function higherOrderUnitOnDrop(
  unit: Unit,
  person_id: PrimaryKey,
  person_name: string,
  _interval: Interval,
  _columnInterval: Interval,
  database: Database,
  _options: AbstractViewOptions
): (target: CalendarCell, location: CalendarRelativeLocation) => void {
  return async (target: CalendarCell, location: CalendarRelativeLocation) => {
    const newStartDate = dateFromIntervalAndLocation(
      target.interval,
      location,
      profiledCalendarConstants(ConstantsDayView).ROUND_DROP_TO_NEAREST
    );

    const newEndDate = addMinutes(
      newStartDate,
      differenceInMinutes(unit.to, unit.from)
    );

    const componentProps = reactive({
      unit,
      newStartDate,
      newEndDate,
      notifyPatient: true,
      isLoading: false,
      reason: null as string | null,
      originalPersonName: person_name,
      newPersonId: isPerson(target.columnPivot)
        ? target.columnPivot.id
        : person_id,
    });

    new MoveModal()
      .setData(componentProps)
      .setPersonIDOriginal(person_id)
      .onSuccess(() => database.connection?.triggerRefreshNeeded())
      .show();
  };
}

function higherOrderUnitOnResize(
  unit: Unit,
  person: Person,
  _interval: Interval,
  _columnInterval: Interval,
  database: Database,
  _options: AbstractViewOptions
): (
  entry: CalendarEntry,
  resizingPosition: ResizingPosition,
  offsetAbsolute: number,
  resizingEnded: boolean,
  targetDate: Date
) => void {
  return async (
    _entry: CalendarEntry,
    resizingPosition: ResizingPosition,
    _offsetAbsolute: number,
    resizingEnded: boolean,
    targetDate: Date
  ) => {
    if (!resizingEnded) return;

    const [newStartDate, newEndDate] = [
      resizingPosition === "start" ? unit.to : unit.from,
      targetDate,
    ].sort(compareAsc);

    new MoveModal()
      .setData(
        reactive({
          unit,
          newStartDate,
          newEndDate,
          notifyPatient: true,
          isLoading: false,
          reason: null as string | null,
          originalPersonName: person.name,
          newPersonId: person.id,
        })
      )
      .setPersonIDOriginal(person.id)
      .onSuccess(() => database.connection?.triggerRefreshNeeded())
      .show();
  };
}

function ctxToEntry(
  unit: Unit,
  database: Database
): {
  ctxAppointmentStatusPatientArrived?: ConditionalContextMenuEntry;
  ctxAppointmentStatusPatientNotArrived?: ConditionalContextMenuEntry;
  ctxAppointmentStatusDone?: ConditionalContextMenuEntry;
  ctxCutAppointment?: ConditionalContextMenuEntry;
  ctxDeleteAppointment?: ConditionalContextMenuEntry;
  ctxAppointmentSuggestions?: ConditionalContextMenuEntry;
  ctxOpenPatientProfile?: ConditionalContextMenuEntry;
  ctxOpenPatientInPVS?: ConditionalContextMenuEntry;
} {
  return {
    ctxAppointmentStatusPatientArrived:
      unit.search /* disable if we are in search mode, eg creating new appointments */
        ? undefined
        : ctxAppointmentStatusPatientArrived(unit, database),
    ctxAppointmentStatusPatientNotArrived:
      unit.search /* disable if we are in search mode, eg creating new appointments */
        ? undefined
        : ctxAppointmentStatusPatientNotArrived(unit, database),
    ctxAppointmentStatusDone:
      unit.search /* disable if we are in search mode, eg creating new appointments */
        ? undefined
        : ctxAppointmentStatusDone(unit, database),
    ctxCutAppointment: unit.search
      ? undefined
      : ctxCutAppointment(unit, database),
    ctxDeleteAppointment: unit.search
      ? undefined
      : ctxDeleteAppointment(unit, database),
    ctxAppointmentSuggestions:
      unit.search /* disable if we are in search mode, eg creating new appointments */
        ? undefined
        : ctxAppointmentSuggestions(unit, database),
    ctxOpenPatientProfile:
      unit.search /* disable if we are in search mode, eg creating new appointments */
        ? undefined
        : ctxOpenPatientProfile(unit, database),
    ctxOpenPatientInPVS: unit.appointment?.patient.current_pvs_id
      ? ctxOpenPatientInPVS(unit, database)
      : undefined,
  };
}

function ctxAppointmentStatusPatientArrived(
  unit: Unit,
  database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit.appointment);
  const statusIsActive = computed(() =>
    isActive(unit.appointment?.status ?? 0, AppointmentStatus.patient_arrived)
  );
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.appointmentStatusPatientArrived,
      category: ContextMenuCategory.appointmentStatus,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit.appointment) return;

        const newStatus = await toggleStatusAppointment(unit.appointment.id, {
          patient_arrived: !statusIsActive.value,
        });
        onUpdateAppointmentStatus(unit, database)(newStatus);
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => setIcon(statusIsActive.value)),
        label: "Patient in der Praxis",
      }),
    },
  };
}

function ctxAppointmentStatusPatientNotArrived(
  unit: Unit,
  database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit.appointment);
  const statusIsActive = computed(() =>
    isActive(
      unit.appointment?.status ?? 0,
      AppointmentStatus.patient_not_arrived
    )
  );
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.appointmentStatusPatientNotArrived,
      category: ContextMenuCategory.appointmentStatus,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit.appointment) return;

        const newStatus = await toggleStatusAppointment(unit.appointment.id, {
          patient_not_arrived: !statusIsActive.value,
        });
        onUpdateAppointmentStatus(unit, database)(newStatus);
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => setIcon(statusIsActive.value)),
        label: "Patient nicht erschienen",
      }),
    },
  };
}

function ctxAppointmentStatusDone(
  unit: Unit,
  database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit.appointment);
  const statusIsActive = computed(() =>
    isActive(unit.appointment?.status ?? 0, AppointmentStatus.done)
  );
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.appointmentStatusDone,
      category: ContextMenuCategory.appointmentStatus,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit.appointment) return;

        const newStatus = await toggleStatusAppointment(unit.appointment.id, {
          done: !statusIsActive.value,
        });
        onUpdateAppointmentStatus(unit, database)(newStatus);
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => setIcon(statusIsActive.value)),
        label: "Termin abgeschlossen",
      }),
    },
  };
}

function ctxCutAppointment(
  unit: Unit,
  _database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit);
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.cutAppointment,
      category: ContextMenuCategory.appointmentActions,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit) return;
        const clipboardStore = useClipboardStore();
        clipboardStore.setUnit(unit);
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Termin Ausschneiden",
      }),
    },
  };
}

function ctxDeleteAppointment(
  unit: Unit,
  database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit);
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.deleteAppointment,
      category: ContextMenuCategory.appointmentActions,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit) return;
        new DeleteModal()
          .setData(
            reactive({
              unit,
              notifyPatientOnDeletion: !!unit.appointment?.patient,
              isLoading: false,
              reasonForDeletion: null as string | null,
            })
          )
          .onSuccess(() => database.connection?.triggerRefreshNeeded())
          .show();

        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Termin Löschen",
      }),
    },
  };
}

function ctxAppointmentSuggestions(
  unit: Unit,
  _database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit.appointment);
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.appointmentSuggestions,
      category: ContextMenuCategory.appointmentSuggestions,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit.appointment) return;
        router.push({
          name: "appointment-new",
          query: {
            patient: unit.appointment.patient.id,
            appointmentType: unit.appointment.appointment_type.id,
            appointment: unit.appointment.id,
          },
        });
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Alternative Vorschläge",
      }),
    },
  };
}

function ctxOpenPatientProfile(
  unit: Unit,
  _database: Database
): ConditionalContextMenuEntry {
  const statusExists = computed(() => !!unit.appointment?.patient);
  return {
    condition: statusExists,
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.openPatientProfile,
      category: ContextMenuCategory.patientActions,
      onTrigger: async (api: ContextMenuAPI) => {
        if (!unit.appointment) return;
        router.push({
          name: "patient-show",
          params: {
            id: unit.appointment.patient.id,
          },
        });
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Patientenprofil öffnen",
      }),
    },
  };
}

function ctxOpenPatientInPVS(
  unit: Unit,
  _database: Database
): ConditionalContextMenuEntry {
  return {
    condition: computed(
      () =>
        !!unit.appointment?.patient.current_pvs_id &&
        (unit.appointment?.patient.current_pvs_features ?? []).indexOf(
          "open_patient_in_pvs"
        ) > 0
    ),
    entry: {
      target: computed(() => null),
      component: markRaw(LinkEntry),
      position: ContextMenuPositions.openPatientInPVS,
      category: ContextMenuCategory.patientActions,
      onTrigger: async (api: ContextMenuAPI) => {
        api.doClose();
      },
      options: {
        iconName: "fe-note-clinical",
        label: "Patient in PVS öffnen",
        href: `monks-pvs-bridge://open_patient/${unit.appointment?.patient.current_pvs_id}`,
      },
    },
  };
}

function setIcon(active: boolean) {
  return active ? "fe-checkmark" : null;
}
