import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  isAxiosError,
} from "axios";

import { PathURL, PathURLTemplate, PathParams } from "shared/utils/pathify";
import {
  HttpMethod,
  HttpHeaders,
  HttpPayload,
  HttpStatus,
  HttpResponse,
} from "frontend/interfaces/http";
import { log, LogLevel } from "shared/utils/logger";

export const RESPONSE_HEADER_CSRF_TOKEN = "csrf-token";
export const REQUEST_HEADER_CSRF_TOKEN = "X-CSRF-Token";

export interface RawResponse {
  body: HttpPayload;
  status: HttpStatus;
  headers?: HttpHeaders;
}

export type RequestOptions = Record<string, never>;

export async function doRequest(
  method: HttpMethod,
  path: PathURLTemplate,
  params: PathParams,
  payload: HttpPayload,
  headers: HttpHeaders,
  onImminent: () => void,
  onRawResponse: (rawResponse: RawResponse) => void,
  suppressLog: boolean,
  options?: RequestOptions
): Promise<HttpResponse> {
  try {
    onImminent();
    const rawResponse = await doRequestLowLevel(
      method,
      path(params),
      payload,
      headers,
      suppressLog,
      options
    );
    onRawResponse(rawResponse);

    const { body, status } = rawResponse;
    return {
      body,
      status,
      error: null,
      statusIsSuccess: statusIsSuccess(status),
    };
  } catch (e) {
    const error = e instanceof Error ? e : new Error(e as string);
    const status = null;

    return {
      body: null,
      status,
      error,
      statusIsSuccess: statusIsSuccess(status),
    };
  }
}

export async function doRequestLowLevel(
  method: HttpMethod,
  path: PathURL,
  body: HttpPayload,
  headers: HttpHeaders,
  suppressLog: boolean,
  options?: RequestOptions
): Promise<RawResponse> {
  // Request options
  const config: AxiosRequestConfig = {
    headers: headers, // see eslint exception down below if changing!
    method: translateHttpMethod(method),
    url: path,
    data: body,
    validateStatus: () => true, // always resolve without error if we
    ...options,
  };

  if (method != HttpMethod.GET) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- cannot be null, because it is set above
    config.headers!.contentType = "application/json";
  }

  if (!suppressLog)
    log(
      LogLevel.Debug,
      "[REQUEST] Calling " + path + " [" + translateHttpMethod(method) + "]",
      body
    );

  try {
    const response = (await axios({
      ...config,
    })) as AxiosResponse<HttpPayload>;

    return translateResponse(response);
  } catch (e) {
    if (isAxiosError(e)) {
      return translateError(e as AxiosError<HttpPayload>);
    } else {
      log(LogLevel.Error, "[REQUEST] there was an unkown error requesting", e);
      throw e; // reraise
    }
  }
}

function translateHttpMethod(method: HttpMethod): Method {
  switch (method) {
    case HttpMethod.GET:
      return "GET";
    case HttpMethod.POST:
      return "POST";
    case HttpMethod.PATCH:
      return "PATCH";
    case HttpMethod.DELETE:
      return "DELETE";
  }
}

function translateResponse(response: AxiosResponse<HttpPayload>): RawResponse {
  return {
    body: response.data,
    status: response.status,
    headers: extractResponseHeaders(response),
  };
}

function translateError(error: AxiosError<HttpPayload>): RawResponse {
  log(LogLevel.Error, "[REQUEST] there was an error requesting", error);
  if (error.response) {
    // this should never happen, because
    log(
      LogLevel.Error,
      "[REQUEST] there was an error while requesting, but we got a response",
      error
    );
    return {
      body: error.response.data,
      status: error.response.status,
    };
  } else {
    throw error;
  }
}

export function statusIsSuccess(status: HttpStatus | null) {
  if (status) {
    return status >= 200 && status < 300;
  } else {
    return false;
  }
}

function extractResponseHeaders(
  response: AxiosResponse<HttpPayload>
): HttpHeaders | undefined {
  if (
    !response.headers ||
    !response.headers.toJSON ||
    typeof response.headers.toJSON === "string"
  )
    return undefined;

  const result: Record<string, string> = {};
  const headers = {
    ...response.headers,
  };
  for (const key in headers) {
    const value = headers[key];

    if (typeof value !== "string")
      log(LogLevel.Error, "Unknown header result", key, value);
    else result[key] = value;
  }

  return result;
}
