<script lang="ts">
  import { defineComponent, PropType, toRefs, watch } from "vue";

  import { AttendancesSettingsManager } from "frontend/uses/settings/manager/attendances-settings-manager";
  import { FilterBackendParams } from "frontend/uses/filter/filter-definition";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";

  export default defineComponent({
    components: { SettingsContainer },
    props: {
      filterParams: {
        type: Object as PropType<FilterBackendParams>,
        required: true,
      },
    },
    setup(props) {
      const { filterParams } = toRefs(props);

      const settingsManager = new AttendancesSettingsManager();
      settingsManager.filterParams = filterParams;
      settingsManager.provide();

      watch(filterParams, () => settingsManager.refreshEntities());
    },
  });
</script>

<template>
  <div class="settings-attendances">
    <SettingsContainer />
  </div>
</template>
