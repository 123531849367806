import { busEvent } from "frontend/events/bus-event";
import { RawResponse } from "frontend/api/common";
import { HttpMethod } from "frontend/interfaces/http";
import { PathURLTemplate } from "shared/utils/pathify";
import { UserEntity } from "frontend/interfaces/user";
import {
  OfficeStoreHeaders,
  PatientStoreHeaders,
} from "frontend/api/application";

// ATTENTION: Use with care as it might quickly get very complicated and
//            confusing!

export const OnRequestHasRawResponse = busEvent<{
  rawResponse: RawResponse;
  path: PathURLTemplate;
}>("on-request-has-raw-response");
export const OnRequestHasOfficeStoreHeaders = busEvent<{
  headers: OfficeStoreHeaders;
  path: PathURLTemplate;
}>("on-request-has-office-store-headers");
export const OnRequestHasPatientStoreHeaders = busEvent<{
  headers: PatientStoreHeaders;
  path: PathURLTemplate;
}>("on-request-has-patient-store-headers");

export const OnRequestImminent = busEvent<{
  method: HttpMethod;
  path: PathURLTemplate;
}>("on-request-imminent");

export const OnLogoutBefore =
  busEvent<Record<string, never>>("on-logout-before");
export const OnLogoutAfter = busEvent<Record<string, never>>("on-logout-after");
export const OnLoginBefore = busEvent<{
  userEntity: UserEntity;
}>("on-login-before");
export const OnLoginSuccess = busEvent<{
  userEntity: UserEntity;
}>("on-login-success");
