import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { SearchResultRaw } from "frontend/interfaces/search-results";

export async function requestSearch(
  query?: string
): Promise<SearchResultRaw[]> {
  const params: {
    query?: string;
  } = {};
  if (query) params.query = query;
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_searches_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    return (body as unknown as { results: SearchResultRaw[] }).results;
  } else {
    return [];
  }
}
