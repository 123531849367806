import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  ISODateString,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { UnitRaw } from "frontend/interfaces/unit";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestUnit(id: PrimaryKey): Promise<UnitRaw> {
  const { body } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_unit_path,
    { id: id },
    {}
  );

  return body as unknown as UnitRaw;
}

export async function updateUnit(
  id: PrimaryKey,
  duration: {
    from: ISODateString;
    to: ISODateString;
  },
  notifyPatient: boolean,
  reason: string | null,
  participation: {
    personIDOriginal: PrimaryKey;
    personIDNew: PrimaryKey;
  } | null
): Promise<{ success: boolean }> {
  const participationData = participation
    ? {
        participation: {
          person_id_original: participation.personIDOriginal,
          person_id_new: participation.personIDNew,
        },
      }
    : ({} as Record<string, never>);

  return await updateUnitUnified({
    duration,
    notify_patient: notifyPatient,
    reason,
    ...participationData,
    id,
  });
}

export async function updateUnitUnified(
  unit: HttpPayload
): Promise<UnifiedResponse<UnitRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_unit_path,
    { id: unit.id as string },
    unit
  );

  return {
    entityRaw: (body as unknown as { entity: UnitRaw }).entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}

export async function createUnit(unit: HttpPayload): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_units_path,
    {},
    unit
  );
  return statusIsSuccess;
}

export async function destroyUnit(
  id: PrimaryKey,
  notifyPatient: boolean,
  reason: string | null
): Promise<{ success: boolean }> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_unit_path,
    { id: id },
    { notify_patient: notifyPatient, reason }
  );

  return { success: statusIsSuccess };
}
