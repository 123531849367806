import { toHandlerKey } from "vue";

import { ModalComponentOn } from "frontend/uses/simple-modal/use-simple-modal";

/**
 * Takes a ModalComponentOn-Hash and converts its key so that
 * the resulting Hash can directly be bound to a component.
 *
 * Example:
 * event-name: 'refresh-needed:something-important
 *
 * will be converted to: 'onRefresh-needed:something-important
 * (see convertSingleEventName)
 *
 * this can then be used to bind events to the new v4-api of vue-final-modal
 *
 * It is unclear if the convertSingleEventName has to be changed at some point to convert
 * the example above to: refreshNeeded:somethingImportant (using vue's camelize-method)
 *
 * @param eventHash
 */
export function eventNameConverter(
  eventHash: ModalComponentOn
): ModalComponentOn {
  const result: ModalComponentOn = {};
  for (const key in eventHash) {
    result[convertSingleEventName(key)] = eventHash[key];
  }

  return result;
}

function convertSingleEventName(eventName: string): string {
  // uses vues internal's method to convert a event name to the
  // corresponding method name that is bound as a component attribute
  // alternative version, if this does not work everytime:
  //   return toHandlerKey(camelize(eventName));
  // where camlize can be imported from vue.
  return toHandlerKey(eventName);
}
