<script lang="ts">
  import { defineComponent, PropType, ref, toRefs, watch } from "vue";

  import { requestPatient } from "frontend/api/application/request-patients";
  import {
    Patient,
    PatientWithAppointments,
  } from "frontend/interfaces/patient";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { parsePatientWithAppointments } from "frontend/parser/parse-patient";

  import ShowPatient from "frontend/components/ShowPatient.vue";

  export default defineComponent({
    components: { ShowPatient },
    props: {
      id: {
        type: String as PropType<PrimaryKey>,
        required: true,
      },
    },
    setup(props) {
      const { id } = toRefs(props);
      const currentPatient = ref<PatientWithAppointments | null>(null);

      const reloadPatient = async () => {
        const patientRaw = await requestPatient(id.value);
        if (patientRaw) {
          currentPatient.value = parsePatientWithAppointments(patientRaw);
        } else {
          currentPatient.value = null;
        }
      };
      watch(
        id,
        () => {
          reloadPatient();
        },
        { immediate: true }
      );

      const methods = {
        updatePatientBase: (patient: Patient) => {
          if (!currentPatient.value) return;

          const { appointments, ...tmp } = currentPatient.value;
          currentPatient.value = {
            ...tmp,
            ...patient,
            appointments: [...appointments],
          };
        },
      };

      return {
        currentPatient,

        ...methods,
        reloadPatient,
      };
    },
  });
</script>

<template>
  <div class="plain">
    <ShowPatient
      v-if="currentPatient"
      v-bind:patient="currentPatient"
      v-on:update:patient="updatePatientBase"
      v-on:reload-patient="reloadPatient"
    />
  </div>
</template>
