import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import { Office } from "frontend/interfaces/office";
import { httpPayloadOffice, parseOffice } from "frontend/parser/parse-office";
import {
  requestOffice,
  updateOffice,
} from "frontend/api/application/request-office";

import EditOffice from "frontend/components/settings/EditOffice.vue";

const ERROR_MESSAGE = "Method not implemented.";

// Special case --> only ever exactly one instance available
export class GeneralSettingsManager extends SettingsManager<Office> {
  entityName(): string {
    throw new Error(ERROR_MESSAGE);
  }
  componentForEdit(): Component {
    return EditOffice;
  }
  craftNewEntity(): Office {
    throw new Error(ERROR_MESSAGE);
  }
  async hookList(): Promise<SettingsListResult<Office>> {
    return {
      entities: [parseOffice(await requestOffice())],
      success: true,
      errors: {},
    };
    throw new Error(ERROR_MESSAGE);
  }
  async hookSave(record: Office): Promise<SettingsSaveResult<Office>> {
    const result = await updateOffice(httpPayloadOffice(record));

    if (result.success) {
      return {
        success: true,
        entity: parseOffice(result.entityRaw),
        errors: result.errors,
      };
    } else {
      return {
        success: false,
        entity: record,
        errors: result.errors,
      };
    }
  }
  hookDelete(): Promise<SettingsDeleteResult> {
    throw new Error(ERROR_MESSAGE);
  }

  enableNewEntities(): boolean {
    return false;
  }
}
