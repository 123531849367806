import { ref, Ref, unref } from "vue";

import { requestLogin as praxisappRequestLogin } from "frontend/api/praxisapp/request-login";
import { requestLogin as applicationRequestLogin } from "frontend/api/application/request-login";
import { useStore } from "frontend/stores/login";
import { UserEntity } from "frontend/interfaces/user";
import { log, LogLevel } from "shared/utils/logger";
import {
  useSubscription,
  SubscriptionSubscribe,
} from "frontend/uses/use-subscription";
import { usePending } from "frontend/uses/use-pending";

export function useLoginForm(): {
  username: Ref<string>;
  password: Ref<string>;
  isPending: Ref<boolean>;
  errors: Ref<Array<string>>;
  doLogin: () => Promise<void>;
  onLoginSuccess: SubscriptionSubscribe<never>;
} {
  const { isPending, doWithPending } = usePending();
  const { notify, subscribe } = useSubscription<never>();
  const store = useStore();

  const username = ref("");
  const password = ref("");
  const errors = ref<Array<string>>([]);

  const doLogin = async (event?: Event) => {
    if (event) event.preventDefault();

    await doWithPending(async () => {
      log(LogLevel.Info, "[LOGIN] Request login");
      errors.value = []; // clear error messages

      const { success, accessToken } = await praxisappRequestLogin(
        unref(username),
        unref(password)
      );

      if (success && accessToken) {
        log(LogLevel.Debug, "[LOGIN] Login was successful.");

        // start a session on the backend
        const { success } = await applicationRequestLogin(accessToken);

        if (success) {
          store.login(UserEntity.Practice);

          setTimeout(() => {
            notify(); // notify all subscribers (that subscribed via onLoginSuccess)
          });
        } else {
          unref(errors).push(
            "Ein unbekannter Fehler hat den Login verhindert. Bitte wenden Sie sich an unseren Support."
          );
        }
      } else {
        unref(errors).push(
          "Der Login war nicht erfolgreich. Bitte überprüfen Sie Ihren Nutzernamen und Ihr Passwort."
        );
      }
    });
  };

  return {
    username,
    password,
    doLogin,
    isPending,
    errors,
    onLoginSuccess: subscribe,
  };
}
