import { Modal } from "frontend/utils/modals/modal";
import { Patient } from "frontend/interfaces/patient";

import PatientSelectionComponent from "frontend/components/PatientSelection.vue";

export interface PatientSelectionModalData {
  selectedPatient: Patient | null;
}

export class PatientSelectionModal extends Modal<
  typeof PatientSelectionComponent,
  PatientSelectionModalData
> {
  protected modalComponent() {
    return PatientSelectionComponent;
  }

  protected hideConfirm(): boolean {
    return true;
  }
}
