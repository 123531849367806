import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { api } from "frontend/api/application/settings/request-schedules";
import {
  httpPayloadSchedule,
  newSchedule,
  parseSchedule,
} from "frontend/parser/settings/parse-schedule";
import { Schedule } from "frontend/interfaces/schedule";

import EditSchedule from "frontend/components/settings/EditSchedule.vue";
import ScheduleComponent from "frontend/components/ShowSchedule.vue";

export class SchedulesSettingsManager extends SettingsManager<Schedule> {
  entityName(): string {
    return "Zeitplan";
  }
  componentForEdit(): Component {
    return EditSchedule;
  }
  craftNewEntity(): Schedule {
    return newSchedule();
  }
  hookList(): Promise<SettingsListResult<Schedule>> {
    return genericListPattern(parseSchedule, api.requestIndex);
  }
  hookSave(record: Schedule): Promise<SettingsSaveResult<Schedule>> {
    return genericSavePattern(
      record,
      parseSchedule,
      httpPayloadSchedule,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: Schedule): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
  useModal(): boolean {
    return true;
  }
  modalComponent(): Component {
    return ScheduleComponent;
  }
}
