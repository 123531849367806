export interface ModalOptions {
  title?: string;
}

export abstract class SimpleModal {
  private internalOptions: ModalOptions;
  private internalContent: string;

  constructor(content: string, options: ModalOptions) {
    this.internalContent = content;
    this.internalOptions = { ...options };
  }

  get title(): string {
    return this.internalOptions.title || this.defaultTitle;
  }

  get content() {
    return this.internalContent;
  }

  get confirmLabel(): undefined | string {
    return undefined;
  }

  get cancelLabel(): undefined | string {
    return undefined;
  }

  get hideCancel() {
    return false;
  }

  get hideConfirm() {
    return false;
  }

  abstract get defaultTitle(): string;
}
