<script lang="ts">
  import { defineComponent } from "vue";

  import { GeneralSettingsManager } from "frontend/uses/settings/manager/general-settings-manager";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";

  export default defineComponent({
    components: { BaseHelp, SettingsContainer },
    setup() {
      new GeneralSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-general">
    <h2>Allgemein</h2>

    <BaseHelp>
      Geben Sie den Namen Ihrer Praxis bzw. des
      <strong>Praxisverbundes</strong> (Gesamtnamen bei mehreren Standorten) an.
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
