export class PointElement {
  private clientX = 0;
  private clientY = 0;

  constructor(clientX: number, clientY: number) {
    this.clientX = clientX;
    this.clientY = clientY;
  }
  public getBoundingClientRect() {
    return {
      width: 0,
      height: 0,
      x: this.clientX,
      y: this.clientY,
      top: this.clientY,
      left: this.clientX,
      right: this.clientX,
      bottom: this.clientY,
    };
  }
}
