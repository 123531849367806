<script lang="ts">
  import { defineComponent } from "vue";

  import { OperatorsManager } from "frontend/uses/settings/manager/operators-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new OperatorsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-colors">
    <h2>Praxisanwender</h2>

    <BaseHelp>
      <p>
        Sie können (optional) Praxisanwender anlegen. Das sind alle Personen,
        die die Anwendung in der Praxis bedienen. Anschließen können Sie
        auswählen, welcher der Praxisanwender aktuell das Programm bedient. Die
        Namen tauchen dann im Protokoll der Praxis auf. Das erleichtert das
        Nachvollziehen von Aktionen in der Online-Terminverwaltung.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
