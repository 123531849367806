<script lang="ts">
  import { defineComponent } from "vue";

  import { ColorsSettingsManager } from "frontend/uses/settings/manager/colors-settings-manager";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";

  export default defineComponent({
    components: { BaseHelp, SettingsContainer },
    setup() {
      new ColorsSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-colors">
    <h2>Farben</h2>

    <BaseHelp>
      Hier können Sie die Farbgebung von bestimmten Termintypen/Einträgen im
      Kalender wählen und definieren, um sie deutlicher voneinander abzuheben.
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
