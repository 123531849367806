import { Component } from "vue";

import {
  ExtractFilterState,
  ExtractFilterOptions,
  FilterBackendParams,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { FilterManager } from "frontend/uses/filter/filter-manager";

export class Filter<EntityType, ComponentType extends Component> {
  private internalFilterDefinition: Readonly<
    FilterDefinition<EntityType, ComponentType>
  >;
  private internalFilterState: ExtractFilterState<ComponentType>;
  private internalFilterManager: FilterManager<EntityType>;

  constructor(
    filterDefinition: FilterDefinition<EntityType, ComponentType>,
    filterManager: FilterManager<EntityType>
  ) {
    this.internalFilterDefinition = Object.freeze({ ...filterDefinition });
    this.internalFilterState =
      this.internalFilterDefinition.initialFilterState();
    this.internalFilterManager = filterManager;
  }

  get isFrontendFilter(): boolean {
    return !!this.internalFilterDefinition.frontendFilter;
  }

  get isBackendFilter(): boolean {
    return !!this.internalFilterDefinition.backendFilter;
  }

  get isFrontendSort(): boolean {
    return !!this.internalFilterDefinition.frontendSort;
  }

  get component(): ComponentType {
    return this.internalFilterDefinition.component;
  }

  get filterState(): ExtractFilterState<ComponentType> {
    return this.internalFilterState;
  }

  get filterOptions(): ExtractFilterOptions<ComponentType> {
    return this.internalFilterDefinition.filterOptions;
  }

  filter(collection: EntityType[]): EntityType[] {
    let currentCollection = collection;

    const frontendFilter = this.internalFilterDefinition.frontendFilter;
    if (this.isFrontendFilter && frontendFilter) {
      currentCollection = currentCollection.filter((entry) =>
        frontendFilter(entry, this.internalFilterState)
      );
    }

    return currentCollection;
  }

  sort(collection: EntityType[]): EntityType[] {
    let currentCollection = collection;

    const frontendSort = this.internalFilterDefinition.frontendSort;
    if (this.isFrontendSort && frontendSort) {
      currentCollection = frontendSort(
        currentCollection,
        this.internalFilterState
      );
    }

    return currentCollection;
  }

  backendQuery(): FilterBackendParams {
    if (this.isBackendFilter) {
      const backendFilter = this.internalFilterDefinition.backendFilter;
      if (backendFilter) {
        return backendFilter(this.internalFilterState);
      }
    }
    return {};
  }

  registerStateChange(newState: ExtractFilterState<ComponentType>) {
    for (const key in newState) {
      this.internalFilterState[key] = newState[key];
    }

    this.internalFilterManager.registerStateChange(this);
  }
}
