import { PrimaryKey } from "frontend/interfaces/primary-key";

export function uniqBy<T>(a: Array<T>, key: (item: T) => string): Array<T> {
  const seen = new Set<string>();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export function removeIfExists<T>(a: Array<T>, element: T): void {
  const index = a.indexOf(element);
  if (index >= 0) a.splice(index, 1);
}

export function highestBy<T>(
  a: Array<T>,
  ranking: (e: T) => number | null // null --> ignore element
): T | null {
  let current = null;
  let currentRank = null;

  for (const e of a) {
    const rank = ranking(e);
    if (rank && (!currentRank || rank > currentRank)) {
      currentRank = rank;
      current = e;
    }
  }

  return current;
}

export function clearArray(a: Array<unknown>): void {
  a.splice(0);
}

export function sortArrayByArrangement<T extends { id: PrimaryKey }>(
  arr: Array<T>,
  arrangement: Array<PrimaryKey>
): Array<T> {
  return arr
    .slice()
    .sort((a, b) =>
      arrangement.indexOf(a.id.toString()) >
        arrangement.indexOf(b.id.toString()) ||
      arrangement.indexOf(a.id.toString()) === -1 ||
      arrangement.indexOf(b.id.toString()) === -1
        ? 1
        : -1
    );
}
