import { v4 as uuid } from "uuid";
import { addMinutes, differenceInMinutes, isSameMinute } from "date-fns";

import { ConstantsDayView } from "frontend/utils/constants";
import { Person } from "frontend/interfaces/person";
import { Interval } from "shared/interfaces/interval";
import {
  AbstractViewCellPosition,
  AbstractViewOptions,
  Database,
} from "frontend/uses/abstract-view/use-abstract-view-parser";
import {
  CalendarEntryType,
  CalendarEntryVisual,
} from "frontend/interfaces/calendar";
import { topOffsetPercentage } from "frontend/utils/base-calendar-utils";
import { range } from "frontend/utils/array";
import { profiledCalendarConstants } from "frontend/utils/profile-helper";

import BCVisualLine from "frontend/components/base-calendar/BCVisualLine.vue";

export function entriesVisuals(
  _person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  _database: Database,
  _options: AbstractViewOptions
): Array<CalendarEntryVisual> {
  return calculateVisualSteps(
    interval,
    columnInterval,
    profiledCalendarConstants(ConstantsDayView)
      .TIMESCALE_VISUAL_INDICATOR_STEP_SIZE
  ).map(
    (timeOfVisual: Date): CalendarEntryVisual => ({
      key: uuid(),
      type: CalendarEntryType.Visual,
      componentCalendar: () => BCVisualLine,
      topOffsetPercentage: topOffsetPercentage(
        timeOfVisual,
        interval.from,
        cellPosition,
        profiledCalendarConstants(ConstantsDayView)
      ),
      heightPercentage: 0,
    })
  );
}

function calculateVisualSteps(
  interval: Interval,
  columnInterval: Interval,
  stepSize?: number
): Date[] {
  if (!stepSize || stepSize <= 0) return [];

  const minutesUntilIntervalStart = differenceInMinutes(
    interval.from,
    columnInterval.from
  );

  const minutesUntilIntervalOver = differenceInMinutes(
    interval.to,
    columnInterval.from
  );

  const firstTick =
    Math.ceil((minutesUntilIntervalStart * 1.0) / stepSize) * stepSize;
  const lastTick =
    Math.floor((minutesUntilIntervalOver * 1.0) / stepSize) * stepSize;

  if (firstTick >= minutesUntilIntervalOver) return [];
  if (lastTick <= minutesUntilIntervalStart) return [];
  if (firstTick === lastTick)
    return [addMinutes(columnInterval.from, firstTick)];

  const tickCount = Math.max(
    0,
    Math.floor((Math.max(0, lastTick - firstTick) * 1.0) / stepSize) + 1
  );

  return range(0, tickCount, { excludeTo: true })
    .map((index) =>
      addMinutes(columnInterval.from, firstTick + index * stepSize)
    )
    .filter(
      (time) =>
        !isSameMinute(time, interval.from) && !isSameMinute(time, interval.to)
    );
}
