<script lang="ts">
  import { defineComponent } from "vue";

  import { AppointmentTypesSettingsManager } from "frontend/uses/settings/manager/appointment-types-settings-manager";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";

  export default defineComponent({
    components: { BaseHelp, SettingsContainer },
    setup() {
      new AppointmentTypesSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-appointment-types">
    <h2>Termintypen</h2>

    <BaseHelp>
      <p>
        Die Praxis kann verschiedene
        <strong>Standard-Termintypen</strong> anlegen, z.B. U2 - U9, J1 und J2,
        Akuttermin, Impftermin, Attest usw. Es können auch spezielle Termine,
        wie z.B. die Jugendsprechstunde, konfiguriert werden, die die Patienten
        dann in der App auswählen können.
      </p>
      <p>
        Sie legen fest, welche Termine für Kassen und/oder Privatpatienten
        angelegt werden.
      </p>
      <p>
        Sie können <strong>Einzeltermine</strong> konfigurieren (nur
        Arzt/Ärztin) oder zusammenhängende Termine (Kettentermine mit
        Arzt/Ärztin + MFA wie bei vielen U-Untersuchungen).
      </p>
      <p>
        Für <strong>Kettentermine</strong> sollten Sie die Rollen (siehe Rubrik
        „Rollen“) entsprechend angegeben haben.
      </p>
      <p>
        Ein Kettentermin ist sinnvoll, wenn verschiedenes
        <strong
          >Praxispersonal gleichzeitig und/oder nacheinander dafür nötig</strong
        >
        ist.
      </p>

      <p>
        <strong>Beschreibung:</strong> Die Beschreibung des Termintyps sehen
        Eltern/Jugendliche in der App, wenn sie auf den Termin klicken.
      </p>
      <p>
        <strong>Nachricht an Patienten:</strong> Diese Zusatzinformation
        erhalten Nutzer der App im Erinnerungstext für den Termin. Wann, wie oft
        und in welchem Abstand vor dem Termin der Patient erinnert werden soll,
        legen Sie unter dem Punkt „Terminerinnerung“ fest.
      </p>
      <p>
        <strong>Tipp:</strong> Um die neue Online-Terminverwaltung einzuführen
        (für Mitarbeiter und Patienten), können Sie zunächst mit einzelnen
        Termintypen beginnen, die Sie dann nach und nach erweitern.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
