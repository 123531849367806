import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import { Room, RoomNew, RoomRaw } from "frontend/interfaces/room";

export function parseRoom(room: RoomRaw): Room {
  return { ...room };
}

export function httpPayloadRoom(room: RoomRaw): HttpPayload {
  return {
    ...room,
  };
}

export function newRoom(): RoomNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    location_id: null,
    unsaved: true,
  };
}
