import { PathURLTemplate } from "shared/utils/pathify";

export function createInterpolateFunction(
  pathUrl: string,
  base: string
): PathURLTemplate {
  return (params: { [key: string]: string } | null) => {
    const availableParams: {
      [key: string]: string;
    } = params || {};
    const { url, unusedKeys } = interpolateUrl(pathUrl, availableParams);
    const parsedUrl = new URL(url, base); // polyfill via core-js for IE11

    for (const key of unusedKeys) {
      parsedUrl.searchParams.append(key, availableParams[key]);
    }

    return parsedUrl.toString();
  };
}

function interpolateUrl(
  url: string | null,
  params: { [key: string]: string }
): { url: string; unusedKeys: string[]; missingKeys: string[] } {
  if (url !== undefined && url !== null && typeof url !== "string") {
    throw new Error("url must be a string");
  }

  const missingKeys: string[] = [];
  let unusedKeys: string[] = Object.keys(params);

  const result: string[] = [];

  (url || "").split("/:").forEach((segment, i) => {
    if (i === 0) {
      result.push(segment);
    } else {
      const segmentMatch = segment.match(/(\w+)(?:[?*])?(.*)/) || [];
      const key = segmentMatch[1] || "";

      if (params[key] !== undefined) {
        unusedKeys = unusedKeys.filter((aKey) => aKey !== key);
        result.push("/" + params[key]);
      } else {
        missingKeys.push(key);
        result.push("/:" + key);
      }

      result.push(segmentMatch[2] || "");
    }
  });

  return {
    url: result.join(""),
    unusedKeys,
    missingKeys,
  };
}
