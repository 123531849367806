import { DateFormat, formatDate } from "shared/utils/date-utils";
import { Unit } from "frontend/interfaces/unit";

export function createPdf(startDate: Date, endDate: Date, units: Unit[]) {
  const pdfMake = require("pdfmake/build/pdfmake");
  const pdfFonts = require("pdfmake/build/vfs_fonts");
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const title = {
    text: "TERMINLISTE",
    style: "title",
  };
  const subtitle = {
    text: `Von: ${startDate.toLocaleDateString()}  Bis: ${endDate.toLocaleDateString()}`,
    style: "subtitle",
  };
  const appointmentList = units.map((unit) => {
    const unitDate = {
      text: `${formatDate(unit.from, DateFormat.DateOnlyLong)}`,
      style: "date",
    };
    const unitColor = {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 14,
          lineWidth: 5,
          lineColor: unit.color ?? unit.session?.color ?? "#000000",
        },
      ],
      width: 5,
    };
    const unitContent = {
      columns: [
        {
          width: 75,
          text: unitInterval(unit),
          style: "time",
        },
        {
          width: "auto",
          text: unitOrderTime(unit, false) + unitDescription(unit),
          style: "description",
        },
      ],
    };
    const unitTable = {
      table: {
        widths: ["100%", "*"],
        body: [
          [
            {
              columns: [unitColor, unitContent],
            },
          ],
        ],
        dontBreakRows: true,
      },
      margin: [5, 0, 0, 3],
      layout: {
        hLineWidth: () => 0.5,
        vLineWidth: () => 0.5,
        hLineColor: () => "#d5d5d5",
        vLineColor: () => "#d5d5d5",
      },
    };
    if (isFirstDay(units, unit)) return [unitDate, unitTable];
    else return unitTable;
  });
  const pdfContent = [title, subtitle, appointmentList];
  const pdfDefinition = {
    info: {
      title: `Terminliste__
              ${startDate.toLocaleDateString()}-
              ${endDate.toLocaleDateString()}
             `,
    },
    content: pdfContent,
    header: {
      text: `${formatDate(new Date(), DateFormat.DateAndTime)}`,
      style: "header",
    },
    footer: (currentPage: number, pageCount: number) => ({
      text: `Seite ${currentPage.toString()} von ${pageCount}`,
      style: "footer",
    }),
    styles: pdfStyles(),
  };
  pdfMake.createPdf(pdfDefinition).open();
}

export function unitInterval(unit: Unit) {
  return `${formatDate(
    unit.from,
    DateFormat.TimeOnlyWithoutSeconds
  )} - ${formatDate(unit.to, DateFormat.TimeOnlyWithoutSeconds)}`;
}

export function unitOrderTime(unit: Unit, long: boolean = true) {
  if (!unit.appointment?.order_time_in_minutes) return "";
  const orderTime = formatDate(
    unit.appointment.begin_for_patient,
    DateFormat.TimeOnlyWithoutSeconds
  );
  return long ? ` (Einbestellzeit: ${orderTime})` : `(${orderTime})/`;
}

export function unitDescription(unit: Unit) {
  let result = "";
  if (unit.appointment?.patient)
    result += `${unit.appointment.patient.display_name}/`;
  result +=
    unit.name ?? unit.session?.name ?? unit.appointment?.appointment_type.name;
  if (unit.participations.length > 0)
    result += `/${unit.participations[0].person_name}`;
  if (unit.room) result += `/${unit.room.name}`;
  return result;
}

export function unitNote(unit: Unit) {
  return unit.appointment?.internal_office_note
    ? unit.appointment.internal_office_note
    : unit.appointment?.message
      ? ` Nachricht: ${unit.appointment.message}`
      : "";
}

export function isFirstDay(units: Unit[], unit: Unit) {
  const position = units.indexOf(unit);
  if (position === 0) return true;
  else return units[position - 1].from.getDate() !== unit.from.getDate();
}

function pdfStyles() {
  return {
    header: {
      alignment: "right",
      color: "#333",
      fontSize: 10,
      margin: [0, 20, 50, 0],
    },
    footer: {
      alignment: "center",
      fontSize: 10,
      margin: [0, 10, 0, 0],
    },
    title: {
      fontSize: 20,
      bold: true,
      margin: [0, 20, 0, 0],
    },
    subtitle: {
      fontSize: 14,
      margin: [0, 10, 0, 10],
    },
    date: {
      margin: [0, 10, 0, 2],
      bold: true,
      fontSize: 10,
    },
    time: {
      color: "#3b7d8b",
      bold: true,
      fontSize: 11,
    },
    description: {
      fontSize: 10,
    },
  };
}
