<script lang="ts">
  import { defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Office } from "frontend/interfaces/office";
  import { OfficeUnits } from "shared/static/time-units.ts.erb";
  import { useStore as useProfileStore } from "frontend/stores/profile";
  import { today } from "shared/utils/date-utils";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormRelativeTime from "frontend/components/form/FormRelativeTime.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import FormRichedit from "frontend/components/form/FormRichedit.vue";
  import FormSelectOfficeSettingsProfile from "frontend/components/form/FormSelectOfficeSettingsProfile.vue";

  export default defineComponent({
    components: {
      FormInput,
      BaseHelp,
      FormRelativeTime,
      FormRichedit,
      FormSelectOfficeSettingsProfile,
    },
    props: {
      ...editProps<Office>(),
    },
    setup(props) {
      const { entity } = toRefs(props);

      const profileStore = useProfileStore();

      const editComponent = useEditComponent(entity.value, {
        onSaved: () => {
          const profile = profileStore.lookupProfile(
            editComponent.entityEditable.value.default_settings_profile_id
          );

          profileStore.provideOfficeProfile(profile);
        },
      });

      return {
        ...editComponent,

        //libs
        OfficeUnits,
        today,
      };
    },
  });
</script>

<template>
  <div class="edit-office">
    <h5>{{ entity.name }}</h5>
    <FormInput
      v-model="entityEditable.name"
      label="Name der Praxis"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />

    <BaseHelp class="eo__help-text">
      Wählen Sie den Zeitraum aus, für den Online-Buchungen in Ihrer Praxis im
      Voraus möglich sind.
    </BaseHelp>

    <div class="eo__duration-pickers">
      <FormRelativeTime
        v-model:amount="entityEditable.booking_range_begin_number"
        v-model:unit="entityEditable.booking_range_begin_unit"
        v-bind:time-units="OfficeUnits"
        label="Terminbuchung frühestens möglich in"
        v-bind:errors="errorFor('booking_range_begin_number')"
        v-bind:animation-status="statusFor('booking_range_begin_number')"
        v-on="{ autosave: onAutosaveFor('booking_range_begin_number') }"
      />
    </div>

    <div class="eo__duration-pickers">
      <FormRelativeTime
        v-model:amount="entityEditable.booking_range_end_number"
        v-model:unit="entityEditable.booking_range_end_unit"
        v-bind:time-units="OfficeUnits"
        label="Terminbuchung höchstens möglich bis in"
        v-bind:errors="errorFor('booking_range_end_number')"
        v-bind:animation-status="statusFor('booking_range_end_number')"
        v-on="{ autosave: onAutosaveFor('booking_range_end_number') }"
      />
    </div>

    <BaseHelp class="eo__help-text">
      Sie können hier einen (Banner-)Text eingeben, der dem Patienten
      <strong>vor der Terminbuchung</strong> angezeigt wird. Hier können Sie
      beispielsweise allgemein über Ihre Praxis informieren oder eine
      Einverständniserklärung anzeigen lassen.
    </BaseHelp>

    <FormRichedit
      v-model="entityEditable.banner_text_html"
      label="Bannertext an Patient"
      v-bind:errors="errorFor('banner_text_html')"
      v-bind:animation-status="statusFor('banner_text_html')"
      v-on="{ autosave: onAutosaveFor('banner_text_html') }"
    />
    <FormInput
      v-model="entityEditable.banner_submit_label"
      label="Buttonbeschriftung zur Bestätigung des Bannertextes"
      v-bind:errors="errorFor('banner_submit_label')"
      v-bind:animation-status="statusFor('banner_submit_label')"
      v-on="{ autosave: onAutosaveFor('banner_submit_label') }"
    />

    <BaseHelp class="eo__help-text">
      Die Emailsignatur wird bei allen Emails, die das System an Ihre Patienten
      verschickt, verwendet. Hier können z.B. der Name oder die Adresse der
      Praxis angegeben werden. Aus Datenschutzgründen können Praxis spezifische
      Informationen auch weggelassen werden.
    </BaseHelp>

    <FormInput
      v-model="entityEditable.email_signature"
      label="Emailsignatur"
      type="textarea"
      v-bind:errors="errorFor('email_signature')"
      v-bind:animation-status="statusFor('email_signature')"
      v-on="{ autosave: onAutosaveFor('email_signature') }"
    />
    <FormInput
      v-model="entityEditable.order_time_in_minutes"
      v-bind:type="'number'"
      label="Einbestellzeit der Patienten vor der Untersuchung"
      v-bind:errors="errorFor('order_time_in_minutes')"
      v-bind:animation-status="statusFor('order_time_in_minutes')"
      v-on="{ autosave: onAutosaveFor('order_time_in_minutes') }"
    />
    <FormSelectOfficeSettingsProfile
      v-model="entityEditable.default_settings_profile_id"
      label="Standardeinstellungsprofil der Praxis"
      include-blank="-- Kein Einstellungsprofil --"
      v-bind:errors="errorFor('default_settings_profile_id')"
      v-bind:animation-status="statusFor('default_settings_profile_id')"
      v-on="{ autosave: onAutosaveFor('default_settings_profile_id') }"
    />
  </div>
</template>

<style lang="scss" scoped>
  .eo__help-text {
    margin-top: 30px;
  }
  .eo__duration-pickers {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
    }
  }
</style>
