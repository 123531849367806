import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  Schedule,
  ScheduleNew,
  ScheduleRaw,
} from "frontend/interfaces/schedule";

export function parseSchedule(schedule: ScheduleRaw): Schedule {
  return {
    ...schedule,
  };
}

export function httpPayloadSchedule(schedule: Schedule): HttpPayload {
  return {
    ...schedule,
  };
}

export function newSchedule(): ScheduleNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    description: "",
    unsaved: true,
  };
}
