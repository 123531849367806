import { compareDesc, parseISO } from "date-fns";

type HTMLString = string;

// ascending id, the application will remember the highest ID seen and
// only show a hint if there is a new id that is higher then the last seen
// the date will be used and only for a week a notification will be shown
interface NewsEntry {
  id: number;
  title: string;
  date: Date;
  content_html: HTMLString;
  list_html?: HTMLString[];
}

export const News: NewsEntry[] = [
  {
    id: 1,
    title: "Überarbeitung der Einstellungen",
    date: parseISO("2022-02-07"),
    content_html: "Die Einstellungen wurden umfassend überarbeitet:",
    list_html: [
      "Veränderte Einstellungen werden jetzt sofort gespeichert.",
      "Es wurden Hilfetexte hinzugefügt. ",
      "Der Zeitplan hat eine zusätzliche Ansicht in Kalenderform bekommen, um den besseren Überblick behalten zu können.",
      "Viele Fehlerbehebungen und Verbesserungen",
    ],
  },
  {
    id: 2,
    title: "Weitere Überarbeitung der Einstellungen",
    date: parseISO("2022-03-09"),
    content_html: "Die Einstellungen wurden weiter überarbeitet:",
    list_html: [
      "Abwesenheiten (Krankheit/Urlaub) der Mitarbeiter können nun unter Einstellungen &rarr; Zeitplan &rarr; Abwesenheiten eingetragen werden.",
      "Die Oberfläche zur Eingabe der Termintypen wurde überarbeitet.",
      "Die Terminbuchung kann für die Praxis und die Patienten blockiert werden.",
      "Fehlerbehebungen und Verbesserungen",
    ],
  },
  {
    id: 3,
    title: "Weitere Überarbeitung der Einstellungen und Arztauswahl",
    date: parseISO("2022-03-16"),
    content_html: "Die Einstellungen wurden weiter überarbeitet:",
    list_html: [
      "Für die Praxis kann eingestellt werden, wie weit im voraus eine Terminbuchung möglich ist. Diese Einstellung kann für einzelne Termintypen überschrieben werden.",
      "Für Termintypen kann optional ein Mindest- und Höchstalter angegeben werden.",
      "Die Praxis kann einstellen, welche Mitarbeiter bei der Terminbuchung von den Patienten ausgewählt werden können.",
      "Fehlerbehebungen und Verbesserungen",
    ],
  },
  {
    id: 4,
    title: "Neue Funktion",
    date: parseISO("2022-04-12"),
    content_html: "Viele neue Funktionen:",
    list_html: [
      "Kalenderansicht: In der Terminkachel, wird angezeigt, ob der Patient die PraxisApp hat, ob der Termin über diese gebucht worden ist und ob es sich bei dem Patienten um einen Privatpatienten handelt.",
      "Praxisanwender: In den Einstellungen können Praxisanwender hinterlegt werden, um nachzuvollziehen wer Aktionen (z.B. Termin verschieben) durchgeführt hat.",
      "Protokoll: Hier wird aufgelistet, welche Aktionen durchgeführt worden sind - z.B. eine neue Terminbuchung eines Patienten über die PraxisApp.",
      "Einstellungen: Die Übersichtlichkeit der Termintypenliste wurde verbessert.",
    ],
  },
  {
    id: 5,
    title: "Neue Funktion",
    date: parseISO("2022-05-12"),
    content_html: "Neue Funktionen:",
    list_html: [
      "In den Termintypen kann festgelegt werden, ob dem Patienten bei der Terminbuchung ein Freitextfeld zur Verfügung steht.",
      "In den allgemeinen Einstellungen kann ein Text eingegeben werden, der den Patienten beim Öffnen der Terminbuchung angezeigt wird.",
    ],
  },
  {
    id: 7,
    title: "Neue Funktionen",
    date: parseISO("2022-06-02"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Die Wochenansicht im Kalender ist nun verfügbar.",
      "Ein roter Strich Zeigt die aktuelle Uhrzeit im Kalender an.",
      "Beim Erstellen eines Termins durch den Klick in den Kalender stehen nun mehr Optionen zur Verfügung.",
      "Beim Verschieben oder Absagen eines Termins, kann eine Nachricht an den Patienten hinzugefügt werden.",
      "Kleine Fehler wurden behoben.",
    ],
  },
  {
    id: 8,
    title: "Neue Funktionen",
    date: parseISO("2022-06-08"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Einträge im Zeitplan können jetzt mit einer Farbe versehen werden.",
      "Kleine Fehler wurden behoben.",
    ],
  },
  {
    id: 9,
    title: "Neue Funktionen und Fehlerbehebungen",
    date: parseISO("2022-06-15"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Je Termintyp kann die Einbestellzeit für die Patienten festgelegt werden.",
      "Termine können zwischen Mitarbeitern verschoben werden.",
      "Fehler wurden behoben.",
    ],
  },
  {
    id: 10,
    title: "Einstellungsprofile",
    date: parseISO("2022-07-25"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Die Schrittweite im Kalender kann jetzt angepasst werden.",
      "Es ist einstellbar, ob der Patientenname und / oder der Vorstellungsanlass angezeigt werden soll.",
      "Einstellungen können gespeichert werden und in sogenannten Einstellungsprofilen schnell wieder aufgerufen werden.",
      "Sie können im Kalender die Terminlänge direkt per Drag & Drop ändern und anpassen.",
      "Viele Bugfixes und Fehlerbehebungen.",
    ],
  },
  {
    id: 11,
    title: "Einstellungsmöglichkeiten",
    date: parseISO("2022-09-01"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Die Wochenansicht kann jetzt als Standardansicht ausgewählt werden.",
      "Einstellungsprofile können jetzt für die Praxis und jeden Praxisanwender separat als Standardprofil ausgewählt werden. Das Standardprofil wird nach dem Login automatisch geladen.",
      "Die aktuellen Einstellungen werden lokal im Browser gespeichert und beim erneuten Laden der Seite wiederhergestellt.",
      "In der Kalenderansicht können jetzt Hilfslinien für 10 Minuten Intervalle angezeigt werden.",
      "Für Patiententermine können interne Kurznotizen angelegt werden und auf den Kacheln in der Kalenderansicht angezeigt werden.",
      "Die Einbestellzeit wird jetzt beim Ändern eines Termins angezeigt",
      "Viele Bugfixes und Fehlerbehebungen u.a. beim Verschieben von Terminen, bei der Patientensuche und beim Logout.",
    ],
  },
  {
    id: 12,
    title: "Mehrere Verbesserungen",
    date: parseISO("2022-09-21"),
    content_html: "Neue Funktionen:",
    list_html: [
      "Angezeigte Wochentage in der Wochenansicht können über die Einstellungsprofile gefiltert werden.",
      "<b>In der App der Patienten:</b><ul><li>Suchzeitraum für Terminvorschläge kann gewählt werden</li><li>Falls kein passender Termin gefunden wird, kann über einen Klick nach weiteren Terminen in der Zukunft gesucht werden.</li></ul>",
      "Fehlerbehebungen",
    ],
  },
  {
    id: 13,
    title: "Mehrere Verbesserungen",
    date: parseISO("2022-10-04"),
    content_html: "Neue Funktionen:",
    list_html: [
      'Patienten können mit Ihrem Geburtsdatum im Format ("TT.MM.JJJJ") gesucht werden',
      "Patienten, die über die App angemeldet sind, werden in der Suche mit einem Symbol markiert.",
      "Die Schriftgröße passt sich dynamisch der Größe der Terminkachel an.",
      "Händisch angelegte Patienten können nun bearbeitet und gelöscht werden. Außerdem können diese mit Patienten aus der PraxisApp zusammengeführt werden, falls sich diese nachträglich in der App regisitrieren.",
      "Bei der händischen Eingabe von Patienten kann das Geburtsdatum auch mit der Tastatur eingegeben werden.",
      "Fehlerbehebungen",
    ],
  },
  {
    id: 14,
    title: "Verbesserungen",
    date: parseISO("2022-10-10"),
    content_html: "Verbesserung der Benutzerfreundlichkeit:",
    list_html: [
      "Die Ansicht für die Patienten bei der Terminbuchung wurde umfassend überarbeitet.",
      "Die Patientenseite wurde übersichtlicher gestaltet.",
    ],
  },
  {
    id: 15,
    title: "Features",
    date: parseISO("2022-10-27"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Beim Anlegen von Abwesenheiten wird der Nutzer bei gefährlichen Vorgängen gewarnt.",
      "Bei bereits angelegten Terminen können die Farben geändert werden",
      "Bei Terminen ohne Patienten kann der Titel geändert werden",
      "Auf der Kachel in der Kalenderansicht wird wieder der korrekte Termintyp angezeigt.",
      "Vergangene Termine bei den Patienten werden jetzt korrekt eingeordnet.",
    ],
  },
  {
    id: 16,
    title: "Features",
    date: parseISO("2022-11-17"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Zugang über einheitlichen Login für Hausärzte hinzugefügt.",
      "Die Mitarbeiter sind in der Tagesansicht sortier- und filterbar.",
      "Bei der Terminvereinbarung durch die Praxis kann jetzt ein Zeitraum und Mitarbeiter ausgewählt werden.",
    ],
  },
  {
    id: 17,
    title: "Features",
    date: parseISO("2023-02-03"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Eine eigene Emailsignatur kann unter Einstellungen &rarr; Allgemein festgelegt werden.",
      "Fehlerbehebungen",
    ],
  },
  {
    id: 18,
    title: "Features",
    date: parseISO("2023-02-16"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Termintypen können in den Einstellungen per Drag and Drop sortiert werden.",
      "Durch einen Rechtsklick auf Terminkacheln öffnet sich ein Kontextmenü, in dem Aktionen bezüglich des Termins schnell ausgeführt werden können.",
      "Fehlerbehebungen.",
    ],
  },
  {
    id: 19,
    title: "Features",
    date: parseISO("2023-03-06"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Unter Einstellungen > Zeitplan > Feiertage können Buchungen an Feiertagen blockiert werden.",
      "Fehlerbehebungen.",
    ],
  },
  {
    id: 20,
    title: "Features",
    date: parseISO("2023-04-04"),
    content_html: "Neue Funktionen und Verbesserungen:",
    list_html: [
      "Start- und Endzeit des angezeigten Kalenders können in den Einstellungsprofilen festgelegt werden.",
      "Terminvorschläge werden schneller geladen.",
      "Fehlerbehebungen.",
    ],
  },
  {
    id: 21,
    title: "Praxiszeiten",
    date: parseISO("2023-06-22"),
    content_html: "Umstellung der Zeitplan-Einstelungen:",
    list_html: [
      "Es können nun mehrere Zeitpläne angelegt werden. Dadurch können verschiede Sprechzeitenangebote - wie z.B. angepasste Sprechzeiten in der Urlaubszeit - hinterlegt werden.",
      "Zeitpläne können für bestimmte Zeiträume aktiviert werden. Ein Zeitplan kann mehrfach aktiviert werden. Es können mehrere Zeitpläne gleichzeitig aktiviert werden.",
      "WICHTIG: Ihre bisherigen Einstellungen wurden automatisch auf die neuen Einstellungsoptionen angepasst. Es besteht kein Handlungsbedarf Ihrer Seite.",
      "Sollten Sie Fragen zu den neuen Einstellungen haben, wenden Sie sich gerne an unseren Support unter <a href='mailto:terminverwaltung@monks-aerzte-im-netz.de'>terminverwaltung@monks-aerzte-im-netz.de</a>",
    ],
  },
  {
    id: 22,
    title: "Verbesserungen",
    date: parseISO("2023-10-24"),
    content_html: "Verbesserungen:",
    list_html: [
      "Terminvorschläge werden schneller geladen.",
      "Zeitpläne können dupliziert werden.",
      "Fehlerbehebungen.",
    ],
  },
  {
    id: 23,
    title: "Verbesserungen",
    date: parseISO("2023-11-24"),
    content_html: "Verbesserungen:",
    list_html: [
      "Termine können per Rechtsklick ausgeschnitten und eingefügt werden.",
      "Zeitslots können per Rechtsklick kopiert und eingefügt werden.",
      "Termine können in einer Listenansicht angezeigt werden. Zu dieser Liste steht ein PDF-Export zur Verfügung.",
    ],
  },
  {
    id: 24,
    title: "Verbesserungen",
    date: parseISO("2024-05-22"),
    content_html: "Verbesserungen:",
    list_html: [
      "Per Rechtsklick auf einen Termin können alternative Terminvorschläge erzeugt werden, falls Sie einen Termin verschieben wollen.",
      "Es wurden weitere Optionen zum Rechtsklick auf den Termin hinzugefügt.",
      "Patientennachrichten, die bei der Buchung angegeben werden, können in der Terminkachel angezeigt werden.",
      "Die Termintypauswahl im Klicktermine markiert Termintypen mit '**', die zu diesem Zeitpunkt im Zeitplan hinterlegt sind.",
      "Weitere kleine Verbesserungen.",
      "Fehlerbehebungen.",
    ],
  },
].sort((el1, el2) => compareDesc(el1.date, el2.date));
