import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { RoleRaw } from "frontend/interfaces/role";
import { RoomRaw } from "frontend/interfaces/room";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestRoles(): Promise<UnifiedListResponse<RoleRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_roles_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { roles: RoomRaw[] }).roles,
  };
}

export async function updateRole(
  role: HttpPayload
): Promise<UnifiedResponse<RoleRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_role_path,
    { id: role.id as string },
    role
  );

  return {
    entityRaw: (body as unknown as { role: RoomRaw }).role,
    success: statusIsSuccess,
    errors:
      (body as unknown as { role: { _errors?: ErrorDatabase } }).role._errors ||
      {},
  };
}

export async function createRole(
  role: HttpPayload
): Promise<UnifiedResponse<RoleRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_roles_path,
    {},
    role
  );

  return {
    entityRaw: (body as unknown as { role: RoomRaw }).role,
    success: statusIsSuccess,
    errors:
      (body as unknown as { role: { _errors?: ErrorDatabase } }).role._errors ||
      {},
  };
}

export async function deleteRole(id: PrimaryKey): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_role_path,
    { id },
    {}
  );

  return { success: statusIsSuccess, errors: {} };
}
