import { parseISO } from "date-fns";

import {
  AppointmentType,
  AppointmentTypeRaw,
} from "frontend/interfaces/p/appointment-type";

export function parseAppointmentType(
  appointmentTypeRaw: AppointmentTypeRaw
): AppointmentType {
  return {
    ...appointmentTypeRaw,
    allowed_booking_range_from: parseISO(
      appointmentTypeRaw.allowed_booking_range_from
    ),
    allowed_booking_range_to: parseISO(
      appointmentTypeRaw.allowed_booking_range_to
    ),
  };
}
