<script lang="ts" setup>
  import { computed, PropType, ref } from "vue";

  import { FILTER_EVENT_STATE_CHANGE } from "frontend/uses/filter/use-filter";
  import { FilterManager } from "frontend/uses/filter/filter-manager";

  defineProps({
    filterManager: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- needed as we have (not yet) generic vue components: https://github.com/vuejs/rfcs/discussions/436
      type: Object as PropType<FilterManager<any>>,
      required: true,
    },
  });

  const filterExpanded = ref<boolean>(false);
  const iconName = computed(() =>
    filterExpanded.value ? "fe-chevron-up" : "fe-chevron-down"
  );
  const accordionLabel = computed(() =>
    filterExpanded.value ? "Filter ausblenden" : "Filter anzeigen"
  );
</script>

<template>
  <div>
    <div class="accordion__bar" v-on:click="filterExpanded = !filterExpanded">
      <div class="accordion__bar__label">
        <i class="filter-icon fe fe-funnel"></i>{{ accordionLabel }}
      </div>
      <i class="accordion__bar__icon fe" v-bind:class="[iconName]"></i>
    </div>
    <div v-if="filterExpanded" class="accordion__content">
      <div
        v-for="(filter, index) in filterManager.filters()"
        v-bind:key="index"
      >
        <component
          v-bind:is="filter.component"
          v-bind:filter-state="filter.filterState"
          v-bind:filter-options="filter.filterOptions"
          v-on="{
            [FILTER_EVENT_STATE_CHANGE]:
              filter.registerStateChange.bind(filter),
          }"
        ></component>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .accordion__bar {
    cursor: pointer;
    user-select: none;
    padding: 10px 0;

    display: flex;
    justify-content: space-between;

    border-top: 1px solid colors.$color_filter-container__accordion--border;
    border-bottom: 1px solid colors.$color_filter-container__accordion--border;
  }

  .filter-icon {
    font-size: 16px;
    margin-right: 5px;
  }

  .accordion__bar__label {
    font-size: 12px;
    font-weight: bold;
    color: colors.$color_filter-container__accordion--label;
  }

  .accordion__bar__icon {
    color: colors.$color_filter-container__accordion--label;
  }

  .accordion__content {
    border-bottom: 1px solid colors.$color_filter-container__accordion--border;
    padding: 20px 0;
  }
</style>
