import { log, LogLevel } from "shared/utils/logger";

type SubscriptionHandle = number;
type SubscriptionCallback<T> = (
  handle: SubscriptionHandle,
  argument?: T
) => void;
interface SubscriptionDatabase<T> {
  subscriptions: Map<SubscriptionHandle, SubscriptionCallback<T>>;
  currentHandle: SubscriptionHandle;
}

export type SubscriptionNotify<T> = (argument?: T) => Array<SubscriptionHandle>;
export type SubscriptionSubscribe<T> = (
  callback: SubscriptionCallback<T>
) => SubscriptionHandle;
export type SubscriptionUnsubscribe = (handle: SubscriptionHandle) => void;

/**
 * Creates a subscription that can be subscribed to with callbacks.
 * These callbacks can be triggered via a second function.
 *
 * @export
 * @returns {{
 *   notify: SubscriptionNotify; function that notifies all subscribers
 *   subscribe: SubscriptionSubscribe; function that adds a callback as subscriber
 * }}
 */
export function useSubscription<T>(): {
  notify: SubscriptionNotify<T>;
  subscribe: SubscriptionSubscribe<T>;
  unsubscribe: SubscriptionUnsubscribe;
} {
  const database: SubscriptionDatabase<T> = {
    subscriptions: new Map<SubscriptionHandle, SubscriptionCallback<T>>(),
    currentHandle: calculateInitialHandle(),
  };

  const subscribe: SubscriptionSubscribe<T> = (
    callback: SubscriptionCallback<T>
  ): SubscriptionHandle => {
    // calculate handle
    const handle = calculateNextHandle(database);

    // check sanity: this branch should never be entered
    if (database.subscriptions.get(handle)) {
      log(
        LogLevel.Error,
        "[SUBSCRIPTION] Subscription handle " +
          handle +
          " already exists. This should never happen.",
        handle,
        database
      );

      throw new Error("internal error. clash of handles.");
    }

    database.subscriptions.set(handle, callback);
    return handle;
  };

  const notify: SubscriptionNotify<T> = (
    argument?: T
  ): Array<SubscriptionHandle> => {
    const notifiedHandles: Array<SubscriptionHandle> = [];

    for (const [handleRaw, callback] of database.subscriptions.entries()) {
      const handle = handleRaw as unknown as SubscriptionHandle;

      try {
        callback(handle, argument); // call the subscribed callback
        notifiedHandles.push(handle); // remember that we successfully called that handle
      } catch {
        log(
          LogLevel.Error,
          "[SUBSCRIPTION] an error occurred in callback with provided handle. the handle will thus not occur in the returned array of triggered handles.",
          handle
        );
      }
    }

    return notifiedHandles;
  };

  const unsubscribe = (handle: SubscriptionHandle) => {
    if (!database.subscriptions.delete(handle)) {
      log(LogLevel.Warn, "there is no subscription with given handle", handle);
    }
  };

  return {
    notify,
    subscribe,
    unsubscribe,
  };
}

function calculateNextHandle<T>(
  database: SubscriptionDatabase<T>
): SubscriptionHandle {
  database.currentHandle += 1;
  return database.currentHandle;
}

function calculateInitialHandle(): SubscriptionHandle {
  return 0;
}
