import { routes, doRequest } from "frontend/api/application";
import { HttpMethod, UnifiedResponse } from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { PvsRaw } from "frontend/interfaces/settings/pvs";

export async function requestPvsConfiguration(): Promise<
  UnifiedResponse<PvsRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.current_config_frontend_settings_pvs_path,
    {},
    {}
  );

  return {
    entityRaw: (body as unknown as { entity: PvsRaw }).entity,
    success: statusIsSuccess,
    errors: {},
  };
}

export async function updatePvsSystem(
  pvs_system_id: PrimaryKey,
  pvs: string
): Promise<UnifiedResponse<PvsRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.update_pvs_system_frontend_settings_pvs_path,
    { pvs_system_id },
    { pvs }
  );

  return {
    entityRaw: (body as unknown as { entity: PvsRaw }).entity,
    success: statusIsSuccess,
    errors: {},
  };
}

export async function updateConfiguration(
  configuration_id: PrimaryKey,
  name: string,
  pvs_executable_path: string | null,
  pvs_username: string | null,
  pvs_password: string | null
): Promise<UnifiedResponse<PvsRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.update_configuration_frontend_settings_pvs_path,
    { configuration_id },
    { name, pvs_executable_path, pvs_username, pvs_password }
  );

  return {
    entityRaw: (body as unknown as { entity: PvsRaw }).entity,
    success: statusIsSuccess,
    errors: {},
  };
}

export async function createConfiguration(
  pvs_system_id: PrimaryKey
): Promise<UnifiedResponse<PvsRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.create_configuration_frontend_settings_pvs_path,
    {},
    { pvs_system_id }
  );

  return {
    entityRaw: (body as unknown as { entity: PvsRaw }).entity,
    success: statusIsSuccess,
    errors: {},
  };
}
