import { computed, Ref, ref } from "vue";
import iro from "@jaames/iro";

export function useColorPicker(onChange: (newValue: string) => void): {
  container: Ref<HTMLElement | null>;
  doOpenPicker: (currentValue: string) => void;
  pickerIsOpen: Ref<boolean>;
  setColor: (newValue: string) => void;
  calculatContrastColorCSS: (color: string | null) => string;
} {
  const container = ref<HTMLElement | null>(null);
  const pickerInstance = ref<iro.ColorPicker | null>(null);

  const doOpenPicker = (currentValue: string) => {
    if (pickerInstance.value) return; // picker already open
    if (!container.value) return; // no container available

    pickerInstance.value = iro.ColorPicker(container.value, {
      layoutDirection: "horizontal",
      color: currentValue,
      width: 100,
    });
    pickerInstance.value.on("color:change", (color: iro.Color) => {
      onChange(color.hexString.toUpperCase());
    });
  };

  const setColor = (newValue: string) => {
    if (!pickerInstance.value) return;
    pickerInstance.value.color.set(newValue);
  };

  return {
    container,
    doOpenPicker,
    pickerIsOpen: computed<boolean>(() => !!pickerInstance.value),
    setColor,
    calculatContrastColorCSS,
  };
}

export function calculatContrastColorCSS(color: string | null): string {
  if (!color) return "black";

  const parsedColor = new iro.Color(color);
  if (parsedColor.hsl.l >= 50) return "black";
  else return "white";
}
