import { computed, ComputedRef, ref } from "vue";

import { Cache } from "frontend/utils/request-cache";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { Operator } from "frontend/interfaces/settings/operator";

export function useOperator(operator_id: ComputedRef<PrimaryKey | null>): {
  operator: ComputedRef<Operator | null>;
} {
  const operators = ref<Array<Operator>>([]);
  const reloadOperators = async () => {
    operators.value = await Cache.getCachedOperators();
  };

  reloadOperators();

  return {
    operator: computed(() => {
      if (!operator_id.value) return null;

      for (const operator of operators.value) {
        if (operator.id === operator_id.value) return operator;
      }

      return null;
    }),
  };
}
