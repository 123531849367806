<script lang="ts" setup>
  import {
    filterEmits,
    filterProps,
    FILTER_EVENT_STATE_CHANGE,
  } from "frontend/uses/filter/use-filter";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { FormSelectOption } from "frontend/uses/use-form-select";

  import FormSelect from "frontend/components/form/FormSelect.vue";

  interface FilterState {
    selectedID: PrimaryKey | null;
  }

  interface FilterOptions {
    label: string;
    options: Array<FormSelectOption>;
    includeBlank?: string | boolean;
  }

  defineProps({
    ...filterProps<FilterState, FilterOptions>(),
  });

  const emit = defineEmits([...filterEmits()]);

  const onSelectionChanged = (selectedID: PrimaryKey | null) => {
    emit(FILTER_EVENT_STATE_CHANGE, {
      selectedID,
    });
  };
</script>

<template>
  <div>
    <FormSelect
      v-bind:model-value="filterState.selectedID"
      v-bind:label="filterOptions.label"
      v-bind:options="filterOptions.options"
      v-bind:include-blank="filterOptions.includeBlank"
      visual-small
      v-on:update:model-value="onSelectionChanged"
    />
  </div>
</template>
