import { v4 as uuid } from "uuid";
import { parseISO } from "date-fns";

import { HttpPayload } from "frontend/interfaces/http";
import {
  ScheduleActivation,
  ScheduleActivationNew,
  ScheduleActivationRaw,
} from "frontend/interfaces/schedule-activation";
import { DateFormat, formatDate } from "shared/utils/date-utils";

export function parseScheduleActivation(
  scheduleActivation: ScheduleActivationRaw
): ScheduleActivation {
  return {
    ...scheduleActivation,
    from: scheduleActivation.from ? parseISO(scheduleActivation.from) : null,
    to: scheduleActivation.to ? parseISO(scheduleActivation.to) : null,
  };
}

export function httpPayloadScheduleActivation(
  scheduleActivation: ScheduleActivation
): HttpPayload {
  return {
    id: scheduleActivation.id,
    schedule_activation: {
      schedule_id: scheduleActivation.schedule_id,
      from: scheduleActivation.from
        ? formatDate(scheduleActivation.from, DateFormat.ISO)
        : null,
      to: scheduleActivation.to
        ? formatDate(scheduleActivation.to, DateFormat.ISO)
        : null,
    },
  };
}

export function newScheduleActivation(): ScheduleActivationNew {
  return {
    id: `MOCK-${uuid()}`,
    from: null,
    to: null,
    schedule_id: "",
    schedule_name: "",
    unsaved: true,
  };
}
