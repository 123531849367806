<script lang="ts">
  import { defineComponent, onMounted, ref, toRefs, unref } from "vue";

  import { requestSearch } from "frontend/api/application/request-search";
  import { SearchResult } from "frontend/interfaces/search-results";
  import {
    buildQuery,
    parseSearchResults,
  } from "frontend/parser/parse-search-results";
  import { useThrottledWatcher } from "frontend/uses/use-throttled-watcher";

  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: { FormInput },
    props: {
      visualDummy: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { visualDummy } = toRefs(props);
      const currentSearch = ref<string>("");
      const showSearchResults = ref<boolean>(false);
      const currentQuery = ref<string>("");
      const searchResults = ref<Array<SearchResult>>([]);
      const { subscribe } = useThrottledWatcher(currentSearch, 500);
      subscribe(async () => {
        await loadSearchResults(unref(currentSearch));
      });
      const loadSearchResults = async (query: string | null) => {
        if (visualDummy.value) {
          searchResults.value = [];
          return;
        }
        if (query) currentQuery.value = query;
        else currentQuery.value = "";
        let results = [];
        if (query) results = await requestSearch(buildQuery(query));
        else results = [] as SearchResult[];
        searchResults.value = results.map(parseSearchResults);
      };
      onMounted(() => loadSearchResults(null));
      return {
        searchResults,
        currentSearch,
        showSearchResults,
      };
    },
  });
</script>

<template>
  <div>
    <FormInput
      v-model="currentSearch"
      visual-full-width
      v-bind:completions="searchResults"
      placeholder="Suche"
    />
  </div>
</template>
