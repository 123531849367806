import { Component, CSSProperties, reactive, shallowReactive } from "vue";

import { FilterDefinition } from "frontend/uses/filter/filter-definition";
import { RequiredKeys } from "shared/utils/typescript-helper";

import FilterFlex from "frontend/components/filter/FilterFlex.vue";

export type FilterFlexOptions = Pick<CSSProperties, "flexDirection">;

function withFlexOptionDefaults(
  flexOptions?: Partial<FilterFlexOptions>
): FilterFlexOptions {
  return {
    flexDirection: "column",
    ...flexOptions,
  };
}

interface FilterDefinitionWithIndex<T> {
  filter: FilterDefinition<T, Component>;
  index: number;
}
interface FilterDefinitionWithIndexAndRequiredKeys<
  T,
  K extends keyof FilterDefinitionWithIndex<T>["filter"],
> extends Omit<FilterDefinitionWithIndex<T>, "filter"> {
  filter: RequiredKeys<FilterDefinitionWithIndex<T>["filter"], K>;
}

function simpleFlex<T>(
  filters: Array<FilterDefinition<T, Component>>,
  flexOptions?: Partial<FilterFlexOptions>
): FilterDefinition<T, typeof FilterFlex> {
  const filtersWithIndex = filters.map(
    (filter, index): FilterDefinitionWithIndex<T> => ({ filter, index })
  );
  const filtersWithFrontendFilter = filtersWithIndex.filter(
    (
      fwi
    ): fwi is FilterDefinitionWithIndexAndRequiredKeys<T, "frontendFilter"> =>
      !!fwi.filter.frontendFilter
  );
  const filtersWithBackendFilter = filtersWithIndex.filter(
    (
      fwi
    ): fwi is FilterDefinitionWithIndexAndRequiredKeys<T, "backendFilter"> =>
      !!fwi.filter.backendFilter
  );
  const filtersWithFrontendSort = filtersWithIndex.filter(
    (fwi): fwi is FilterDefinitionWithIndexAndRequiredKeys<T, "frontendSort"> =>
      !!fwi.filter.frontendSort
  );

  const frontendFilter =
    filtersWithFrontendFilter.length > 0
      ? (entry: T, state: { childrenStates: Array<unknown> }) =>
          filtersWithFrontendFilter.reduce(
            (result, { filter, index }) =>
              result &&
              filter.frontendFilter(entry, state.childrenStates[index]),
            true
          )
      : undefined;
  const backendFilter =
    filtersWithBackendFilter.length > 0
      ? (state: { childrenStates: Array<unknown> }) =>
          filtersWithBackendFilter.reduce(
            (result, { filter, index }) => ({
              ...result,
              ...filter.backendFilter(state.childrenStates[index]),
            }),
            {}
          )
      : undefined;

  const frontendSort =
    filtersWithFrontendSort.length > 0
      ? (entries: Array<T>, state: { childrenStates: Array<unknown> }) =>
          filtersWithFrontendSort.reduce(
            (result, { filter, index }) =>
              filter.frontendSort(result, state.childrenStates[index]),
            entries
          )
      : undefined;
  return {
    component: FilterFlex,
    frontendFilter,
    backendFilter,
    frontendSort,
    initialFilterState: () =>
      reactive({
        childrenStates: filters.map((filter) => filter.initialFilterState()),
      }),
    filterOptions: shallowReactive({
      childrenComponents: filters.map((filter) => filter.component),
      childrenOptions: reactive(filters.map((filter) => filter.filterOptions)),
      flexOptions: reactive(withFlexOptionDefaults(flexOptions)),
    }),
  };
}

export function simpleRow<T>(
  filters: Array<FilterDefinition<T, Component>>
): FilterDefinition<T, typeof FilterFlex> {
  return simpleFlex(filters, { flexDirection: "row" });
}

export function simpleColumn<T>(
  filters: Array<FilterDefinition<T, Component>>
): FilterDefinition<T, typeof FilterFlex> {
  return simpleFlex(filters, { flexDirection: "column" });
}
