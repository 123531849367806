import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { v4 as uuid } from "uuid";

import type {
  ConditionalContextMenuEntry,
  ContextMenuControl,
  ContextMenuEntry,
  ContextMenuEntryControl,
} from "shared/utils/context-menu/interfaces";
import { Manager } from "shared/utils/context-menu/manager";

export function useContextMenu(): ContextMenuControl {
  const componentInstanceID = uuid(); // needed to cleanup entries
  const manager = Manager.inject();

  // get root element of component
  const rootElement = ref<HTMLElement | null>(null);
  onMounted(() => {
    const rootCandidate = getCurrentInstance()?.vnode.el as HTMLElement;
    if (rootCandidate && rootCandidate instanceof HTMLElement)
      rootElement.value = rootCandidate;
  });
  onUnmounted(() => {
    // cleanup once parent gets unmounted
    manager.destroyFor(componentInstanceID);
  });

  return {
    addEntry: (entry) => {
      const entryWithTarget: ContextMenuEntry = {
        ...entry,
        target: computed<HTMLElement | null>(
          () => entry.target?.value ?? rootElement.value ?? null
        ),
      } as ContextMenuEntry;
      return manager.addEntry(componentInstanceID, entryWithTarget);
    },
    setEventPosition: (position) => manager.setEventPosition(position),
    setEventElement: (element) => manager.setEventElement(element),
  };
}

export function conditionalContextMenuEntry(
  ctx: ContextMenuControl,
  entry: ConditionalContextMenuEntry
): void {
  let currentEntry: ContextMenuEntryControl | null = null;
  watch(
    entry.condition,
    (newCondition) => {
      if (currentEntry) {
        currentEntry.destroy();
        currentEntry = null;
      }

      if (newCondition) {
        currentEntry = ctx.addEntry(entry.entry);
      }
    },
    { immediate: true }
  );
}
