<script lang="ts" setup>
  import { computed, onMounted, ref, watch } from "vue";
  import { compareDesc } from "date-fns";
  import { useRouter } from "vue-router";

  import {
    requestEventEntries,
    seenEventEntries,
  } from "frontend/api/application/request-event-entries";
  import { EventEntry, EventEntryRaw } from "frontend/interfaces/event-entry";
  import { parseEventEntry } from "frontend/parser/parse-event-entry";
  import {
    PageDefinition,
    UnifiedListResponseWithPagination,
  } from "frontend/interfaces/pagination";
  import { useFilter } from "frontend/uses/filter/use-filter";
  import { simpleCheckbox } from "frontend/uses/filter/predefined/checkbox";

  import EventBase from "frontend/components/events/EventBase.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import PageNavigation from "frontend/components/PageNavigation.vue";
  import FilterContainer from "frontend/components/filter/FilterContainer.vue";

  const props = defineProps({
    pageNumber: {
      type: String,
      default: null,
    },
  });

  const ENTRIES_PER_PAGE = 50;
  const router = useRouter();

  const sanitizedPageNumber = computed(() =>
    Math.max(parseInt(props.pageNumber || "1", 10), 1)
  );
  const totalEntries = ref<number>(0);
  const totalPages = computed(() =>
    Math.ceil((totalEntries.value * 1.0) / ENTRIES_PER_PAGE)
  );
  const pageDefinition = computed<PageDefinition>(() => {
    const limit = ENTRIES_PER_PAGE;
    const offset = ENTRIES_PER_PAGE * (sanitizedPageNumber.value - 1);

    return { limit, offset };
  });

  const handleNavigation = () => {
    const navigate =
      totalPages.value < sanitizedPageNumber.value &&
      sanitizedPageNumber.value > 1;
    const newPageNumberCandidate = sanitizedPageNumber.value - 1;

    if (navigate)
      router.push({
        name: "event-log",
        params: { pageNumber: newPageNumberCandidate },
      });
  };

  const locallyAvailableEventEntries = ref<Array<EventEntry>>([]);
  const renderedEventEntries = computed<Array<EventEntry>>(() =>
    [...locallyAvailableEventEntries.value].sort((a, b) =>
      compareDesc(a.event_date, b.event_date)
    )
  );
  const { backendParams, filteredCollection, filterContainerBinding } =
    useFilter(renderedEventEntries, [
      simpleCheckbox(
        { backend: (checked) => ({ ...(checked ? { show_seen: "1" } : {}) }) },
        { label: "Gesehene Protokolleinträge anzeigen" }
      ),
    ]);
  watch(backendParams, async () => {
    await loadEventEntries();
    handleNavigation();
  });
  watch(filteredCollection, handleNavigation);

  const applyResponse = (
    response: UnifiedListResponseWithPagination<EventEntryRaw>
  ) => {
    if (response.success) {
      locallyAvailableEventEntries.value =
        response.entities.map(parseEventEntry);
      totalEntries.value = response.pagination.total_entries;
    }
  };

  const loadEventEntries = async () => {
    applyResponse(
      await requestEventEntries(pageDefinition.value, backendParams.value)
    );
  };
  onMounted(loadEventEntries);
  watch(sanitizedPageNumber, loadEventEntries);

  const doMarkPageAsSeen = async () => {
    applyResponse(
      await seenEventEntries(
        renderedEventEntries.value.map((entry) => entry.id),
        backendParams.value,
        pageDefinition.value
      )
    );

    handleNavigation();
  };
  const doMarkAllAsSeen = async () => {
    applyResponse(
      await seenEventEntries(null, backendParams.value, pageDefinition.value)
    );
    router.push({ name: "event-log" });
  };

  const showActions = computed(() => totalEntries.value > 0);
</script>

<template>
  <div class="vue-route__event-log--index plain">
    <h2>Protokoll</h2>

    <BaseHelp>
      <p>
        Hier sehen Sie das Protokoll für Ihre Praxis. Hier werden chronologisch
        Aktivitäten in Ihrer Praxis aufgelistet.
      </p>
    </BaseHelp>

    <FilterContainer class="filter-container" v-bind="filterContainerBinding" />

    <template v-if="renderedEventEntries.length > 0">
      <PageNavigation
        v-if="totalPages > 1"
        route-name="event-log"
        v-bind:total-pages="Math.ceil((totalEntries * 1.0) / ENTRIES_PER_PAGE)"
        v-bind:current-page-number="sanitizedPageNumber"
      />
    </template>
    <div v-else class="no-entries">
      Es wurden keine Protokolleinträge gefunden.
    </div>

    <EventBase
      v-for="eventEntry in filteredCollection"
      v-bind:key="eventEntry.id"
      v-bind:event-entry="eventEntry"
    />

    <PageNavigation
      v-if="totalPages > 1"
      route-name="event-log"
      v-bind:total-pages="totalPages"
      v-bind:current-page-number="sanitizedPageNumber"
    />

    <template v-if="showActions">
      <div class="action" v-on:click="doMarkPageAsSeen">
        Diese Seite als gesehen markieren
      </div>
      <div class="action" v-on:click="doMarkAllAsSeen">
        Gesamtes Protokoll als gesehen markieren
      </div>
      <i
        v-tooltip="'Diese Protokollseite als gesehen markieren'"
        class="sticky-icon fe fe-checkmark--with-circle"
        v-on:click="doMarkPageAsSeen"
      ></i>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/mixins/link";

  .sticky-icon {
    @include link.link;
    text-decoration: none;

    position: fixed;
    bottom: 40px;
    right: 40px;
    font-size: 45px;

    background-color: colors.$color_link--optional-background;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 5px 5px 12px 3px #00000040;
    margin-right: 10px;

    text-align: right;

    float: right;

    &:hover {
      color: colors.$color_link--optional-hover;
    }
  }

  .no-entries {
    text-align: center;
    font-weight: bold;
    font-size: 16px;

    margin: 30px 0;
  }

  .filter-container {
    margin-bottom: 20px;
  }

  .action {
    @include link.link;
    font-size: 12px;

    display: inline-block;

    margin-right: 10px;
    margin-top: 10px;

    user-select: none;
  }
</style>
