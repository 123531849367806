<script lang="ts">
  import {
    defineComponent,
    ref,
    toRefs,
    PropType,
    onUnmounted,
    onMounted,
    unref,
    reactive,
    computed,
  } from "vue";
  import {
    computePosition,
    autoUpdate,
    Placement,
    flip,
    shift,
  } from "@floating-ui/dom";

  import { getCurrentHeaderHeight } from "frontend/utils/header";

  export default defineComponent({
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      placement: {
        type: String as PropType<Placement>,
        default: "left",
      },
      enableClose: {
        type: Boolean,
        default: false,
      },
      paddingTop: {
        type: Number,
        default: 0,
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const { modelValue, placement, enableClose, paddingTop } = toRefs(props);
      const popupElement = ref<HTMLElement | null>(null);
      const targetElement = ref<HTMLElement | null>(null);

      const clickHandler = () => {
        if (enableClose.value) emit("update:modelValue", false);
      };

      const popupPosition = reactive<{ x: number | null; y: number | null }>({
        x: null,
        y: null,
      });

      const reCalculatePosition = async () => {
        const popup = unref(popupElement);
        const target = unref(targetElement);

        if (!popup || !target) return;

        const { x, y } = await computePosition(target, popup, {
          placement: placement.value,
          middleware: [
            flip(),
            shift({
              padding: {
                top: getCurrentHeaderHeight() + unref(paddingTop),
                left: 0,
                right: 0,
                bottom: 0,
              },
            }),
          ],
        });
        popupPosition.x = x;
        popupPosition.y = y;
      };

      let autoUpdater: (() => void) | null = null;
      const ensureCleanAutoUpdater = () => {
        if (autoUpdater) {
          autoUpdater();
        }
        autoUpdater = null;
      };
      onMounted(() => {
        const popup = unref(popupElement);
        const target = unref(targetElement);

        if (!popup || !target) return;

        ensureCleanAutoUpdater();
        autoUpdater = autoUpdate(target, popup, reCalculatePosition);
      });
      onUnmounted(ensureCleanAutoUpdater);

      const popupStyles = computed(() => {
        const styles: {
          left?: string;
          top?: string;
          display?: "none";
        } = {
          left: undefined,
          top: undefined,
          display: modelValue.value ? undefined : "none",
        };

        if (popupPosition.x) styles.left = `${popupPosition.x}px`;
        if (popupPosition.y) styles.top = `${popupPosition.y}px`;
        return styles;
      });

      onMounted(() => {
        document.addEventListener("click", clickHandler);
      });

      return { popupElement, targetElement, popupStyles };
    },
  });
</script>

<template>
  <div class="base-popup">
    <div
      ref="popupElement"
      class="base-popup__popup"
      v-bind:style="popupStyles"
    >
      <slot name="popup">{{ modelValue }}</slot>
    </div>
    <div ref="targetElement" class="base-popup__target"><slot></slot></div>
  </div>
</template>

<style lang="scss" scoped>
  .base-popup {
    &,
    > .base-popup__target {
      // display: none;
      width: 100%;
    }
  }

  .base-popup__popup {
    position: absolute;

    // needed to mitigate ResizeObserver loop
    // see: https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1620002162
    width: max-content;
  }
</style>
