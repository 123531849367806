<!-- for this component to work properly the parent has to respond to the top-property -->
<!-- which will be used to position it relativley to its parent -->
<!-- the most easy solution is to set the parent to "position: relative" in its CSS -->
<!-- and set the element to position: absolute respectivley -->

<script lang="ts" setup>
  import { computed, onMounted, onUnmounted, ref } from "vue";

  import { disposableEventListener } from "frontend/utils/disposable-event-listener";

  const emit = defineEmits(["resizing"]);

  let disposeMouseup: null | (() => void) = null;
  let disposeMousemove: null | (() => void) = null;

  const cleanupListeners = () => {
    if (disposeMouseup) {
      disposeMouseup();
      disposeMouseup = null;
    }

    if (disposeMousemove) {
      disposeMousemove();
      disposeMousemove = null;
    }
  };
  onUnmounted(cleanupListeners);

  const yOffset = ref<number | null>(null);
  const resetResizing = () => {
    yOffset.value = null;
  };
  onMounted(resetResizing);

  const element = ref<HTMLElement | null>(null);

  const onMousedown = (event: MouseEvent) => {
    // do not propagate the event
    event.stopPropagation();
    event.preventDefault();

    disposeMouseup = disposableEventListener(document.body, "mouseup", () => {
      emit("resizing", yOffset.value, true);

      cleanupListeners();
      resetResizing();
    }).dispose;
    disposeMousemove = disposableEventListener(
      document.body,
      "mousemove",
      (event: MouseEvent) => {
        const parent = element.value?.parentElement;
        if (!parent) return;

        yOffset.value = parent.getBoundingClientRect().top - event.clientY;
        emit("resizing", yOffset.value, false);
      }
    ).dispose;
  };

  const positionStyles = computed(() =>
    yOffset.value
      ? {
          top: `${-yOffset.value}px`,
        }
      : undefined
  );
</script>

<template>
  <div
    ref="element"
    class="base-resize-bar"
    v-bind:class="{ 'base-resize-bar--resizing': !!yOffset }"
    v-bind:style="positionStyles"
    v-on:mousedown="onMousedown"
    v-on:click.stop
  ></div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .base-resize-bar {
    height: 1px;
    width: 100%;

    cursor: row-resize;

    &:hover {
      border: 1px solid colors.$color_base-resize-bar--separator;
    }
  }

  .base-resize-bar--resizing {
    &,
    &:hover {
      border: 1px solid colors.$color_base-resize-bar--separator--resizing;
    }
  }
</style>
