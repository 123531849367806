<script lang="ts">
  import { defineComponent, toRefs } from "vue";
  import { useRouter } from "vue-router";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Schedule } from "frontend/interfaces/schedule";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import { useToasts } from "frontend/uses/use-toasts";
  import { duplicateSchedule } from "frontend/api/application/request-schedules";

  import BaseListItem from "frontend/components/base/BaseListItem.vue";

  export default defineComponent({
    components: { BaseListItem },
    props: {
      ...editProps<Schedule>(),
    },
    emits: ["refresh-needed"],
    setup(props, { emit }) {
      const { entity } = toRefs(props);
      const editComponent = useEditComponent(entity.value);

      const router = useRouter();

      const routeToSchedule = () => {
        router.push({
          name: "settings-edit-schedule",
          params: { id: entity.value.id },
        });
      };

      const doDuplicateSchedule = async () => {
        const { confirm: confirmModal } = useSimpleModal();
        const { confirm } = await confirmModal(
          "Mit dieser Aktion duplizieren Sie den gesamten Zeitplan. Bitte beachten Sie, dass die Aktivierungen des Zeitplans nicht übernommen werden.",
          { title: "Zeitplan duplizieren?" }
        );
        const { error: errorToast, success: successToast } = useToasts();
        if (confirm) {
          const result = await duplicateSchedule(
            editComponent.entityEditable.value.id
          );
          const { success } = result;
          if (success) {
            emit("refresh-needed");
            successToast("Der Zeitplan wurde erfolgreich dupliziert.");
          } else {
            errorToast("Der Zeitplan konnte nicht dupliziert werden.");
          }
        }
      };

      return {
        ...editComponent,
        routeToSchedule,
        doDuplicateSchedule,
      };
    },
  });
</script>

<template>
  <BaseListItem
    v-bind:title="entityEditable.name"
    v-bind:subtitle="entityEditable.description ?? '-'"
    edit-action
    delete-action
    duplicate-action
    chevron
    v-on:edit-item="saveRecord"
    v-on:delete-item="deleteRecord"
    v-on:duplicate-item="doDuplicateSchedule"
    v-on:select="routeToSchedule"
  />
</template>
