import { computed, Ref, unref } from "vue";
import { transparentize } from "color2k";

export function useColorModification(
  colorHex: Readonly<Ref<string | null>>,
  alpha: Readonly<Ref<number>> | number
): {
  colorRgba: Readonly<Ref<string | null>>;
} {
  return {
    colorRgba: computed(() =>
      colorHex.value ? transparentize(colorHex.value, unref(alpha)) : null
    ),
  };
}
