import { addDays } from "date-fns";

import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { formatDate, DateFormat } from "shared/utils/date-utils";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { SuggestionsRaw } from "frontend/interfaces/suggestions";
import { PathParams } from "shared/utils/pathify";

export async function requestSuggestions(
  patientID: PrimaryKey,
  appointmentTypeID: PrimaryKey,
  searchFrom: Date,
  personID: PrimaryKey,
  appointmentID: PrimaryKey
): Promise<{
  suggestions: SuggestionsRaw | null;
}> {
  const params: PathParams = {
    patient_id: patientID,
    appointment_type_id: appointmentTypeID,
    search_from: formatDate(searchFrom, DateFormat.DateOnlyISO),
    search_to: formatDate(addDays(searchFrom, 56), DateFormat.DateOnlyISO),
  };
  if (personID) params.person_id = personID;
  if (appointmentID) params.appointment_id = appointmentID;

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.suggestions_frontend_appointments_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    return {
      suggestions: body as unknown as SuggestionsRaw,
    };
  } else {
    return {
      suggestions: null,
    };
  }
}

export async function confirmSuggestion(
  unitID: PrimaryKey,
  notifyPatient: boolean,
  remindPatient: boolean,
  internalOfficeNote: string
): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.confirm_frontend_appointments_path,
    {
      unit_id: unitID,
    },
    {
      notify_patient: notifyPatient,
      remind_patient: remindPatient,
      internal_office_note: internalOfficeNote,
    }
  );

  return statusIsSuccess;
}

export async function confirmMoveSuggestion(
  appointmentID: string,
  unitID: PrimaryKey,
  notifyPatient: boolean,
  reason: string
): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.confirm_move_frontend_appointments_path,
    {
      appointment_id: appointmentID,
    },
    {
      suggested_unit_id: unitID,
      notify_patient: notifyPatient,
      reason: reason,
    }
  );

  return statusIsSuccess;
}

export async function discardSuggestion(searchRef: PrimaryKey): Promise<void> {
  await doRequest(
    HttpMethod.POST,
    routes.paths.discard_frontend_appointments_path,
    {
      search_ref: searchRef,
    },
    {}
  );
}
