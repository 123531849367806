import { camelize, Component, DefineComponent } from "vue";

import { PartialProps } from "shared/utils/typescript-helper";
import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
import { log, LogLevel } from "shared/utils/logger";

export abstract class Modal<
  T extends Component,
  Data extends PartialProps<T> = PartialProps<T>,
> {
  private showCustomModal: ReturnType<typeof useSimpleModal>["custom"];
  private internalData: Data | undefined;
  private isSuccess: boolean;
  protected successCallback: (() => void) | undefined;

  constructor() {
    this.showCustomModal = useSimpleModal().custom;
    this.isSuccess = this.defaultSuccessState();
  }

  public setData(data?: Data): typeof this {
    this.internalData = data;
    return this;
  }

  public async show(): Promise<void> {
    await this.showCustomModal(this.modalComponent(), {
      hideCancel: this.hideCancel(),
      hideConfirm: this.hideConfirm(),
      confirmLabel: this.confirmLabel(),
      noAutoClose: true,
      componentProps: this.internalData,
      componentOn: this.eventHandler(),
      onClose: async (confirm: boolean, close: () => void) => {
        await this.onClose(confirm, close);
        if (this.isSuccess && this.successCallback) this.successCallback();
        this.isSuccess = this.defaultSuccessState();
      },
    });
  }

  public onSuccess(callback?: () => void): typeof this {
    this.successCallback = callback;
    return this;
  }

  protected abstract modalComponent(): T;

  protected confirmLabel(): string | undefined {
    return undefined;
  }

  protected async onClose(
    userConfirmed: boolean,
    closeModal: () => void,
    forceClose = true
  ): Promise<void> {
    if (forceClose) closeModal();
    this.setSuccess(userConfirmed);
  }

  protected handleEvent(eventName: string, ...args: unknown[]): void {
    const variableName = this.extractVariableNameFromEventName(eventName);

    log(
      LogLevel.Debug,
      "[MODAL] handle event",
      eventName,
      variableName,
      ...args
    );

    if (
      variableName &&
      this.internalData &&
      variableName in this.internalData &&
      args.length >= 1
    ) {
      log(LogLevel.Debug, "[MODAL] update internal data");
      // ATTENTION: This is not 100% typesafe
      // this only works if the shown Modal-component sends the correct types
      // via its events!
      // TODO: Might be a better was to do this in the future if
      //       we tackle #240
      this.internalData[variableName] = args[0] as Data[keyof Data];
    }
  }

  protected getData(): Data | undefined {
    return this.internalData;
  }

  protected setSuccess(success: boolean): void {
    this.isSuccess = !!success;
  }

  protected defaultSuccessState(): boolean {
    return false;
  }

  protected hideConfirm(): boolean {
    return false;
  }

  protected hideCancel(): boolean {
    return false;
  }

  private eventHandler():
    | undefined
    | Record<string, (newValue: unknown) => void> {
    const emits = (this.modalComponent() as DefineComponent).emits; // as EmitsOptions | undefined;

    const mapper = (eventNames: string[]) =>
      Object.fromEntries(
        eventNames.map((eventName) => [
          eventName,
          (newValue: unknown) => this.handleEvent(eventName, newValue),
        ])
      );

    if (emits) {
      if (Array.isArray(emits)) {
        return mapper(emits);
      } else {
        return mapper(Object.keys(emits));
      }
    } else {
      return undefined;
    }
  }

  private extractVariableNameFromEventName(
    eventName: string
  ): null | keyof PartialProps<T> {
    // check if component even has props
    const component = this.modalComponent();
    const props = (component as DefineComponent).props as
      | PartialProps<T>
      | undefined;
    if (!props) return null;

    // get last part of event
    const lastPartOfEvent = eventName.split(":").at(-1);
    if (!lastPartOfEvent) return null;
    const camelName = camelize(lastPartOfEvent);

    log(LogLevel.Debug, `[MODAL] got potential variable name: '${camelName}'`);

    if (camelName in props) return camelName;
    return null;
  }
}
