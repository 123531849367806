import detectElementOverflow from "detect-element-overflow";

export interface CollisionDirection {
  top?: true;
  bottom?: true;
  right?: true;
  left?: true;
}

export function hasOverflow(
  parent: HTMLElement,
  child: HTMLElement,
  direction: CollisionDirection
): boolean {
  const collision = detectElementOverflow(child, parent);
  return (
    (!!direction.top && collision.collidedTop) ||
    (!!direction.bottom && collision.collidedBottom) ||
    (!!direction.right && collision.collidedRight) ||
    (!!direction.left && collision.collidedLeft)
  );
}

export function hasOverflowingChild(
  parent: HTMLElement,
  recursive: boolean,
  direction: CollisionDirection,
  originalParent?: HTMLElement
): boolean {
  for (const child of parent.children) {
    const childElement = child as HTMLElement;
    if (hasOverflow(originalParent ?? parent, childElement, direction))
      return true;

    if (
      recursive &&
      hasOverflowingChild(
        childElement,
        recursive,
        direction,
        originalParent ?? parent
      )
    )
      return true;
  }

  return false;
}
