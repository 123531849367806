import { routes } from "frontend/api/application";
import { requestGenericSettings } from "frontend/api/application/settings/request-generic-settings";
import { SettingsProfileRaw } from "frontend/interfaces/settings/settings-profile";

export const api = requestGenericSettings<SettingsProfileRaw>({
  endpoints: {
    indexEndpoint: routes.paths.frontend_settings_settings_profiles_path,
    showEndpoint: routes.paths.frontend_settings_settings_profile_path,
  },
});
