import { Unit } from "frontend/interfaces/unit";
import { Database } from "frontend/uses/abstract-view/use-abstract-view-parser";

export function onUpdateAppointmentStatus(unit: Unit, database: Database) {
  return (newStatus: number) => {
    // lookup all related units
    const unitsToChange = database.units.filter(
      (unitCandidate) => unitCandidate.appointment_id === unit.appointment_id
    );
    for (const unitToChange of unitsToChange) {
      if (unitToChange.appointment) unitToChange.appointment.status = newStatus;
    }
  };
}

export function onUpdateAppointmentInternalOfficeNote(
  unit: Unit,
  database: Database
) {
  return (newInternalNote: string) => {
    // lookup all related units
    const unitsToChange = database.units.filter(
      (unitCandidate) => unitCandidate.appointment_id === unit.appointment_id
    );
    for (const unitToChange of unitsToChange) {
      if (unitToChange.appointment)
        unitToChange.appointment.internal_office_note = newInternalNote;
    }
  };
}
