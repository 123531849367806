import {
  computed,
  ComputedRef,
  DeepReadonly,
  onUnmounted,
  readonly,
  Ref,
  ref,
  watch,
  CSSProperties,
} from "vue";

import { useResponsiveness } from "frontend/uses/use-responsiveness";
import { useObserver } from "frontend/uses/use-observer";

export function useStickyness(
  element: Ref<Element | null>,
  initialOffsetInPx: () => number,
  targetElement?: Ref<Element | null>
): {
  offsetInPx: Ref<number>;
  targetCSS: ComputedRef<{
    position?: CSSProperties["position"];
    top?: CSSProperties["top"];
  }>;
  isStuck: DeepReadonly<Ref<boolean>>;
  targetElement: Ref<Element | null>;
} {
  const positionSource = ref<number | null>(null);
  const offsetInPx = ref<number>(initialOffsetInPx());
  const stickyY = computed(() => {
    if (!positionSource.value) return null;
    return positionSource.value + initialOffsetInPx();
  });
  const theTargetElement = targetElement || ref(null);

  const { doDisconnect } = useResponsiveness((_info: ResizeObserverEntry) => {
    if (element.value) {
      const rect = element.value.getBoundingClientRect();
      positionSource.value =
        rect.top + rect.height ? rect.top + rect.height : null;
    } else {
      positionSource.value = null;
    }
  }, element);

  onUnmounted(doDisconnect);

  return {
    offsetInPx,
    targetCSS: computed(() => {
      if (positionSource.value) {
        return {
          position: "sticky",
          top: `${stickyY.value}px`,
        };
      } else {
        return {};
      }
    }),
    targetElement: theTargetElement,
    ...stuckDetermination(theTargetElement, stickyY),
  };
}

function stuckDetermination(
  targetElement: Ref<Element | null>,
  stickyY: Ref<number | null>
): {
  isStuck: DeepReadonly<Ref<boolean>>;
} {
  const isStuck = ref(false);

  let currentDisconnector: null | (() => void) = null;
  const disconnect = () => {
    if (currentDisconnector) currentDisconnector();
    currentDisconnector = null;
    isStuck.value = false;
  };

  watch([targetElement, stickyY], () => {
    disconnect();

    if (!stickyY.value || !targetElement.value) return;

    const { doDisconnect } = useObserver(
      IntersectionObserver,
      (info) => {
        isStuck.value = !info.isIntersecting;
      },
      targetElement,
      {
        root: null,
        rootMargin: `-${stickyY.value + 1}px 0px 0px 0px`,
        threshold: [1],
      }
    );
    currentDisconnector = doDisconnect;
  });

  onUnmounted(disconnect);

  return {
    isStuck: readonly(isStuck),
  };
}

export function findOffset(element: Element | null): number {
  return element ? element.getBoundingClientRect().height : 0;
}
