<script lang="ts">
  import { defineComponent } from "vue";

  import { RemindersManager } from "frontend/uses/settings/manager/reminders-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new RemindersManager().provide();
    },
  });
</script>

<template>
  <div class="settings-colors">
    <h2>Terminerinnerungen</h2>

    <BaseHelp>
      <p>
        Hier können Sie einstellen, wann und in welchem Abstand die Patienten
        erinnert werden.
      </p>
      <p>
        Dies ist eine Standardeinstellung für alle Termintypen. Alle von der
        Praxis konfigurierten Termintypen erhalten – abhängig vom verbleibenden
        Zeitraum zum Termin – automatisch die hier festgelegten Erinnerungen
        (Akuttermine, die nur 1 Tag vor dem Termin buchbar sind, erhalten z.B.
        nur 1 Stunde vorher eine Erinnerung; U-Untersuchungen, die 2 Monate in
        der Zukunft liegen, erhalten nach Festlegung der Praxis z.B. 4 Wochen
        vorher, 1 Woche vorher, 1 Tag vorher und 1 Stunde vor dem Termin eine
        Erinnerung). Diese Erinnerungsabstände können Sie hier festlegen.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
