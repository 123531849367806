<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    toRefs,
    watch,
  } from "vue";
  import { isSameMinute, isWithinInterval } from "date-fns";

  import { Patient } from "frontend/interfaces/patient";
  import { AppointmentType } from "frontend/interfaces/appointment-type";
  import { requestAppointmentTypes } from "frontend/api/application/request-appointment-types";
  import { parseAppointmentType } from "frontend/parser/parse-appointment-type";
  import {
    FormSelectId,
    useFormSelectOptions,
  } from "frontend/uses/use-form-select";
  import { PatientSelectionModal } from "frontend/utils/modals/patient-selection-modal";
  import { Office } from "frontend/interfaces/office";
  import { requestOffice } from "frontend/api/application/request-office";
  import { parseOffice } from "frontend/parser/parse-office";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { requestAttendances } from "frontend/api/application/request-attendances";
  import { parseAttendance } from "frontend/parser/settings/parse-attendance";
  import { homgenizeDateTo } from "frontend/uses/use-attendances-parser";

  import FormText from "frontend/components/form/FormText.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormInput from "frontend/components/form/FormInput.vue";

  const UPDATE_PATIENT = "update:selectedPatient";
  const UPDATE_ATId = "update:selectedAppointmentTypeId";
  const UPDATE_ATs = "update:appointmentTypes";
  const UPDATE_ORDER_TIME = "update:orderTimeInMinutes";

  export default defineComponent({
    components: {
      FormText,
      FormSelect,
      FormInput,
    },
    props: {
      selectedPatient: {
        type: Object as PropType<Patient | null>,
        default: null,
      },
      selectedAppointmentTypeId: {
        type: [String, Number] as PropType<FormSelectId>,
        default: null,
      },
      appointmentTypes: {
        type: Array as PropType<Array<AppointmentType>>,
        default: () => [],
      },
      orderTimeInMinutes: {
        type: Number,
        default: 0,
      },
      hidePatientSelection: {
        type: Boolean,
        default: false,
      },
      selectedPersonId: {
        type: [String, Number] as PropType<FormSelectId>,
        default: null,
      },
      selectedFrom: {
        type: Date,
        default: null,
      },
    },
    emits: [UPDATE_PATIENT, UPDATE_ATId, UPDATE_ATs, UPDATE_ORDER_TIME],
    setup(props, { emit }) {
      const {
        selectedPatient,
        selectedAppointmentTypeId,
        appointmentTypes,
        orderTimeInMinutes,
      } = toRefs(props);

      //Patient
      const computedPatientName = computed(() => {
        if (selectedPatient.value) {
          return (
            selectedPatient.value.first_name + " " + selectedPatient.value.name
          );
        } else {
          return null;
        }
      });
      const openPatientSelection = async () => {
        const data = reactive({ selectedPatient: selectedPatient.value });
        await new PatientSelectionModal()
          .setData(data)
          .onSuccess(() => {
            emit(UPDATE_PATIENT, data.selectedPatient);
          })
          .show();
      };

      //Appointment type
      const selectedATId = ref<FormSelectId>(selectedAppointmentTypeId.value);
      const reloadAppointmentTypes = async () => {
        if (!selectedPatient.value) return;
        const data = await requestAppointmentTypes(selectedPatient.value.id);
        const parsedValues = data.appointment_types.map(parseAppointmentType);
        emit(UPDATE_ATs, parsedValues);
      };
      watch(selectedPatient, () => {
        if (selectedPatient.value) {
          reloadAppointmentTypes();
        }
      });
      const scheduledSessions = ref<string[]>([]);
      onMounted(async () => {
        if (selectedPatient.value && props.hidePatientSelection) {
          selectedATId.value = "";
          reloadAppointmentTypes();
        }
        const filterParams = {
          date: formatDate(props.selectedFrom, DateFormat.ISO),
        };
        const data = await requestAttendances(filterParams);
        const attendances = data.entities.map(parseAttendance);
        const hom = homgenizeDateTo(props.selectedFrom);
        scheduledSessions.value = attendances
          .filter(
            (a) =>
              a.person_id === props.selectedPersonId &&
              isWithinInterval(props.selectedFrom, {
                start: hom(a.from, true),
                end: hom(a.to, true),
              }) &&
              !isSameMinute(props.selectedFrom, hom(a.to, true))
          )
          .flatMap((a) => a.session_ids);
      });

      const belongsToSchedule = (entry: AppointmentType) => {
        return entry.sessions.some((s) =>
          scheduledSessions.value.includes(s.id)
        );
      };
      const { options, currentOption: currentAT } = useFormSelectOptions(
        selectedATId,
        appointmentTypes,
        (entry) => ({
          id: entry.id,
          label: belongsToSchedule(entry) ? `${entry.name}**` : entry.name,
        })
      );
      const onUpdateAppointmentType = (newValue: FormSelectId) => {
        emit(UPDATE_ATId, newValue);
      };

      //Order Time
      const orderTime = ref<number>(orderTimeInMinutes.value);
      const office = ref<Office | null>(null);
      const loadOffice = async () => {
        office.value = parseOffice(await requestOffice());
      };
      onMounted(loadOffice);
      const computedOrderTime = computed(() => {
        if (!currentAT.value || !office.value) return 0;
        return currentAT.value.order_time_in_minutes !== null
          ? currentAT.value.order_time_in_minutes
          : office.value.order_time_in_minutes !== null
            ? office.value.order_time_in_minutes
            : 0;
      });
      watch(currentAT, () => {
        orderTime.value = computedOrderTime.value;
        emit(UPDATE_ORDER_TIME, orderTime.value);
      });
      const onUpdateOrderTime = (newValue: number) => {
        emit(UPDATE_ORDER_TIME, +newValue);
      };

      return {
        openPatientSelection,
        computedPatientName,
        options,
        selectedATId,
        onUpdateAppointmentType,
        orderTime,
        onUpdateOrderTime,
      };
    },
  });
</script>

<template>
  <div>
    <div class="popup-appointment__element">
      <FormText label="Patient">
        <template v-if="computedPatientName">
          {{ computedPatientName }}
        </template>
        <template v-else>(<i>Keine Auswahl</i>)</template>
        <span
          v-if="!hidePatientSelection"
          class="popup-appointment__search-patient-link"
          v-on:click="openPatientSelection"
        >
          auswählen
        </span>
      </FormText>
    </div>
    <FormSelect
      v-if="selectedPatient"
      v-model="selectedATId"
      label="Vorstellungsanlass"
      v-bind:options="options"
      include-blank
      visual-small
      v-on:update:model-value="onUpdateAppointmentType"
    />
    <FormInput
      v-if="selectedATId"
      v-model="orderTime"
      v-bind:type="'number'"
      label="Einbestellzeit"
      visual-small
      v-on:update:model-value="onUpdateOrderTime"
    />
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/link";

  .popup-appointment__search-patient-link {
    @include link.link;

    margin-left: 10px;
  }

  .popup-appointment__element {
    margin-bottom: 10px;
  }
</style>
