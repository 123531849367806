import { routes, doRequest } from "frontend/api/application";
import { ColorRaw } from "frontend/interfaces/color";
import {
  HttpMethod,
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestColors(): Promise<UnifiedListResponse<ColorRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_colors_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { colors: ColorRaw[] }).colors,
  };
}

export async function updateColor(
  color: HttpPayload
): Promise<UnifiedResponse<ColorRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_color_path,
    { id: color.id as string },
    color
  );

  return {
    entityRaw: (body as unknown as { color: ColorRaw }).color,
    success: statusIsSuccess,
    errors:
      (body as unknown as { color: { _errors?: ErrorDatabase } }).color
        ._errors || {},
  };
}

export async function createColor(
  color: HttpPayload
): Promise<UnifiedResponse<ColorRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_colors_path,
    {},
    color
  );

  return {
    entityRaw: (body as unknown as { color: ColorRaw }).color,
    success: statusIsSuccess,
    errors:
      (body as unknown as { color: { _errors?: ErrorDatabase } }).color
        ._errors || {},
  };
}

export async function deleteColor(
  id: PrimaryKey
): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_color_path,
    { id },
    {}
  );

  return { success: statusIsSuccess, errors: {} };
}
