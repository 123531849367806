import { routes } from "frontend/api/application";
import { requestGenericSettings } from "frontend/api/application/settings/request-generic-settings";
import { OperatorRaw } from "frontend/interfaces/settings/operator";

export const api = requestGenericSettings<OperatorRaw>({
  endpoints: {
    indexEndpoint: routes.paths.frontend_settings_operators_path,
    showEndpoint: routes.paths.frontend_settings_operator_path,
  },
});
