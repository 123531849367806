<script lang="ts">
  import { defineComponent, PropType, toRefs, watch } from "vue";

  import { WizardStep, WizardStepStatus } from "frontend/interfaces/wizard";
  import { useWizardSteps } from "frontend/uses/use-wizard-steps";

  import RenderSlot from "frontend/components/RenderSlot.vue";

  export default defineComponent({
    components: { RenderSlot },
    props: {
      visualInverted: {
        type: Boolean,
        default: false,
      },
      steps: {
        type: Array as PropType<Array<WizardStep>>,
        default: () => [],
      },
      selectStep: {
        type: Object as PropType<WizardStep>,
        default: () => null,
      },
      disableForwardNavigation: {
        type: Boolean,
        default: false,
      },
      disableBackwardNavigation: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["step-selected"],
    setup(props, { emit }) {
      const {
        steps,
        selectStep,
        disableForwardNavigation,
        disableBackwardNavigation,
      } = toRefs(props);
      const { stepsRendering, currentSelection } = useWizardSteps(
        steps,
        disableForwardNavigation,
        disableBackwardNavigation
      );
      watch(
        selectStep,
        (newStep) => {
          currentSelection.value = newStep;
        },
        { immediate: true }
      );
      watch(currentSelection, (newStep) => {
        emit("step-selected", newStep);
      });
      return {
        WizardStepStatus,
        stepsRendering,
        currentSelection,
      };
    },
  });
</script>

<template>
  <div
    class="vue-component__wizard-steps"
    v-bind:class="{ 'vue-component__wizard-steps--inverted': visualInverted }"
  >
    <div class="steps">
      <div
        v-for="(step, index) in stepsRendering"
        v-bind:key="step.label"
        class="steps__step step"
        v-bind:class="{
          'step--active': step.status === WizardStepStatus.Active,
          'step--inactive': step.status === WizardStepStatus.Inactive,
        }"
      >
        <div
          class="step__line"
          v-bind:class="{ 'step__line--invisible': index === 0 }"
        ></div>
        <div
          class="step__icon"
          v-bind:class="{
            'step__icon--disabled': step.disabled,
          }"
          v-on:click="step.doSelect()"
        >
          <i
            class="fe fe-circle"
            v-bind:class="{
              'fe-circle-with-dot': step.status === WizardStepStatus.Active,
              'fe-circle': step.status !== WizardStepStatus.Active,
            }"
          ></i>
          <div class="step__label">
            {{ step.label }}
          </div>
        </div>
        <div
          class="step__line"
          v-bind:class="{
            'step__line--invisible': index === steps.length - 1,
          }"
        ></div>
      </div>
    </div>
    <div
      v-if="currentSelection && $slots[currentSelection.slotName]"
      class="content"
    >
      <RenderSlot v-bind:rendered-slot="$slots[currentSelection.slotName]" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .vue-component__wizard-steps {
    $_label-overlap: 30px;
    .steps {
      display: flex;
      justify-content: space-between;

      padding-bottom: 25px;
      padding-left: $_label-overlap;
      padding-right: $_label-overlap;
    }

    .steps__step {
      flex: 1;

      &:first-of-type,
      &:last-of-type {
        flex: 0.5;
      }
    }

    .step {
      display: flex;
      align-items: center;

      position: relative;
    }

    .step__label {
      font-size: 12px;
      position: absolute;
      text-align: center;
      bottom: -20px;
      left: -$_label-overlap;
      right: -$_label-overlap;
    }

    .step__icon--disabled {
      &,
      &:hover {
        cursor: not-allowed;
        .step__label {
          font-weight: initial;
        }
      }
    }

    .step__icon {
      $_font-size: 20px;
      font-size: $_font-size;

      display: flex;
      align-items: center;

      position: relative;

      &:hover {
        cursor: pointer;

        .step__label {
          font-weight: bold;
        }
      }
    }

    .step__line {
      flex: 1;
    }

    .step__line--invisible {
      display: none;
    }

    .step--disabled {
      cursor: not-allowed;
    }

    .content {
      margin-top: 15px;
    }
  }
</style>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  // COLORS
  .vue-component__wizard-steps {
    .step {
      color: colors.$color_wizard__step--indicator;
    }
    .step__icon {
      &:hover {
        color: colors.$color_wizard__step--hover-indicator;

        &.step__icon--disabled {
          color: colors.$color_wizard__step-inactive--indicator;
        }
      }
    }
    .step__line {
      border-top: 2px solid colors.$color_wizard__step--indicator;
    }
    .step--inactive {
      color: colors.$color_wizard__step-inactive--indicator;
      .step__line {
        border-color: colors.$color_wizard__step-inactive--indicator;
      }
    }
    .step__icon .step__label {
      color: colors.$color_wizard__step--label;
    }
  }

  .vue-component__wizard-steps.vue-component__wizard-steps--inverted {
    .step {
      color: colors.$color_wizard__step-inverted--indicator;
    }
    .step__icon {
      &:hover {
        color: colors.$color_wizard__step-inverted--hover-indicator;
      }
    }
    .step__line {
      border-top: 2px solid colors.$color_wizard__step-inverted--indicator;
    }
    .step--inactive {
      color: colors.$color_wizard__step-inverted-inactive--indicator;
      .step__line {
        border-color: colors.$color_wizard__step-inverted-inactive--indicator;
      }
    }
    .step__label {
      color: colors.$color_wizard__step-inverted--label;
    }
  }
</style>
