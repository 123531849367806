import { Component, computed, Ref, ref } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import {
  httpPayloadAttendance,
  newAttendance,
  parseAttendance,
} from "frontend/parser/settings/parse-attendance";
import { api } from "frontend/api/application/settings/request-attendances";
import {
  Attendance,
  AttendanceNew,
} from "frontend/interfaces/settings/attendance";
import {
  BaseS,
  FilterBackendParams,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { simpleRow } from "frontend/uses/filter/predefined/flex";
import { Person } from "frontend/interfaces/person";
import { Cache } from "frontend/utils/request-cache";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { simpleCheckboxGroup } from "frontend/uses/filter/predefined/checkbox";

import EditAttendance from "frontend/components/settings/EditAttendance.vue";

export class AttendancesSettingsManager extends SettingsManager<Attendance> {
  filterParams: Ref<FilterBackendParams> = ref({});
  persons = ref<Person[]>([]);

  entityName(): string {
    return "Anwesenheit";
  }
  componentForEdit(): Component {
    return EditAttendance;
  }
  craftNewEntity(): Attendance {
    const attendance = newAttendance();
    attendance.schedule_id = this.filterParams.value.schedule_id ?? "";
    return attendance;
  }
  async hookList(): Promise<SettingsListResult<Attendance>> {
    return genericListPattern(
      parseAttendance,
      api.requestIndex,
      this.filterParams.value
    );
  }
  hookSave(record: Attendance): Promise<SettingsSaveResult<Attendance>> {
    return genericSavePattern(
      record,
      parseAttendance,
      httpPayloadAttendance,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: Attendance): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }

  enableFilter() {
    return true;
  }
  //Filter
  async initFilterOptions(): Promise<void> {
    this.persons.value = await Cache.getCachedPersons();
  }
  initFilterDefinitions(): FilterDefinition<
    Attendance | AttendanceNew,
    BaseS
  >[] {
    return super.initFilterDefinitions().concat([
      simpleRow([
        simpleCheckboxGroup(
          {
            frontend: (
              entry: Attendance | AttendanceNew,
              selectedIDs: Array<PrimaryKey>
            ) =>
              selectedIDs.length === 0 ||
              selectedIDs.indexOf(entry.person_id) >= 0,
          },
          {
            label: "Mitarbeiter",
            options: computed(() =>
              this.persons.value.map((p) => ({
                id: p.id,
                label: p.name,
              }))
            ),
          }
        ),
      ]),
    ]);
  }
}
