import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { api } from "frontend/api/application/settings/request-settings-profiles";
import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";
import {
  httpPayloadSettingsProfile,
  newSettingsProfile,
  parseSettingsProfile,
} from "frontend/parser/settings/parse-settings-profile";

import EditSettingsProfile from "frontend/components/settings/EditSettingsProfile.vue";

export class SettingsProfilesManager extends SettingsManager<SettingsProfile> {
  entityName(): string {
    return "Einstellungsprofil";
  }
  componentForEdit(): Component {
    return EditSettingsProfile;
  }
  craftNewEntity(): SettingsProfile {
    return newSettingsProfile();
  }
  hookList(): Promise<SettingsListResult<SettingsProfile>> {
    return genericListPattern(parseSettingsProfile, api.requestIndex);
  }
  hookSave(
    record: SettingsProfile
  ): Promise<SettingsSaveResult<SettingsProfile>> {
    return genericSavePattern(
      record,
      parseSettingsProfile,
      httpPayloadSettingsProfile,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: SettingsProfile): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
}
