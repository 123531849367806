import { SettingsProfileRaw } from "frontend/interfaces/settings-profile";
import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { api } from "frontend/api/application/settings/request-settings-profiles";

export async function requestSettingsProfiles(): Promise<
  UnifiedListResponse<SettingsProfileRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_settings_profiles_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: SettingsProfileRaw[] }).entities,
  };
}

export async function createSettingsProfile(
  settingsProfile: HttpPayload
): Promise<UnifiedResponse<SettingsProfileRaw>> {
  // use create route of settings
  return await api.requestCreate(settingsProfile);
}
