<script lang="ts">
  import { computed, defineComponent, toRefs, unref } from "vue";
  import { addDays, isValid, parseISO } from "date-fns";

  import { router } from "frontend/router";
  import { useDayView } from "frontend/uses/abstract-view/use-day-view";
  import { formatDate, DateFormat } from "shared/utils/date-utils";
  import {
    AbstractCalendarOptions,
    mainSwitchButtons,
    useAbstractCalendar,
  } from "frontend/uses/use-abstract-calendar";

  import MiniCalendarAsBox from "frontend/components/MiniCalendarAsBox.vue";
  import AbstractCalendarView from "frontend/routes/calendar/AbstractCalendarView.vue";

  export default defineComponent({
    components: {
      MiniCalendarAsBox,
      AbstractCalendarView,
    },
    props: {
      day: {
        type: String,
        default: null,
      },
      highlightAppointmentId: {
        type: String,
        default: null,
      },
    },
    setup(props) {
      const { day } = toRefs(props);
      const dayParsed = computed(() => {
        // try to parse date
        const parsedInitialDate = parseISO(unref(day));
        return isValid(parsedInitialDate) ? parsedInitialDate : null;
      });

      const {
        calendar,
        date,
        triggerReload: doTriggerReload,
      } = useDayView(dayParsed);

      const displayDate = computed(() => {
        const dateUnref = unref(date);
        if (!dateUnref) return "-";
        else return formatDate(dateUnref, DateFormat.DateOnlyLong);
      });
      const options: AbstractCalendarOptions = {
        labelCurrent: "Heute",
        switchButtons: mainSwitchButtons(date),
      };

      return {
        displayDate,

        calendar,
        doTriggerReload,
        options,

        ...useAbstractCalendar(
          date,
          (destination: Date) => {
            router.push({
              name: "calendar-day",
              params: {
                day: formatDate(destination, DateFormat.DateOnlyISO),
              },
            });
          },
          (date: Date, directionUp: boolean) =>
            addDays(date, directionUp ? 1 : -1)
        ),

        highlightedDays: computed(() => (date.value ? [date.value] : [])),
      };
    },
  });
</script>

<template>
  <div class="vue-route__calendar-day-view">
    <AbstractCalendarView
      v-if="calendar"
      v-bind:calendar="calendar"
      v-bind:options="options"
      v-bind:initial-highlight="highlightAppointmentId"
      v-bind:highlighted-days="highlightedDays"
      v-bind:display-date="displayDate"
      context-name="calendar-day-view"
      v-on:refresh-needed="doTriggerReload"
      v-on:navigate:forward="doNavigateForward"
      v-on:navigate:back="doNavigateBack"
      v-on:navigate:current="doNavigateCurrent"
    >
      <template v-slot:sidebar>
        <MiniCalendarAsBox
          v-model="miniCalendarMonth"
          v-bind:selected-days="highlightedDays"
          v-on:date-selected="(dateObj) => doNavigateTo(dateObj.date)"
        />
      </template>
    </AbstractCalendarView>
  </div>
</template>
