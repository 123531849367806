import { nextTick, Ref, watch, watchEffect } from "vue";

import { useStore as useOfficeStore } from "frontend/stores/office";
import { OperatorProfile } from "frontend/stores/profile";
import { SettingsProfile } from "frontend/interfaces/settings-profile";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import {
  OnLoginBefore,
  OnLoginSuccess,
  OnLogoutAfter,
} from "frontend/events/topics";
import { UserEntity } from "frontend/interfaces/user";
import { log, LogLevel } from "shared/utils/logger";

export function watchAndSelectOperators(
  officeProfile: Ref<SettingsProfile | null>,
  operatorProfile: Ref<OperatorProfile | null>,
  selectProfile: (profile: SettingsProfile) => void,
  lookupProfile: (profileID: PrimaryKey | null) => SettingsProfile | null,
  provideOperatorProfile: (profile: OperatorProfile) => void
) {
  const officeStore = useOfficeStore();

  // nextTick needed for persistence to be loaded before logic is applied
  nextTick().then(() => {
    watchEffect(() => {
      const currentOperator = officeStore.currentOperator;
      if (!currentOperator) return;

      const settingsProfile = lookupProfile(
        currentOperator.settings_profile_id
      );

      provideOperatorProfile({
        operatorID: currentOperator.id,
        profile: settingsProfile,
      });
    });

    watch(
      () => ({
        officeProfile: officeProfile.value,
        operatorProfile: operatorProfile.value,
      }),
      (newValue, oldValue) => {
        if (
          newValue.operatorProfile &&
          newValue.operatorProfile.profile &&
          (newValue.operatorProfile.operatorID !==
            oldValue.operatorProfile?.operatorID ||
            newValue.operatorProfile.profile.id !==
              oldValue.operatorProfile?.profile?.id)
        ) {
          selectProfile(newValue.operatorProfile.profile);
        }

        if (newValue.operatorProfile && newValue.operatorProfile.profile)
          return;

        if (
          newValue.officeProfile &&
          (!oldValue.officeProfile ||
            oldValue.officeProfile.id !== newValue.officeProfile.id)
        ) {
          selectProfile(newValue.officeProfile);
        }
      }
    );
  });
}

export function watchLoginState(
  officeProfile: Ref<SettingsProfile | null>,
  operatorProfile: Ref<OperatorProfile | null>,
  settingsProfiles: Ref<Array<SettingsProfile>>,
  selectProfile: (profile?: SettingsProfile) => void,
  fetchProfiles: () => Promise<void>
) {
  OnLogoutAfter.or(OnLoginBefore).subscribe(() => {
    // on logout --> clear office and operator profiles
    // but keep the individual profile
    log(LogLevel.Debug, "[PROFILE-STORE] purge office and operator profiles");
    operatorProfile.value = null;
    officeProfile.value = null;
    selectProfile();
    settingsProfiles.value = [];
  });

  OnLoginSuccess.subscribe((data) => {
    // on login --> reload settings profiles
    if (data.userEntity === UserEntity.Practice) fetchProfiles();
  });
}
