<script lang="ts" setup>
  import { computed, ref } from "vue";

  import {
    createConfiguration,
    requestPvsConfiguration,
  } from "frontend/api/application/settings/request-pvs";
  import { PvsRaw } from "frontend/interfaces/settings/pvs";
  import { UnifiedResponse } from "frontend/interfaces/http";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import EditPvsSystem from "frontend/components/settings/pvs/EditPvsSystem.vue";
  import EditPvsConfiguration from "frontend/components/settings/pvs/EditPvsConfiguration.vue";

  const config = ref<null | PvsRaw>(null);
  const hasFailure = ref(false);

  const processResponse = (response: UnifiedResponse<PvsRaw>) => {
    hasFailure.value = !response.success;

    if (response.success) {
      config.value = response.entityRaw;
    }
  };

  const loadPvsConfiguration = async () => {
    const response = await requestPvsConfiguration();
    processResponse(response);
  };
  loadPvsConfiguration();

  const pvsOptions = computed(() => {
    if (!config.value) return undefined;

    return config.value.pvs.map((pvs) => ({
      id: pvs.value,
      label: pvs.label,
    }));
  });

  const addConfiguration = async () => {
    if (!config.value?.pvs_system) return;

    const response = await createConfiguration(config.value?.pvs_system.id);
    processResponse(response);
  };
</script>

<template>
  <h2>PVS-Schnittstelle</h2>
  <BaseHelp>
    <p>Hier können Sie eine Schnittstelle zu Ihrem PVS einrichten.</p>
  </BaseHelp>

  <template v-if="hasFailure">
    <div class="error-text">
      Die PVS-Konfiguration ist zur Zeit wegen eines technischen Fehlers nicht
      erreichbar. Versuchen Sie es bitte später erneut.
    </div>
  </template>
  <template v-else-if="config && pvsOptions">
    <h3>PVS-System</h3>
    <EditPvsSystem
      v-bind:entity="config.pvs_system"
      v-bind:pvs-options="pvsOptions"
      class="pvs-options"
      v-on:update:config="processResponse"
    />

    <div
      v-for="configuration in config.configurations"
      v-bind:key="configuration.id"
      class="configuration"
    >
      <EditPvsConfiguration
        v-bind:entity="configuration"
        v-on:update:config="processResponse"
      />
    </div>

    <BaseButton v-on:submit="addConfiguration"
      >Weitere Konfiguration hinzufügen</BaseButton
    >
  </template>
  <template v-else>
    <div class="loading-text">
      <div class="loading-text__text">
        Die PVS-Konfiguration wird geladen...
      </div>
      <BaseSpinner />
    </div>
  </template>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .loading-text {
    display: flex;
    align-items: center;
  }

  .loading-text__text {
    margin-right: 10px;
  }

  .error-text {
    color: colors.$color_text--global--error;
    font-weight: bold;
  }

  .pvs-options {
    margin-bottom: 5px;
  }

  .configuration {
    padding: 40px 0;
  }
</style>
