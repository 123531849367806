import { Notyf, NotyfNotification } from "notyf";
import "notyf/notyf.min.css";

type ToastCreator = (content: string) => void;

const NOTIFICATION_LIB = new Notyf({
  duration: 2000,
  dismissible: true,
  ripple: false,
  position: {
    x: "right",
    y: "bottom",
  },
  types: [
    {
      type: "error",
      className: "toast toast--error",
      icon: {
        className: "fe fe-close toast__icon toast__icon--error",
        tagName: "i",
      },
    },
    {
      type: "success",
      className: "toast--success",
      icon: {
        className: "fe fe-checkmark toast__icon toast__icon--success",
        tagName: "i",
      },
    },
  ],
});

export function useToasts(): {
  error: ToastCreator;
  success: ToastCreator;
} {
  return {
    error: newToastCreator(NOTIFICATION_LIB.error.bind(NOTIFICATION_LIB)),
    success: newToastCreator(NOTIFICATION_LIB.success.bind(NOTIFICATION_LIB)),
  };
}

function newToastCreator(
  method: (content: string) => NotyfNotification
): ToastCreator {
  return (content) => {
    method(content);
  };
}
