import { Ref } from "vue";

import { PendingLogic } from "frontend/utils/pending-logic";

/**
 * Use this to do something (callback to doWithPending)
 * while a reactive variable (isPending) is automatically set to
 * true while doing it.
 * It will automatically debounce further calls while the first
 * call is still pending.
 *
 * @export
 * @returns {{
 *   isPending: Ref<boolean>;
 *   doWithPending: (callback: () => Promise<void>) => void;
 * }}
 */
export function usePending(): {
  isPending: Ref<boolean>;
  doWithPending: (callback: () => Promise<void>) => Promise<void>;
} {
  const pending = new PendingLogic();
  return {
    isPending: pending.isPendingR(),
    doWithPending: async (cb) => await pending.doWithPending(cb),
  };
}
