<script lang="ts">
  import { PropType, defineComponent, ref, toRefs, watch } from "vue";

  import { UnifiedResponse } from "frontend/interfaces/http";
  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Pvs } from "frontend/interfaces/settings/pvs";
  import { updatePvsSystem } from "frontend/api/application/settings/request-pvs";
  import { PrimaryKey } from "frontend/interfaces/primary-key";

  import FormSelect from "frontend/components/form/FormSelect.vue";

  export default defineComponent({
    components: {
      FormSelect,
    },
    props: {
      ...editProps<Pvs["pvs_system"]>(),
      pvsOptions: {
        type: Array as PropType<
          Array<{
            id: PrimaryKey;
            label: string;
          }>
        >,
        required: true,
      },
    },
    emits: {
      "update:config": (_config: UnifiedResponse<Pvs>) => true,
    },
    setup(props, { emit }) {
      const isPending = ref<boolean>(false);

      const editComponent = useEditComponent(props.entity, {
        manager: {
          isPendingR: () => isPending,
          createEditable: (record) => ({ ...record }),
          save: async (record) => {
            try {
              isPending.value = true;
              const response = await updatePvsSystem(record.id, record.pvs);

              if (response.success) {
                emit("update:config", response);
              }

              return {
                entity: response.entityRaw.pvs_system,
                success: response.success,
                errors: response.errors,
              };
            } finally {
              isPending.value = false;
            }
          },
          delete: async () => {
            return;
          },
        },
      });

      watch(toRefs(props).entity, () => {
        editComponent.recreateEditable(props.entity);
      });

      return {
        ...editComponent,
      };
    },
  });
</script>

<template>
  <div>
    <!-- select pvs system -->
    <FormSelect
      v-model="entityEditable.pvs"
      v-bind:options="pvsOptions"
      v-bind:errors="errorFor('pvs')"
      v-bind:animation-status="statusFor('pvs')"
      v-on="{ autosave: onAutosaveFor('pvs') }"
    />
  </div>
</template>
