<script lang="ts">
  import { defineComponent } from "vue";

  import { RoomsSettingsManager } from "frontend/uses/settings/manager/rooms-settings-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new RoomsSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-locations">
    <h2>Räume</h2>

    <BaseHelp>
      <p>
        Diese Funktion ist für Praxen mit mehreren Untersuchungsräumen wichtig,
        um <strong>Doppelbelegungen vermeiden</strong>. Sind
        <strong>bestimmte Räume nur für bestimmte Untersuchungen</strong> (z.B.
        Ultraschall, EKG) geeignet, kann dies hinterlegt werden und für die
        Terminvergabe automatisch berücksichtigt werden.
      </p>
      <p>
        Praxen mit wenig Räumen ohne Spezialfunktionen, wie z.B. Ultraschall,
        EKG, können diese Funktion unberücksichtigt lassen.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>

<style lang="scss" scoped>
  .settings-locations__location {
    margin-bottom: 20px;
  }
</style>
