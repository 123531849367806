import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  Appointment,
  AppointmentNew,
  AppointmentRaw,
} from "frontend/interfaces/appointment";

export function parseAppointment(apppointment: AppointmentRaw): Appointment {
  return { ...apppointment };
}

export function httpPayloadLocation(apppointment: Appointment): HttpPayload {
  return {
    ...apppointment,
  };
}

export function newLocation(): AppointmentNew {
  return {
    id: `MOCK-${uuid()}`,

    internal_office_note: "",

    unsaved: true,
  };
}
