<template>
  <div class="vue-route__not-found plain">
    <h1>Seite nicht gefunden</h1>
    <p>Es tut uns Leid!</p>
    <p>Die von Ihnen aufgerufene Seite konnte nicht gefunden werden.</p>
    <div class="error-code">404</div>
  </div>
</template>

<style lang="scss" scoped>
  .vue-route__not-found {
    text-align: center;
  }
  .error-code {
    margin-top: 20px;
    font-size: 75px;
    text-align: center;

    font-family: monospace;
  }
</style>
