import { computed, Ref, unref } from "vue";
import { v4 } from "uuid";
import { compareAsc, startOfDay } from "date-fns";

import {
  Droplet,
  Pillar,
  PillarCalendar,
  PillarCalendarEntry,
} from "frontend/interfaces/pillar-calendar";
import { DateFormat, formatDate } from "shared/utils/date-utils";

type ExternalMapping<T> = (entry: T) => PillarCalendarEntry;

export function usePillarCalendar<T>(
  entries: Ref<Array<T>>,
  map: ExternalMapping<T>,
  onClick?: (entry: T) => void
): {
  pillarCalendar: Ref<PillarCalendar>;
} {
  const pillarCalendar = computed(() => {
    const droplets = mapTToDroplets<T>(unref(entries), map, onClick);
    const pillars = sortPillars(groupDropletsToPillars(droplets));

    return {
      pillars,
    };
  });

  return {
    pillarCalendar,
  };
}

function mapTToDroplets<T>(
  entries: Array<T>,
  map: ExternalMapping<T>,
  onClick?: (entry: T) => void
): Array<Droplet> {
  return entries.map((entry) => ({
    ...map(entry),
    onClick: () => {
      if (onClick) onClick(entry);
    },
  }));
}

function groupDropletsToPillars(droplets: Array<Droplet>): Array<Pillar> {
  const pillarStorage: Partial<Record<string, Pillar>> = {};

  for (const droplet of droplets) {
    const pillar = ensurePillarInStorage(pillarStorage, droplet.time);
    pillar.droplets.push(droplet);
  }

  return Object.values(pillarStorage).filter(
    (pillar): pillar is Pillar => !!pillar
  ) as Array<Pillar>;
}

function ensurePillarInStorage(
  storage: Partial<Record<string, Pillar>>,
  day: Date
): Pillar {
  const key = formatDate(day, DateFormat.DateOnlyISO);

  let pillar = storage[key];

  if (!pillar) {
    pillar = createNewPillar(day);
    storage[key] = pillar;
  }

  return pillar;
}

function createNewPillar(day: Date): Pillar {
  return {
    id: v4(),
    day: startOfDay(day),
    droplets: [],
  };
}

function sortPillars(pillars: Array<Pillar>): Array<Pillar> {
  for (const pillar of pillars) {
    pillar.droplets = pillar.droplets.sort((a, b) =>
      compareAsc(a.time, b.time)
    );
  }
  return pillars.sort((a, b) => compareAsc(a.day, b.day));
}

export function getPillarIndex(
  pillarCalendar: Ref<PillarCalendar>,
  pillar: Ref<Pillar | null>
): number {
  if (!pillar.value) return 0;

  const index = pillarCalendar.value.pillars.indexOf(pillar.value);
  return Math.max(index, 0);
}
