import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";

export async function requestLogin(token: string): Promise<{
  success: boolean;
}> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.user_session_path,
    {},
    {
      user: {
        token,
      },
    }
  );

  if (statusIsSuccess) {
    return {
      success: true,
    };
  } else {
    return {
      success: false,
    };
  }
}

export async function requestLogout(): Promise<void> {
  await doRequest(
    HttpMethod.DELETE,
    routes.paths.destroy_user_session_path,
    {},
    {}
  );
}
