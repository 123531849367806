import {
  AbstractViewCellPosition,
  AbstractViewOptions,
  Database,
} from "frontend/uses/abstract-view/use-abstract-view-parser";
import { Attendance } from "frontend/interfaces/attendance";
import {
  CalendarEntryAttendance,
  CalendarEntryType,
} from "frontend/interfaces/calendar";
import { Person } from "frontend/interfaces/person";
import { Interval } from "shared/interfaces/interval";
import {
  height,
  higherOrderIsInInterval,
  topOffsetPercentage,
} from "frontend/utils/base-calendar-utils";
import { ConstantsDayView } from "frontend/utils/constants";
import { profiledCalendarConstants } from "frontend/utils/profile-helper";

import AttendanceComponent from "frontend/components/base-calendar/BCAttendance.vue";

export function entriesAttendances(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): Array<CalendarEntryAttendance> {
  return person?.attendances
    .filter(
      higherOrderAttendanceIsInInterval(interval, columnInterval, cellPosition)
    )
    .map((attendance) =>
      attendanceToEntry(
        attendance,
        person,
        interval,
        columnInterval,
        cellPosition,
        database,
        options
      )
    );
}

function attendanceToEntry(
  attendance: Attendance,
  _person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  _database: Database,
  _options: AbstractViewOptions
): CalendarEntryAttendance {
  return {
    type: CalendarEntryType.Attendance,
    key: `key--${attendance.id}`,
    componentCalendar: () => AttendanceComponent,
    topOffsetPercentage: topOffsetPercentage(
      attendance.from,
      interval.from,
      cellPosition,
      profiledCalendarConstants(ConstantsDayView)
    ),
    ...height(
      attendance,
      columnInterval,
      profiledCalendarConstants(ConstantsDayView)
    ),
    color: attendance.color,
    tooltipLabel: attendance.name,
  };
}

function higherOrderAttendanceIsInInterval(
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition
): (attendance: Attendance) => boolean {
  return (attendance: Attendance) =>
    higherOrderIsInInterval(
      interval,
      columnInterval,
      cellPosition
    )(attendance.from, attendance.to);
}
