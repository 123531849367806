import { doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { FilterBackendParams } from "frontend/uses/filter/filter-definition";
import { ErrorDatabase } from "frontend/uses/use-errors";
import { PathURLTemplate } from "shared/utils/pathify";

//////////////////////////////////////////////////////////////////////////////
// Interfaces / Types
////

interface APIEndpoints {
  indexEndpoint: PathURLTemplate;
  showEndpoint: PathURLTemplate;
  updateEndpoint?: PathURLTemplate;
  createEndpoint?: PathURLTemplate;
  destroyEndpoint?: PathURLTemplate;
}

interface APIEndpointsFull extends APIEndpoints {
  indexEndpoint: PathURLTemplate;
  showEndpoint: PathURLTemplate;
  updateEndpoint: PathURLTemplate;
  createEndpoint: PathURLTemplate;
  destroyEndpoint: PathURLTemplate;
}

interface APIOptions {
  endpoints: APIEndpoints | APIEndpointsFull;
}

interface APIOptionsFull {
  endpoints: APIEndpointsFull;
}

interface APICollection<T> {
  requestIndex: (
    filterParams?: FilterBackendParams
  ) => Promise<UnifiedListResponse<T>>;
  requestShow: (id: PrimaryKey) => Promise<UnifiedResponse<T>>;
  requestUpdate: (entity: HttpPayload) => Promise<UnifiedResponse<T>>;
  requestCreate: (entity: HttpPayload) => Promise<UnifiedResponse<T>>;
  requestDestroy: (id: PrimaryKey) => Promise<UnifiedBaseResponse>;
}

//////////////////////////////////////////////////////////////////////////////
// Generic requests
////

function createRequestorIndex<T>(
  options: APIOptionsFull
): (filterParams?: FilterBackendParams) => Promise<UnifiedListResponse<T>> {
  return async (filterParams?: FilterBackendParams) => {
    const { body, statusIsSuccess } = await doRequest(
      HttpMethod.GET,
      options.endpoints.indexEndpoint,
      filterParams || {},
      {}
    );

    return {
      success: statusIsSuccess,
      errors: {},
      entities: (body as unknown as { entities: T[] }).entities,
    };
  };
}

function createRequestorShow<T>(
  options: APIOptionsFull
): (id: PrimaryKey) => Promise<UnifiedResponse<T>> {
  return async (id: PrimaryKey) => {
    const { statusIsSuccess, body } = await doRequest(
      HttpMethod.GET,
      options.endpoints.showEndpoint,
      { id: id },
      {}
    );

    return {
      entityRaw: (body as unknown as { entity: T }).entity,
      success: statusIsSuccess,
      errors:
        (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
          ._errors || {},
    };
  };
}

function createRequestorUpdate<T>(
  options: APIOptionsFull
): (entity: HttpPayload) => Promise<UnifiedResponse<T>> {
  return async (entity: HttpPayload) => {
    const { statusIsSuccess, body } = await doRequest(
      HttpMethod.PATCH,
      options.endpoints.updateEndpoint,
      { id: entity.id as string },
      entity
    );

    return {
      entityRaw: (body as unknown as { entity: T }).entity,
      success: statusIsSuccess,
      errors:
        (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
          ._errors || {},
    };
  };
}

function createRequestorCreate<T>(
  options: APIOptionsFull
): (entity: HttpPayload) => Promise<UnifiedResponse<T>> {
  return async (entity: HttpPayload) => {
    const { statusIsSuccess, body } = await doRequest(
      HttpMethod.POST,
      options.endpoints.createEndpoint,
      {},
      entity
    );

    return {
      entityRaw: (body as unknown as { entity: T }).entity,
      success: statusIsSuccess,
      errors:
        (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
          ._errors || {},
    };
  };
}

function createRequestorDestroy(
  options: APIOptionsFull
): (id: PrimaryKey) => Promise<UnifiedBaseResponse> {
  return async (id: PrimaryKey) => {
    const { statusIsSuccess } = await doRequest(
      HttpMethod.DELETE,
      options.endpoints.destroyEndpoint,
      { id },
      {}
    );

    return {
      success: statusIsSuccess,
      errors: {},
    };
  };
}

//////////////////////////////////////////////////////////////////////////////
// Helper functions
////

function ensureFullEndpoints(
  endpoints: APIEndpoints | APIEndpointsFull
): APIEndpointsFull {
  return {
    ...endpoints,
    updateEndpoint: endpoints.updateEndpoint ?? endpoints.showEndpoint,
    createEndpoint: endpoints.createEndpoint ?? endpoints.indexEndpoint,
    destroyEndpoint: endpoints.destroyEndpoint ?? endpoints.showEndpoint,
  };
}

//////////////////////////////////////////////////////////////////////////////
// Exporting / Assembling
////

export function requestGenericSettings<T>(
  options: APIOptions
): APICollection<T> {
  const sanitizedOptions = {
    ...options,
    endpoints: ensureFullEndpoints(options.endpoints),
  };

  return {
    requestIndex: createRequestorIndex<T>(sanitizedOptions),
    requestShow: createRequestorShow<T>(sanitizedOptions),
    requestUpdate: createRequestorUpdate<T>(sanitizedOptions),
    requestCreate: createRequestorCreate<T>(sanitizedOptions),
    requestDestroy: createRequestorDestroy(sanitizedOptions),
  };
}
