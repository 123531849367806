<script lang="ts" setup>
  import { computed, PropType, ref } from "vue";

  import { Absence } from "frontend/interfaces/settings/absence";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { createAbsence } from "frontend/api/application/settings/request-absence";
  import { httpPayloadAbsence } from "frontend/parser/settings/parse-absence";

  import FormInput from "frontend/components/form/FormInput.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  const props = defineProps({
    ...useAsModalChildProps(),
    absence: {
      type: Object as PropType<Absence>,
      required: true,
    },
  });

  const isLoading = ref<boolean>(false);

  const requireConfirmation = computed(
    () => props.absence.meta.warnings.length > 0
  );
  const confirmationValue = ref<string>("");
  const confirmationCorrect = computed(
    () =>
      confirmationValue.value.toString() ===
      props.absence.meta.affected_appointments_count.toString()
  );

  // actions
  const doCancel = () => {
    props.asModalChild?.doClose(false);
  };
  const doConfirmAbsence = async () => {
    if (!props.asModalChild) return;
    if (requireConfirmation.value && !confirmationCorrect.value) return;

    try {
      isLoading.value = true;

      const result = await createAbsence(httpPayloadAbsence(props.absence));
      if (result.success) {
        props.asModalChild.doClose(true);
      }
    } finally {
      isLoading.value = false;
    }
  };
</script>

<template>
  <div class="confirm-absence">
    <h3>Abwesenheit bestätigen</h3>
    <p>
      Bei dieser Aktion werden
      <strong>{{ absence.meta.affected_appointments_count }} Termine</strong>
      gelöscht.
      <template v-if="absence.notify_patients"
        >Anschließend werden die Patienten über die gelöschten Termine
        informiert.</template
      >
    </p>
    <template v-if="absence.meta.warnings.length > 0">
      <h5>Warnungen</h5>
      Bitte beachten Sie auch folgende Warnungen vom System:
      <ul class="warnings">
        <li
          v-for="(warning, index) in absence.meta.warnings"
          v-bind:key="index"
          class="warning"
        >
          {{ warning }}
        </li>
      </ul>
    </template>
    <template v-if="requireConfirmation">
      <FormInput
        v-model="confirmationValue"
        label="Bitte geben Sie zur Sicherheit die Anzahl der betroffene Termine in das
      Feld ein"
        v-bind:disabled="confirmationCorrect"
      />
    </template>
    <div class="actions">
      <BaseButton
        class="action"
        v-bind:disabled="isLoading"
        v-on:submit="doCancel"
      >
        Abbrechen</BaseButton
      >
      <BaseButton
        v-if="!requireConfirmation || confirmationCorrect"
        class="action"
        v-bind:visual-action="!requireConfirmation"
        v-bind:visual-warn="requireConfirmation"
        v-bind:disabled="isLoading"
        v-on:submit="doConfirmAbsence"
        >Bestätigen</BaseButton
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .warning {
    color: colors.$color_confirm_absence__warning--text;

    margin-bottom: 5px;
    font-size: 13px;
  }

  .actions {
    margin-top: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
</style>
