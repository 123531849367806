import { App } from "vue";

export function setupConsole(app: App<Element>): void {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console -- intentionally and only for debug
    app.config.globalProperties.$log = console.log;
  } else {
    app.config.globalProperties.$log = () => {};
  }
}
