import { computed, reactive, markRaw } from "vue";

import {
  EventPositioner,
  IEventPositioner,
} from "shared/utils/event-positioner";
import {
  Calendar,
  CalendarCell,
  CalendarConnection,
  CalendarHeader,
  CalendarLayer,
  CalendarNote,
  CalendarRow,
} from "frontend/interfaces/calendar";
import { TmpUnit, Unit } from "frontend/interfaces/unit";
import { Person } from "frontend/interfaces/person";
import {
  calculateOpenTimes,
  calculateTimescaleBoundaries,
  calculateTimeSteps,
  tmpUnitBoundaries,
} from "frontend/utils/base-calendar-utils";
import { ConstantsDayView } from "frontend/utils/constants";
import { Interval } from "shared/interfaces/interval";
import { cell, interval } from "frontend/uses/abstract-view/keys-and-labels";
import {
  attendances,
  units,
  tmpUnits,
  visuals,
} from "frontend/uses/abstract-view/layers";
import { mergeDates } from "shared/utils/date-utils";
import {
  onClickCreateClickUnit,
  setupClickUnit,
} from "frontend/uses/abstract-view/entries/tmp-units";
import {
  profiledCalendarConstants,
  profiledTimescaleBoundariesOptions,
} from "frontend/utils/profile-helper";
import { useStore as useClipboardStore } from "frontend/stores/clipboard";
import {
  ConditionalContextMenuEntry,
  ContextMenuAPI,
} from "shared/utils/context-menu/interfaces";
import { higherOrderUnitOnDrop } from "frontend/uses/abstract-view/entries/units";
import {
  ContextMenuCategory,
  ContextMenuPositions,
} from "shared/utils/context-menu/positions";

import SimpleEntry from "shared/utils/context-menu/components/SimpleEntry.vue";

export type AbstractViewOptions = object;

export interface AbstractViewData {
  units: Array<Unit>;
  persons: Array<Person>;
  dates: Array<Date>;
}

export interface AbstractViewMethods<T> {
  // what are considered columns in the view?
  calculateColumnPivots: (
    database: Database,
    options: AbstractViewOptions
  ) => Array<T>;

  // take a column(-pivot) and generate a header from it
  calculateColumnHeader: (
    pivot: T,
    database: Database,
    options: AbstractViewOptions
  ) => CalendarHeader;

  calculateColumnNote: (
    pivot: T,
    database: Database,
    options: AbstractViewOptions
  ) => Array<CalendarNote>;
  // default implementation: first entry of "dates"
  // should return the date which should be used
  // to calculate the timesteps
  // usually the default implementation is fine.
  getReferenceDateForTimesteps?: (
    database: Database,
    options: AbstractViewOptions
  ) => Date;

  // gets the interval (in reference date) and the pivot
  // and should return the person, an (real date) interval
  // for the cell and a unique key for the cell (for v-for)
  calculateCellAt: (
    pivot: T,
    intervalReferenceDate: Interval,
    columnIntervalReferenceDate: Interval,
    database: Database,
    options: AbstractViewOptions
  ) => {
    person: Person;
    interval: Interval;
    columnInterval: Interval;
    key: string;
  };
}

export interface Database {
  connection: CalendarConnection | null;
  units: Unit[];
  persons: Person[];
  dates: Date[];
  clickUnit: TmpUnit | null;
  positionerFor: (person: Person) => IEventPositioner;
}

export interface AbstractViewCellPosition {
  firstRow: boolean;
  lastRow: boolean;
  firstColumn: boolean;
  lastColumn: boolean;
}

export function useAbstractViewParser<ColumnType>(
  data: AbstractViewData,
  methods: AbstractViewMethods<ColumnType>,
  options: AbstractViewOptions
): Calendar {
  const positioners: Map<Person, IEventPositioner> = new Map();
  const database: Database = reactive({
    connection: null,
    ...data,
    clickUnit: setupClickUnit(data, options),
    positionerFor: (person: Person) => {
      const positioner = positioners.get(person) || new EventPositioner();
      positioners.set(person, positioner);
      return positioner;
    },
  });

  const columnPivots = computed(() =>
    methods.calculateColumnPivots(database, options)
  );

  return reactive({
    columnHeaders: computed(() =>
      columnPivots.value.map((columnPivot) =>
        methods.calculateColumnHeader(columnPivot, database, options)
      )
    ),
    columnNotes: computed(() =>
      columnPivots.value.map((columnPivot) =>
        methods.calculateColumnNote(columnPivot, database, options)
      )
    ),
    rows: computed(() => {
      positioners.clear();
      return otfRows(columnPivots.value, database, options, methods);
    }),

    onCalendarInit: (connection: CalendarConnection) => {
      database.connection = connection;
    },
  });
}

function otfRows<ColumnType>(
  columnPivots: Array<ColumnType>,
  database: Database,
  options: AbstractViewOptions,
  methods: AbstractViewMethods<ColumnType>
): Array<CalendarRow> {
  const { columnIntervalReferenceDate, timeSteps } = otfCalculateTimeSteps(
    database,
    options,
    methods
  );
  return timeSteps.map((timeStep, index) => ({
    ...interval(timeStep),
    cells: otfCalculateCells(
      columnPivots,
      timeStep,
      columnIntervalReferenceDate,
      {
        firstRow: index === 0,
        lastRow: index === timeSteps.length - 1,
      },
      database,
      options,
      methods
    ),
  }));
}

function otfCalculateCells<ColumnType>(
  columnPivots: Array<ColumnType>,
  intervalReferenceDate: Interval,
  columnIntervalReferenceDate: Interval,
  cellPosition: Omit<AbstractViewCellPosition, "firstColumn" | "lastColumn">,
  database: Database,
  options: AbstractViewOptions,
  methods: AbstractViewMethods<ColumnType>
): Array<CalendarCell> {
  return columnPivots.map((columnPivot, index) => {
    const { person, interval, columnInterval, key } = methods.calculateCellAt(
      columnPivot,
      intervalReferenceDate,
      columnIntervalReferenceDate,
      database,
      options
    );

    return {
      ...cell(key, interval),
      interval,
      layers: otfCalculateLayers(
        person,
        interval,
        columnInterval,
        {
          ...cellPosition,
          firstColumn: index === 0,
          lastColumn: index === columnPivots.length - 1,
        },
        database,
        options
      ),
      onClick: onClickCreateClickUnit(
        person,
        interval,
        columnInterval,
        database,
        options
      ),
      columnPivot,
      ctxPasteAppointment: ctxPasteAppointment(
        interval,
        columnInterval,
        database,
        options
      ),
    };
  });
}

function otfCalculateTimeSteps<ColumnType>(
  database: Database,
  options: AbstractViewOptions,
  methods: AbstractViewMethods<ColumnType>
): {
  timeSteps: Interval[];
  columnIntervalReferenceDate: Interval;
} {
  const baseDate = otfReferenceDate<ColumnType>(database, options, methods);

  const allDates = database.persons
    .flatMap((person) => person.attendances)
    .flatMap((attendance) => [attendance.from, attendance.to])
    .concat(
      database.units.flatMap((unit) => [unit.visual.from, unit.visual.to])
    )
    .concat(Object.values(calculateOpenTimes(baseDate)));
  if (database.clickUnit) {
    allDates.push(database.clickUnit.from);
    allDates.push(database.clickUnit.to);
  }
  const { minDate, maxDate } = calculateTimescaleBoundaries(
    baseDate,
    allDates.map((date) => mergeDates(baseDate, date)),
    tmpUnitBoundaries(database.clickUnit, baseDate),
    profiledCalendarConstants(ConstantsDayView),
    profiledTimescaleBoundariesOptions()
  );

  const timeSteps = calculateTimeSteps(
    minDate,
    maxDate,
    profiledCalendarConstants(ConstantsDayView).TIMESCALE_STEP_SIZE
  );

  // reflect rounded timesteps
  const columnIntervalReferenceDate = {
    from: timeSteps[0]?.from ?? minDate,
    to: timeSteps[timeSteps.length - 1]?.to ?? maxDate,
  };

  return {
    timeSteps,
    columnIntervalReferenceDate,
  };
}

export function otfCalculateLayers(
  person: Person,
  interval: Interval,
  columnInterval: Interval,
  cellPosition: AbstractViewCellPosition,
  database: Database,
  options: AbstractViewOptions
): Array<CalendarLayer> {
  return [
    attendances(
      person,
      interval,
      columnInterval,
      cellPosition,
      database,
      options
    ),
    visuals(person, interval, columnInterval, cellPosition, database, options),
    units(person, interval, columnInterval, cellPosition, database, options),
    tmpUnits(person, interval, columnInterval, cellPosition, database, options),
  ];
}

function otfReferenceDate<ColumnType>(
  database: Database,
  options: AbstractViewOptions,
  methods: AbstractViewMethods<ColumnType>
): Date {
  return methods.getReferenceDateForTimesteps
    ? methods.getReferenceDateForTimesteps(database, options)
    : (database.dates[0] ?? new Date());
}

function ctxPasteAppointment(
  interval: Interval,
  columnInterval: Interval,
  database: Database,
  options: AbstractViewOptions
): ConditionalContextMenuEntry {
  const clipboardStore = useClipboardStore();
  return {
    condition: computed(() => !!clipboardStore.getUnit()),
    entry: {
      target: computed(() => null),
      component: markRaw(SimpleEntry),
      position: ContextMenuPositions.pasteAppointment,
      category: ContextMenuCategory.appointmentActions,
      onTrigger: async (api: ContextMenuAPI) => {
        const unit = clipboardStore.getUnit();
        if (!unit || !api.eventPosition || !api.eventElement) return;
        higherOrderUnitOnDrop(
          unit,
          unit.participations[0].person_id,
          unit.participations[0].person_name,
          interval,
          columnInterval,
          database,
          options
        )(api.eventElement, api.eventPosition);
        clipboardStore.clearUnit();
        api.doClose();
      },
      options: reactive({
        iconName: computed(() => null),
        label: "Termin Einfügen",
      }),
    },
  };
}
