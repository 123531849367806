import { App } from "vue";
import { createPinia } from "pinia";

import { id as LoginStoreId } from "frontend/stores/login";
import { id as NewsStoreId } from "frontend/stores/news";
import { id as ProfileStoreId } from "frontend/stores/profile";
import { piniaPluginPersist } from "shared/utils/pinia-plugin-persist";

export function setupPinia(app: App<Element>): void {
  const pinia = createPinia();

  pinia.use(piniaPluginPersist(LoginStoreId, ["loginStatus"]));
  pinia.use(piniaPluginPersist(NewsStoreId, ["highestNewsIdSeen"]));
  pinia.use(
    piniaPluginPersist(ProfileStoreId, [
      "profile",
      "officeProfile",
      "operatorProfile",
    ])
  );

  app.use(pinia);
}
