import { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

import { useStore } from "frontend/stores/office";

/**
 * Navigation Guard
 * Checks if the user is authenticated and redirects him if not.
 *
 * @export
 * @param {RouteLocationNormalized} to
 * @returns {(RouteLocationRaw | void)}
 */
export async function navigationGuardRequireTemplating(
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | void> {
  const store = useStore();
  if (
    to.meta &&
    to.meta.requiresTemplatingAvailable &&
    !store.isTemplatingAvailable
  ) {
    return {
      name: "not-found",
    };
  }
}
