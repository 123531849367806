<script lang="ts">
  import { defineComponent } from "vue";

  import BaseModal from "frontend/components/base/BaseModal.vue";

  export default defineComponent({
    components: { BaseModal },
    inheritAttrs: false,
    props: {
      title: {
        type: String,
        default: null,
      },
      content: {
        type: String,
        default: null,
      },
    },
    emits: ["_beforeOpen", "_closed"],
  });
</script>

<template>
  <BaseModal v-bind="$attrs">
    <h3 v-if="title">{{ title }}</h3>
    <div v-if="content" class="simple-modal__content">{{ content }}</div>
  </BaseModal>
</template>

<style lang="scss" scoped>
  .simple-modal__content {
    margin-bottom: 20px;
  }
</style>
