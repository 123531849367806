import { v4 as uuid } from "uuid";
import { parseISO } from "date-fns";

import { HttpPayload } from "frontend/interfaces/http";
import {
  SettingsProfile,
  SettingsProfileRaw,
  SettingsProfileNew,
} from "frontend/interfaces/settings/settings-profile";
import {
  SettingsProfileAttributes,
  SettingsProfileEnumCalendarDefaultViews,
} from "shared/static/model-attributes.ts.erb";
import { DateFormat, formatDate } from "shared/utils/date-utils";

export function parseSettingsProfile(
  settingsProfile: SettingsProfileRaw
): SettingsProfile {
  return {
    ...settingsProfile,
    // duplicate array value to mitigate copy by reference
    calendar_shown_weekdays: [...settingsProfile.calendar_shown_weekdays],
    calendar_sorted_persons: [...settingsProfile.calendar_sorted_persons],
    calendar_removed_persons: [...settingsProfile.calendar_removed_persons],

    calendar_range_begin_local_as_utc:
      settingsProfile.calendar_range_begin_local_as_utc
        ? parseISO(settingsProfile.calendar_range_begin_local_as_utc)
        : null,
    calendar_range_end_local_as_utc:
      settingsProfile.calendar_range_end_local_as_utc
        ? parseISO(settingsProfile.calendar_range_end_local_as_utc)
        : null,
  };
}

export function httpPayloadSettingsProfile(
  settingsProfile: SettingsProfile
): HttpPayload {
  return {
    ...settingsProfile,
    // duplicate array value to mitigate copy by reference
    calendar_shown_weekdays: [...settingsProfile.calendar_shown_weekdays],
    calendar_sorted_persons: [...settingsProfile.calendar_sorted_persons],
    calendar_removed_persons: [...settingsProfile.calendar_removed_persons],

    calendar_range_begin_local_as_utc:
      settingsProfile.calendar_range_begin_local_as_utc
        ? formatDate(
            settingsProfile.calendar_range_begin_local_as_utc,
            DateFormat.ISO
          )
        : null,
    calendar_range_end_local_as_utc:
      settingsProfile.calendar_range_end_local_as_utc
        ? formatDate(
            settingsProfile.calendar_range_end_local_as_utc,
            DateFormat.ISO
          )
        : null,
  };
}

export function newSettingsProfile(): SettingsProfileNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    is_office_default: false,
    unsaved: true,

    calendar_timescale_in_minutes:
      SettingsProfileAttributes.calendar_timescale_in_minutes.default_value,
    calendar_show_visual_lines:
      SettingsProfileAttributes.calendar_show_visual_lines.default_value,
    calendar_default_view:
      SettingsProfileEnumCalendarDefaultViews[
        SettingsProfileAttributes.calendar_default_view.default_value
      ],
    calendar_shown_weekdays: [
      // duplicate array value to mitigate copy by reference
      ...SettingsProfileAttributes.calendar_shown_weekdays.default_value,
    ],
    calendar_sorted_persons: [
      ...SettingsProfileAttributes.calendar_sorted_persons.default_value,
    ],
    calendar_removed_persons: [
      ...SettingsProfileAttributes.calendar_removed_persons.default_value,
    ],

    unit_show_appointment_type_name:
      SettingsProfileAttributes.unit_show_appointment_type_name.default_value,
    unit_show_patient_name:
      SettingsProfileAttributes.unit_show_patient_name.default_value,
    unit_show_room: SettingsProfileAttributes.unit_show_room.default_value,
    unit_show_internal_office_note:
      SettingsProfileAttributes.unit_show_internal_office_note.default_value,

    calendar_range_begin_local_as_utc: null,
    calendar_range_end_local_as_utc: null,
    unit_show_patient_message:
      SettingsProfileAttributes.unit_show_patient_message.default_value,
  };
}
