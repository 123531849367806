import { setupEvents as setupEventsTranslation } from "frontend/events/translate-events";
import { setupEvents as setupEventsLoginStore } from "frontend/stores/login";
import { setupEvents as setupEventsOfficeStore } from "frontend/stores/office";
import { setupEvents as setupEventsPatientStore } from "frontend/stores/patient";
import { setupEvents as setupEventsProfileStore } from "frontend/stores/profile";
import { setupEvents as setupEventsRequestCache } from "frontend/utils/request-cache";

export function setupEvents() {
  setupEventsTranslation();
  setupEventsLoginStore();
  setupEventsOfficeStore();
  setupEventsPatientStore();
  setupEventsProfileStore();
  setupEventsRequestCache();
}
