import { compareAsc, parseISO } from "date-fns";

import { Suggestions, SuggestionsRaw } from "frontend/interfaces/suggestions";

export function parseSuggestions(suggestions: SuggestionsRaw): Suggestions {
  return {
    search_ref: suggestions.search_ref,
    found_on_days: suggestions.found_on_days.map((day) => parseISO(day)),
    reserved_until: parseISO(suggestions.reserved_until),
    searched_from: parseISO(suggestions.searched_from),
    searched_to: parseISO(suggestions.searched_to),
    suggestion_list: suggestions.suggestion_list.map((appointment) => ({
      appointment_id: appointment.appointment_id,
      begin: parseISO(appointment.begin),
      begin_for_patient: parseISO(appointment.begin_for_patient),
      units: appointment.units
        .map((unit) => ({
          ...unit,
          from: parseISO(unit.from),
        }))
        .sort((a, b) => compareAsc(a.from, b.from)),
    })),
  };
}
