<script lang="ts">
  import { defineComponent } from "vue";

  import { ScheduleActivationsSettingsManager } from "frontend/uses/settings/manager/schedule-activations-settings-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new ScheduleActivationsSettingsManager().provide();
    },
  });
</script>

<template>
  <div class="settings-schedule-activations">
    <h2>Aktivierung von Zeitplänen</h2>
    <BaseHelp>
      <p>
        Beim aktivieren eines Zeitplans muss ein Gültigkeitsintrerval festgelegt
        werden. Ein Zeitplan kann für meherere Intervale eingesetzt werden.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
