/* rails-erb-loader-dependencies ../config/routes.rb objects/utils/praxisapp.rb */

export const praxisappBase = "https://praxisapp.monks-aerzte-im-netz.de";

export const praxisappPaths = Object.freeze({
  
    login: "/d/otv_login.json",
  
});

export const applicationBase =
  "https://staging.terminverwaltung.monks-aerzte-im-netz.de/";

export const applicationPaths = Object.freeze({
  add_user_frontend_patient_path: "/frontend/patients/:id/add_user",
  admin_appointment_import_path: "/admin/appointment_imports/:id",
  admin_appointment_import_parser_path: "/admin/appointment_import_parsers/:id",
  admin_appointment_import_parsers_path: "/admin/appointment_import_parsers",
  admin_appointment_imports_path: "/admin/appointment_imports",
  admin_comments_path: "/admin/comments",
  admin_dashboard_path: "/admin/dashboard",
  admin_email_path: "/admin/emails/:id",
  admin_email_sendings_path: "/admin/email_sendings",
  admin_emails_path: "/admin/emails",
  admin_feature_path: "/admin/features/:id",
  admin_features_path: "/admin/features",
  admin_office_path: "/admin/offices/:id",
  admin_offices_path: "/admin/offices",
  admin_root_path: "/admin",
  admin_tag_path: "/admin/tags/:id",
  admin_tags_path: "/admin/tags",
  api_path: "/api/v0/:a",
  api_insurances_path: "/api/v1/insurances",
  api_offices_path: "/api/v1/offices",
  api_patient_path: "/api/v1/patients/:id",
  block_frontend_office_path: "/frontend/office/block",
  bulk_api_patients_path: "/api/v1/patients/bulk",
  by_external_id_frontend_patients_path: "/frontend/patients/by_external_id/:external_id",
  confirm_frontend_appointments_path: "/frontend/appointments/confirm",
  confirm_frontend_p_suggestions_path: "/frontend/p/suggestions/confirm",
  confirm_move_frontend_appointments_path: "/frontend/appointments/confirm_move",
  create_configuration_frontend_settings_pvs_path: "/frontend/settings/pvs/create/configuration",
  create_es_index_admin_office_path: "/admin/offices/:id/create_es_index",
  current_config_frontend_settings_pvs_path: "/frontend/settings/pvs/current_config",
  current_frontend_patients_path: "/frontend/patients/current",
  day_view_frontend_calendar_path: "/frontend/calendar/day_view",
  destroy_user_session_path: "/frontend/users/sign_out.json",
  discard_frontend_appointments_path: "/frontend/appointments/discard/:search_ref",
  discard_frontend_p_suggestions_path: "/frontend/p/suggestions/discard/:search_ref",
  download_package_frontend_settings_pvs_path: "/frontend/settings/pvs/download_package/:configuration_id",
  duplicate_frontend_settings_schedules_path: "/frontend/settings/schedules/duplicate",
  edit_admin_feature_path: "/admin/features/:id/edit",
  frontend_appointment_path: "/frontend/appointments/:id",
  frontend_appointment_toggle_status_path: "/frontend/appointments/:appointment_id/toggle_status",
  frontend_appointment_types_path: "/frontend/appointment_types",
  frontend_appointments_path: "/frontend/appointments",
  frontend_attendance_path: "/frontend/attendances/:id",
  frontend_attendances_path: "/frontend/attendances",
  frontend_calendar_path: "/frontend/calendar",
  frontend_color_path: "/frontend/colors/:id",
  frontend_colors_path: "/frontend/colors",
  frontend_competence_path: "/frontend/competences/:id",
  frontend_competences_path: "/frontend/competences",
  frontend_event_entries_path: "/frontend/event_entries",
  frontend_holidays_path: "/frontend/holidays",
  frontend_insurances_path: "/frontend/insurances",
  frontend_location_path: "/frontend/locations/:id",
  frontend_locations_path: "/frontend/locations",
  frontend_occupancies_path: "/frontend/occupancies",
  frontend_occupancy_path: "/frontend/occupancies/:id",
  frontend_offer_path: "/frontend/offers/:id",
  frontend_offers_path: "/frontend/offers",
  frontend_office_path: "/frontend/office",
  frontend_operators_path: "/frontend/operators",
  frontend_p_appointment_path: "/frontend/p/appointments/:id",
  frontend_p_appointment_types_path: "/frontend/p/appointment_types",
  frontend_p_appointments_path: "/frontend/p/appointments",
  frontend_p_persons_path: "/frontend/p/persons",
  frontend_p_suggestions_path: "/frontend/p/suggestions",
  frontend_patient_path: "/frontend/patients/:id",
  frontend_patients_path: "/frontend/patients",
  frontend_person_path: "/frontend/persons/:id",
  frontend_persons_path: "/frontend/persons",
  frontend_role_path: "/frontend/roles/:id",
  frontend_roles_path: "/frontend/roles",
  frontend_room_path: "/frontend/rooms/:id",
  frontend_rooms_path: "/frontend/rooms",
  frontend_searches_path: "/frontend/searches",
  frontend_settings_absences_path: "/frontend/settings/absences",
  frontend_settings_appointment_type_path: "/frontend/settings/appointment_types/:id",
  frontend_settings_appointment_types_path: "/frontend/settings/appointment_types",
  frontend_settings_attendance_path: "/frontend/settings/attendances/:id",
  frontend_settings_attendances_path: "/frontend/settings/attendances",
  frontend_settings_operator_path: "/frontend/settings/operators/:id",
  frontend_settings_operators_path: "/frontend/settings/operators",
  frontend_settings_profiles_path: "/frontend/settings_profiles",
  frontend_settings_reminder_path: "/frontend/settings/reminders/:id",
  frontend_settings_reminders_path: "/frontend/settings/reminders",
  frontend_settings_schedule_path: "/frontend/settings/schedules/:id",
  frontend_settings_schedule_activation_path: "/frontend/settings/schedule_activations/:id",
  frontend_settings_schedule_activations_path: "/frontend/settings/schedule_activations",
  frontend_settings_schedules_path: "/frontend/settings/schedules",
  frontend_settings_settings_profile_path: "/frontend/settings/settings_profiles/:id",
  frontend_settings_settings_profiles_path: "/frontend/settings/settings_profiles",
  frontend_task_path: "/frontend/tasks/:id",
  frontend_tasks_path: "/frontend/tasks",
  frontend_templates_path: "/frontend/templates",
  frontend_unit_path: "/frontend/units/:id",
  frontend_units_path: "/frontend/units",
  import_admin_appointment_import_path: "/admin/appointment_imports/:id/import",
  load_frontend_templates_path: "/frontend/templates/load",
  login_status_frontend_misc_path: "/frontend/misc/login_status",
  mark_as_seen_frontend_event_entries_path: "/frontend/event_entries/mark_as_seen",
  merge_frontend_patient_path: "/frontend/patients/:id/merge_from/:src_patient_id",
  new_admin_feature_path: "/admin/features/new",
  new_user_session_path: "/frontend/users/sign_in",
  preview_frontend_settings_absences_path: "/frontend/settings/absences/preview",
  rails_blob_representation_path: "/rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/:filename",
  rails_blob_representation_proxy_path: "/rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/:filename",
  rails_direct_uploads_path: "/rails/active_storage/direct_uploads",
  rails_disk_service_path: "/rails/active_storage/disk/:encoded_key/:filename",
  rails_service_blob_path: "/rails/active_storage/blobs/redirect/:signed_id/:filename",
  rails_service_blob_proxy_path: "/rails/active_storage/blobs/proxy/:signed_id/:filename",
  remove_pvs_users_frontend_patient_path: "/frontend/patients/:id/remove_pvs_users",
  revert_import_admin_appointment_import_path: "/admin/appointment_imports/:id/revert_import",
  root_path: "/",
  schedules_admin_dashboard_path: "/admin/dashboard/schedules",
  select_frontend_holidays_path: "/frontend/holidays/select",
  select_frontend_operator_path: "/frontend/operators/:id/select",
  sidekiq_web_path: "/sidekiq",
  sort_frontend_settings_appointment_types_path: "/frontend/settings/appointment_types/sort",
  sso_office_path: "/sso/office",
  suggestions_frontend_appointments_path: "/frontend/appointments/suggestions",
  unblock_frontend_office_path: "/frontend/office/unblock",
  update_configuration_frontend_settings_pvs_path: "/frontend/settings/pvs/update/configuration/:configuration_id",
  update_pvs_system_frontend_settings_pvs_path: "/frontend/settings/pvs/update/pvs_system/:pvs_system_id",
  update_rails_disk_service_path: "/rails/active_storage/disk/:encoded_token",
  user_session_path: "/frontend/users/sign_in.json",
  user_zugang_telemedizin_omniauth_authorize_path: "/frontend/users/auth/zugang_telemedizin",
  user_zugang_telemedizin_omniauth_callback_path: "/frontend/users/auth/zugang_telemedizin/callback",
});
;
