import { Ref } from "vue";

import { useObserver } from "frontend/uses/use-observer";

export function useResponsiveness(
  callback: (info: ResizeObserverEntry) => void,
  element?: Ref<Element | null>
) {
  return useObserver(ResizeObserver, callback, element);
}
