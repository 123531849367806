import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import { formatDate, DateFormat } from "shared/utils/date-utils";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { SuggestionRaw } from "frontend/interfaces/p/suggestion";
import { PathParams } from "shared/utils/pathify";

export async function requestSuggestions(
  appointmentTypeID: PrimaryKey,
  search_from: Date,
  options?: { person_id?: PrimaryKey; search_ref?: PrimaryKey | null }
): Promise<{
  suggestions: Array<SuggestionRaw>;
  search_ref: PrimaryKey | null;
  days_searched: Array<string>;
  reserved_until: string | null;
}> {
  const params: PathParams = {
    appointment_type_id: appointmentTypeID,
    search_from: formatDate(search_from, DateFormat.DateOnlyISO),
  };
  if (options?.person_id) params.person_id = options.person_id;
  if (options?.search_ref) params.search_ref = options.search_ref;

  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_p_suggestions_path,
    params,
    {}
  );

  if (statusIsSuccess) {
    const bodyTyped = body as unknown as {
      search_ref: PrimaryKey;
      suggestions: Array<SuggestionRaw>;
      days_searched: Array<string>;
      reserved_until: string;
    };

    return {
      suggestions: bodyTyped.suggestions,
      search_ref: bodyTyped.search_ref,
      days_searched: bodyTyped.days_searched,
      reserved_until: bodyTyped.reserved_until,
    };
  } else {
    return {
      suggestions: [],
      search_ref: null,
      days_searched: [],
      reserved_until: null,
    };
  }
}

export async function confirmSuggestion(
  appointmentID: PrimaryKey,
  patientMessage: string
): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.confirm_frontend_p_suggestions_path,
    {
      appointment_id: appointmentID,
      message: patientMessage,
    },
    {}
  );

  return statusIsSuccess;
}

export async function discardSuggestions(searchRef: PrimaryKey): Promise<void> {
  await doRequest(
    HttpMethod.POST,
    routes.paths.discard_frontend_p_suggestions_path,
    {
      search_ref: searchRef,
    },
    {}
  );
}
