import { ComputedRef, ref, Ref } from "vue";

import { useDayViewParser } from "frontend/uses/abstract-view/use-day-view-parser";
import { DayViewRaw } from "frontend/interfaces/day-view";
import { Calendar } from "frontend/interfaces/calendar";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { useAbstractView } from "frontend/uses/abstract-view/use-abstract-view";
import { requestWeekView } from "frontend/api/application/request-week-view";

export function useDayView(
  day: Ref<Date | null>,
  options?: {
    searchRef: Ref<PrimaryKey | null>;
  }
): {
  date: ComputedRef<Date | null>;
  calendar: Ref<Calendar | null>;
  triggerReload: () => void;
} {
  return useAbstractView(
    day,
    ref<PrimaryKey | null>(null),
    (date: Date, dayView: DayViewRaw) => useDayViewParser(date, dayView),
    async (date, searchRef) => {
      const { weekView } = await requestWeekView(date, date, "", searchRef);
      return weekView;
    },
    options
  );
}
