export interface MiniCalendarDay {
  key: string;
  label: string;
  date: Date;
  visual: MiniCalendarDayVisual;
  highlighted: boolean;
}

export interface MiniCalendarWeek {
  key: string;
  days: Array<MiniCalendarDay>;
}

export interface MiniCalendar {
  headers: Array<string>;
  weeks: Array<MiniCalendarWeek>;
}

export enum MiniCalendarDayVisual {
  Selected,
  Normal,
  Light,
}
