<script lang="ts" setup>
  import { computed, onMounted, PropType, ref, watch } from "vue";
  import { useRouter } from "vue-router";
  import { compareAsc } from "date-fns";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { requestWeekView } from "frontend/api/application/request-week-view";
  import { Unit } from "frontend/interfaces/unit";
  import { parseUnit } from "frontend/parser/parse-unit";
  import { isActive } from "shared/utils/bitwise-enum";
  import { AppointmentStatus } from "shared/static/enums.ts.erb";
  import { useFilter } from "frontend/uses/filter/use-filter";
  import { simpleCheckboxGroup } from "frontend/uses/filter/predefined/checkbox";
  import { simpleRow } from "frontend/uses/filter/predefined/flex";
  import { Cache } from "frontend/utils/request-cache";
  import { Person } from "frontend/interfaces/person";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { requestAppointmentTypes } from "frontend/api/application/request-appointment-types";
  import { parseAppointmentType } from "frontend/parser/parse-appointment-type";
  import { AppointmentType } from "frontend/interfaces/appointment-type";
  import { Room } from "frontend/interfaces/room";
  import {
    isFirstDay,
    unitInterval,
    unitOrderTime,
    unitDescription,
    unitNote,
    createPdf,
  } from "frontend/utils/appointment-list-utils";

  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import BaseListItem from "frontend/components/base/BaseListItem.vue";
  import FilterContainer from "frontend/components/filter/FilterContainer.vue";

  const props = defineProps({
    ...useAsModalChildProps(),
    start: {
      type: Object as PropType<Date>,
      required: true,
    },
    end: {
      type: Object as PropType<Date>,
      required: true,
    },
  });
  const startDate = ref<Date>(props.start);
  const endDate = ref<Date>(props.end);

  const units = ref<Array<Unit>>([]);
  const loadUnits = async () => {
    const { weekView } = await requestWeekView(
      startDate.value,
      endDate.value,
      "",
      null
    );
    if (weekView)
      units.value = weekView.units
        .filter((unit) => unit.unit_type !== 1)
        .map((unit) => parseUnit(unit))
        .sort((a, b) => compareAsc(a.from, b.from));
  };

  const persons = ref<Person[]>([]);
  const loadPersons = async () => {
    persons.value = await Cache.getCachedPersons();
  };

  const appointmentTypes = ref<AppointmentType[]>([]);
  const loadAppointmentTypes = async () => {
    const data = await requestAppointmentTypes();
    appointmentTypes.value = data.appointment_types.map(parseAppointmentType);
  };

  const rooms = ref<Room[]>([]);
  const loadRooms = async () => {
    rooms.value = await Cache.getCachedRooms();
  };

  onMounted(() => {
    loadUnits();
    loadPersons();
    loadAppointmentTypes();
    loadRooms();
  });

  const hoveredUnit = ref<Unit | null>(null);
  const isHovered = (unit: Unit) => {
    if (!hoveredUnit.value || !unit.appointment_id) return false;
    return hoveredUnit.value.appointment_id === unit.appointment_id;
  };
  const onHover = (hover: boolean, unit: Unit) => {
    hoveredUnit.value = hover ? unit : null;
  };
  const isHighlighted = (unit: Unit) => {
    if (!unit.appointment) return false;
    return (
      isActive(unit.appointment.status, AppointmentStatus.patient_arrived) &&
      !isActive(unit.appointment.status, AppointmentStatus.done)
    );
  };
  const isCheckmark = (unit: Unit) => {
    if (!unit.appointment) return false;
    return isActive(unit.appointment.status, AppointmentStatus.done);
  };

  const isPatientNotArrived = (unit: Unit) => {
    if (!unit.appointment) return false;
    return isActive(
      unit.appointment.status,
      AppointmentStatus.patient_not_arrived
    );
  };

  watch([startDate, endDate], () => loadUnits());

  //FILTER
  const { filteredCollection, filterContainerBinding } = useFilter(units, [
    simpleRow([
      simpleCheckboxGroup(
        {
          frontend: (entry: Unit, selectedIDs: Array<PrimaryKey>) =>
            selectedIDs.length === 0 ||
            selectedIDs.indexOf(entry.participations[0].person_id) >= 0,
        },
        {
          label: "Mitarbeiter",
          options: computed(() =>
            persons.value.map((p) => ({
              id: p.id,
              label: p.name,
            }))
          ),
        }
      ),
      simpleCheckboxGroup(
        {
          frontend: (entry: Unit, selectedIDs: Array<PrimaryKey>) =>
            selectedIDs.length === 0 ||
            (!!entry.appointment?.appointment_type?.id &&
              selectedIDs.indexOf(entry.appointment.appointment_type.id) >= 0),
        },
        {
          label: "Termintyp",
          options: computed(() =>
            appointmentTypes.value.map((p) => ({
              id: p.id,
              label: p.name,
            }))
          ),
        }
      ),
      simpleCheckboxGroup(
        {
          frontend: (entry: Unit, selectedIDs: Array<PrimaryKey>) =>
            selectedIDs.length === 0 ||
            (!!entry.room && selectedIDs.indexOf(entry.room.id) >= 0),
        },
        {
          label: "Raum",
          options: computed(() =>
            rooms.value.map((p) => ({
              id: p.id,
              label: p.name,
            }))
          ),
        }
      ),
    ]),
  ]);

  const router = useRouter();
  const showInCalendar = (unit: Unit) => {
    router.push({
      name: "calendar-day",
      params: { day: formatDate(unit.from, DateFormat.DateOnlyISO) },
      query: {
        highlightAppointmentId: unit.appointment_id,
      },
    });
    if (props.asModalChild) props.asModalChild.doClose(true);
  };
</script>

<template>
  <div v-bind:class="{ 'appointment-list--as-modal': asModalChild }">
    <h2>Terminliste</h2>
    <div class="appointment-list__flex-container">
      <div class="columns">
        Von:
        <FormDatetime
          v-model="startDate"
          v-bind:allow-deletion="false"
          class="columns__column"
          visual-small
        />
        Bis:
        <FormDatetime
          v-model="endDate"
          v-bind:min-date="startDate"
          v-bind:allow-deletion="false"
          class="columns__column"
          visual-small
        />
      </div>
      <i
        class="fe fe-print pdf-icon"
        v-on:click="createPdf(startDate, endDate, filteredCollection)"
      ></i>
    </div>
    <FilterContainer v-bind="filterContainerBinding" />
    <div v-if="filteredCollection.length <= 0">
      <p><i>Keine Termine vorhanden</i></p>
    </div>

    <div v-for="unit in filteredCollection" v-bind:key="unit.id">
      <div
        v-if="isFirstDay(filteredCollection, unit)"
        class="appointment-list__date"
      >
        {{ formatDate(unit.from, DateFormat.DateOnlyLong) }}
      </div>
      <BaseListItem
        v-bind:title="unitInterval(unit)"
        v-bind:subtitle="unitDescription(unit)"
        v-bind:color="unit.color ?? unit.session?.color ?? '#000000'"
        v-bind:text="unitNote(unit)"
        v-bind:class="{
          'appointment-list--highlight-background': isHighlighted(unit),
          'appointment-list--hover-on': isHovered(unit),
        }"
        calendar-action
        enable-color
        v-on:hover="(hover) => onHover(hover, unit)"
        v-on:show-item="showInCalendar(unit)"
      >
        <template v-slot:description>
          {{ unitOrderTime(unit) }}
        </template>
        <template v-slot:icons>
          <i
            v-if="isCheckmark(unit)"
            v-tooltip="'Termin ist abgeschlossen'"
            class="fe fe-checkmark appointment-list__icon"
          ></i>
          <i
            v-if="isPatientNotArrived(unit)"
            v-tooltip="'Patient nicht erschienen'"
            class="fe fe-person--off appointment-list__icon"
          ></i>
          <i
            v-if="unit.appointment?.patient.is_private_insurance"
            v-tooltip="'Patient hat private Versicherung'"
            class="fe fe-star--with-badge appointment-list__icon"
          ></i>
          <i
            v-if="unit.appointment?.patient.is_praxisapp"
            v-tooltip="'Nutzer hat PraxisApp'"
            class="fe fe-smartphone icon--has-praxisapp"
          ></i>
          <i
            v-if="unit.created_by_patient"
            v-tooltip="'Termin durch Nutzer gebucht'"
            class="fe fe-smartphone--touch appointment-list__icon"
          ></i>
        </template>
      </BaseListItem>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .appointment-list--as-modal {
    width: 90vw;
    min-height: 90vh;
  }

  .columns {
    display: flex;
    align-items: center;
  }
  .columns__column {
    width: 120px;
    margin: 0 10px;
  }

  .appointment-list__date {
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
  }

  .appointment-list__icon {
    padding: 4px 0;
    color: colors.$color_calendar__unit__icon--text;
  }
  .icon--has-praxisapp {
    padding: 4px 0;
    color: colors.$color_calendar__unit__praxisapp-icon--text;
  }
  .appointment-list--highlight-background {
    background-color: colors.$color_calendar__unit--highlight-background;
  }
  .appointment-list--hover-on {
    background-color: colors.$color_base-list__hover--background;
  }

  .appointment-list__flex-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .pdf-icon {
    margin-left: auto;
  }
</style>
