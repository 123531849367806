import { routes } from "frontend/api/application";
import { requestGenericSettings } from "frontend/api/application/settings/request-generic-settings";
import { AttendanceRaw } from "frontend/interfaces/settings/attendance";

export const api = requestGenericSettings<AttendanceRaw>({
  endpoints: {
    indexEndpoint: routes.paths.frontend_attendances_path,
    showEndpoint: routes.paths.frontend_settings_attendance_path,
    createEndpoint: routes.paths.frontend_settings_attendances_path,
  },
});
