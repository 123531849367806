import { parseISO } from "date-fns";

import { HttpPayload } from "frontend/interfaces/http";
import {
  atDefaultStartOf,
  atDefaultEndOf,
  DateFormat,
  formatDate,
  today,
} from "shared/utils/date-utils";
import {
  Absence,
  AbsenceNew,
  AbsenceRaw,
} from "frontend/interfaces/settings/absence";
import { UnitType } from "shared/static/enums.ts.erb";
import { parsePerson } from "frontend/parser/parse-person";

export function parseAbsence(absence: AbsenceRaw): Absence {
  type ValueType = (typeof UnitType)[keyof typeof UnitType];
  const reasonValues = Object.values(UnitType) as unknown[];

  return {
    ...absence,
    absent_from: parseISO(absence.absent_from),
    absent_to: parseISO(absence.absent_to),
    reason:
      reasonValues.indexOf(absence.reason) >= 0
        ? (absence.reason as ValueType)
        : 1,
    persons: absence.persons.map(parsePerson),
    meta: {
      ...absence.meta,
      warnings: [...absence.meta.warnings],
    },
  };
}

export function httpPayloadAbsence(absence: Absence): HttpPayload {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- literally unused property
    unsaved: _unused_1,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- literally unused property
    meta: _unused_2,
    ...strippedAbsence
  } = absence as AbsenceNew;
  return {
    ...strippedAbsence,
    absent_from: absence.absent_from
      ? formatDate(absence.absent_from, DateFormat.ISO)
      : null,
    absent_to: absence.absent_to
      ? formatDate(absence.absent_to, DateFormat.ISO)
      : null,
    persons: [],
  };
}

export function newAbsence(): AbsenceNew {
  return {
    person_ids: [],
    absent_from: atDefaultStartOf(today()),
    absent_to: atDefaultEndOf(today()),
    name: "",
    reason: 1,
    cancel_units: false,
    notify_patients: false,
    unsaved: true,

    persons: [],

    meta: {
      affected_appointments_count: 0,
      warnings: [],
    },
  };
}
