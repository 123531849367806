<script lang="ts">
  import { defineComponent, toRefs, ref, onMounted } from "vue";

  import { Person } from "frontend/interfaces/person";
  import { Role } from "frontend/interfaces/session";
  import { Cache } from "frontend/utils/request-cache";
  import {
    useEditComponent,
    editProps,
  } from "frontend/uses/settings/use-edit-component";
  import { useFormCheckbox } from "frontend/uses/use-form-checkbox";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import CheckboxAccordion from "frontend/components/CheckboxAccordion.vue";

  export default defineComponent({
    components: {
      FormInput,
      FormCheckboxGroup,
      BaseButton,
      CheckboxAccordion,
    },
    props: {
      ...editProps<Person>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      const roles = ref<Array<Role>>([]);
      onMounted(async () => {
        roles.value = await Cache.getCachedRoles();
      });
      const { options } = useFormCheckbox(roles, (role) => ({
        id: role.id,
        label: role.name,
      }));

      const editComponent = useEditComponent(entity.value);

      const onChangeSelectable = (newStatus: boolean) => {
        editComponent.entityEditable.value.selectable = newStatus;
        editComponent.onAutosaveFor("selectable")();
      };

      return {
        ...editComponent,

        onChangeSelectable,

        roles,
        options,
      };
    },
  });
</script>

<template>
  <div class="edit-location">
    <h5>{{ entity.name }}</h5>
    <FormInput
      v-model="entityEditable.name"
      label="Name"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <CheckboxAccordion
      label="Kann vom Patienten in der PraxisApp ausgewählt werden"
      v-bind:show="entityEditable.selectable"
      v-on:content:visibility-change="onChangeSelectable"
    >
      <FormInput
        v-model="entityEditable.shown_name"
        label="Beim Patienten angezeigter Name"
        v-bind:errors="errorFor('shown_name')"
        v-bind:animation-status="statusFor('shown_name')"
        v-on="{ autosave: onAutosaveFor('shown_name') }"
      />
    </CheckboxAccordion>
    <FormCheckboxGroup
      v-model="entityEditable.role_ids"
      label="Rollen"
      v-bind:options="options"
      v-bind:animation-status="statusFor('role_ids')"
      v-on="{ autosave: onAutosaveFor('role_ids') }"
    />
    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>
