import { routes, doRequest } from "frontend/api/application";
import { HttpMethod } from "frontend/interfaces/http";
import {
  AppointmentTypeRaw,
  AppointmentTypesRaw,
} from "frontend/interfaces/p/appointment-type";

export async function requestAppointmentTypes(): Promise<{
  appointment_types: Array<AppointmentTypeRaw>;
}> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_p_appointment_types_path,
    {},
    {}
  );

  if (statusIsSuccess) {
    return {
      appointment_types: (body as unknown as AppointmentTypesRaw)
        .appointment_types,
    };
  } else {
    return {
      appointment_types: [],
    };
  }
}
