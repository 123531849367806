import { identity } from "shared/utils/helper-functions";
/* eslint-disable no-console -- this is a logger and thus (the only location where this is) allowed*/

export enum LogLevel {
  Trace = 1,
  Debug = 2,
  Info = 3,
  Warn = 4,
  Error = 5,
  Fatal = 6,
}

const CURRENT_LOGLEVEL = LogLevel.Debug;

export function log(level: LogLevel, ...args: unknown[]): void {
  if (level < CURRENT_LOGLEVEL) return;

  switch (level) {
    case LogLevel.Trace:
    case LogLevel.Debug:
    case LogLevel.Info:
      console.log("[", LogLevel[level], "]", ...args);
      break;
    case LogLevel.Warn:
      console.warn("[", LogLevel[level], "]", ...args);
      break;
    case LogLevel.Error:
    case LogLevel.Fatal:
      console.error("[", LogLevel[level], "]", ...args);
      break;
    default:
      console.log("[", LogLevel[level], "]", ...args);
      break;
  }
}
/* eslint-enable no-console -- and reenable*/

export function identityWarn<T>(
  entity: T,
  warning: string,
  level = LogLevel.Warn
): T {
  log(level, warning);
  return identity(entity);
}
