<script lang="ts">
  import { defineComponent, inject, onMounted, ref } from "vue";

  import {
    SettingsManager,
    MinimalEntity,
  } from "frontend/uses/settings/manager/settings-manager";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FilterContainer from "frontend/components/filter/FilterContainer.vue";

  export default defineComponent({
    components: { BaseButton, FilterContainer },
    setup() {
      const settingsManager = inject(
        "settings-manager"
      ) as SettingsManager<MinimalEntity>;
      onMounted(() => {
        settingsManager.refreshEntities();
      });

      const onRefreshNeeded = () => {
        settingsManager.refreshEntities();
      };

      const entityIsOpen = ref<boolean>(false);
      const changeEntity = (accordionIsOpen: boolean) => {
        entityIsOpen.value = accordionIsOpen;
      };
      return {
        entities: settingsManager.entitiesR(),

        addNewEntry: () => {
          settingsManager.addNewEntity();
          entityIsOpen.value = true;
        },

        settingsManager,
        entityIsOpen,
        changeEntity,
        onRefreshNeeded,
      };
    },
  });
</script>

<template>
  <div class="settings-container" v-on:dragover.prevent v-on:dragenter.prevent>
    <template v-if="settingsManager">
      <FilterContainer
        v-if="settingsManager.enableFilter()"
        v-bind:filter-manager="settingsManager.getFilterManager()"
      />
      <div v-for="entity in entities" v-bind:key="entity.id">
        <component
          v-bind:is="settingsManager.componentForEdit()"
          v-bind:entity="entity"
          v-bind:draggable="
            !entityIsOpen && settingsManager.enableDragAndDrop()
          "
          v-bind:class="{
            draggable: !entityIsOpen && settingsManager.enableDragAndDrop(),
          }"
          v-on:dragstart="settingsManager.onDrag(entity)"
          v-on:drop.stop="settingsManager.onDrop(entity)"
          v-on:entity-open="changeEntity"
          v-on:refresh-needed="onRefreshNeeded"
        ></component>
      </div>
      <div
        v-if="settingsManager.enableNewEntities()"
        class="settings-container__new-entry"
      >
        <BaseButton v-on:submit="addNewEntry">
          {{ settingsManager.entityName() }} hinzufügen
        </BaseButton>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/drag";

  .settings-container__new-entry {
    margin-top: 35px;
  }

  .draggable {
    @include drag.draggable;
  }
</style>
