<script lang="ts">
  import { defineComponent, onMounted, ref } from "vue";

  import { Absence } from "frontend/interfaces/settings/absence";
  import { requestAbsences } from "frontend/api/application/settings/request-absence";
  import { parseAbsence } from "frontend/parser/settings/parse-absence";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import CreateAbsence from "frontend/components/settings/CreateAbsence.vue";
  import ShowAbsence from "frontend/components/settings/ShowAbsence.vue";

  export default defineComponent({
    components: { BaseHelp, CreateAbsence, ShowAbsence },
    setup() {
      const absences = ref<Array<Absence>>([]);
      const loadAbsences = async () => {
        absences.value = (await requestAbsences()).map(parseAbsence);
      };
      onMounted(loadAbsences);

      const onRefreshNeeded = async () => {
        await loadAbsences();
      };
      return {
        absences,
        onRefreshNeeded,
      };
    },
  });
</script>

<template>
  <h2>Abwesenheit</h2>
  <BaseHelp
    >Sollte ein Mitarbeiter z.B. krankheitsbedingt ausfallen, könne Sie die
    Fehlzeiten hier eingeben, damit sie automatisch bei der Terminbuchung
    berücksichtigt werden können.</BaseHelp
  >
  <CreateAbsence v-on:refresh-needed="onRefreshNeeded" />
  <ShowAbsence v-bind:absences="absences" />
</template>
