<script lang="ts">
  import { defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Operator } from "frontend/interfaces/settings/operator";
  import { useStore as useProfileStore } from "frontend/stores/profile";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import OperatorTag from "frontend/components/OperatorTag.vue";
  import FormSelectOfficeSettingsProfile from "frontend/components/form/FormSelectOfficeSettingsProfile.vue";

  export default defineComponent({
    components: {
      BaseButton,
      FormInput,
      OperatorTag,
      FormSelectOfficeSettingsProfile,
    },
    props: {
      ...editProps<Operator>(),
    },
    setup(props) {
      const { entity } = toRefs(props);

      const profileStore = useProfileStore();

      const editComponent = useEditComponent(entity.value, {
        onSaved: () => {
          profileStore.provideProfileForOperator(
            editComponent.entityEditable.value.id,
            profileStore.lookupProfile(
              editComponent.entityEditable.value.settings_profile_id
            )
          );
        },
      });

      return {
        ...editComponent,
      };
    },
  });
</script>

<template>
  <div class="edit-operator">
    <h5>
      <template v-if="entity.name">{{ entity.name }}</template
      ><template v-else>Praxisanwender</template>
      <OperatorTag v-bind:operator="entity" />
    </h5>
    <FormInput
      v-model="entityEditable.abbreviation"
      label="Kürzel (max. 5 Zeichen)"
      v-bind:errors="errorFor('abbreviation')"
      v-bind:animation-status="statusFor('abbreviation')"
      v-on="{ autosave: onAutosaveFor('abbreviation') }"
    />
    <FormInput
      v-model="entityEditable.name"
      label="Voller Name (optional)"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <FormSelectOfficeSettingsProfile
      v-model="entityEditable.settings_profile_id"
      label="Standardeinstellungsprofil des Praxisanwenders"
      include-blank="-- Kein Einstellungsprofil --"
      v-bind:errors="errorFor('settings_profile_id')"
      v-bind:animation-status="statusFor('settings_profile_id')"
      v-on="{ autosave: onAutosaveFor('settings_profile_id') }"
    />

    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>
