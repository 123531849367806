import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { Reminder } from "frontend/interfaces/settings/reminder";
import {
  httpPayloadReminder,
  newReminder,
  parseReminder,
} from "frontend/parser/settings/parse-reminder";
import { api } from "frontend/api/application/settings/request-reminders";

import EditReminder from "frontend/components/settings/EditReminder.vue";

export class RemindersManager extends SettingsManager<Reminder> {
  entityName(): string {
    return "Terminerinnerung";
  }
  componentForEdit(): Component {
    return EditReminder;
  }
  craftNewEntity(): Reminder {
    return newReminder();
  }
  hookList(): Promise<SettingsListResult<Reminder>> {
    return genericListPattern(parseReminder, api.requestIndex);
  }
  hookSave(record: Reminder): Promise<SettingsSaveResult<Reminder>> {
    return genericSavePattern(
      record,
      parseReminder,
      httpPayloadReminder,
      api.requestCreate,
      api.requestUpdate
    );
  }
  hookDelete(record: Reminder): Promise<SettingsDeleteResult> {
    return genericDeletePattern(
      record,
      (entity) => entity.id,
      api.requestDestroy
    );
  }
}
