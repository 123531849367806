import { Component } from "vue";

import { Location } from "frontend/interfaces/location";
import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  httpPayloadLocation,
  newLocation,
  parseLocation,
} from "frontend/parser/parse-location";
import {
  createLocation,
  requestLocations,
  updateLocation,
} from "frontend/api/application/request-locations";
import {
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";

import EditLocation from "frontend/components/settings/EditLocation.vue";

export class LocationsSettingsManager extends SettingsManager<Location> {
  entityName(): string {
    return "Standort";
  }
  componentForEdit(): Component {
    return EditLocation;
  }
  craftNewEntity(): Location {
    return newLocation();
  }
  async hookList(): Promise<SettingsListResult<Location>> {
    return genericListPattern(parseLocation, requestLocations);
  }
  async hookSave(record: Location): Promise<SettingsSaveResult<Location>> {
    return genericSavePattern(
      record,
      parseLocation,
      httpPayloadLocation,
      createLocation,
      updateLocation
    );
  }
  async hookDelete(/*record: Location*/): Promise<SettingsDeleteResult> {
    throw new Error("Method not implemented."); // not supported ATM
  }
  enableNewEntities(): boolean {
    return this.entitiesR().value.length <= 0;
  }
}
