import { UnitType } from "shared/static/enums.ts.erb";
import { AllTimeUnitsType } from "shared/static/time-units.ts.erb";

export function translateTimeUnit(unit: AllTimeUnitsType): {
  singular: string;
  plural: string;
} {
  switch (unit) {
    case "week":
      return { singular: "Woche", plural: "Wochen" };
    case "lifetime":
      return { singular: "Lebzeit", plural: "Lebzeiten" };
    case "quarter":
      return { singular: "Quartal", plural: "Quartale" };
    case "day":
      return { singular: "Tag", plural: "Tage" };
    case "hour":
      return { singular: "Stunde", plural: "Stunden" };
    case "minute":
      return { singular: "Minute", plural: "Minuten" };
    case "month":
      return { singular: "Monat", plural: "Monate" };
    case "year":
      return { singular: "Jahr", plural: "Jahre" };
  }
}

export type UnitTypeKey = keyof typeof UnitType;
export function translateUnitType(unitType: UnitTypeKey): {
  label: string;
} {
  switch (unitType) {
    case "vacation":
      return { label: "Urlaub" };
    case "sick_leave":
      return { label: "Krankheit" };
  }
}

// returns the first key that matches the value in the enum
export function inverseEnum<EnumType extends object>(
  theEnum: EnumType,
  value: EnumType[keyof EnumType]
): keyof EnumType {
  for (const key of Object.keys(theEnum) as unknown as Array<keyof EnumType>) {
    const theValue = theEnum[key];
    if (theValue === value) return key;
  }

  // this should never be reached as long as the types are correct
  return (Object.keys(theEnum) as Array<keyof EnumType>)[0];
}
