import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { Room } from "frontend/interfaces/room";
import {
  httpPayloadRoom,
  newRoom,
  parseRoom,
} from "frontend/parser/parse-room";
import {
  createRoom,
  deleteRoom,
  requestRooms,
  updateRoom,
} from "frontend/api/application/request-rooms";

import EditRoom from "frontend/components/settings/EditRoom.vue";

export class RoomsSettingsManager extends SettingsManager<Room> {
  entityName(): string {
    return "Raum";
  }
  componentForEdit(): Component {
    return EditRoom;
  }
  craftNewEntity(): Room {
    return newRoom();
  }
  hookList(): Promise<SettingsListResult<Room>> {
    return genericListPattern(parseRoom, requestRooms);
  }
  hookSave(record: Room): Promise<SettingsSaveResult<Room>> {
    return genericSavePattern(
      record,
      parseRoom,
      httpPayloadRoom,
      createRoom,
      updateRoom
    );
  }
  hookDelete(record: Room): Promise<SettingsDeleteResult> {
    return genericDeletePattern(record, (entity) => entity.id, deleteRoom);
  }
}
