<script lang="ts">
  import { computed, defineComponent, onMounted, reactive, ref } from "vue";
  import { useRouter } from "vue-router";

  import { requestLogout } from "frontend/api/application/request-login";
  import { useStore as useLoginStore } from "frontend/stores/login";
  import { useStore as useNewsStore } from "frontend/stores/news";
  import { useStore as useProfileStore } from "frontend/stores/profile";
  import { UserEntity } from "frontend/interfaces/user";
  import { CreateProfileModal } from "frontend/utils/modals/create-profile-modal";
  import { duplicateSettingsProfile } from "frontend/parser/parse-settings-profile";

  import BasePopup from "frontend/components/base/BasePopup.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { BasePopup, BaseButton },
    setup() {
      const router = useRouter();

      const loginStore = useLoginStore();
      const profileStore = useProfileStore();

      const { doesANewNewsExist } = useNewsStore();

      const showMenu = ref<boolean>(false);
      const doCloseMenu = () => {
        showMenu.value = false;
      };
      const showLogoutButton = computed<boolean>(() =>
        loginStore.isLoggedInAny()
      );
      const showSettingsButton = computed<boolean>(() =>
        loginStore.isLoggedIn(UserEntity.Practice)
      );
      const showNewsButton = computed<boolean>(() => showSettingsButton.value);
      const showNewsBadge = computed<boolean>(() => doesANewNewsExist());
      const showOpenButton = computed<boolean>(() => {
        return showLogoutButton.value || showSettingsButton.value;
      });
      // top-level badge on burger-icon
      const showBadge = computed<boolean>(() => showNewsBadge.value);
      const showEventLogButton = computed<boolean>(() =>
        loginStore.isLoggedIn(UserEntity.Practice)
      );

      const showSettingsProfiles = computed<boolean>(() =>
        loginStore.isLoggedIn(UserEntity.Practice)
      );
      onMounted(profileStore.fetchProfiles);
      const doCreateSettingsProfile = () => {
        const data = reactive({
          profile: duplicateSettingsProfile(profileStore.profile),
          errorDatabase: {},
        });
        new CreateProfileModal().setData(data).show();
      };

      const doLogout = async () => {
        await requestLogout();
        router.push({ name: "login" });
        loginStore.logout();
        doCloseMenu();
      };

      return {
        showMenu,
        showBadge,
        doLogout,
        doCloseMenu,
        showOpenButton,
        showLogoutButton,
        showNewsButton,
        showNewsBadge,
        showSettingsButton,
        showEventLogButton,

        profileStore,
        showSettingsProfiles,
        doCreateSettingsProfile,
      };
    },
  });
</script>

<template>
  <div class="base-menu">
    <BasePopup v-model="showMenu" enable-close v-bind:placement="'bottom-end'">
      <BaseButton
        v-if="showOpenButton"
        class="base-menu__open-button"
        v-on:submit="showMenu = !showMenu"
      >
        <i class="fe fe-hamburger"></i>
        <i
          v-if="showBadge"
          class="base-menu__badge base-menu__badge--open-menu fe fe-circle--filled"
        ></i>
      </BaseButton>
      <template v-slot:popup>
        <div class="base-menu__dropdown">
          <BaseButton
            v-if="showSettingsButton"
            v-bind:route="{ name: 'settings' }"
            v-on:submit="doCloseMenu()"
          >
            <i class="fe fe-cog"></i> Einstellungen
          </BaseButton>

          <BaseButton
            v-if="showNewsButton"
            v-bind:route="{ name: 'news' }"
            v-on:submit="doCloseMenu()"
          >
            <i class="fe fe-info--with-circle"></i> News
            <i
              v-if="showNewsBadge"
              class="base-menu__badge fe fe-circle--filled"
            ></i>
          </BaseButton>

          <BaseButton
            v-if="showEventLogButton"
            v-bind:route="{ name: 'event-log' }"
            v-on:submit="doCloseMenu()"
          >
            <i class="fe fe-clipboard--clock"></i> Protokoll
          </BaseButton>

          <BaseButton v-if="showLogoutButton" v-on:submit="doLogout">
            <i class="fe fe-logout"></i> Logout
          </BaseButton>

          <template v-if="showSettingsProfiles">
            <div class="profile-selector__heading">
              Einstellungsprofil wählen:
            </div>
            <div
              v-for="profile in profileStore.settingsProfiles"
              v-bind:key="profile.id"
              class="profile-selector__item"
              v-on:click="profileStore.selectProfile(profile)"
            >
              {{ profile.name }}
            </div>
            <div
              class="profile-selector__item"
              v-on:click="doCreateSettingsProfile"
            >
              <i class="fe fe-plus--with-circle--inverted"></i>
              Einstellungsprofil anlegen
            </div>
          </template>
        </div>
      </template>
    </BasePopup>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .base-menu__dropdown {
    padding: 16px;

    background-color: colors.$color_popup--background;

    display: flex;
    flex-direction: column;

    > div {
      border-radius: 0;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .base-menu__open-button {
    // size and spacing
    padding: 5px;
    font-size: 30px;

    position: relative;
  }

  .base-menu__badge {
    font-size: 17px;
    color: colors.$color_badge;
  }

  .base-menu__badge--open-menu {
    position: absolute;
    right: 3px;
    bottom: 5px;
  }

  .profile-selector__item {
    cursor: pointer;

    padding: 2px 0;

    font-size: 12px;
  }

  .profile-selector__heading {
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
  }
</style>
