<script lang="ts">
  import { defineComponent, nextTick, PropType, ref, toRefs } from "vue";
  import { v4 as uuid } from "uuid";
  import { useRouter } from "vue-router";

  import { LogLevel, log } from "shared/utils/logger";
  import { useFormLabelling } from "frontend/uses/use-form-labelling";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";
  import { SearchResult } from "frontend/interfaces/search-results";

  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  const UPDATE_V_MODEL_EVENT = "update:model-value";

  export default defineComponent({
    components: { AnimationStatus },
    props: {
      name: {
        type: String,
        default: () => uuid(),
      },
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      autocomplete: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: [String, Number] as PropType<string | number | null>,
        default: "",
      },
      rows: {
        // only type === 'textarea'
        type: Number,
        default: 3,
      },
      errors: {
        type: Array as PropType<Errors>,
        default: () => [],
      },
      visualFullWidth: {
        type: Boolean,
        default: false,
      },
      completions: {
        type: Array as PropType<Array<SearchResult>>,
        default: () => [],
      },
      animationStatus: {
        type: Number as PropType<StatusValue | null>,
        default: null,
      },
      deferEmit: {
        // defers emitting the new modelValue until focus is lost
        type: Boolean,
        default: false,
      },
      visualSmall: {
        // only input, not textarea
        type: Boolean,
        default: false,
      },
    },
    emits: [UPDATE_V_MODEL_EVENT, "autosave"],
    setup(props, { emit }) {
      const router = useRouter();

      const { modelValue, deferEmit } = toRefs(props);

      let modelValueSave: string | number = modelValue.value ?? "";

      const onModelValueChange = (event: Event) => {
        foc.value = true;
        const target = event.target as HTMLInputElement;
        modelValueSave = target.value;

        if (!deferEmit.value) {
          emit(UPDATE_V_MODEL_EVENT, modelValueSave);
        }

        // Firefox does not fire blur-event when using the integer controls
        // inside a number input field
        // so we simulate it, by catching it via "insertReplacementText"
        if ((event as InputEvent).inputType === "insertReplacementText") {
          onBlur();
        }
      };

      const completionsContainerElement = ref<HTMLDivElement | null>(null);

      const setFocus = async (hasFocus: boolean, waitForNavigation = false) => {
        if (waitForNavigation) await router.isReady();

        foc.value = !!hasFocus;
      };
      const foc = ref<boolean>(false);
      const { pairing } = useFormLabelling();
      // Tracking of editing
      let valueBeforeEdit: null | typeof modelValue.value = null;
      const onFocus = () => {
        valueBeforeEdit = modelValue.value;
        setFocus(true);
      };
      const onBlur = async (event?: FocusEvent) => {
        if (deferEmit.value) {
          emit(UPDATE_V_MODEL_EVENT, modelValueSave);
          await nextTick();
        }

        doHandleAutosave();
        valueBeforeEdit = null;

        const target = (event?.relatedTarget ??
          (
            event as {
              explicitOriginalTarget?: {
                parentElement?: HTMLElement | null | undefined;
              };
            }
          )?.explicitOriginalTarget?.parentElement) as
          | HTMLElement
          | null
          | undefined;
        if (
          target &&
          completionsContainerElement.value &&
          completionsContainerElement.value.contains(target)
        ) {
          // if we are open and blurred due to completions --> stay open until click propagates
          // otherwise we will close the completions too early
          // issue #332
          log(
            LogLevel.Debug,
            "[FORM-INPUT] defer blur, because user clicked on completions link"
          );
        } else {
          setFocus(false);
        }
      };
      const doHandleAutosave = () => {
        if (
          (deferEmit.value ? modelValueSave : modelValue.value) !==
          valueBeforeEdit
        ) {
          valueBeforeEdit = modelValue.value;
          emit("autosave", valueBeforeEdit, modelValue.value);
        }
      };

      return {
        onModelValueChange,

        completionsContainerElement,

        onFocus,
        onBlur,
        doHandleAutosave,

        setFocus,

        foc,
        pairing,
      };
    },
  });
</script>

<template>
  <div
    class="vue-component__form-input"
    v-bind:class="{ 'form-input--full-width': visualFullWidth }"
  >
    <label v-if="label" class="form-input__label" v-bind:for="pairing">{{
      label
    }}</label>
    <div class="form-input__status-container">
      <input
        v-if="type !== 'textarea'"
        v-bind:id="pairing"
        v-bind:name="name"
        v-bind:value="modelValue"
        v-bind:placeholder="placeholder"
        v-bind:type="type"
        v-bind:disabled="disabled"
        class="form-input__input"
        v-bind:class="{ 'form-input__input--small': visualSmall }"
        v-bind:autocomplete="autocomplete"
        v-on:input="onModelValueChange"
        v-on:focus="onFocus"
        v-on:blur="onBlur"
        v-on:keyup.enter="doHandleAutosave"
      />
      <textarea
        v-else
        v-bind:id="pairing"
        v-bind:name="name"
        v-bind:value="modelValue ?? ''"
        v-bind:placeholder="placeholder"
        v-bind:disabled="disabled"
        v-bind:rows="rows"
        class="form-input__input"
        v-bind:autocomplete="autocomplete"
        v-on:input="onModelValueChange"
        v-on:focus="onFocus"
        v-on:blur="onBlur"
      />
      <AnimationStatus
        v-if="animationStatus !== null"
        class="form-input__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="form-input__label__error"
        v-bind:for="pairing"
      >
        {{ error }}
      </label>
    </template>
    <Transition name="fade">
      <div
        v-if="completions.length > 0 && foc"
        ref="completionsContainerElement"
        class="form-input__completions"
      >
        <div
          v-for="entry in completions"
          v-bind:key="entry.id"
          v-on:click="setFocus(false, true)"
        >
          <RouterLink
            v-bind:to="{ name: 'patient-show', params: { id: entry.id } }"
          >
            <div class="form-input__completion">
              {{ entry.text }}
              <i
                v-if="entry?.info.includes('praxisapp')"
                v-tooltip="'Nutzer hat PraxisApp'"
                class="fe fe-smartphone icon--has-praxisapp"
              ></i>
            </div>
          </RouterLink>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "frontend/styles/colors";
  @use "frontend/styles/mixins/label";

  $_padding: 7px;

  .vue-component__form-input {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .form-input__input {
    width: 100%;
    padding: $_padding;
    font-size: 15px;
    line-height: 23px;
  }

  .form-input__input--small {
    font-size: 12px;
    padding: 0 $_padding;
  }

  .form-input__label,
  .form-input__label__error {
    @include label.label;
  }

  .form-input__label__error {
    @include label.label--error;
  }

  .form-input--full-width {
    width: 100%;
    padding: $_padding;
  }

  .form-input__completion {
    padding: 10px;
    border: 1px solid colors.$color_header_search--border;
    color: colors.$color_plain--text;

    cursor: pointer;

    &:hover {
      background: color.adjust(
        colors.$color_plain--background,
        $lightness: -20%
      );
    }
  }

  .form-input__completions {
    background: colors.$color_plain--background;
    position: absolute;
    border: 1px solid colors.$color_header_search--border;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: calc(100% - $_padding);
    left: $_padding;
    right: $_padding;
  }

  .form-input__status-container {
    display: flex;
    align-items: center;

    .form-input__input {
      flex: 1;
    }

    .form-input__animation-status {
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
