import { computed, ComputedRef, Ref, unref } from "vue";
import { isSameMonth } from "date-fns";

import {
  getFullWeeksOfMonth,
  getShortWeekDayNames,
  formatDate,
  DateFormat,
  dateIncludedIn,
} from "shared/utils/date-utils";
import {
  MiniCalendar,
  MiniCalendarDay,
  MiniCalendarDayVisual,
  MiniCalendarWeek,
} from "frontend/interfaces/mini-calendar";

export function useMiniCalendar(
  month: Ref<Date>,
  highlightedDays: Ref<Array<Date>>,
  selectedDays: Ref<Array<Date>>
): { calendar: ComputedRef<MiniCalendar> } {
  const calendar = computed(() => {
    return weeksToMiniCalendar(
      getFullWeeksOfMonth(unref(month)),
      unref(month),
      unref(highlightedDays),
      unref(selectedDays)
    );
  });

  return { calendar };
}

function weeksToMiniCalendar(
  weeks: Array<Array<Date>>,
  month: Date,
  highlightedDays: Array<Date>,
  selectedDays: Array<Date>
): MiniCalendar {
  return {
    weeks: weeks.map((week) =>
      weekToCalendarWeek(week, month, highlightedDays, selectedDays)
    ),
    headers: getShortWeekDayNames(),
  };
}

function dayToMiniCalendarDay(
  day: Date,
  month: Date,
  highlightedDays: Array<Date>,
  selectedDays: Array<Date>
): MiniCalendarDay {
  return {
    key: `day--${formatDate(day, DateFormat.ISO)}`,
    label: formatDate(day, DateFormat.DayOfMonth),
    date: day,
    visual: selectDayVisual(day, month, highlightedDays, selectedDays),
    highlighted: dateIncludedIn(day, highlightedDays),
  };
}

function weekToCalendarWeek(
  week: Array<Date>,
  month: Date,
  highlightedDays: Array<Date>,
  selectedDays: Array<Date>
): MiniCalendarWeek {
  return {
    key: `week--${formatDate(week[0] || new Date(), DateFormat.ISO)}`,
    days: week.map((day) =>
      dayToMiniCalendarDay(day, month, highlightedDays, selectedDays)
    ),
  };
}

function selectDayVisual(
  day: Date,
  month: Date,
  highlightedDays: Array<Date>,
  selectedDays: Array<Date>
): MiniCalendarDayVisual {
  if (dateIncludedIn(day, selectedDays)) {
    return MiniCalendarDayVisual.Selected;
  } else if (isSameMonth(day, month)) {
    return MiniCalendarDayVisual.Normal;
  } else {
    return MiniCalendarDayVisual.Light;
  }
}
