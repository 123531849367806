<script lang="ts">
  import { defineComponent } from "vue";

  import { SettingsProfilesManager } from "frontend/uses/settings/manager/settings-profiles-manager";

  import SettingsContainer from "frontend/components/settings/SettingsContainer.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  export default defineComponent({
    components: { SettingsContainer, BaseHelp },
    setup() {
      new SettingsProfilesManager().provide();
    },
  });
</script>

<template>
  <div class="settings-colors">
    <h2>Einstellungsprofile</h2>

    <BaseHelp>
      <p>
        Sie können (optional) Einstellungsprofile anlegen. Diese dienen dazu die
        Benutzeroberfläche und Anwendungslogik der Online-Terminverwaltung
        individuell anzupassen.
      </p>
    </BaseHelp>

    <SettingsContainer />
  </div>
</template>
