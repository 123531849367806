import {
  _ActionsTree,
  _GettersTree,
  PiniaPluginContext,
  PiniaPlugin,
  StateTree,
} from "pinia";

import { log, LogLevel } from "shared/utils/logger";
import { parseJSON, stringifyJSON } from "shared/utils/json";

const STORAGE = localStorage;
type StateData = string | null;

export function piniaPluginPersist(
  storeId: string,
  keysToPersist: Array<string>
): PiniaPlugin {
  let registered = false;
  return (context) => {
    // correct store?
    if (context.store.$id !== storeId) return;

    // yes, so register the plugin (only) once
    // as this is called every time useStore is invoked
    if (registered) return;
    registered = true;

    log(
      LogLevel.Info,
      `[PINIA-PLUGIN-PERSIST] registering plugin for store "${storeId}"`
    );

    // Initially load values stored if they are not "truthy"
    loadValues(storeId, keysToPersist, context);

    // Register listener for changes in store / state to persist them
    context.store.$subscribe(() => {
      saveValues(storeId, keysToPersist, context);
    });

    // Register listener for changes in localstorage (if another tab / context changes the value)
    window.addEventListener("storage", () =>
      loadValues(storeId, keysToPersist, context)
    );
  };
}

function calculateStorageKey(storeId: string, key: string): string {
  return `pinia-plugin-persist__${storeId}--${key}`;
}

function loadValues(
  storeId: string,
  keysToPersist: Array<string>,
  context: PiniaPluginContext<
    string,
    StateTree,
    _GettersTree<StateTree>,
    _ActionsTree
  >
) {
  for (const key of keysToPersist) {
    const storageKey = calculateStorageKey(storeId, key);
    const storedValue = STORAGE.getItem(storageKey);

    if (
      storedValue &&
      Object.prototype.hasOwnProperty.call(context.store.$state, key)
    ) {
      const storedValueParsed = parseJSON(storedValue);
      const stateValue = (
        context.store.$state as {
          [key: string]: StateData;
        }
      )[key];

      let restoreValue = storedValueParsed;
      if (context.options.actions._piniaPluginPersist_onLoad)
        // call a hook on the store (action with name "_piniaPluginPersist_onLoad" to allow modification of loaded value)
        // this can be used to sanitize and validate more complex stored values
        restoreValue = context.options.actions._piniaPluginPersist_onLoad(
          key,
          storedValueParsed,
          stateValue
        );

      // NOTE: if the value is non-primitive this will always evaluate to true
      if (stateValue !== restoreValue) {
        (
          context.store.$state as {
            [key: string]: StateData;
          }
        )[key] = restoreValue;
      }
    } else {
      log(
        LogLevel.Info,
        `[PINIA-PLUGIN-PERSIST] property "${key}" has no persisted value or does not exist on "${storeId}" store`
      );
    }
  }
}

function saveValues(
  storeId: string,
  keysToPersist: Array<string>,
  context: PiniaPluginContext<
    string,
    StateTree,
    _GettersTree<StateTree>,
    _ActionsTree
  >
) {
  for (const key of keysToPersist) {
    if (Object.prototype.hasOwnProperty.call(context.store.$state, key)) {
      // property exists on state, so store it into storage
      const storageKey = calculateStorageKey(storeId, key);

      const storedValue = STORAGE.getItem(storageKey);
      const stateValue = (
        context.store.$state as {
          [key: string]: StateData;
        }
      )[key];

      if (storedValue !== stateValue) {
        log(
          LogLevel.Info,
          `[PINIA-PLUGIN-PERSIST] dump property "${key}" to storage`
        );
        STORAGE.setItem(storageKey, stringifyJSON(stateValue));
      }
    } else {
      log(
        LogLevel.Info,
        `[PINIA-PLUGIN-PERSIST] property "${key}" not found in state of "${storeId}"`
      );
    }
  }
}
