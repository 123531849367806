<script lang="ts">
  import { defineComponent, toRefs } from "vue";

  import { Location } from "frontend/interfaces/location";
  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";

  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: { FormInput },
    props: {
      ...editProps<Location>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      return {
        ...useEditComponent(entity.value),
      };
    },
  });
</script>

<template>
  <div class="edit-location">
    <h5>{{ entity.name }}</h5>
    <FormInput
      v-model="entityEditable.name"
      label="Standortname"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <FormInput
      v-model="entityEditable.street"
      label="Straße"
      v-bind:errors="errorFor('street')"
      v-bind:animation-status="statusFor('street')"
      v-on="{ autosave: onAutosaveFor('street') }"
    />
    <div class="edit-location__two-columns">
      <FormInput
        v-model="entityEditable.zip"
        class="edit-location__cell"
        label="PLZ"
        v-bind:errors="errorFor('zip')"
        v-bind:animation-status="statusFor('zip')"
        v-on="{ autosave: onAutosaveFor('zip') }"
      />
      <FormInput
        v-model="entityEditable.city"
        class="edit-location__cell"
        label="Ort"
        v-bind:errors="errorFor('city')"
        v-bind:animation-status="statusFor('city')"
        v-on="{ autosave: onAutosaveFor('city') }"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .edit-location__two-columns {
    display: flex;

    > .edit-location__cell {
      flex: 1;

      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
</style>
