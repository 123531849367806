import { ErrorDatabase } from "frontend/uses/use-errors";

export enum HttpMethod {
  GET,
  POST,
  PATCH,
  DELETE,
}

type HttpPayloadPrimitive = number | string | boolean | null;

export type ISODateString = string; // Date as iso-string

export interface HttpPayload {
  [key: string]: HttpPayloadPrimitive | HttpPayload | HttpPayloadArray;
}
type HttpPayloadArray = Array<
  HttpPayloadPrimitive | HttpPayload | HttpPayloadArray
>;

export type HttpStatus = number;

export interface HttpResponse {
  body: HttpPayload | null;
  status: HttpStatus | null;
  error: Error | null;
  statusIsSuccess: boolean;
}

export interface UnifiedBaseResponse {
  success: boolean;
  errors: ErrorDatabase;
}

export interface UnifiedResponse<T> extends UnifiedBaseResponse {
  entityRaw: T;
}

export interface UnifiedListResponse<T> extends UnifiedBaseResponse {
  entities: Array<T>;
}

type HttpHeaderKey = string;
type HttpHeaderValue = string;
export type HttpHeaders = Record<HttpHeaderKey, HttpHeaderValue>;
