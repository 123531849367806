<script lang="ts">
  import { computed, defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { ScheduleActivation } from "frontend/interfaces/schedule-activation";
  import { formatDate, DateFormat } from "shared/utils/date-utils";

  import BaseListItem from "frontend/components/base/BaseListItem.vue";

  export default defineComponent({
    components: { BaseListItem },
    props: {
      ...editProps<ScheduleActivation>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      const editComponent = useEditComponent(entity.value);
      const computedTitle = computed(() => {
        const from = entity.value.from
          ? formatDate(entity.value.from, DateFormat.DateOnly)
          : "";
        const to = entity.value.to
          ? formatDate(entity.value.to, DateFormat.DateOnly)
          : "";
        return from + "-" + to;
      });

      return {
        ...editComponent,
        computedTitle,
      };
    },
  });
</script>

<template>
  <BaseListItem
    v-bind:title="computedTitle"
    v-bind:subtitle="entityEditable.schedule_name"
    delete-action
    edit-action
    flush
    v-on:delete-item="deleteRecord"
    v-on:edit-item="saveRecord"
  />
</template>
