<template>
  <div class="base-columns">
    <div class="base-columns__columns-container">
      <div class="base-columns__column">
        <slot></slot>
      </div>

      <div v-if="$slots.actions" class="base-columns__column">
        <h5 class="base-column__heading--no-upper-distance">Aktionen</h5>
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .base-columns__columns-container {
    display: flex;
  }

  .base-columns__column {
    $_line-distance: 20px;

    margin-right: $_line-distance;
    padding-right: $_line-distance;

    border-right: 1px solid colors.$color_base-columns--separator;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;

      border-right: none;
    }
  }

  .base-column__heading--no-upper-distance {
    margin-top: 0;
  }
</style>
