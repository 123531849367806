<script lang="ts" setup>
  import type { Component } from "vue";

  import {
    FILTER_EVENT_STATE_CHANGE,
    filterProps,
    filterEmits,
  } from "frontend/uses/filter/use-filter";
  import { FilterFlexOptions } from "frontend/uses/filter/predefined/flex";

  interface FilterState {
    childrenStates: Array<unknown>;
  }

  interface FilterOptions {
    childrenComponents: Array<Component>;
    childrenOptions: Array<unknown>;
    flexOptions: FilterFlexOptions;
  }

  const props = defineProps({ ...filterProps<FilterState, FilterOptions>() });
  const emit = defineEmits([...filterEmits()]);

  const onSingleFilterChange = (filterIndex: number, singleState: unknown) => {
    // create the event data
    const data: FilterState = {
      childrenStates: [
        ...props.filterState.childrenStates.slice(0, filterIndex),
        singleState,
        ...props.filterState.childrenStates.slice(filterIndex + 1),
      ],
    };

    // rebuild the state
    emit(FILTER_EVENT_STATE_CHANGE, data);
  };
</script>

<template>
  <div class="filter-flex" v-bind:style="filterOptions.flexOptions">
    <template
      v-for="(cmpt, index) in filterOptions.childrenComponents"
      v-bind:key="index"
    >
      <component
        v-bind:is="cmpt"
        class="filter-flex__item"
        v-bind:filter-state="filterState.childrenStates[index]"
        v-bind:filter-options="filterOptions.childrenOptions[index]"
        v-on="{
          [FILTER_EVENT_STATE_CHANGE]: (state: unknown) =>
            onSingleFilterChange(index, state),
        }"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .filter-flex {
    display: flex;
    align-items: flex-start;
  }

  .filter-flex__item {
    margin: 5px;
  }
</style>
