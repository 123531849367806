<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from "vue";
  import { compareDesc } from "date-fns";

  import { Appointment } from "frontend/interfaces/p/appointment";
  import {
    destroyAppointment,
    requestAppointments,
  } from "frontend/api/application/p/request-appointments";
  import { parseAppointment } from "frontend/parser/p/parse-appointment";
  import { DateFormat, formatDate, today } from "shared/utils/date-utils";
  import { log, LogLevel } from "shared/utils/logger";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import { useStore as useOfficeStore } from "frontend/stores/office";

  import BaseBox from "frontend/components/base/BaseBox.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import PatientAppointmentBox from "frontend/components/PatientAppointmentBox.vue";

  export default defineComponent({
    components: { BaseBox, BaseButton, PatientAppointmentBox },
    props: {},
    setup() {
      const appointments = ref<Array<Appointment>>([]);
      const loadAppointments = async () => {
        const data = await requestAppointments();
        appointments.value = data.appointments.map(parseAppointment);
      };
      onMounted(loadAppointments);

      const activeAppointments = computed(() =>
        appointments.value.filter((appointment) => appointment.begin >= today())
      );

      const pastAppointments = computed(() =>
        appointments.value
          .filter((appointment) => appointment.begin < today())
          .sort((a, b) => compareDesc(a.begin, b.begin))
      );

      const confirmDestroyAppointment = async (appointment: Appointment) => {
        if (!appointment) return;
        const { confirm, failure } = useSimpleModal();
        const result = await confirm(
          "Sind Sie sicher, dass Sie Ihren Termin löschen wollen? Sie können diesen Vorgang NICHT rückgängig machen.",
          {}
        );
        if (!result.confirm) return;
        const index = appointments.value.indexOf(appointment);
        if (index < 0 || index >= appointments.value.length) {
          log(
            LogLevel.Error,
            "Could not find appointment withing all appointments. This should never happen."
          );
          return;
        }
        const success = await destroyAppointment(appointment.id);
        if (success) {
          appointments.value.splice(index, 1);
        } else {
          // TODO: #173
          await failure(
            "Konnte den Termin aufgrund eines Serverfehlers nicht löschen.",
            {}
          );
        }
      };

      const officeStore = useOfficeStore();

      return {
        appointments,
        confirmDestroyAppointment,

        officeStore,

        // libs
        formatDate,
        DateFormat,
        activeAppointments,
        pastAppointments,
      };
    },
  });
</script>

<template>
  <div>
    <BaseBox heading="Ihre Termine" visual-light>
      <div v-if="!officeStore.isBlocked">
        <BaseButton
          class="navigation-button__neuer-termin"
          visual-full-width
          v-bind:route="{ name: 'patient-appointment-new' }"
        >
          <i class="fe fe-plus--with-circle--inverted"></i>
          Neuer Termin
        </BaseButton>
      </div>
      <div v-else class="navigation-button__container">
        Das Terminangebot wird gerade konfiguriert. Sie können daher aktuell
        keine weiteren Termine buchen.
      </div>
      <PatientAppointmentBox
        v-bind:appointments="activeAppointments"
        v-bind:title="'Anstehende Termine'"
        v-bind:note="'Sie haben keine anstehenden Termine.'"
        v-on:delete-appointment="confirmDestroyAppointment"
      />
      <PatientAppointmentBox
        v-bind:appointments="pastAppointments"
        v-bind:title="'Vergangene Termine'"
        v-bind:note="'Sie haben keine vergangenen Termine.'"
        v-on:delete-appointment="confirmDestroyAppointment"
      />
    </BaseBox>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .navigation-button__container {
    padding: 15px 0;
    width: 180px;
    margin: 0 auto; // center horizontally

    text-align: center;
  }

  .modal__text {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 80vw;
  }

  .modal__text--error {
    color: colors.$color_form__errors--text;
  }

  .navigation-button__neuer-termin {
    padding: 22px;
    font-size: 20px;
    margin: 40px 0;
  }
</style>
