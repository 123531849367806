import { updateUnit } from "frontend/api/application/request-unit";
import { Unit } from "frontend/interfaces/unit";
import { Modal } from "frontend/utils/modals/modal";
import { formatDate, DateFormat } from "shared/utils/date-utils";

import MoveAppointmentComponent from "frontend/components/MoveAppointment.vue";

export interface MoveModalData {
  unit: Unit;
  newStartDate: Date;
  newEndDate: Date;
  notifyPatient: boolean;
  isLoading: boolean;
  reason: string | null;
  originalPersonName: string;
  newPersonId: string;
}
export class MoveModal extends Modal<
  typeof MoveAppointmentComponent,
  MoveModalData
> {
  private personIDOriginal: string | undefined;

  public setPersonIDOriginal(personIDOriginal: string): typeof this {
    this.personIDOriginal = personIDOriginal;
    return this;
  }

  protected modalComponent() {
    return MoveAppointmentComponent;
  }

  protected async onClose(
    userConfirmed: boolean,
    closeModal: () => void,
    _forceClose = true
  ): Promise<void> {
    super.onClose(userConfirmed, closeModal, false);

    const data = this.getData();
    if (!data) {
      closeModal();
      return;
    }

    if (userConfirmed) {
      try {
        data.isLoading = true;

        const { success } = await updateUnit(
          data.unit.id,
          {
            from: formatDate(
              data.newStartDate || data.unit.from,
              DateFormat.ISO
            ),
            to: formatDate(data.newEndDate || data.unit.to, DateFormat.ISO),
          },
          !data.unit.appointment_id || data.unit.search
            ? false
            : !!data.notifyPatient,
          data.reason,
          this.personIDOriginal
            ? {
                personIDOriginal: this.personIDOriginal,
                personIDNew: data.newPersonId,
              }
            : null
        );

        // setup success logic in modals
        this.setSuccess(success);

        if (success) {
          closeModal();
        }
      } finally {
        data.isLoading = false;
      }
    } else {
      closeModal();
    }
  }
}
