<script lang="ts">
  import { defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Role } from "frontend/interfaces/role";

  import FormInput from "frontend/components/form/FormInput.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { FormInput, BaseButton },
    props: {
      ...editProps<Role>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      return { ...useEditComponent(entity.value) };
    },
  });
</script>

<template>
  <div class="edit-location">
    <h5>{{ entity.name }}</h5>
    <FormInput
      v-model="entityEditable.name"
      label="Name der Rolle"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>
