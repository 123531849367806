<script lang="ts" setup>
  import { onMounted, ref, toRefs } from "vue";
  import { useRouter } from "vue-router";

  import { useShowUnit } from "frontend/uses/use-show-unit";
  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { confirmSuggestion } from "frontend/api/application/request-suggestions";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";

  import ShowLoadedUnit from "frontend/components/ShowLoadedUnit.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import FormInput from "frontend/components/form/FormInput.vue";

  const props = defineProps({
    unitId: {
      type: String,
      required: true,
    },
    ...useAsModalChildProps(),
  });

  const router = useRouter();

  const { currentUnit } = useShowUnit(toRefs(props).unitId);

  const notifyPatient = ref<boolean>(true);
  const remindPatient = ref<boolean>(true);
  const internalOfficeNote = ref<string>("");
  onMounted(() => {
    notifyPatient.value = true;
    remindPatient.value = true;
  });

  const doConfirmAppointment = async () => {
    if (!currentUnit.value) return; // should never happen

    if (
      await confirmSuggestion(
        props.unitId,
        notifyPatient.value,
        remindPatient.value,
        internalOfficeNote.value
      )
    ) {
      if (props.asModalChild) props.asModalChild.doClose(true);

      router.push({
        name: "calendar-day",
        params: {
          day: formatDate(currentUnit.value.from, DateFormat.DateOnlyISO),
        },
      });
    }
  };
</script>

<template>
  <div>
    <h2>Terminbuchung bestätigen</h2>
    <h3>Termindetails</h3>
    <ShowLoadedUnit v-if="currentUnit" v-bind:current-unit="currentUnit">
      <template v-slot:actions>
        <BaseButton v-on:submit="doConfirmAppointment">
          <i class="fe fe-checkmark--with-circle"></i>
          Termin buchen
        </BaseButton>

        <FormCheckbox
          v-model="notifyPatient"
          label="Patienten über Terminbuchung informieren"
        />
        <FormCheckbox
          v-model="remindPatient"
          label="Patienten Terminerinnerungen senden"
        />
        <FormInput
          v-model="internalOfficeNote"
          label="Interne Kurznotiz"
          type="textarea"
          defer-emit
        />
      </template>
    </ShowLoadedUnit>
  </div>
</template>
