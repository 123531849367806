<script lang="ts" setup>
  import { computed } from "vue";

  interface Options {
    options: {
      label: string;
      iconName: string | null;
    };
  }

  const props = withDefaults(defineProps<Options>(), {});

  const iconClasses = computed(() => {
    return props.options.iconName ? { [props.options.iconName]: true } : {};
  });
</script>

<template>
  <div class="simple-entry">
    <i class="simple-entry__icon fe" v-bind:class="iconClasses"></i>
    <div>{{ options.label }}</div>
  </div>
</template>

<style lang="scss" scoped>
  @use "shared/styles/colors";
  @use "shared/utils/context-menu/context-menu";

  .simple-entry__icon {
    font-size: 16px;
    width: 22px;
    margin-left: 7px;

    color: colors.$color_context-menu--simple-entry--icon-color;
  }

  .simple-entry {
    @include context-menu.entry;
    padding-left: 0; // adjust padding for icon

    display: flex;
    align-items: center;

    &:hover {
      &,
      .simple-entry__icon {
        @include context-menu.entry--hover;
      }
    }
  }
</style>
