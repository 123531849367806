import { parseISO } from "date-fns";

import { Holiday, HolidayRaw } from "frontend/interfaces/settings/holiday";

export function parseHoliday(holiday: HolidayRaw): Holiday {
  return {
    ...holiday,
    date: parseISO(holiday.date),
  };
}
