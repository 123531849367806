<script lang="ts">
  import { computed, defineComponent, PropType, toRefs, unref } from "vue";
  import { addMonths } from "date-fns";

  import { DateFormat, formatDate } from "shared/utils/date-utils";
  import { createEventForwarder } from "shared/utils/create-event-forwarder";
  import { useMonthRotation } from "frontend/uses/use-month-rotation";

  import BaseBox from "frontend/components/base/BaseBox.vue";
  import MiniCalendar from "frontend/components/MiniCalendar.vue";

  export default defineComponent({
    components: { BaseBox, MiniCalendar },
    props: {
      modelValue: {
        // shows the month view of the given date (time and day of the month don't matter)
        type: Date as PropType<Date>,
        default: () => new Date(),
      },
      highlightedDays: {
        type: Array as PropType<Array<Date>>,
        default: () => [],
      },
      selectedDays: {
        type: Array as PropType<Array<Date>>,
        default: () => [],
      },
    },
    emits: ["dateSelected", "update:modelValue"],
    setup(props, { emit }) {
      const { modelValue, selectedDays, highlightedDays } = toRefs(props);
      const updateModelValue = (newValue: Date) => {
        emit("update:modelValue", newValue);
      };
      const monthLabel = computed(() => {
        return formatDate(unref(modelValue), DateFormat.NameOfMonthWithYear);
      });
      const doMiniCalendarNextMonth = (up: boolean) => {
        const amount = up ? 1 : -1;
        updateModelValue(addMonths(unref(modelValue), amount));
      };
      const forwardDateSelected = createEventForwarder("dateSelected", emit);
      const {
        hasRotation: highlightedHasRotation,
        hasPreviousRotation: highlightedHasPreviousRotation,
        hasNextRotation: highlightedHasNextRotation,
        gotoPreviousRotation: highlightedGotoPreviousRotation,
        gotoNextRotation: highlightedGotoNextRotation,
      } = useMonthRotation(highlightedDays, modelValue, updateModelValue);
      const {
        hasRotation: selectedHasRotation,
        hasPreviousRotation: selectedHasPreviousRotation,
        hasNextRotation: selectedHasNextRotation,
        gotoPreviousRotation: selectedGotoPreviousRotation,
        gotoNextRotation: selectedGotoNextRotation,
      } = useMonthRotation(selectedDays, modelValue, updateModelValue);
      return {
        monthLabel,
        doMiniCalendarNextMonth,
        forwardDateSelected,
        highlightedHasRotation,
        highlightedHasNextRotation,
        highlightedHasPreviousRotation,
        highlightedGotoNextRotation,
        highlightedGotoPreviousRotation,
        selectedHasRotation,
        selectedHasNextRotation,
        selectedHasPreviousRotation,
        selectedGotoNextRotation,
        selectedGotoPreviousRotation,
      };
    },
  });
</script>

<template>
  <BaseBox class="vue-component__mini-calendar-as-box" visual-bottom-spacing>
    <template v-slot:heading>
      <div class="mini-calendar__title">
        <i
          class="fe fe-arrow-left-boxed"
          v-on:click="doMiniCalendarNextMonth(false)"
        ></i
        >{{ monthLabel }}
        <i
          class="fe fe-arrow-right-boxed"
          v-on:click="doMiniCalendarNextMonth(true)"
        ></i>
      </div>
    </template>
    <div class="box__content">
      <MiniCalendar
        v-bind:month="modelValue"
        v-bind:highlighted-days="highlightedDays"
        v-bind:selected-days="selectedDays"
        v-on:date-selected="forwardDateSelected"
      />
      <div class="mini-calendar__actions">
        <div v-if="highlightedHasRotation" class="mini-calendar__rotation">
          <div
            v-if="highlightedHasPreviousRotation"
            class="mini-calendar__rotation-arrow"
            v-on:click="highlightedGotoPreviousRotation"
          >
            <i class="fe fe-arrow-left"></i>
          </div>
          <div
            class="mini-calendar__rotation-icon mini-calendar__rotation-icon--highlighted"
            title="Markierter Tag im Kalender"
          ></div>
          <div
            v-if="highlightedHasNextRotation"
            class="mini-calendar__rotation-arrow"
            v-on:click="highlightedGotoNextRotation"
          >
            <i class="fe fe-arrow-right"></i>
          </div>
        </div>

        <div v-if="selectedHasRotation" class="mini-calendar__rotation">
          <div
            v-if="selectedHasPreviousRotation"
            class="mini-calendar__rotation-arrow"
            v-on:click="selectedGotoPreviousRotation"
          >
            <i class="fe fe-arrow-left"></i>
          </div>
          <div
            class="mini-calendar__rotation-icon mini-calendar__rotation-icon--selected"
            title="Ausgewählter Tag im Kalender"
          ></div>
          <div
            v-if="selectedHasNextRotation"
            class="mini-calendar__rotation-arrow"
            v-on:click="selectedGotoNextRotation"
          >
            <i class="fe fe-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </BaseBox>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";
  @use "frontend/styles/mixins/mini-calendar";

  .mini-calendar__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    i {
      color: colors.$color_header--text;

      cursor: pointer;
      font-size: 17px;
    }
  }

  .mini-calendar__actions {
    display: flex;
    margin-top: 10px;
  }

  .mini-calendar__rotation {
    display: flex;
    margin-right: 5px;
  }

  .mini-calendar__rotation-icon {
    @include mini-calendar.cell($scale: 0.75);
    @include mini-calendar.day($interactive: false);

    margin-right: 2px;
  }

  .mini-calendar__rotation-icon--highlighted {
    @include mini-calendar.day--highlighted($interactive: false);
  }

  .mini-calendar__rotation-icon--selected {
    @include mini-calendar.day--selected($interactive: false);
  }

  .mini-calendar__rotation-arrow {
    cursor: pointer;

    .fe {
      font-size: 19px;
    }
  }
</style>
