import { Component, computed, ComputedRef, reactive, unref } from "vue";

import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsRefreshResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import { PendingLogic } from "frontend/utils/pending-logic";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { Provider } from "frontend/utils/provider";
import { FilterManager, FilterType } from "frontend/uses/filter/filter-manager";
import {
  BaseS,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
import { Errors } from "frontend/uses/use-errors";

export type MinimalEntity = { id: PrimaryKey };

const PROVIDE_INJECT_KEY = "settings-manager";

export abstract class SettingsManager<T extends MinimalEntity> {
  private internalPending: PendingLogic;
  private internalData;
  private internalProvider: Provider<SettingsManager<T>>;
  private internalFilterManager: FilterManager<T> | undefined;
  private dragIndex = -1;
  private dragItem: T | undefined;

  constructor() {
    this.internalPending = new PendingLogic();
    this.internalData = reactive({
      entities: [],
    }) as {
      entities: T[];
    };
    this.internalProvider = new Provider(PROVIDE_INJECT_KEY, this);

    if (this.enableFilter())
      this.getFilterManager().subscribeStateChange((filterType) => {
        this.onFilterStateChange(filterType);
      });
  }

  static inject<R extends MinimalEntity, T extends SettingsManager<R>>(): T {
    return Provider.injectSafe<T>(PROVIDE_INJECT_KEY);
  }

  provide(): void {
    this.internalProvider.provide();
  }

  async refreshEntities(): Promise<SettingsRefreshResult<T> | void> {
    return await this.internalPending.doWithPending(async () => {
      this.internalData.entities = [];

      if (this.enableFilter()) await this.initFilterOptions();

      const result = await this.hookList();
      if (result.success) this.internalData.entities = result.entities;
      return {
        entities: this.entitiesR(),
        success: result.success,
        errors: result.errors,
      };
    });
  }
  async save(record: T): Promise<SettingsSaveResult<T> | void> {
    if (this.useModal()) {
      this.openEntityModal(record);
    } else
      return await this.internalPending.doWithPending(async () => {
        const result = await this.hookSave(record);
        if (result.success) {
          this.updateEntityById(record.id, result.entity);
        }
        return result;
      });
  }
  async delete(record: T): Promise<SettingsDeleteResult | void> {
    return await this.internalPending.doWithPending(async () => {
      const result = await this.hookDelete(record);
      if (result.success) {
        this.removeEntityById(record.id);
      }
      return result;
    });
  }
  addNewEntity(): void {
    if (this.useModal()) this.openEntityModal(this.craftNewEntity());
    else unref(this.internalData.entities).push(this.craftNewEntity());
  }

  initParams(params: Record<string, unknown>): Record<string, unknown> {
    return params;
  }

  abstract entityName(): string;
  abstract componentForEdit(): Component;
  abstract craftNewEntity(): T;
  abstract hookList(): Promise<SettingsListResult<T>>;
  abstract hookSave(record: T): Promise<SettingsSaveResult<T>>;
  abstract hookDelete(record: T): Promise<SettingsDeleteResult>;

  enableNewEntities(): boolean {
    return true;
  }

  enableFilter(): boolean {
    return false;
  }

  useModal(): boolean {
    return false;
  }

  modalComponent(): Component | void {
    return;
  }

  enableDragAndDrop(): boolean {
    return false;
  }

  onDrag(entity: T): void {
    this.dragIndex = this.getIndexOfEntity(entity.id);
    this.dragItem = entity;
  }

  async onDrop(entity: T): Promise<void> {
    if (this.dragItem) {
      const dropIndex = this.getIndexOfEntity(entity.id);
      this.internalData.entities.splice(this.dragIndex, 1);
      this.internalData.entities.splice(dropIndex, 0, this.dragItem);
      await this.sortEntities(
        this.internalData.entities.map((entity) => entity.id)
      );
    }
  }

  openEntityModal(record: T): void {
    const component = this.modalComponent();
    if (component) {
      const componentProps = reactive({
        entity: record,
        errors: {} as Partial<Record<string, Errors>>,
        globalErrors: [] as string[],
        visualModal: true,
      });
      useSimpleModal().custom(component, {
        noAutoClose: true,
        hideCancel: false,
        hideConfirm: false,
        componentProps,
        onClose: async (confirm, close) => {
          if (confirm) {
            const result = await this.hookSave(record);
            if (result.errors && Object.keys(result.errors).length > 0) {
              componentProps.errors = result.errors;
            } else if (!result.success) {
              componentProps.globalErrors.push(
                `${this.entityName()} konnte nicht gespeichert werden.`
              );
            } else {
              this.refreshEntities();
              close();
            }
          } else {
            close();
          }
        },
      });
    }
  }

  async sortEntities(_entity_ids: PrimaryKey[]): Promise<boolean> {
    return false;
  }

  getFilterManager(): FilterManager<T> {
    if (!this.internalFilterManager)
      this.internalFilterManager = new FilterManager<T>(
        this.initFilterDefinitions()
      );
    return this.internalFilterManager;
  }

  async initFilterOptions(): Promise<void> {
    return;
  }
  initFilterDefinitions(): FilterDefinition<T, BaseS>[] {
    return [];
  }

  onFilterStateChange(_filterType: FilterType) {
    if (_filterType.isBackend) {
      this.refreshEntities();
    }

    if (_filterType.isFrontend) {
      // trigger computation / effect in vuejs
      this.internalData.entities = [...this.internalData.entities];
    }
  }

  removeEntityById(id: PrimaryKey): void {
    const index = this.getIndexOfEntity(id);
    if (index >= 0) {
      this.internalData.entities.splice(index, 1);
    }
  }

  updateEntityById(id: PrimaryKey, newRecord: T) {
    const index = this.getIndexOfEntity(id);
    if (index >= 0) {
      this.internalData.entities[index] = newRecord;
    }
  }

  getIndexOfEntity(id: PrimaryKey): number {
    return this.internalData.entities.findIndex((entry) => entry.id === id);
  }

  createEditable(record: T): T {
    return {
      ...record,
    };
  }

  isPendingR() {
    return this.internalPending.isPendingR();
  }

  entitiesR(): ComputedRef<T[]> {
    return computed(() => {
      const filter = this.enableFilter()
        ? this.getFilterManager().filter.bind(this.getFilterManager())
        : (collection: T[]) => collection;
      return filter(this.internalData.entities);
    });
  }
}
