import { reactive } from "vue";

import { assertEntryType } from "shared/utils/typescript-helper";
import {
  OnLoginBefore,
  OnLogoutAfter,
  OnRequestHasRawResponse,
  OnRequestImminent,
} from "frontend/events/topics";
import { requestColors } from "frontend/api/application/request-colors";
import { requestInsurances } from "frontend/api/application/request-insurances";
import { requestLocations } from "frontend/api/application/request-locations";
import { requestPersons } from "frontend/api/application/request-persons";
import { requestRoles } from "frontend/api/application/request-roles";
import { parseInsurance } from "frontend/parser/parse-insurance";
import { parseLocation } from "frontend/parser/parse-location";
import { parsePerson } from "frontend/parser/parse-person";
import { parseRole } from "frontend/parser/parse-role";
import { parseColor } from "frontend/parser/parse-color";
import { synchronized } from "frontend/utils/synchronize";
import { requestRooms } from "frontend/api/application/request-rooms";
import { parseRoom } from "frontend/parser/parse-room";
import { parseOperator } from "frontend/parser/parse-operator";
import { requestOperators } from "frontend/api/application/request-operators";
import { requestSettingsProfiles } from "frontend/api/application/request-settings-profiles";
import { parseSettingsProfile } from "frontend/parser/settings/parse-settings-profile";
import { capitalize } from "shared/utils/string-helper";
import { HttpMethod } from "frontend/interfaces/http";
import { log, LogLevel } from "shared/utils/logger";
import { PathURLTemplate } from "shared/utils/pathify";
import { routes } from "frontend/api/application";
import { clearArray } from "shared/utils/array-utils";
import { requestSchedules } from "frontend/api/application/request-schedules";
import { parseSchedule } from "frontend/parser/settings/parse-schedule";

// ----------------------------------------------------------------------------
// Configuration and setup of caches
// ----------------------------------------------------------------------------
// Available cache entries
// add news as you go
// each entry
//   * should be of type CacheEntry<Raw, Parsed>
//   * the key will be transformed to `getCached${capitalize(key)}`
// and the module will export these as method to retrieve
// the (reactive) cache
const CACHE_ENTRIES = {
  locations: {
    load: requestLocations,
    parse: parseLocation,
  },
  roles: {
    load: requestRoles,
    parse: parseRole,
  },
  persons: {
    load: requestPersons,
    parse: parsePerson,
  },
  insurances: {
    load: requestInsurances,
    parse: parseInsurance,
  },
  colors: {
    load: requestColors,
    parse: parseColor,
  },
  rooms: {
    load: requestRooms,
    parse: parseRoom,
  },
  operators: {
    load: requestOperators,
    parse: parseOperator,
  },
  schedules: {
    load: requestSchedules,
    parse: parseSchedule,
  },
  settingsProfiles: {
    load: requestSettingsProfiles,
    parse: parseSettingsProfile,
  },
} as const;

// Define when cache should be cleared automatically upon certain requests
const CLEARING_MAP = new Map<PathURLTemplate, CacheName[]>();
CLEARING_MAP.set(routes.paths.frontend_location_path, ["locations"]);
CLEARING_MAP.set(routes.paths.frontend_locations_path, ["locations"]);
CLEARING_MAP.set(routes.paths.frontend_role_path, ["roles"]);
CLEARING_MAP.set(routes.paths.frontend_roles_path, ["roles"]);
CLEARING_MAP.set(routes.paths.frontend_person_path, ["persons"]);
CLEARING_MAP.set(routes.paths.frontend_persons_path, ["persons"]);
CLEARING_MAP.set(routes.paths.frontend_color_path, ["colors"]);
CLEARING_MAP.set(routes.paths.frontend_colors_path, ["colors"]);
CLEARING_MAP.set(routes.paths.frontend_room_path, ["rooms"]);
CLEARING_MAP.set(routes.paths.frontend_rooms_path, ["rooms"]);
CLEARING_MAP.set(routes.paths.frontend_settings_operator_path, ["operators"]);
CLEARING_MAP.set(routes.paths.frontend_settings_operators_path, ["operators"]);
CLEARING_MAP.set(routes.paths.frontend_settings_schedule_path, ["schedules"]);
CLEARING_MAP.set(routes.paths.frontend_settings_schedules_path, ["schedules"]);
CLEARING_MAP.set(routes.paths.frontend_settings_settings_profile_path, [
  "settingsProfiles",
]);
CLEARING_MAP.set(routes.paths.frontend_settings_settings_profiles_path, [
  "settingsProfiles",
]);
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Type definition of a single cache entry for CACHE_ENTRIES
// ----------------------------------------------------------------------------
type LoadMethod<Raw> = () => Promise<{ entities: Raw[] }>;
type ParseMethod<Raw, Parsed> = (entity: Raw) => Parsed;
interface CacheEntry<Raw, Parsed> {
  readonly load: LoadMethod<Raw>;
  readonly parse: ParseMethod<Raw, Parsed>;
}
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Helper types to transform type of CacheEntries to
// resulted exported object
// ----------------------------------------------------------------------------
type KeyToExportedMethodName<Key extends string> =
  `getCached${Capitalize<Key>}`;
type ExportedMethodNameToKey<Key extends string> =
  Key extends `getCached${infer Original}` ? Uncapitalize<Original> : never;
type ExportKeysOf<OriginalHash extends object> = KeyToExportedMethodName<
  Extract<keyof OriginalHash, string> // convert keyof OriginalHash from string | number | symbol to string
>;
type ExportedMethodType<Parsed> = (
  options?: LoadOptions
) => Promise<Array<Parsed>>;
type TransformKeysOf<OriginalHash extends object> = {
  [MethodName in ExportKeysOf<OriginalHash>]: ExportedMethodNameToKey<MethodName> extends keyof OriginalHash
    ? OriginalHash[ExportedMethodNameToKey<MethodName>]
    : never;
};
type TransformValuesOf<TransformedKeysHash extends object> = {
  [key in keyof TransformedKeysHash]: TransformedKeysHash[key] extends {
    parse: ParseMethod<never, infer Parsed>;
  }
    ? ExportedMethodType<Parsed>
    : never;
};
type CacheEntries = typeof CACHE_ENTRIES;
type CacheName = keyof CacheEntries;
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// compile time assertions
// if you get an error here,
//   you made something wrong in CACHE_ENTRIES
//   typewise
// ----------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- typescript type assertion, not meant to be "used"
assertEntryType<CacheEntry<any, any>, typeof CACHE_ENTRIES>(
  CACHE_ENTRIES,
  "CACHE_ENTRIES contains an entry that is not of type CacheEntry<any, any>"
);
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// helper methods
// ----------------------------------------------------------------------------
// What options can be given to getCached${capitalize(key)}?
// forceLoad: should the cache be fetched from the backend
//            even if it is already loaded?
type LoadOptions = {
  forceLoad?: boolean;
};

// generic higher order function to create the cache getter for a given entry
function createCacheGetter<Raw, Parsed>(
  key: CacheName,
  entry: CacheEntry<Raw, Parsed>
): (options?: LoadOptions) => Promise<Array<Parsed>> {
  return async (options?: LoadOptions) =>
    getOrLoadCache(
      key,
      {
        forceLoad: options?.forceLoad,
      },
      async () => ((await entry.load()).entities || []).map(entry.parse)
    );
}

// takes an original key from CACHE_ENTRIES and transforms it to the exported
// key according to the type ExportMethodName<Key> where Key is the literal
// string type of the original key
function mapKey<Key extends string>(key: Key): KeyToExportedMethodName<Key> {
  return `getCached${capitalize(key)}`;
}
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Exported Cache-object
// ----------------------------------------------------------------------------
export const Cache = Object.fromEntries(
  Object.entries(CACHE_ENTRIES).map(([key, entry]) => [
    mapKey(key),
    createCacheGetter(key as CacheName, entry as CacheEntry<unknown, unknown>),
  ])
) as unknown as TransformValuesOf<TransformKeysOf<typeof CACHE_ENTRIES>>;

// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// internal logic of cache
// ----------------------------------------------------------------------------
// database object
let CACHE: Partial<Record<CacheName, Array<unknown>>> = {};
resetCache();

// database of keys that have to be reloaded after a / the request
const keysToReload: CacheName[] = [];

// access for database object
async function getOrLoadCache<T>(
  key: CacheName,
  options: LoadOptions,
  load: () => Promise<Array<T>>
): Promise<Array<T>> {
  await synchronized(key, async () => {
    if (!CACHE[key] || options.forceLoad) {
      const data = await load();

      if (!CACHE[key]) CACHE[key] = reactive([]);
      clearArray(CACHE[key] as Array<T>);
      (CACHE[key] as Array<T>).push(...data);
    }
  });

  return CACHE[key] as Array<T>;
}

// reset database for a given key
function clearCache(key: CacheName): void {
  if (CACHE[key]) {
    clearArray(CACHE[key] as Array<never>);
    keysToReload.push(key);
  }
}

function resetCache(): void {
  log(LogLevel.Debug, "[CACHE] resetting cache");
  CACHE = {};
}

// reload database for a given key:
//   * after clearing, because should be reactive
async function reloadCache(key: CacheName): Promise<void> {
  log(LogLevel.Trace, `[CACHE] reloading ${key}`);
  await Cache[mapKey(key)]({ forceLoad: true });
}

// setup method to be called upon application setup
// to enable listening to backend-requests and thus
// allowing to clear a stale cache
export function setupEvents() {
  OnRequestImminent.subscribe((data) => {
    if (data.method === HttpMethod.GET) return;

    for (const key of CLEARING_MAP.get(data.path) || []) {
      log(LogLevel.Trace, `[CACHE] clearing ${key}`);
      clearCache(key);
    }
  });

  OnRequestHasRawResponse.subscribe(async () => {
    await synchronized("CACHE:keysToReload", async () => {
      for (const key of new Set(keysToReload)) {
        await reloadCache(key);
      }
      clearArray(keysToReload);
    });
  });

  OnLogoutAfter.or(OnLoginBefore).subscribe(async () => {
    resetCache();
  });
}
// ----------------------------------------------------------------------------
