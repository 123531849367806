<script lang="ts">
  import { computed, defineComponent, ref } from "vue";
  import { addWeeks, eachDayOfInterval } from "date-fns";

  import {
    DateFormat,
    formatDate,
    today,
    weekBoundaries,
  } from "shared/utils/date-utils";
  import { MiniCalendarDay } from "frontend/interfaces/mini-calendar";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import AttendancesCalendar from "frontend/routes/settings/AttendancesCalendar.vue";
  import AttendancesList from "frontend/routes/settings/AttendancesList.vue";
  import MiniCalendarAsBox from "frontend/components/MiniCalendarAsBox.vue";

  export default defineComponent({
    components: {
      BaseHelp,
      BaseButton,
      AttendancesCalendar,
      AttendancesList,
      MiniCalendarAsBox,
    },
    setup() {
      const currentDate = ref<Date>(new Date());

      //current Date
      const currentDateFormatted = computed(() => {
        const { start, end } = weekBoundaries(currentDate.value);
        return `${formatDate(
          currentDate.value,
          DateFormat.CalendarWeek
        )}, ${formatDate(start, DateFormat.DateOnly)} - ${formatDate(
          end,
          DateFormat.DateOnly
        )}`;
      });
      const doCalendarNextDate = (forward: boolean) => {
        currentDate.value = addWeeks(currentDate.value, forward ? 1 : -1);
      };
      const jumpToCurrentDate = () => {
        currentDate.value = today();
      };

      //Switch Buttons
      const attendanceCalendar = ref<boolean>(true);
      const mainSwitchButtons = [
        {
          key: "1",
          label: "Kalender",
          onSubmit: () => {
            attendanceCalendar.value = true;
          },
          isActive: computed(() => attendanceCalendar.value),
        },
        {
          key: "2",
          label: "Liste",
          onSubmit: () => {
            attendanceCalendar.value = false;
          },
          isActive: computed(() => !attendanceCalendar.value),
        },
      ];
      const isOpen = ref<boolean>(false);
      const iconClass = computed(() =>
        isOpen.value ? "fe-chevron-up" : "fe-chevron-down"
      );
      const highlightedDays = computed(() =>
        currentDate.value
          ? eachDayOfInterval(weekBoundaries(currentDate.value))
          : []
      );
      const selectDate = (selectedDate: MiniCalendarDay) => {
        currentDate.value = selectedDate.date;
      };
      const filterParams = computed(() => ({
        date: formatDate(currentDate.value, DateFormat.ISO),
      }));

      return {
        mainSwitchButtons,
        currentDateFormatted,
        doCalendarNextDate,
        jumpToCurrentDate,
        iconClass,
        currentDate,
        highlightedDays,
        isOpen,
        selectDate,
        attendanceCalendar,
        filterParams,
      };
    },
  });
</script>

<template>
  <div class="settings-schedule">
    <h2>Wochenansicht</h2>
    <BaseHelp>
      <p>
        Diese Ansicht zeight die Praxiszeiten für die augewählte Woche. Hier
        haben Sie Einblick auf die von Ihnen eingegebene Anwesenheiten der
        einzelnen Mitarbeiter (Übersicht bei Auswahl aller Mitarbeiter durch
        Haken).
      </p>
    </BaseHelp>

    <div class="topbar__container">
      <div class="topbar__button-container">
        <div class="topbar__time-navigator">
          <BaseButton class="topbar__time-navigator-button">
            <i
              class="fe fe-arrow-left-boxed"
              v-on:click="doCalendarNextDate(false)"
            ></i>
            {{ currentDateFormatted }}
            <i
              class="fe fe-arrow-right-boxed"
              v-on:click="doCalendarNextDate(true)"
            ></i>
            <i
              class="fe"
              v-bind:class="[iconClass]"
              v-on:click="isOpen = !isOpen"
            ></i>
          </BaseButton>
          <MiniCalendarAsBox
            v-if="isOpen"
            v-model="currentDate"
            class="topbar__mini-calendar"
            v-bind:selected-days="highlightedDays"
            v-on:date-selected="selectDate"
          />
        </div>
        <div>
          <BaseButton v-on:submit="jumpToCurrentDate"
            >Aktuelle Woche</BaseButton
          >
        </div>
      </div>
      <div class="topbar__attendance-view">
        <BaseButton
          v-for="button in mainSwitchButtons"
          v-bind:key="button.key"
          v-bind:class="{ 'button--inverted': !button.isActive.value }"
          v-on:submit="button.onSubmit()"
        >
          {{ button.label }}
        </BaseButton>
      </div>
    </div>
    <AttendancesCalendar
      v-if="attendanceCalendar"
      v-bind:filter-params="filterParams"
    />
    <AttendancesList
      v-if="!attendanceCalendar"
      v-bind:filter-params="filterParams"
    />
  </div>
</template>

<style lang="scss" scoped>
  .topbar__button-container {
    display: flex;
    margin-bottom: 10px;
    > div {
      margin-right: 22px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .topbar__time-navigator-button {
    // REFACTOR: overwritten button styles?
    padding-left: 7px;
    padding-right: 7px;

    .fe-arrow-left-boxed {
      // REFACTOR: dimension-variable?
      margin-right: 15px;
    }
    .fe-arrow-right-boxed {
      // REFACTOR: dimension-variable?
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  .topbar__container {
    display: flex;
    margin: 20px 0;
  }

  .topbar__attendance-view {
    margin-left: auto;
  }

  .topbar__time-navigator {
    display: flex;
    flex-direction: column;
  }

  .topbar__mini-calendar {
    width: 220px;
    margin-top: 10px;
    align-self: center;
  }
</style>
