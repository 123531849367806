import { formatISO, isDate, isValid, parseISO } from "date-fns";

const PARSE_HINT_DATE = ":>>PARSE-HINT/DATE<<:"; // this expression should not occur in the wild

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- match JSON.parse signature
export function parseJSON(json: string): any {
  return JSON.parse(json, function (key: string, value: unknown) {
    if (typeof value === "string" && value.startsWith(PARSE_HINT_DATE)) {
      const valueAsserted = value as string;
      const date = parseISO(valueAsserted.slice(PARSE_HINT_DATE.length));
      return isValid(date) ? date : new Date();
    } else {
      return value;
    }
  });
}

export function stringifyJSON(obj: unknown): string {
  return JSON.stringify(obj, function (_key: string, value: unknown) {
    const originalValue = this[_key];
    if (isDate(originalValue)) {
      const valueAsserted = originalValue as Date;
      return `${PARSE_HINT_DATE}${formatISO(valueAsserted)}`;
    } else {
      return value;
    }
  });
}
