import { CalendarConstants } from "frontend/interfaces/calendar";

export const ConstantsDayView: CalendarConstants = Object.freeze({
  // step size in day view
  //   in minutes
  TIMESCALE_STEP_SIZE: 15,
  // step size for a little visual indicator
  //   in day-view
  //   in minutes
  TIMESCALE_VISUAL_INDICATOR_STEP_SIZE: 10,
  // minimum "virtual" duration each unit
  // gets displayed with even if the real duration
  // is less
  //   in minutes
  DISPLAY_DURATION_AT_LEAST: 5,
  CREATION_DEFAULT_DURATION: 5,
  // resolution of drop in day-view in minutes
  ROUND_DROP_TO_NEAREST: 5,
  // resolution of click in day-view in minutes
  ROUND_CLICK_TO_NEAREST: 5,
  // step size beginning point
  FLOOR_CEIL_STEP_SIZE: 15,
});

export const ConstantsSettingsAttendances: CalendarConstants = Object.freeze({
  // step size in day view
  //   in minutes
  TIMESCALE_STEP_SIZE: 30,
  // minimum "virtual" duration each unit
  // gets displayed with even if the real duration
  // is less
  //   in minutes
  DISPLAY_DURATION_AT_LEAST: 10,
  CREATION_DEFAULT_DURATION: 5,
  // resolution of drop in day-view in minutes
  ROUND_DROP_TO_NEAREST: 5,
  // resolution of click in day-view in minutes
  ROUND_CLICK_TO_NEAREST: 5,
  // step size beginning point
  FLOOR_CEIL_STEP_SIZE: 15,
});

// global BaseCalendar-settings
export const Constants = Object.freeze({
  // width of the horizontal cell sperators in calendar
  BORDER_WIDTH_IN_PIXELS: 1,
});
