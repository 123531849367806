<script lang="ts">
  import { computed, defineComponent, toRefs } from "vue";

  import { isActive, setFlag } from "shared/utils/bitwise-enum";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  export default defineComponent({
    components: { FormCheckbox },
    props: {
      modelValue: {
        type: Number,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      flag: {
        type: Number,
        default: null,
      },
    },
    emits: ["update:modelValue", "autosave"],
    setup(props, { emit }) {
      const { modelValue, flag } = toRefs(props);
      return {
        isChecked: computed(() => {
          if (modelValue.value == null) return false;
          if (flag.value == null) return false;
          return isActive(modelValue.value, flag.value);
        }),
        onUpdate: (checked: boolean) => {
          const newValue = setFlag(modelValue.value, flag.value, checked);
          emit("update:modelValue", newValue);
          emit("autosave");
        },
      };
    },
  });
</script>

<template>
  <div class="form-checkbox-bitwise">
    <FormCheckbox
      v-bind:label="label"
      v-bind:model-value="isChecked"
      v-on:update:model-value="onUpdate"
    />
  </div>
</template>
