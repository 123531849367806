import { Modal } from "frontend/utils/modals/modal";
import { Absence } from "frontend/interfaces/settings/absence";

import ConfirmAbsenceComponent from "frontend/components/ConfirmAbsence.vue";

export interface ConfirmAbsenceModalData {
  absence: Absence;
  // errorDatabase: ErrorDatabase;
}
export class ConfirmAbsenceModal extends Modal<
  typeof ConfirmAbsenceComponent,
  ConfirmAbsenceModalData
> {
  protected modalComponent() {
    return ConfirmAbsenceComponent;
  }

  protected hideCancel() {
    return true;
  }

  protected hideConfirm() {
    return true;
  }
}
