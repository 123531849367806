// Adding older browser (e.g. IE11) compatibility
import "core-js/stable";
import "regenerator-runtime/runtime";

// Fire up application
require("./frontend/main");

// Load styles
require("./frontend/styles/main.scss");

// Load iconfont(s)
require("./shared/iconfonts/frontend.font");
