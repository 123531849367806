import { ref, Ref, unref, watch } from "vue";

import { AssertsNonUnion } from "shared/utils/typescript-helper";

type Observers =
  | typeof ResizeObserver
  | typeof IntersectionObserver
  | typeof MutationObserver;

export function useObserver<T extends Observers>(
  observerClass: AssertsNonUnion<T>,
  callback: (info: Parameters<ConstructorParameters<T>[0]>[0][number]) => void,
  element?: Ref<Element | null>,
  options?: ConstructorParameters<T>[1],
  observeOptions?: Parameters<InstanceType<T>["observe"]>[1]
): {
  element: Ref<Element | null>;
  doDisconnect: () => void;
} {
  const theElement = element || ref(null);
  const observer = new observerClass((entries) => {
    for (const entry of entries) {
      if (entry.target == unref(theElement)) {
        callback(entry);
      }
    }
  }, options);

  watch(
    theElement,
    () => {
      observer.disconnect();

      const elementUnref = unref(theElement);
      if (elementUnref) {
        observer.observe(elementUnref, observeOptions);
      }
    },
    { immediate: true }
  );

  return {
    element: theElement,
    doDisconnect: () => {
      observer.disconnect();
    },
  };
}
