import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { RoomRaw } from "frontend/interfaces/room";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestRooms(): Promise<UnifiedListResponse<RoomRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_rooms_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { rooms: RoomRaw[] }).rooms,
  };
}

export async function updateRoom(
  room: HttpPayload
): Promise<UnifiedResponse<RoomRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_room_path,
    { id: room.id as string },
    room
  );

  return {
    entityRaw: (body as unknown as { room: RoomRaw }).room,
    success: statusIsSuccess,
    errors:
      (body as unknown as { room: { _errors?: ErrorDatabase } }).room._errors ||
      {},
  };
}

export async function createRoom(
  room: HttpPayload
): Promise<UnifiedResponse<RoomRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_rooms_path,
    {},
    room
  );

  return {
    entityRaw: (body as unknown as { room: RoomRaw }).room,
    success: statusIsSuccess,
    errors:
      (body as unknown as { room: { _errors?: ErrorDatabase } }).room._errors ||
      {},
  };
}

export async function deleteRoom(id: PrimaryKey): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_room_path,
    { id },
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
  };
}
