<script lang="ts">
  import { defineComponent, PropType } from "vue";

  import { Unit } from "frontend/interfaces/unit";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: {
      FormCheckbox,
      BaseSpinner,
      FormInput,
    },
    props: {
      unit: {
        type: Object as PropType<Unit>,
        required: true,
      },
      notifyPatientOnDeletion: {
        type: Boolean,
        default: true,
      },
      reasonForDeletion: {
        type: String as PropType<string | null>,
        default: null,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:notifyPatientOnDeletion", "update:reasonForDeletion"],
    setup(_, { emit }) {
      return {
        onUpdateNotifyPatientOnDeletion: (newValue: boolean) => {
          emit("update:notifyPatientOnDeletion", newValue);
        },
        onUpdateReasonForDeletion: (newValue: string) => {
          emit("update:reasonForDeletion", newValue);
        },
      };
    },
  });
</script>

<template>
  <div class="delete-appointment">
    <h3>Bestätigung</h3>
    <div>
      Sind Sie sicher, dass Sie den
      <strong>Termin löschen</strong> möchten?
    </div>
    <br /><br />
    <FormCheckbox
      v-if="unit.appointment?.patient"
      v-bind:model-value="notifyPatientOnDeletion"
      label="den Patienten über den abgesagten Termin informieren"
      v-on:update:model-value="onUpdateNotifyPatientOnDeletion"
    />
    <FormInput
      v-if="unit.appointment?.patient && notifyPatientOnDeletion"
      v-bind:model-value="reasonForDeletion"
      label="Nachrichtentext an den Patienten z.B Grund der Absage (optional)"
      type="textarea"
      v-on:update:model-value="onUpdateReasonForDeletion"
    />
    <br /><br />
    <div v-if="isLoading" class="delete-appointment__loading">
      <BaseSpinner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .delete-appointment__loading {
    margin-top: 50px;
  }
</style>
