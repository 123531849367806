type BitwiseEnumValue = number;
type BitwiseEnumFlag = number;

export function isActive(
  value: BitwiseEnumValue,
  flag: BitwiseEnumFlag
): boolean {
  return (value & flag) === flag;
}

export function activate(
  value: BitwiseEnumValue,
  flag: BitwiseEnumFlag
): BitwiseEnumValue {
  return value | flag;
}

export function deactivate(
  value: BitwiseEnumValue,
  flag: BitwiseEnumFlag
): BitwiseEnumValue {
  if (isActive(value, flag)) {
    return value - flag;
  }

  return value;
}

export function setFlag(
  value: BitwiseEnumValue,
  flag: BitwiseEnumFlag,
  enable: boolean
) {
  if (enable) {
    return activate(value, flag);
  } else {
    return deactivate(value, flag);
  }
}
