import { Component } from "vue";

import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  genericDeletePattern,
  genericListPattern,
  genericSavePattern,
} from "frontend/uses/settings/use-edit-component";
import { Color } from "frontend/interfaces/color";
import {
  httpPayloadColor,
  newColor,
  parseColor,
} from "frontend/parser/parse-color";
import {
  createColor,
  deleteColor,
  requestColors,
  updateColor,
} from "frontend/api/application/request-colors";

import EditColor from "frontend/components/settings/EditColor.vue";

export class ColorsSettingsManager extends SettingsManager<Color> {
  entityName(): string {
    return "Farbe";
  }
  componentForEdit(): Component {
    return EditColor;
  }
  craftNewEntity(): Color {
    return newColor();
  }
  hookList(): Promise<SettingsListResult<Color>> {
    return genericListPattern(parseColor, requestColors);
  }
  hookSave(record: Color): Promise<SettingsSaveResult<Color>> {
    return genericSavePattern(
      record,
      parseColor,
      httpPayloadColor,
      createColor,
      updateColor
    );
  }
  hookDelete(record: Color): Promise<SettingsDeleteResult> {
    return genericDeletePattern(record, (entity) => entity.id, deleteColor);
  }
}
