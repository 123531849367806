import { AppointmentStatus } from "shared/static/enums.ts.erb";

interface EnumSettingDef {
  enumKey: keyof typeof AppointmentStatus;
  editable: boolean;
  label: string;
}
export type EnumSetting = Readonly<EnumSettingDef>;
export type EnumSettings = readonly EnumSetting[];

export const AppointmentStatusControls: EnumSettings = Object.freeze([
  Object.freeze({
    enumKey: "patient_arrived",
    editable: true,
    label: "Patient in der Praxis",
  }),
  Object.freeze({
    enumKey: "patient_not_arrived",
    editable: true,
    label: "Patient nicht erschienen",
  }),
  Object.freeze({
    enumKey: "done",
    editable: true,
    label: "Termin abgeschlossen",
  }),
]);
