import { routes, doRequest } from "frontend/api/application";
import { OfficeRaw } from "frontend/interfaces/office";
import {
  HttpMethod,
  HttpPayload,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestOffice(): Promise<OfficeRaw> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_office_path,
    {},
    {}
  );

  if (statusIsSuccess) {
    return body?.office as unknown as OfficeRaw;
  } else {
    throw new Error("Error requesting the office");
  }
}

export async function updateOffice(
  office: HttpPayload
): Promise<UnifiedResponse<OfficeRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_office_path,
    {},
    office
  );

  return {
    entityRaw: (body as unknown as { office: OfficeRaw }).office,
    success: statusIsSuccess,
    errors:
      (body as unknown as { office: { _errors?: ErrorDatabase } }).office
        ._errors || {},
  };
}

export async function block(): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.block_frontend_office_path,
    {},
    {}
  );

  return statusIsSuccess;
}

export async function unblock(): Promise<boolean> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.unblock_frontend_office_path,
    {},
    {}
  );

  return statusIsSuccess;
}
