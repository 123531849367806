<script lang="ts">
  import { computed, defineComponent, PropType, toRefs, unref } from "vue";
  import { RouteLocationRaw, useRouter } from "vue-router";

  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";

  export default defineComponent({
    components: { BaseSpinner },
    props: {
      // Variants of the button
      // (mostly styling)
      visualAction: {
        type: Boolean,
        default: false,
      },
      visualInverted: {
        type: Boolean,
        default: false,
      },
      visualFullWidth: {
        type: Boolean,
        default: false,
      },
      visualIcon: {
        type: Boolean,
        default: false,
      },
      visualSmall: {
        type: Boolean,
        default: false,
      },
      visualWarn: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      route: {
        type: Object as PropType<RouteLocationRaw | null>,
        default: null,
      },
    },
    emits: [
      "submit", // called if the button is clicked or otherwise "submitted"
    ],
    setup(props, { emit }) {
      const { disabled, route, loading } = toRefs(props);
      const router = useRouter();
      const inNotSubmitMode = computed(() => {
        return unref(disabled) || unref(loading);
      });
      const onClick = (event: Event) => {
        if (unref(inNotSubmitMode)) {
          return;
        }
        event.stopPropagation();
        const routeInfo = unref(route);
        if (routeInfo) {
          router.push(routeInfo);
        }
        emit("submit");
      };
      return { onClick };
    },
  });
</script>

<template>
  <div
    class="vue-component__base-button button"
    v-bind:class="{
      'button--action': visualAction,
      'button--full-width': visualFullWidth,
      'button--inverted': visualInverted,
      'button--icon': visualIcon,
      'button--disabled': disabled,
      'button--loading': loading,
      'button--warn': visualWarn,
      'button--small': visualSmall,
    }"
    v-on:click="onClick"
  >
    <slot v-if="!loading"></slot>
    <template v-else>
      <BaseSpinner v-bind:visual-inverted="!visualInverted" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "frontend/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";

  .button {
    // positioning outer
    display: inline-block;

    // size and spacing
    padding: 7px 20px;
    line-height: 1;

    // visual style
    font-family: "Open Sans Condensed", sans-serif;
    background-color: colors.$color_button--background;
    color: colors.$color_button--text;
    border: none;
    border-radius: dimensions.$dimension_button--border-radius;

    @if features.$feature_button--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    text-decoration: none;
    font-size: 17px;

    // functional style
    cursor: pointer;

    &:hover {
      background-color: color.adjust(
        colors.$color_button--background,
        $lightness: -5%
      );
    }
  }

  .button--action {
    // visual style
    background-color: colors.$color_button__action--background;
    color: colors.$color_button__action--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__action--background,
        $lightness: -5%
      );
    }
  }

  .button--warn {
    background-color: colors.$color_button__warn--background;
    color: colors.$color_button__warn--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__warn--background,
        $lightness: -5%
      );
    }
  }

  .button--inverted {
    // visual style
    color: colors.$color_button__inverted--text;
    background-color: colors.$color_button__inverted--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__inverted--background,
        $lightness: -5%
      );
    }
  }

  .button--inverted.button--action {
    // visual style
    background-color: colors.$color_button__action--text;
    color: colors.$color_button__action--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__action--text,
        $lightness: -5%
      );
    }
  }

  .button--inverted.button--warn {
    background-color: colors.$color_button__warn--text;
    color: colors.$color_button__warn--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__warn--text,
        $lightness: -5%
      );
    }
  }

  .button--full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  .button--disabled,
  .button--loading {
    cursor: not-allowed;
  }

  .button--small {
    font-size: 12px;
  }
</style>

<style lang="scss">
  .button__icon {
    margin-right: 5px;
  }
</style>
