import { SimpleModal } from "frontend/uses/simple-modal/variants/simple-modal";

export class ConfirmModal extends SimpleModal {
  get defaultTitle(): string {
    return "Bestätigung";
  }
  get confirmLabel() {
    return "Ja";
  }

  get cancelLabel() {
    return "Nein";
  }
}
