import { parseISO } from "date-fns";

import { EventEntry, EventEntryRaw } from "frontend/interfaces/event-entry";

export function parseEventEntry(eventEntry: EventEntryRaw): EventEntry {
  return {
    ...eventEntry,
    event_date: parseISO(eventEntry.event_date),
    ...parseEventEntryUser(eventEntry),
  };
}

function parseEventEntryUser(
  eventEntry: EventEntryRaw
): Record<string, never> | { user: NonNullable<typeof eventEntry.user> } {
  if (eventEntry.user) {
    const user = eventEntry.user;
    return {
      user: {
        ...user,
      },
    };
  } else return {};
}
