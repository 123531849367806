import { v4 as uuid } from "uuid";
import { Ref, ref, unref } from "vue";

export function useFormLabelling(): {
  pairing: Ref<string>;
  getPairing: () => string;
} {
  const pairing = ref(uuid());
  return {
    pairing,
    getPairing: () => unref(pairing),
  };
}
