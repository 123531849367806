import { v4 as uuid } from "uuid";

import {
  AppointmentType,
  AppointmentTypeNew,
  AppointmentTypeRaw,
} from "frontend/interfaces/appointment-type";
import { HttpPayload } from "frontend/interfaces/http";
import { parseSession } from "frontend/parser/parse-session";

export function parseAppointmentType(
  appointmentTypeRaw: AppointmentTypeRaw
): AppointmentType {
  const { sessions, ...temp } = appointmentTypeRaw;
  return {
    ...temp,
    sessions: sessions?.map(parseSession),
  };
}

export function httpPayloadAppointmentType(
  appointmentType: AppointmentType
): HttpPayload {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars --  we want to remove it, so we need it in destructuring
  const { sessions, ...temp } = appointmentType;
  return {
    ...temp,
  };
}

// FIXME: @daniel wird das verwendet?
export function newAppointmentType(): AppointmentTypeNew {
  return {
    id: `MOCK-${uuid()}`,
    description_html: "",
    name: "",
    notes: "",
    message: "",
    allow_message: false,
    message_description: "",
    configs: 3,
    sessions: [],
    on_schedule: true,
    matching_insurance: true,
    order_time_in_minutes: 0,
    max_booking_count_reached: false,
    unsaved: true,
  };
}
