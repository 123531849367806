<script lang="ts">
  import { defineComponent, watchEffect } from "vue";
  import { useRouter } from "vue-router";

  import { requestLoginStatus } from "frontend/api/application/request-misc";
  import { useStore } from "frontend/stores/login";
  import { useLoginForm } from "frontend/uses/use-login-form";
  import { UserEntity } from "frontend/interfaces/user";

  import BaseLogo from "frontend/components/base/BaseLogo.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import ZugangTelemedizinLogin from "frontend/components/base/ZugangTelemedizinLogin.vue";

  export default defineComponent({
    components: { BaseLogo, FormInput, BaseButton, ZugangTelemedizinLogin },
    setup() {
      const router = useRouter();

      const onPracticeLogin = () => {
        router.push({
          name: "calendar", // goto calendar view
        });
      };

      const { username, password, doLogin, isPending, errors, onLoginSuccess } =
        useLoginForm();

      onLoginSuccess(onPracticeLogin);

      const loginStore = useStore();
      requestLoginStatus();

      watchEffect(() => {
        if (loginStore.isLoggedIn(UserEntity.Practice)) onPracticeLogin();
        if (loginStore.isLoggedIn(UserEntity.Patient))
          router.push({
            name: "patient",
          });
      });

      return {
        username,
        password,
        doLogin,
        isPending,
        errors,
        loginStore,
      };
    },
  });
</script>

<template>
  <div class="vue-route__login--index">
    <div class="plain plain--spacious">
      <div class="login-form">
        <BaseLogo />
        <h2>Login</h2>
        <p>
          Melden Sie sich einfach mit Ihren Zugangsdaten zur
          <strong>PraxisApp</strong> an!
        </p>
        <form v-on:submit="doLogin">
          <div class="login-form__inputs">
            <FormInput
              v-model="username"
              label="PraxisApp-Nutzername"
              type="email"
              v-bind:disabled="isPending"
              autocomplete="username"
              name="username"
            />
            <FormInput
              v-model="password"
              type="password"
              v-bind:disabled="isPending"
              label="Passwort"
              autocomplete="current-password"
              name="password"
            />
          </div>
          <div>
            <BaseButton v-bind:disabled="isPending" v-on:submit="doLogin">
              Einloggen
              <div class="login-form__hidden-submit">
                <input type="submit" />
              </div>
            </BaseButton>
          </div>
        </form>
        <h2>Hausärztliche Praxen</h2>
        <p>
          Der Login zur Online-Terminverwaltung über den "Zugang-Telemedizin"
          steht bisher nur für hausärztliche Praxen zur Verfügung. Andere
          Fachgruppen werden folgen.
        </p>
        <form
          v-if="loginStore.csrfToken"
          data-turbo="false"
          method="post"
          action="/frontend/users/auth/zugang_telemedizin"
        >
          <input
            type="hidden"
            name="authenticity_token"
            v-bind:value="loginStore.csrfToken"
          />
          <label>
            <input type="submit" style="display: none" />
            <ZugangTelemedizinLogin />
          </label>
        </form>
        <div v-else>laden</div>
        <p class="login-form__errors">
          <span v-for="(error, index) in errors" v-bind:key="index">
            {{ error }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .vue-route__login--index {
    display: flex;
    justify-content: center; // horizontally
    align-items: center; // vertically
  }

  .login-form {
    max-width: 400px;
  }

  .login-form__inputs {
    margin: 20px 0;
  }

  .login-form__errors {
    color: colors.$color_login-form__errors--text;
    font-size: 14px;
  }

  .login-form__hidden-submit {
    display: none;
  }
</style>
