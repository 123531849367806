import { v4 as uuid } from "uuid";

import { HttpPayload } from "frontend/interfaces/http";
import {
  Role,
  RoleRaw,
  Session,
  SessionNew,
  SessionRaw,
  Task,
  TaskRaw,
} from "frontend/interfaces/session";

function parseTask(taskRaw: TaskRaw): Task {
  const { role, ...temp } = taskRaw;
  return {
    ...temp,
    role: parseRole(role),
  };
}
function httpPayloadTask(task: Task): HttpPayload {
  const { role, ...temp } = task;
  return {
    ...temp,
    role: httpPayloadRole(role),
  };
}

function parseRole(roleRaw: RoleRaw): Role {
  return {
    ...roleRaw,
  };
}
function httpPayloadRole(role: Role): HttpPayload {
  return {
    ...role,
  };
}

export function parseSession(sessionRaw: SessionRaw): Session {
  const { waiting_time, tasks, ...temp } = sessionRaw;
  return { ...temp, waitingTime: waiting_time, tasks: tasks?.map(parseTask) };
}

export function httpPayloadSession(session: Session): HttpPayload {
  const { waitingTime, tasks, ...temp } = session;
  return {
    ...temp,
    waiting_time: waitingTime,
    tasks: tasks.map(httpPayloadTask),
    offers: [],
  };
}

export function newSession(): SessionNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    duration: 0,
    waitingTime: 0,
    unsaved: true,
    tasks: [],
    color_id: "",
  };
}
