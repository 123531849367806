<script lang="ts">
  import { computed, defineComponent, PropType } from "vue";
  import { addMinutes } from "date-fns";

  import { formatDate, DateFormat } from "shared/utils/date-utils";
  import { Unit } from "frontend/interfaces/unit";
  import { PrimaryKey } from "frontend/interfaces/primary-key";

  import FormText from "frontend/components/form/FormText.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";

  export default defineComponent({
    components: {
      FormText,
      FormDatetime,
      FormCheckbox,
      BaseSpinner,
      FormInput,
      FormSelectOfficePerson,
    },
    props: {
      unit: {
        type: Object as PropType<Unit>,
        required: true,
      },
      newStartDate: {
        type: Date,
        required: true,
      },
      newEndDate: {
        type: Date,
        required: true,
      },
      notifyPatient: {
        type: Boolean,
        default: true,
      },
      reason: {
        type: String as PropType<string | null>,
        default: null,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      originalPersonName: {
        type: String,
        default: null,
      },
      newPersonId: {
        type: String as PropType<PrimaryKey>,
        required: true,
      },
    },
    emits: [
      "update:notifyPatient",
      "update:newStartDate",
      "update:newEndDate",
      "update:reason",
      "update:newPersonId",
    ],
    setup(props, { emit }) {
      const computedPatientData = computed(() => {
        if (props.unit.appointment) {
          return `${props.unit.appointment.patient.first_name} ${
            props.unit.appointment.patient.name
          }, ${formatDate(
            props.unit.appointment.patient.date_of_birth,
            DateFormat.DateOnly
          )}`;
        }
        return null;
      });

      return {
        onUpdateNewStartDate: (newValue: Date) => {
          emit("update:newStartDate", newValue);
        },
        onUpdateNewEndDate: (newValue: Date) => {
          emit("update:newEndDate", newValue);
        },
        onUpdateNotifyPatient: (newValue: boolean) => {
          emit("update:notifyPatient", newValue);
        },
        onUpdateReason: (newValue: string) => {
          emit("update:reason", newValue);
        },
        onUpdateNewPersonID: (newPersonId: PrimaryKey) => {
          emit("update:newPersonId", newPersonId);
        },

        //libs
        formatDate,
        DateFormat,

        addMinutes,
        computedPatientData,
      };
    },
  });
</script>

<template>
  <div class="move-appointment">
    <h2>Termin verschieben</h2>
    <p>Sind Sie sicher, dass Sie folgenden Termin verschieben möchten?</p>
    <FormText v-if="unit.appointment" class="termin-info" label="Patient">
      {{ computedPatientData }}
    </FormText>
    <FormText v-if="unit.appointment" class="termin-info" label="Termintyp">
      {{ unit.appointment.appointment_type.name }}
    </FormText>
    <div>
      <FormText v-if="unit" label="Ursprüngliche Startzeit">
        {{ formatDate(unit.from, DateFormat.DateAndTime) }}
      </FormText>
    </div>
    <div>
      <FormDatetime
        v-if="unit"
        enable-time
        visual-small
        static-time-picker
        v-bind:allow-deletion="false"
        label="Neue Startzeit"
        v-bind:model-value="newStartDate"
        v-on:update:model-value="onUpdateNewStartDate"
      />
    </div>
    <div class="move-appointment__spacer-1"></div>
    <div>
      <FormText v-if="unit" label="Ursprüngliche Endzeit">
        {{ formatDate(unit.to, DateFormat.DateAndTime) }}
      </FormText>
    </div>
    <div>
      <FormDatetime
        v-if="unit"
        enable-time
        visual-small
        static-time-picker
        v-bind:allow-deletion="false"
        label="Neue Endzeit"
        v-bind:model-value="newEndDate"
        v-on:update:model-value="onUpdateNewEndDate"
      />
    </div>
    <div class="move-appointment__spacer-1"></div>
    <div v-if="unit.appointment">
      <FormText label="Ursprüngliche Einbestellzeit">
        {{
          formatDate(unit.appointment.begin_for_patient, DateFormat.DateAndTime)
        }}
      </FormText>
      <FormText label="Neue Einbestellzeit">
        <!-- TODO: use helper function to calculate date minus minuts -->
        {{
          formatDate(
            addMinutes(
              newStartDate,
              -1 * (unit.appointment.order_time_in_minutes ?? 0)
            ),
            DateFormat.DateAndTime
          )
        }}
      </FormText>
    </div>
    <div class="move-appointment__spacer-1"></div>
    <div>
      <FormText v-if="unit" label="Ursprünglicher Mitarbeiter">
        {{ originalPersonName }}
      </FormText>
    </div>
    <div>
      <FormSelectOfficePerson
        label="Neuer Mitarbeiter"
        v-bind:model-value="newPersonId"
        v-on:update:model-value="onUpdateNewPersonID"
      />
    </div>
    <div class="move-appointment__spacer-1"></div>
    <template v-if="unit.appointment_id && !unit.search">
      <FormCheckbox
        label="den Patienten über den verschobenen Termin informieren"
        v-bind:model-value="notifyPatient"
        v-on:update:model-value="onUpdateNotifyPatient"
      />
      <FormInput
        v-if="notifyPatient"
        v-bind:model-value="reason"
        label=" Nachrichtentext an den Patienten (z.B Grund der Verschiebung)"
        type="textarea"
        v-on:update:model-value="onUpdateReason"
      />
    </template>
    <div v-if="isLoading" class="move-appointment__loading">
      <BaseSpinner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .move-appointment {
    width: 80vw;
    min-height: 80vh;
  }

  .move-appointment__loading {
    margin-top: 50px;
  }

  .move-appointment__spacer-1 {
    margin-bottom: 30px;
  }

  .termin-info {
    margin: 5px 0;
  }
</style>
