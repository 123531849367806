import { Modal } from "frontend/utils/modals/modal";

import AppointmentListComponent from "frontend/components/AppointmentList.vue";

export interface AppointmentListModalData {
  start: Date;
  end: Date;
}

export class AppointmentListModal extends Modal<
  typeof AppointmentListComponent,
  AppointmentListModalData
> {
  protected modalComponent() {
    return AppointmentListComponent;
  }
  protected hideConfirm() {
    return true;
  }
}
