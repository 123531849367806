import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { ScheduleRaw } from "frontend/interfaces/schedule";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestSchedules(): Promise<
  UnifiedListResponse<ScheduleRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_settings_schedules_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { entities: ScheduleRaw[] }).entities,
  };
}

export async function requestSchedule(
  id: PrimaryKey
): Promise<UnifiedResponse<ScheduleRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_settings_schedule_path,
    { id },
    {}
  );

  return {
    entityRaw: (body as unknown as { entity: ScheduleRaw }).entity,
    success: statusIsSuccess,
    errors:
      (body as unknown as { entity: { _errors?: ErrorDatabase } }).entity
        ._errors || {},
  };
}

export async function duplicateSchedule(
  id: PrimaryKey
): Promise<{ success: boolean }> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.duplicate_frontend_settings_schedules_path,
    {},
    { id }
  );

  return { success: statusIsSuccess };
}
