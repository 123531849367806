<script lang="ts" setup>
  import { onMounted, ref } from "vue";

  import { News } from "frontend/news";
  import { useStore } from "frontend/stores/news";
  import { formatDate, DateFormat } from "shared/utils/date-utils";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";

  const { lastIdSeen, setNewsAsSeen } = useStore();

  const initialNewsIdSeen = ref<number>(0);
  onMounted(() => {
    initialNewsIdSeen.value = lastIdSeen();
    setNewsAsSeen();
  });
</script>

<template>
  <div class="vue-route__news--index plain">
    <h1>Neuigkeiten</h1>
    <BaseHelp>
      Hier sehen Sie eine Zusammenfassung von Neuigkeiten rund um die
      Online-Terminverwaltung.
    </BaseHelp>

    <div v-for="entry in News" v-bind:key="entry.id" class="news-entry">
      <h2 class="news-entry__title">
        {{ entry.title }}
        <i
          v-if="initialNewsIdSeen < entry.id"
          class="news-entry__badge fe fe-circle--filled"
        ></i>
      </h2>
      <div class="news-entry__date">
        {{ formatDate(entry.date, DateFormat.DateOnly) }}
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -- this is a static file serverd with the application -->
      <div v-html="entry.content_html"></div>
      <div v-if="entry.list_html">
        <ul>
          <li
            v-for="listEntry in entry.list_html"
            v-bind:key="listEntry"
            class="news-entry__list-item"
          >
            <!-- eslint-disable-next-line vue/no-v-html -- this is a static file serverd with the application -->
            <span v-html="listEntry"></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/badge";

  .news-entry {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .news-entry__list-item {
    margin: 10px 0;
  }

  .news-entry__title {
    margin-bottom: 5px;

    text-transform: none;

    font-size: 24px;
  }

  .news-entry__date {
    margin-bottom: 12px;

    font-style: italic;
  }

  .news-entry__badge {
    @include badge.base;
  }
</style>
