<script lang="ts" setup>
  import { onMounted, ref } from "vue";

  import {
    loadTemplate,
    requestTemplates,
  } from "frontend/api/application/request-templates";
  import { Template } from "frontend/interfaces/template";
  import { parseTemplate } from "frontend/parser/parse-template";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";

  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import BaseSpinner from "frontend/components/base/BaseSpinner.vue";

  const initiallyLoaded = ref<boolean>(false);
  const isSubmitting = ref<boolean>(false);
  const templates = ref<Array<Template>>([]);

  onMounted(async () => {
    templates.value = (await requestTemplates()).templates.map(parseTemplate);
    initiallyLoaded.value = true;
  });

  const doSelectTemplate = async (template: Template) => {
    if (isSubmitting.value) return;

    const { confirm } = useSimpleModal();

    const result = await confirm(
      `Sind Sie sicher, dass Sie die Vorlage "${template.title}" einspielen möchten? Alle bestehenden Einstellungen und Termine werden dabei gelöscht!`,
      { title: "Bestätigung" }
    );

    if (result.confirm) {
      try {
        isSubmitting.value = true;
        const { success } = await loadTemplate(template.machine_name);
        if (success) location.reload(); // TODO: fix this and use a close method to close the modal
      } finally {
        isSubmitting.value = false;
      }
    }
  };
</script>

<template>
  <div class="apply-template">
    <h2>Praxisvorlage einspielen</h2>
    <BaseHelp>
      <p>
        Sie können hier eine Praxisvorlage als Ausgangspunkt einspielen. Nach
        einem Klick auf die entsprechende Vorlage, wird diese in Ihrem Account
        eingespielt. Anschließend können Sie die Einstellungen individualisieren
        und an Ihre eigenen Bedürfnisse anpassen.
      </p>
      <p>
        Sie haben auch die Möglichkeit später eine andere Vorlage einzuspielen.
      </p>
      <p>
        <strong>Achtung:</strong> Dieser Vorgang löscht alle Einstellungen und
        Termine, die bereits in den Kalender eingetragen sind.
      </p>
    </BaseHelp>
    <h5>Vorlagen:</h5>
    <div v-if="!isSubmitting && initiallyLoaded" class="templates">
      <div
        v-for="template in templates"
        v-bind:key="template.machine_name"
        class="template"
        v-on:click="doSelectTemplate(template)"
      >
        <div class="template__title">{{ template.title }}</div>
        <div class="template__description">
          <!-- eslint-disable-next-line vue/no-v-html -- this is sanitized AND not user supplied -->
          <span v-html="template.description_html"></span>
        </div>
      </div>
    </div>
    <div v-else>
      <p v-if="isSubmitting">
        Die ausgeählte Vorlage wird in Ihrem Account eingespielt. Bitte haben
        Sie etwas Geduld ...
      </p>
      <p v-else>
        Die verfügbaren Vorlagen zum Einrichten Ihrer Praxis werden vom Server
        geladen ...
      </p>
      <BaseSpinner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/tiles";

  .apply-template {
    min-height: 300px;
    min-width: 500px;
    max-width: 80vw;
    padding-top: 30px;
  }

  .templates {
    @include tiles.tile__collection;
  }

  .template {
    @include tiles.tile;
    padding: 25px;
    max-width: 300px;

    user-select: none;
    cursor: pointer;

    &:hover {
      @include tiles.tile--active;
    }
  }

  .template__title {
    font-size: 16px;
    font-weight: bold;

    margin-bottom: 15px;
  }

  .template__description {
    font-size: 12px;
  }
</style>
