import { Ref, watch } from "vue";

import { useSubscription } from "frontend/uses/use-subscription";

type ThrottledCallback = () => void;
type TimeoutHandle = ReturnType<typeof setTimeout>;

export function useThrottledWatcher(
  watched: Ref<unknown>,
  durationInMs: number
): {
  subscribe: (callback: ThrottledCallback) => void;
} {
  const { notify, subscribe } = useSubscription<never>();

  let runningThrottle: TimeoutHandle | null = null;
  let needFinalNotify = false;

  watch(watched, () => {
    if (!runningThrottle) {
      runningThrottle = setTimeout(() => {
        runningThrottle = null;

        if (needFinalNotify) notify();
        needFinalNotify = false;
      }, durationInMs);

      // only notify if there is no throttle
      notify();
    } else {
      needFinalNotify = true;
    }
  });

  return {
    subscribe,
  };
}
