// eslint-disable-next-line @typescript-eslint/no-explicit-any -- type is given by VueJS
type VueEmit<T> = (event: T, ...args: any[]) => void;

export function createEventForwarder<T extends string>(
  eventName: T,
  emit: VueEmit<T>
) {
  return (...args: unknown[]): void => {
    emit(eventName, ...args);
  };
}
