<script lang="ts" setup>
  import { computed, onMounted, PropType, ref, toRefs } from "vue";

  import { Person } from "frontend/interfaces/person";
  import { Cache } from "frontend/utils/request-cache";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";
  import { FormSelectId } from "frontend/uses/use-form-select";
  import { useStore as useProfileStore } from "frontend/stores/profile";
  import { identity } from "shared/utils/helper-functions";

  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";

  const props = defineProps({
    modelValue: {
      type: [String, Array] as PropType<FormSelectId | FormSelectId[]>,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<Errors>,
      default: () => [],
    },
    animationStatus: {
      type: Number as PropType<StatusValue | null>,
      default: null,
    },
    sortByProfile: {
      type: Boolean,
      default: false,
    },
    visualSmall: {
      type: Boolean,
      default: false,
    },
    visualInline: {
      type: Boolean,
      default: false,
    },
    visualInverted: {
      type: Boolean,
      default: false,
    },
    includeBlank: {
      type: [Boolean, String],
      default: false,
    },
  });

  const { modelValue } = toRefs(props);

  const emit = defineEmits(["update:model-value", "autosave"]);

  const profileStore = useProfileStore();

  const persons = ref<Array<Person>>([]);
  const personsLoaded = ref<boolean>(false);
  const options = computed(() => {
    const order = props.sortByProfile ? profileStore.sortPersons : identity;
    return order(persons.value).map((entry) => ({
      id: entry.id,
      label: entry.name,
    }));
  });
  const onUpdateModelValue = (newValue: FormSelectId | null) => {
    emit("update:model-value", newValue);
  };
  const loadPersons = async () => {
    persons.value = await Cache.getCachedPersons();
    personsLoaded.value = true;
  };
  onMounted(loadPersons);

  const multiple = computed(() => {
    return Array.isArray(modelValue.value);
  });

  // typescript type helper
  const modelValueSingle = computed(() => {
    // the condition should never be evaluated, because the corresponding template
    // will not be rendered
    return Array.isArray(modelValue.value) && modelValue.value.length > 0
      ? modelValue.value[0]
      : (modelValue.value as FormSelectId);
  });
  const modelValueMultiple = computed(() =>
    Array.isArray(modelValue.value)
      ? modelValue.value.filter((ele): ele is number | string => !!ele)
      : []
  );
</script>

<template>
  <div
    class="form-select-office-person"
    v-bind:class="{ 'form-select-office-person--inline': visualInline }"
  >
    <template v-if="personsLoaded">
      <template v-if="multiple">
        <FormCheckboxGroup
          v-bind:model-value="modelValueMultiple"
          v-bind:label="label"
          v-bind:options="options"
          v-bind:animation-status="animationStatus"
          v-bind:errors="errors"
          v-on:autosave="$emit('autosave')"
          v-on:update:model-value="onUpdateModelValue"
        />
      </template>
      <template v-else>
        <FormSelect
          v-bind:model-value="modelValueSingle"
          v-bind:label="label"
          v-bind:options="options"
          v-bind:errors="errors"
          v-bind:animation-status="animationStatus"
          v-bind:visual-small="visualSmall"
          v-bind:visual-inverted="visualInverted"
          v-bind:include-blank="includeBlank"
          v-on:autosave="$emit('autosave')"
          v-on:update:model-value="onUpdateModelValue"
        />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .form-select-office-person--inline {
    display: inline;
  }
</style>
