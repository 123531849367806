import { Component } from "vue";

import { Person } from "frontend/interfaces/person";
import { SettingsManager } from "frontend/uses/settings/manager/settings-manager";
import {
  SettingsDeleteResult,
  SettingsListResult,
  SettingsSaveResult,
} from "frontend/uses/settings/types";
import {
  createPerson,
  deletePerson,
  requestPersons,
  updatePerson,
} from "frontend/api/application/request-persons";
import {
  httpPayloadPerson,
  newPerson,
  parsePerson,
} from "frontend/parser/parse-person";
import {
  genericSavePattern,
  genericDeletePattern,
  genericListPattern,
} from "frontend/uses/settings/use-edit-component";

import EditPerson from "frontend/components/settings/EditPerson.vue";

export class PersonsSettingsManager extends SettingsManager<Person> {
  entityName(): string {
    return "Mitarbeiter";
  }

  componentForEdit(): Component {
    return EditPerson;
  }

  craftNewEntity(): Person {
    return newPerson();
  }

  async hookList(): Promise<SettingsListResult<Person>> {
    return genericListPattern(parsePerson, requestPersons);
  }

  async hookSave(record: Person): Promise<SettingsSaveResult<Person>> {
    return genericSavePattern(
      record,
      parsePerson,
      httpPayloadPerson,
      createPerson,
      updatePerson
    );
  }

  async hookDelete(record: Person): Promise<SettingsDeleteResult> {
    return genericDeletePattern(record, (record) => record.id, deletePerson);
  }

  createEditable(record: Person): Person {
    return {
      ...super.createEditable(record),
      attendances: record.attendances.map((entry) => ({ ...entry })),
      role_ids: record.role_ids.map((entry) => entry),
    };
  }
}
