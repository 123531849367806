<script lang="ts" setup>
  import { computed, onMounted, PropType, ref, toRefs } from "vue";

  import { Cache } from "frontend/utils/request-cache";
  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";
  import { FormSelectId } from "frontend/uses/use-form-select";
  import { Schedule } from "frontend/interfaces/schedule";

  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";

  const props = defineProps({
    modelValue: {
      type: [String, Array] as PropType<FormSelectId | FormSelectId[]>,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<Errors>,
      default: () => [],
    },
    animationStatus: {
      type: Number as PropType<StatusValue | null>,
      default: null,
    },
    visualSmall: {
      type: Boolean,
      default: false,
    },
    visualInline: {
      type: Boolean,
      default: false,
    },
    visualInverted: {
      type: Boolean,
      default: false,
    },
    includeBlank: {
      type: [Boolean, String],
      default: false,
    },
  });

  const { modelValue } = toRefs(props);

  const emit = defineEmits<{
    (e: "update:model-value", value: FormSelectId | null): void;
    (e: "autosave"): void;
  }>();

  const schedules = ref<Array<Schedule>>([]);
  const schedulesLoaded = ref<boolean>(false);
  const options = computed(() => {
    return schedules.value.map((entry) => ({
      id: entry.id,
      label: entry.name,
    }));
  });

  const onUpdateModelValue = (newValue: FormSelectId | null) => {
    emit("update:model-value", newValue);
  };
  const loadSchedules = async () => {
    schedules.value = await Cache.getCachedSchedules();
    schedulesLoaded.value = true;
  };
  onMounted(loadSchedules);

  const multiple = computed(() => {
    return Array.isArray(modelValue.value);
  });

  // typescript type helper
  const modelValueSingle = computed(() => {
    // the condition should never be evaluated, because the corresponding template
    // will not be rendered
    return Array.isArray(modelValue.value) && modelValue.value.length > 0
      ? modelValue.value[0]
      : (modelValue.value as FormSelectId);
  });
  const modelValueMultiple = computed(() =>
    Array.isArray(modelValue.value)
      ? modelValue.value.filter((ele): ele is number | string => !!ele)
      : []
  );
</script>

<template>
  <div
    class="form-select-office-schedule"
    v-bind:class="{ 'form-select-office-schedule--inline': visualInline }"
  >
    <template v-if="schedulesLoaded">
      <template v-if="multiple">
        <FormCheckboxGroup
          v-bind:model-value="modelValueMultiple"
          v-bind:label="label"
          v-bind:options="options"
          v-bind:animation-status="animationStatus"
          v-bind:errors="errors"
          v-on:autosave="$emit('autosave')"
          v-on:update:model-value="onUpdateModelValue"
        />
      </template>
      <template v-else>
        <FormSelect
          v-bind:model-value="modelValueSingle"
          v-bind:label="label"
          v-bind:options="options"
          v-bind:errors="errors"
          v-bind:animation-status="animationStatus"
          v-bind:visual-small="visualSmall"
          v-bind:visual-inverted="visualInverted"
          v-bind:include-blank="includeBlank"
          v-on:autosave="$emit('autosave')"
          v-on:update:model-value="onUpdateModelValue"
        />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .form-select-office-schedule--inline {
    display: inline;
  }
</style>
