import { Modal } from "frontend/utils/modals/modal";
import { PrimaryKey } from "frontend/interfaces/primary-key";

import HolidaySelectionComponent from "frontend/components/HolidaySelection.vue";

export interface HolidaySelectionModalData {
  selectedHolidaysIds: PrimaryKey[];
}

export class HolidaySelectionModal extends Modal<
  typeof HolidaySelectionComponent,
  HolidaySelectionModalData
> {
  protected modalComponent() {
    return HolidaySelectionComponent;
  }

  protected confirmLabel(): "auswählen" {
    return "auswählen";
  }
}
