import { defineStore } from "pinia";
import { ref } from "vue";

import { OfficeStoreHeaders } from "frontend/api/application";
import {
  OnLoginBefore,
  OnLogoutAfter,
  OnRequestHasOfficeStoreHeaders,
} from "frontend/events/topics";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { Operator } from "frontend/interfaces/operator";
import { UserEntity } from "frontend/interfaces/user";
import { router } from "frontend/router";
import { Cache } from "frontend/utils/request-cache";
import { OfficeConfigStatus } from "shared/static/enums.ts.erb";
import { isActive } from "shared/utils/bitwise-enum";
import { useStore as useLoginStore } from "frontend/stores/login";

interface OfficeBlockedState {
  blocked: boolean;
  config_status: number | null;
  current_operator_id: PrimaryKey | null | boolean; // false means disabled for office
  frontend_javascript_version: string | null;
  backend_javascript_version: string | null;
  feature_flags: Partial<Record<string, boolean>>;
}

type FeatureFlags = "pvs_integration";

export const id = "office";

const operators = ref<Operator[]>([]);

export const useStore = defineStore({
  id,
  state: (): OfficeBlockedState => ({
    blocked: false,
    config_status: null,
    current_operator_id: false,
    frontend_javascript_version: null,
    backend_javascript_version: null,
    feature_flags: {},
  }),
  getters: {
    isBlocked: (state): boolean => !!state.blocked,
    isTemplatingAvailable: (state): boolean =>
      state.config_status
        ? isActive(state.config_status, OfficeConfigStatus.templating_available)
        : false,
    currentOperator: (state): Operator | null =>
      operators.value
        ? (operators.value.find(
            (entry) => entry.id === state.current_operator_id
          ) ?? null)
        : null,
    operatorsEnabled: (state): boolean =>
      !(
        typeof state.current_operator_id === "boolean" &&
        !state.current_operator_id
      ),
    operators: (_state): Operator[] => operators.value,
    needRefresh: (state): boolean =>
      !!state.backend_javascript_version &&
      state.frontend_javascript_version !== state.backend_javascript_version,
    hasFeature: (state) => (feature: FeatureFlags) =>
      !!state.feature_flags[feature],
  },
  actions: {
    parseHeader(header: OfficeStoreHeaders) {
      const blocked = !!header.blocked;

      if (blocked) this.block();
      else this.unblock();

      const config_status = header.config_status;
      if (typeof config_status === "number")
        this.config_status = Math.floor(config_status);

      const current_operator_id = header.current_operator_id;
      const loginStore = useLoginStore();
      if (loginStore.isLoggedIn(UserEntity.Practice))
        Cache.getCachedOperators().then((result) => (operators.value = result));
      if (
        typeof current_operator_id === "string" ||
        current_operator_id === null
      ) {
        this.current_operator_id = current_operator_id;
      } else {
        this.current_operator_id = false;
      }

      if (typeof header.javascript_version === "string") {
        if (!this.frontend_javascript_version)
          this.frontend_javascript_version = header.javascript_version;

        this.backend_javascript_version = header.javascript_version;
      }

      if (typeof header.feature_flags === "object") {
        this.feature_flags = {};
        for (const key in header.feature_flags) {
          this.feature_flags[key] = !!header.feature_flags[key];
        }
      }
    },
    block() {
      this.blocked = true;
      if (router.currentRoute.value.meta.requiresUnblockedOffice) {
        if (
          router.currentRoute.value.meta.requiresAuth === UserEntity.Patient
        ) {
          router.push({ name: "patient-appointment" });
        } else {
          router.push({ name: "office-blocked" });
        }
      }
    },
    unblock() {
      this.blocked = false;
    },
  },
});

export function setupEvents() {
  OnRequestHasOfficeStoreHeaders.subscribe(({ headers }) => {
    useStore().parseHeader(headers);
  });

  OnLogoutAfter.or(OnLoginBefore).subscribe(() => {
    // clear caches on logout
    operators.value = [];
  });
}
