<script lang="ts">
  import { computed, defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import MutatedSettingsProfile from "frontend/components/mutated/MutatedSettingsProfile.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  export default defineComponent({
    components: { BaseButton, FormInput, MutatedSettingsProfile, FormCheckbox },
    props: {
      ...editProps<SettingsProfile>(),
    },
    setup(props) {
      const { entity } = toRefs(props);

      const test__isUnsaved = computed(
        () => !!(entity.value as unknown as { unsaved: boolean }).unsaved
      );

      return {
        ...useEditComponent(entity.value),

        // for testing
        test__isUnsaved,
      };
    },
  });
</script>

<template>
  <div
    class="edit-settings-profile"
    v-bind:class="{ 'test---unsaved': test__isUnsaved }"
  >
    <h5>
      <template v-if="entity.name">{{ entity.name }}</template>
      <template v-else>Einstellungsprofil</template>
    </h5>
    <FormInput
      v-model="entityEditable.name"
      label="Name des Profils"
      v-bind:errors="errorFor('name')"
      v-bind:animation-status="statusFor('name')"
      v-on="{ autosave: onAutosaveFor('name') }"
    />
    <FormCheckbox
      v-model="entityEditable.is_office_default"
      v-bind:label="`Als Standardprofil der Praxis ${
        entityEditable.is_office_default ? '' : 'nicht'
      } genutzt`"
      disabled
      v-bind:errors="errorFor('is_office_default')"
      v-bind:animation-status="statusFor('is_office_default')"
      v-on="{ autosave: onAutosaveFor('is_office_default') }"
    >
      <RouterLink
        class="edit-settings-profile__default-link"
        v-bind:to="{ name: 'settings-general', hash: '#einstellungsprofil' }"
        >ändern</RouterLink
      >
    </FormCheckbox>
    <MutatedSettingsProfile
      v-bind:entity="entityEditable"
      v-bind:error-for="errorFor"
      v-bind:on-autosave-for="onAutosaveFor"
      v-bind:status-for="statusFor"
      context-name="edit"
    />

    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/colors";

  .edit-settings-profile__default-link {
    color: colors.$color_link--text;
  }
</style>
