import { Ref, ref } from "vue";

export type Error = string;
export type Errors = Array<Error>;
export type ErrorDatabase = Partial<Record<string, Errors>>;

export function useErrors(errors: Ref<ErrorDatabase> | null): {
  errors: Ref<ErrorDatabase>;
  errorFor: (key: string, globalKey?: string) => Errors;
} {
  const internalErrors = errors || ref<ErrorDatabase>({});

  return {
    errors: internalErrors,
    errorFor: (key, globalKey?: string) => {
      let database = internalErrors.value;
      // TODO: fix typing, see commit that introduced this comment
      // errors can come nested as database because of has-many relationships
      if (globalKey) database = (database[globalKey] || {}) as ErrorDatabase;
      const lookup = database[key];
      return lookup && lookup.length > 0 ? lookup : [];
    },
  };
}
