import { Component, PropType } from "vue";
import {
  addDays,
  addMinutes,
  isBefore,
  max,
  min,
  roundToNearestMinutes,
  startOfDay,
  differenceInMinutes,
  isWithinInterval,
  isSameMinute,
  differenceInCalendarDays,
  isAfter,
  startOfMinute,
  addMilliseconds,
  differenceInMilliseconds,
  endOfDay,
} from "date-fns";

import { AbstractViewCellPosition } from "frontend/uses/abstract-view/use-abstract-view-parser";
import {
  Calendar,
  CalendarCell,
  CalendarConnection,
  CalendarConstants,
  CalendarEntry,
  CalendarEntryPositionable,
  CalendarLayer,
  CalendarRelativeLocation,
  CalendarRow,
  CalendarUtils,
  EventLike,
} from "frontend/interfaces/calendar";
import { Interval } from "shared/interfaces/interval";
import { Constants } from "frontend/utils/constants";
import {
  useSimpleModal,
  ModalComponentOn,
} from "frontend/uses/simple-modal/use-simple-modal";
import {
  atDefaultEndOf,
  atDefaultStartOf,
  ceil,
  floor,
  mergeDates,
  sanitizeInterval,
  shiftFromUTCToLocal,
} from "shared/utils/date-utils";
import { CONSTANTS } from "frontend/uses/abstract-view/layers";
import { TmpUnit } from "frontend/interfaces/unit";

type CssStyles = { [name: string]: string };

export function stylesForEntry(
  entry: CalendarEntry,
  layer: CalendarLayer,
  options: {
    isHovered?: boolean;
  }
): CssStyles {
  const generalStyle: CssStyles = {
    zIndex: layer.zIndex.toString(),
    top: entry.topOffsetPercentage * 100 + "%",
    height: entry.heightPercentage * 100 + "%",
    left: "0",
    right: "0",
  };

  let specificStyle: CssStyles = {};

  if ((entry as unknown as CalendarEntryPositionable).position) {
    specificStyle = {
      left: (
        entry as unknown as CalendarEntryPositionable
      ).position.styles.left(),
      right: (
        entry as unknown as CalendarEntryPositionable
      ).position.styles.right(),
    };
  }

  if (entry.heightPixels) {
    specificStyle = {
      ...specificStyle,
      height: `calc( ${generalStyle.height} + ${entry.heightPixels}px )`,
    };
  }

  if (options.isHovered) {
    specificStyle.left = "0";
    specificStyle.right = "0";
    specificStyle.zIndex = CONSTANTS.Z_INDICES.HOVERED.toString();
    specificStyle.height = Math.max(0.35, entry.heightPercentage) * 100 + "%";
  }

  return {
    ...generalStyle,
    ...specificStyle,
  };
}

export function stylesForNote(
  noteStart: number,
  noteEnd: number,
  columnsWidth: number[]
): CssStyles {
  const columnWidth = columnsWidth
    .slice(noteStart, noteEnd)
    .reduce((a, b) => a + b, 0);
  return {
    width: columnWidth - 2 + "px",
  };
}

/**
 * Returns the relative location of a event with respect to the
 * element that is listened on (0 = 0%, 1 = 100%)
 *
 * @param event
 */
export function locatePositionFromEvent(
  event: MouseEvent | PointerEvent | DragEvent,
  positioning?: ElementDescription
): CalendarRelativeLocation | null {
  if (!event.currentTarget || !(event.currentTarget instanceof HTMLElement))
    return null; // When can this happen?

  let translationOffsetY = 0;
  if (
    event.target &&
    event.currentTarget !== event.target &&
    event.currentTarget.contains(event.target as Node)
  ) {
    // target: element that received the element
    // currentTarget: element that we listened to

    let pivot = event.target as HTMLElement;

    while (pivot !== event.currentTarget) {
      const tmpPivot = pivot;
      pivot = pivot.parentElement as HTMLElement;
      const { offsetY: y } = translateElementPositions(tmpPivot, pivot);
      translationOffsetY += y;
    }
  }

  let offsetY = event.offsetY + translationOffsetY;

  if (positioning && positioning.reference === "top") {
    const insideEvent = positioning.event as unknown as Pick<
      typeof positioning.event,
      "offsetY"
    > & { layerY: number };

    // using obsolete layerY for firefox to locate the click inside the tile
    // that started the drag and drop
    // but use offsetY always if possible
    offsetY =
      offsetY - (insideEvent.layerY ? insideEvent.layerY : insideEvent.offsetY);
  }

  return locatePositionFromBaseElement(
    event.offsetX,
    offsetY,
    event.currentTarget
  );
}

function locatePositionFromBaseElement(
  offsetX: number,
  offsetY: number,
  baseElement: HTMLElement
): CalendarRelativeLocation {
  return locatePositionFromUnits(
    offsetX,
    offsetY,
    baseElement.offsetWidth,
    baseElement.offsetHeight
  );
}

function locatePositionFromUnits(
  offsetX: number,
  offsetY: number,
  baseWidth: number,
  baseHeight: number
): CalendarRelativeLocation {
  return {
    x: offsetX / baseWidth,
    y: offsetY / baseHeight,
  };
}

interface ElementDescription {
  event: DragEvent;
  reference: "top";
}

function translateElementPositions(
  source: HTMLElement,
  destination: HTMLElement
): {
  offsetX: number;
  offsetY: number;
} {
  const rectSource = source.getBoundingClientRect();
  const rectDest = destination.getBoundingClientRect();
  return {
    offsetX: rectSource.left - rectDest.left,
    offsetY: rectSource.top - rectDest.top,
  };
}

export function definePropsEntry<K extends CalendarEntry>() {
  return {
    entry: {
      type: Object as PropType<K>,
      required: true,
    },
    utils: {
      type: Object as PropType<CalendarUtils>,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  } as const;
}

export function defineEmitsEntry() {
  return ["refresh-needed"];
}

export function calculateTimeSteps(
  startDate: Date,
  endDate: Date,
  stepSizeInMinutes: number
): Interval[] {
  const stepSize: number = Math.floor(stepSizeInMinutes);

  const steps: Interval[] = [];

  let currentStepStart: Date | null = null;
  let currentStepEnd: Date = startDate;

  while (isBefore(currentStepEnd, endDate)) {
    currentStepStart = currentStepEnd;
    currentStepEnd = addMinutes(currentStepStart, stepSize);
    steps.push({
      from: currentStepStart,
      to: currentStepEnd,
    });
  }
  return steps;
}

export function calculateTimescaleBoundaries(
  date: Date,
  dates: Date[],
  definitleyIncludedDates: Date[],
  constants: CalendarConstants,
  options: {
    overrideMinDate?: Date;
    overrideMaxDate?: Date;
  }
) {
  let rawMinDate = options.overrideMinDate
    ? mergeDates(date, shiftFromUTCToLocal(options.overrideMinDate))
    : max([min(dates), startOfDay(date)]);
  let rawMaxDate = options.overrideMaxDate
    ? mergeDates(date, shiftFromUTCToLocal(options.overrideMaxDate))
    : min([max(dates), addDays(startOfDay(date), 1)]);

  rawMinDate = min([...definitleyIncludedDates, rawMinDate]);
  rawMaxDate = max([...definitleyIncludedDates, rawMaxDate]);

  return {
    minDate: floor(rawMinDate, constants.FLOOR_CEIL_STEP_SIZE),
    maxDate: ceil(rawMaxDate, constants.FLOOR_CEIL_STEP_SIZE),
  };
}

export function tmpUnitBoundaries(
  tmpUnit: TmpUnit | null,
  baseDate: Date
): Date[] {
  if (!tmpUnit) return [];

  const fromDate = mergeDates(baseDate, tmpUnit?.from);
  const toDate = addMilliseconds(
    fromDate,
    differenceInMilliseconds(tmpUnit.to, tmpUnit.from)
  );

  return [
    max([fromDate, startOfDay(baseDate)]),
    min([toDate, endOfDay(baseDate)]),
  ];
}

export function calculateOpenTimes(date: Date) {
  return {
    todayOpen: atDefaultStartOf(date),
    todayClose: atDefaultEndOf(date),
  };
}

export function topOffsetPercentage(
  from: Date,
  boundary: Date,
  cellPosition: AbstractViewCellPosition,
  constants: CalendarConstants
): number {
  const correctPosition =
    differenceInMinutes(from, boundary) / constants.TIMESCALE_STEP_SIZE;

  // if this is the first row, we dont want negative top offsets
  // as they would overflow the calendar
  if (cellPosition.firstRow) return Math.max(correctPosition, 0);

  return correctPosition;
}

export function height(
  displayInterval: Interval,
  columnInterval: Interval,
  constants: CalendarConstants
): {
  heightPercentage: number;
  heightPixels: number;
  heightCutoffTop: boolean;
  heightCutoffBottom: boolean;
} {
  const displayIntervalWithCutoff: Interval = {
    from: max([displayInterval.from, columnInterval.from]),
    to: min([displayInterval.to, columnInterval.to]),
  };

  return {
    heightPercentage: heightPercentage(displayIntervalWithCutoff, constants),
    heightPixels: heightPixels(displayIntervalWithCutoff, constants),
    heightCutoffTop: isBefore(displayInterval.from, columnInterval.from),
    heightCutoffBottom: isAfter(displayInterval.to, columnInterval.to),
  };
}

export function heightPercentage(
  displayInterval: Interval,
  constants: CalendarConstants
): number {
  const displayedDurationInMinutes = differenceInMinutes(
    startOfMinute(displayInterval.to),
    startOfMinute(displayInterval.from)
  );
  return (displayedDurationInMinutes * 1.0) / constants.TIMESCALE_STEP_SIZE;
}

export function heightPixels(
  displayInterval: Interval,
  constants: CalendarConstants
): number {
  return (
    Constants.BORDER_WIDTH_IN_PIXELS *
    Math.floor(
      (differenceInMinutes(displayInterval.to, displayInterval.from) * 1.0) /
        constants.TIMESCALE_STEP_SIZE
    )
  ); // adjust for crossed borders
}

export function dateFromIntervalAndLocation(
  interval: Interval,
  location: CalendarRelativeLocation,
  roundingPrecisionInMinutes = 1
): Date {
  return roundToNearestMinutes(
    addMinutes(
      interval.from,
      Math.round(differenceInMinutes(interval.to, interval.from) * location.y)
    ),
    { nearestTo: roundingPrecisionInMinutes }
  );
}

// Returns a function that checks if a given date ("from") is within the given
// interval ("interval") and returns accordingly.
// Special case: if the given interval corresponds to the first row of the calendar,
//   we need to check if the given date is before the interval's "from" date and ends after it (param "to").
//   In this case it as a appointment starts before the interval and should be assigned
//   to the first row even. Otherwise there would be a gap and the appointment would be invisible.
//   Appointments currently are always assigned to the first row that they should be visible in.
export function higherOrderIsInInterval(
  interval: Interval,
  _columnInterval: Interval,
  cellPosition: AbstractViewCellPosition
): (from: Date, to: Date, visualInterval?: Interval) => boolean {
  return (from: Date, to: Date) =>
    (cellPosition.firstRow &&
      isBefore(from, interval.from) &&
      isAfter(to, interval.from)) ||
    (isWithinInterval(from, {
      start: interval.from,
      end: interval.to,
    }) &&
      !isSameMinute(from, interval.to));
}

export function genericEntryOnClick(
  component: Component,
  connection: CalendarConnection | null,
  modelValue: string,
  componentOn?: ModalComponentOn
) {
  const { custom } = useSimpleModal();

  return async (entry: CalendarEntry, nativeEvent: EventLike) => {
    nativeEvent.stopPropagation();
    await custom(component, {
      hideCancel: true,
      hideConfirm: true,
      componentProps: {
        modelValue,
        entry,
      },
      componentOn: {
        ...forwardBaseCalendarEvents(connection),
        ...(componentOn || {}),
      },
    });
  };
}

function forwardBaseCalendarEvents(connection: CalendarConnection | null) {
  return {
    "refresh-needed": () => {
      connection?.triggerRefreshNeeded();
    },
  };
}

export function mergeInterval(sourceDate: Date, interval: Interval) {
  const dayDifference = differenceInCalendarDays(interval.to, interval.from);

  return sanitizeInterval({
    from: mergeDates(sourceDate, interval.from),
    to: addDays(mergeDates(sourceDate, interval.to), dayDifference),
  });
}

/**
 * calculates utils for any entry that is given to the rendering component
 * BC* to be used there for simpler calculations
 */
export function utilsForEntry<Entry extends CalendarEntry>(
  entry: Entry,
  _layer: CalendarLayer,
  cell: CalendarCell,
  _row: CalendarRow,
  _calendar: Calendar
): CalendarUtils {
  const cellRelativeLocation = (topOffset: number): CalendarRelativeLocation =>
    cell.element
      ? locatePositionFromBaseElement(0, topOffset, cell.element)
      : {
          x: 0,
          y: 0,
        };
  const correctRelativeLocation = (
    location: CalendarRelativeLocation,
    yRelativeOffset: number
  ): CalendarRelativeLocation => ({
    x: location.x,
    y: location.y + yRelativeOffset,
  });

  const relativeDateToCell = (topOffsetAbsolute: number) =>
    dateFromIntervalAndLocation(
      cell.interval,
      cellRelativeLocation(topOffsetAbsolute)
    );
  const relativeDateToEntry = (topOffsetAbsolute: number) =>
    dateFromIntervalAndLocation(
      cell.interval,
      correctRelativeLocation(
        cellRelativeLocation(topOffsetAbsolute),
        entry.topOffsetPercentage
      )
    );

  return {
    relativeDateToCell,
    relativeDateToEntry,
  };
}
