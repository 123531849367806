import { isBefore } from "date-fns";

interface EventPositionerState {
  columns: Array<EventPositionerColumn>;
}

/**
 * This interface represents a single column
 * in the appointment columization process
 *
 * <pre>
 * INVARIANTS:
 * (1) each array has exactly the same length
 * (2) the entries of each array at the same index
 *     belong together, i.e.
 *       (2a) entries[i].from === froms[i]
 *       (2b) entries[i].to === tos[i]
 *       (2c) entries[i].handle === handles[i]
 * (3) the array froms is sorted ascendingly
 * (4) entries are not overlapping in a timley manner
 * (5) entries[i].from < entries[i].to
 *
 * LEMMA:
 *   tos is always sorted (ascendingly) too
 *
 *   Proof:
 *   suppose there exist i, j € {0, 1, 2, ..., entries.length - 1}
 *   s.t. j = i + 1 and tos[i] > tos[j] (*)
 *
 *   ( well-definedness of utils[i], utils[j]: (1) )
 *
 *   we get:
 *
 *   froms[i] <= froms[j] by (3)
 *            < tos[j] by (5) and (2)
 *            < tos[i] by (*)
 *
 *   this means that entries i and j overlap which contradicts (4)
 *   ∎
 * </pre>
 *
 * @interface EventPositionerColumn
 */
interface EventPositionerColumn {
  index: number;
  entries: Array<EventPositionerEntry>;
  froms: Array<Date>;
  tos: Array<Date>;
  handles: Array<EventPositionEntryHandle>;
}

interface EventPositionerNewEntry {
  from: Date;
  to: Date;
}
interface EventPositionerEntry extends Readonly<EventPositionerNewEntry> {
  readonly handle: EventPositionEntryHandle;
  readonly columnIndex: number;
}

export interface EventPositionerPosition {
  styles: { left: () => string; right: () => string };
}
/**
 * Public interface of EventPositioner class
 *
 * @export
 * @interface IEventPositioner
 */
export interface IEventPositioner {
  addEntry(entry: EventPositionerNewEntry): EventPositionerPosition;
}

interface EventPositionerHorizontalPosition {
  startIndex: number;
  size: number;
  endIndexReversed: number; // end index zero-based from the back
}

/**
 * Utility class that can calculate columization of parallel events
 *
 * @export
 * @class EventPositioner
 */
export class EventPositioner implements IEventPositioner {
  private internalState: EventPositionerState;

  constructor() {
    this.internalState = {
      columns: [],
    };
  }

  private totalNumberOfColumns(): number {
    return this.internalState.columns.length;
  }

  private horizontalPositionOfEntry(
    entry: EventPositionerEntry
  ): EventPositionerHorizontalPosition {
    const totalNumberOfColumns = this.totalNumberOfColumns();
    const free = this.internalState.columns
      .map((column) => getColumnStatusAtEntry(column, entry))
      .filter(
        (status) => status.status === EventPositionerColumnStatus.Free
      ).length;
    const occupied = totalNumberOfColumns - free;

    const preliminary = {
      startIndex: (1.0 * entry.columnIndex * totalNumberOfColumns) / occupied,
      size: (1.0 * free) / occupied + 1,
    };

    return {
      ...preliminary,
      endIndexReversed:
        totalNumberOfColumns - preliminary.startIndex - preliminary.size,
    };
  }

  private leftForEntry(entry: EventPositionerEntry): string {
    const { startIndex } = this.horizontalPositionOfEntry(entry);
    return (100.0 / this.totalNumberOfColumns()) * startIndex + "%";
  }
  private rightForEntry(entry: EventPositionerEntry): string {
    const { endIndexReversed } = this.horizontalPositionOfEntry(entry);
    return (100.0 / this.totalNumberOfColumns()) * endIndexReversed + "%";
  }

  addEntry(entry: EventPositionerNewEntry): EventPositionerPosition {
    let insertedEntry: EventPositionerEntry | null = null;
    for (const column of this.internalState.columns) {
      insertedEntry = insertEntryIntoColumn(entry, column);
      if (insertedEntry) break;
    }

    if (!insertedEntry) {
      // we did not success --> create a new column
      const column = this.openNewColumn();
      insertedEntry = insertEntryIntoColumn(entry, column);
    }

    if (insertedEntry) {
      const theEntry = insertedEntry; // typescript non-null-detection
      return {
        styles: {
          left: () => {
            return this.leftForEntry(theEntry);
          },
          right: () => {
            return this.rightForEntry(theEntry);
          },
        },
      };
    } else {
      throw new Error(
        "Could not insert new entry into positioner. This should never happen."
      );
    }
  }

  private openNewColumn(): EventPositionerColumn {
    const column = {
      index: -1,
      entries: [],
      froms: [],
      tos: [],
      handles: [],
    };

    column.index = this.internalState.columns.push(column) - 1;
    return column;
  }
}

/**
 * Helper function that tries to insert an entry in a specific column
 *
 * It will keep the invariants of {@link EventPositionerColumn}
 *
 * @param {EventPositionerEntry} entry
 * @param {EventPositionerColumn} column
 * @returns {boolean} - true if adding was successful, false otherwise
 */
function insertEntryIntoColumn(
  entry: EventPositionerNewEntry,
  column: EventPositionerColumn
): EventPositionerEntry | null {
  const status = getColumnStatusAtEntry(column, entry);
  if (status.status === EventPositionerColumnStatus.Free) {
    return insertEntryIntoColumnAtIndex(entry, column, status.index);
  } else {
    return null;
  }
}

/**
 * Helper function that inserts an entry in a specific column at a given index
 *
 * It will keep the invariants of {@link EventPositionerColumn}
 *
 * @param {EventPositionerEntry} entry
 * @param {EventPositionerColumn} column
 * @param {number} index
 */
function insertEntryIntoColumnAtIndex(
  entry: EventPositionerNewEntry,
  column: EventPositionerColumn,
  index: number
): EventPositionerEntry {
  const internalEntry = Object.freeze({
    ...entry,
    handle: getNextGlobalUniqueId(),
    columnIndex: column.index,
  });

  column.entries.splice(index, 0, internalEntry);
  column.froms.splice(index, 0, internalEntry.from);
  column.tos.splice(index, 0, internalEntry.to);
  column.handles.splice(index, 0, internalEntry.handle);

  return internalEntry;
}

type EventPositionEntryHandle = number;
let GLOBAL_UNIQUE_ID_POINTER: EventPositionEntryHandle = 0;
function getNextGlobalUniqueId(): EventPositionEntryHandle {
  GLOBAL_UNIQUE_ID_POINTER += 1;
  return GLOBAL_UNIQUE_ID_POINTER;
}

enum EventPositionerColumnStatus {
  Free,
  OccupiedByOther,
  OccupiedBySelf,
}

/**
 * Helper function that determines for a given entry (either already inserted or not)
 * if it has space in the also given column.
 * If a space is found the index is returned.
 *
 * @param {EventPositionerColumn} column
 * @param {(EventPositionerNewEntry | EventPositionerEntry)} entry
 * @returns {({
 *   status: EventPositionerColumnStatus.Free;
 *   index: number;
 * } | {
 *   status: Exclude<EventPositionerColumnStatus, EventPositionerColumnStatus.Free>,
 *   index: null;
 * })}
 */
function getColumnStatusAtEntry(
  column: EventPositionerColumn,
  entry: EventPositionerNewEntry | EventPositionerEntry
):
  | {
      status: EventPositionerColumnStatus.Free;
      index: number;
    }
  | {
      status: Exclude<
        EventPositionerColumnStatus,
        EventPositionerColumnStatus.Free
      >;
      index: null;
    } {
  if (isEventPositionerEntry(entry) && entry.columnIndex === column.index) {
    return {
      status: EventPositionerColumnStatus.OccupiedBySelf,
      index: null,
    };
  } else if (
    column.entries.length === 0 ||
    column.entries[0].from >= entry.to
  ) {
    return {
      status: EventPositionerColumnStatus.Free,
      index: 0,
    };
  } else {
    for (const [index, nextEntry] of column.entries.entries()) {
      if (
        nextEntry.to <= entry.from &&
        (column.entries.length === index + 1 ||
          isBefore(entry.to, column.entries[index + 1].from))
      ) {
        return {
          status: EventPositionerColumnStatus.Free,
          index: index + 1,
        };
      }
    }
    return {
      status: EventPositionerColumnStatus.OccupiedByOther,
      index: null,
    };
  }
}

function isEventPositionerEntry(
  entry: EventPositionerNewEntry | EventPositionerEntry
): entry is EventPositionerEntry {
  return "columnIndex" in entry;
}
