import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  HttpPayload,
  UnifiedBaseResponse,
  UnifiedListResponse,
  UnifiedResponse,
} from "frontend/interfaces/http";
import { PersonRaw } from "frontend/interfaces/person";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { ErrorDatabase } from "frontend/uses/use-errors";

export async function requestPersons(): Promise<
  UnifiedListResponse<PersonRaw>
> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_persons_path,
    {},
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { persons: PersonRaw[] }).persons,
  };
}

export async function updatePerson(
  person: HttpPayload
): Promise<UnifiedResponse<PersonRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.PATCH,
    routes.paths.frontend_person_path,
    { id: person.id as string },
    person
  );

  return {
    entityRaw: (body as unknown as { person: PersonRaw }).person,
    success: statusIsSuccess,
    errors:
      (body as unknown as { person: { _errors?: ErrorDatabase } }).person
        ._errors || {},
  };
}

export async function createPerson(
  person: HttpPayload
): Promise<UnifiedResponse<PersonRaw>> {
  const { statusIsSuccess, body } = await doRequest(
    HttpMethod.POST,
    routes.paths.frontend_persons_path,
    {},
    person
  );

  return {
    entityRaw: (body as unknown as { person: PersonRaw }).person,
    success: statusIsSuccess,
    errors:
      (body as unknown as { person: { _errors?: ErrorDatabase } }).person
        ._errors || {},
  };
}

export async function deletePerson(
  id: PrimaryKey
): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.DELETE,
    routes.paths.frontend_person_path,
    { id },
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
  };
}
