<script lang="ts" setup>
  import { computed, onMounted, PropType, reactive, ref } from "vue";

  import { today } from "shared/utils/date-utils";
  import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";
  import {
    OnAutosaveFor,
    ErrorFor,
    StatusFor,
  } from "frontend/uses/settings/use-edit-component";
  import { StatusValue } from "frontend/utils/animation-status";
  import { useEnumSelect } from "frontend/uses/use-form-select";
  import { SettingsProfileEnumCalendarDefaultViews } from "shared/static/model-attributes.ts.erb";
  import { useWeekdayFormCheckbox } from "frontend/uses/use-form-checkbox";
  import {
    settingsProfileContextFor,
    SettingsProfileContextName,
  } from "frontend/utils/settings-profile-contexts";
  import { Cache } from "frontend/utils/request-cache";
  import { Person } from "frontend/interfaces/person";
  import { sortPersonsByProfile } from "frontend/stores/profile";
  import { CreateProfileModal } from "frontend/utils/modals/create-profile-modal";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import FormCheckboxGroup from "frontend/components/form/FormCheckboxGroup.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";

  const props = defineProps({
    entity: {
      type: Object as PropType<SettingsProfile>,
      required: true,
    },
    onAutosaveFor: {
      type: Function as PropType<OnAutosaveFor>,
      default: undefined,
    },
    errorFor: {
      type: Function as PropType<ErrorFor>,
      default: undefined,
    },
    statusFor: {
      type: Function as PropType<StatusFor>,
      default: undefined,
    },
    visualSmall: {
      type: Boolean,
      default: false,
    },
    contextName: {
      type: String as PropType<SettingsProfileContextName>,
      default: "default",
    },
  });

  const errorMessage = ref<string | null>(null);
  const onUpdateTimeScale = (newValue: number) => {
    errorMessage.value = null;

    if (newValue >= 5 && newValue <= 60) {
      props.entity.calendar_timescale_in_minutes = newValue;
    } else errorMessage.value = "Wert muss im Bereich 5 bis 60 sein";
  };

  const deboucedErrorFor = (key: string, globalKey?: string) =>
    props.errorFor ? props.errorFor(key, globalKey) : [];
  const debouncedStatusFor = (key: string) =>
    props.statusFor ? props.statusFor(key) : StatusValue.NoStatus;
  const debouncedOnAutosaveFor = (key: string) =>
    props.onAutosaveFor ? props.onAutosaveFor(key) : async () => false;

  const currentDefaultView = computed({
    get: () => props.entity.calendar_default_view,
    set: (value: typeof props.entity.calendar_default_view) =>
      (props.entity.calendar_default_view = value),
  });
  const {
    currentSelection: calendarDefaultViewCurrentSelection,
    options: calendarDefaultViewOptions,
  } = useEnumSelect(
    currentDefaultView,
    SettingsProfileEnumCalendarDefaultViews,
    {
      day: "Tagesansicht",
      week: "Wochenansicht",
    }
  );

  const { options: weekdayOptions } = useWeekdayFormCheckbox();

  const settingsProfileContext = computed(() =>
    settingsProfileContextFor(props.contextName)
  );

  const key = ref<string>("calendar_removed_persons");
  const persons = ref<Array<Person>>([]);
  const personsOptions = computed(() =>
    sortPersonsByProfile(persons.value, props.entity).map((entry) => ({
      id: entry.id,
      label: entry.name,
    }))
  );
  const loadPersons = async () => {
    persons.value = await Cache.getCachedPersons();
  };
  onMounted(loadPersons);

  const showEditButton = computed(
    () =>
      settingsProfileContext.value &&
      settingsProfileContext.value.showEditButton
  );
  const openEditDialog = () => {
    const data = reactive({
      profile: props.entity,
      errorDatabase: {},
      editMode: true,
    });
    new CreateProfileModal().setData(data).show();
  };
</script>

<template>
  <div class="mutated-settings-profile">
    <FormSelect
      v-if="settingsProfileContext.showControlsFor('calendar_default_view')"
      v-model="calendarDefaultViewCurrentSelection"
      v-bind:label="settingsProfileContext.labelFor('calendar_default_view')"
      v-bind:visual-small="visualSmall"
      v-bind:options="calendarDefaultViewOptions"
      v-bind:errors="
        deboucedErrorFor('calendar_default_view').concat(
          errorMessage ? [errorMessage] : []
        )
      "
      v-bind:animation-status="debouncedStatusFor('calendar_default_view')"
      v-on="{ autosave: debouncedOnAutosaveFor('calendar_default_view') }"
    />
    <FormInput
      v-if="
        settingsProfileContext.showControlsFor('calendar_timescale_in_minutes')
      "
      v-bind:model-value="entity.calendar_timescale_in_minutes"
      defer-emit
      v-bind:visual-small="visualSmall"
      v-bind:label="
        settingsProfileContext.labelFor('calendar_timescale_in_minutes')
      "
      v-bind:errors="
        deboucedErrorFor('calendar_timescale_in_minutes').concat(
          errorMessage ? [errorMessage] : []
        )
      "
      v-bind:animation-status="
        debouncedStatusFor('calendar_timescale_in_minutes')
      "
      v-on="{
        autosave: debouncedOnAutosaveFor('calendar_timescale_in_minutes'),
        'update:modelValue': onUpdateTimeScale,
      }"
    />
    <FormCheckbox
      v-if="settingsProfileContext.showControlsFor('unit_show_patient_name')"
      v-model="entity.unit_show_patient_name"
      v-bind:label="settingsProfileContext.labelFor('unit_show_patient_name')"
      v-bind:errors="deboucedErrorFor('unit_show_patient_name')"
      v-bind:animation-status="debouncedStatusFor('unit_show_patient_name')"
      v-on="{ autosave: debouncedOnAutosaveFor('unit_show_patient_name') }"
    />
    <FormCheckbox
      v-if="
        settingsProfileContext.showControlsFor(
          'unit_show_appointment_type_name'
        )
      "
      v-model="entity.unit_show_appointment_type_name"
      v-bind:label="
        settingsProfileContext.labelFor('unit_show_appointment_type_name')
      "
      v-bind:errors="deboucedErrorFor('unit_show_appointment_type_name')"
      v-bind:animation-status="
        debouncedStatusFor('unit_show_appointment_type_name')
      "
      v-on="{
        autosave: debouncedOnAutosaveFor('unit_show_appointment_type_name'),
      }"
    />
    <FormCheckbox
      v-if="settingsProfileContext.showControlsFor('unit_show_room')"
      v-model="entity.unit_show_room"
      v-bind:label="settingsProfileContext.labelFor('unit_show_room')"
      v-bind:errors="deboucedErrorFor('unit_show_room')"
      v-bind:animation-status="debouncedStatusFor('unit_show_room')"
      v-on="{
        autosave: debouncedOnAutosaveFor('unit_show_room'),
      }"
    />
    <FormCheckbox
      v-if="
        settingsProfileContext.showControlsFor('unit_show_internal_office_note')
      "
      v-model="entity.unit_show_internal_office_note"
      v-bind:label="
        settingsProfileContext.labelFor('unit_show_internal_office_note')
      "
      v-bind:errors="deboucedErrorFor('unit_show_internal_office_note')"
      v-bind:animation-status="
        debouncedStatusFor('unit_show_internal_office_note')
      "
      v-on="{
        autosave: debouncedOnAutosaveFor('unit_show_internal_office_note'),
      }"
    />
    <FormCheckbox
      v-if="settingsProfileContext.showControlsFor('unit_show_patient_message')"
      v-model="entity.unit_show_patient_message"
      v-bind:label="
        settingsProfileContext.labelFor('unit_show_patient_message')
      "
      v-bind:errors="deboucedErrorFor('unit_show_patient_message')"
      v-bind:animation-status="debouncedStatusFor('unit_show_patient_message')"
      v-on="{
        autosave: debouncedOnAutosaveFor('unit_show_patient_message'),
      }"
    />
    <FormCheckbox
      v-if="
        settingsProfileContext.showControlsFor('calendar_show_visual_lines')
      "
      v-model="entity.calendar_show_visual_lines"
      v-bind:label="
        settingsProfileContext.labelFor('calendar_show_visual_lines')
      "
      v-bind:errors="deboucedErrorFor('calendar_show_visual_lines')"
      v-bind:animation-status="debouncedStatusFor('calendar_show_visual_lines')"
      v-on="{
        autosave: debouncedOnAutosaveFor('calendar_show_visual_lines'),
      }"
    />
    <FormDatetime
      v-if="
        settingsProfileContext.showControlsFor(
          'calendar_range_begin_local_as_utc'
        )
      "
      v-model="entity.calendar_range_begin_local_as_utc"
      v-bind:label="
        settingsProfileContext.labelFor('calendar_range_begin_local_as_utc')
      "
      allow-deletion
      use-utc
      static-time-picker
      v-bind:base-date="entity.calendar_range_begin_local_as_utc ?? today()"
      v-bind:errors="deboucedErrorFor('calendar_range_begin_local_as_utc')"
      v-bind:animation-status="
        debouncedStatusFor('calendar_range_begin_local_as_utc')
      "
      v-on="{
        autosave: debouncedOnAutosaveFor('calendar_range_begin_local_as_utc'),
      }"
    ></FormDatetime>
    <FormDatetime
      v-if="
        settingsProfileContext.showControlsFor(
          'calendar_range_end_local_as_utc'
        )
      "
      v-model="entity.calendar_range_end_local_as_utc"
      v-bind:label="
        settingsProfileContext.labelFor('calendar_range_end_local_as_utc')
      "
      allow-deletion
      use-utc
      static-time-picker
      v-bind:base-date="entity.calendar_range_end_local_as_utc ?? today()"
      v-bind:errors="deboucedErrorFor('calendar_range_end_local_as_utc')"
      v-bind:animation-status="
        debouncedStatusFor('calendar_range_end_local_as_utc')
      "
      v-on="{
        autosave: debouncedOnAutosaveFor('calendar_range_end_local_as_utc'),
      }"
    ></FormDatetime>
    <FormCheckboxGroup
      v-if="settingsProfileContext.showControlsFor('calendar_shown_weekdays')"
      v-model="entity.calendar_shown_weekdays"
      v-bind:options="weekdayOptions"
      v-bind:label="settingsProfileContext.labelFor('calendar_shown_weekdays')"
      v-bind:errors="deboucedErrorFor('calendar_shown_weekdays')"
      v-bind:animation-status="debouncedStatusFor('calendar_shown_weekdays')"
      v-bind:visual-small="visualSmall"
      display-type="rows"
      v-on="{ autosave: debouncedOnAutosaveFor('calendar_shown_weekdays') }"
    />
    <FormCheckboxGroup
      v-if="settingsProfileContext.showControlsFor('calendar_removed_persons')"
      v-model="entity.calendar_removed_persons"
      v-model:sorted="entity.calendar_sorted_persons"
      v-bind:options="personsOptions"
      v-bind:label="settingsProfileContext.labelFor('calendar_removed_persons')"
      v-bind:errors="deboucedErrorFor(key)"
      v-bind:animation-status="debouncedStatusFor(key)"
      v-bind:visual-small="visualSmall"
      drag-and-drop
      inverse
      v-on="{ autosave: debouncedOnAutosaveFor(key) }"
      v-on:update:model-value="key = 'calendar_removed_persons'"
      v-on:update:sorted="key = 'calendar_sorted_persons'"
    />

    <div
      v-if="showEditButton"
      class="open-edit-dialog"
      v-on:click="openEditDialog"
    >
      Alle Einstellungen
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/link";

  .open-edit-dialog {
    @include link.link;

    font-size: 12px;
    margin-top: 15px;
  }
</style>
