import { computed, ref, Ref, unref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { DateFormat, formatDate, today } from "shared/utils/date-utils";

export interface AbstractCalendarOptions {
  labelCurrent: string;
  switchButtons: AbstractCalendarSwitchButton[];
}

export interface AbstractCalendarSwitchButton {
  key: string | number;
  label: string;
  onSubmit: () => void;
  isActive: Readonly<Ref<boolean>>;
}

export function useAbstractCalendar(
  date: Readonly<Ref<Date | null>>,
  routeToDate: (destination: Date) => void,
  nextDate: (date: Date, directionUp: boolean) => Date,
  options?: {
    currentDate?: () => Date;
  }
): {
  miniCalendarMonth: Ref<Date>;
  doNavigateForward: () => void;
  doNavigateBack: () => void;
  doNavigateCurrent: () => void;
  doNavigateTo: (destination: Date) => void;
} {
  const currentDate = () =>
    (options?.currentDate ? options.currentDate : today)();
  const doNavigate = (destination: Date) => {
    miniCalendarMonth.value = destination;
    routeToDate(destination);
  };

  const miniCalendarMonth = ref(unref(date) ?? currentDate()); // copy date over initially

  return {
    miniCalendarMonth,
    doNavigateForward: () =>
      doNavigate(nextDate(date.value ?? currentDate(), true)),
    doNavigateBack: () =>
      doNavigate(nextDate(date.value ?? currentDate(), false)),
    doNavigateCurrent: () => doNavigate(currentDate()),
    doNavigateTo: (destination: Date) => doNavigate(destination),
  };
}

export function mainSwitchButtons(currentDate: Ref<Date | null>) {
  const currentRoute = useRoute();
  const router = useRouter();

  return [
    {
      key: "1",
      label: "Tag",
      onSubmit: () => {
        router.push({
          name: "calendar-day",
          params: {
            day: formatDate(
              currentDate.value ?? today(),
              DateFormat.DateOnlyISO
            ),
          },
        });
      },
      isActive: computed(() => currentRoute.name === "calendar-day"),
    },
    {
      key: "2",
      label: "Woche",
      onSubmit: () => {
        router.push({
          name: "calendar-week",
          params: {
            day: formatDate(
              currentDate.value ?? today(),
              DateFormat.DateOnlyISO
            ),
          },
        });
      },
      isActive: computed(() => currentRoute.name === "calendar-week"),
    },
  ];
}
