import { defineStore } from "pinia";
import { addDays, getTime, isAfter } from "date-fns";

import { News } from "frontend/news";
import { highestBy } from "shared/utils/array-utils";

interface NewsStoreState {
  highestNewsIdSeen: number;
}

const SHOW_NOTIFICATION_MAX_FOR_X_DAYS = 7;

export const id = "news";

export const useStore = defineStore({
  id,
  state(): NewsStoreState {
    return {
      highestNewsIdSeen: 0,
    };
  },
  actions: {
    // TODO: make to a getter instead of an action!
    doesANewNewsExist() {
      const highestElement = highestBy(News, (e) =>
        e.id > this.highestNewsIdSeen &&
        isAfter(addDays(e.date, SHOW_NOTIFICATION_MAX_FOR_X_DAYS), new Date())
          ? getTime(e.date)
          : null
      );

      return !!highestElement;
    },
    lastIdSeen() {
      return this.highestNewsIdSeen;
    },
    setNewsAsSeen() {
      this.highestNewsIdSeen = Math.max(...News.map((e) => e.id));
    },
  },
});
