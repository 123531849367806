import { parseISO } from "date-fns";

import { Appointment, AppointmentRaw } from "frontend/interfaces/p/appointment";
import { AppointmentStatus } from "shared/static/enums.ts.erb";
import { isActive } from "shared/utils/bitwise-enum";

export function parseAppointment(appointment: AppointmentRaw): Appointment {
  return {
    ...appointment,
    _policy: {
      ...appointment._policy, // copy, not reference
    },
    begin: parseISO(appointment.begin),
  };
}

export function statusToEntry(status: number): {
  highlightBackground: boolean;
  showCheckmark: boolean;
  iconNotArrived: boolean;
} {
  return {
    highlightBackground: isActive(status, AppointmentStatus.patient_arrived),
    showCheckmark: isActive(status, AppointmentStatus.done),
    iconNotArrived: isActive(status, AppointmentStatus.patient_not_arrived),
  };
}
