<script lang="ts" setup>
  import { computed, onMounted, PropType, toRefs } from "vue";

  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { SettingsProfile } from "frontend/interfaces/settings/settings-profile";
  import { ErrorDatabase, useErrors } from "frontend/uses/use-errors";

  import FormInput from "frontend/components/form/FormInput.vue";
  import MutatedSettingsProfile from "frontend/components/mutated/MutatedSettingsProfile.vue";

  const props = defineProps({
    ...useAsModalChildProps(),
    profile: {
      type: Object as PropType<SettingsProfile>,
      required: true,
    },
    errorDatabase: {
      type: Object as PropType<ErrorDatabase>,
      default: () => ({}),
    },
    editMode: {
      // only edit given profile (without creating a new one or persisting the existing one to the backend)
      // can be used to show all settings in a modal
      // that may be hidden due to the context (see settings-profile-contexts.ts)
      type: Boolean,
      default: false,
    },
  });

  const { errorDatabase } = toRefs(props);

  // eslint-disable-next-line vue/no-mutating-props -- mutation inline of profile is okay because it will be done in MutatedSettingsProfile anyway
  onMounted(() => (props.profile.name = ""));

  const { errorFor } = useErrors(errorDatabase);

  const title = computed(() =>
    props.editMode
      ? "Alle Einstellungen anpassen"
      : "Neues Einstellungsprofil anlegen"
  );
</script>

<template>
  <!-- eslint-disable vue/no-mutating-props -- mutation inline of profile is okay because it will be done in MutatedSettingsProfile anyway-->
  <div v-bind:class="{ 'create-settings-profile--as-modal': asModalChild }">
    <h2>{{ title }}</h2>
    <FormInput
      v-if="!editMode"
      v-model="profile.name"
      label="Name des neuen Profils"
      v-bind:errors="errorFor('name')"
    />
    <MutatedSettingsProfile
      v-bind:entity="profile"
      v-bind:error-for="errorFor"
      context-name="edit"
    />
  </div>
</template>

<style lang="scss" scoped>
  .create-settings-profile--as-modal {
    min-width: 80vw;
    min-height: 20vh;
  }
</style>
