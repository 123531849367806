import { createInterpolateFunction } from "shared/utils/interpolate-url";

export type PathParams = {
  [key: string]: string;
};

export type PathURL = string;
export type PathURLTemplate = (params: PathParams) => PathURL;

/**
 * take raw data from backend and process it for easy route-interpolation (with colon-/variable-parts of the paths)
 * resulting paths will be javascript function that takes the values for the dynamic / variable parts of the url and
 * replaces them
 *
 * See paths.d.ts for resulting types
 *
 * @param {*} pathsDescription
 * @returns
 */
export function pathify<S>(
  base: string,
  pathsDescription: Record<keyof S, string>
): Record<keyof S, PathURLTemplate> {
  return Object.fromEntries(
    Object.entries<string>(pathsDescription).map(([key, value]) => [
      key,
      createInterpolateFunction(value, base),
    ])
  ) as Record<keyof S, PathURLTemplate>;
}
