import { SettingsProfile } from "frontend/interfaces/settings-profile";
import {
  newSettingsProfile,
  httpPayloadSettingsProfile,
  parseSettingsProfile,
} from "frontend/parser/settings/parse-settings-profile";

export function newSettingsProfileDefault(): SettingsProfile {
  return {
    ...newSettingsProfile(),
    name: "Standardeinstellungen",
    id: "MOCK-default",
  };
}

export function duplicateSettingsProfile(
  settingsProfile: SettingsProfile
): SettingsProfile {
  return {
    ...settingsProfile,
    id: "DUPLICATED:" + settingsProfile.id,
    // duplicate array value to mitigate copy by reference
    calendar_shown_weekdays: [...settingsProfile.calendar_shown_weekdays],
    calendar_sorted_persons: [...settingsProfile.calendar_sorted_persons],
    calendar_removed_persons: [...settingsProfile.calendar_removed_persons],
  };
}

// for now these types are identical, so we copy the methods
export { newSettingsProfile, httpPayloadSettingsProfile, parseSettingsProfile };
