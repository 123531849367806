import { routes, doRequest } from "frontend/api/application";
import {
  HttpMethod,
  UnifiedBaseResponse,
  UnifiedListResponse,
} from "frontend/interfaces/http";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { HolidayRaw } from "frontend/interfaces/settings/holiday";

export async function requestHolidays(
  all: string = "true"
): Promise<UnifiedListResponse<HolidayRaw>> {
  const { body, statusIsSuccess } = await doRequest(
    HttpMethod.GET,
    routes.paths.frontend_holidays_path,
    { all },
    {}
  );

  return {
    success: statusIsSuccess,
    errors: {},
    entities: (body as unknown as { holidays: HolidayRaw[] }).holidays,
  };
}

export async function selectHolidays(
  holiday_ids: Array<PrimaryKey>
): Promise<UnifiedBaseResponse> {
  const { statusIsSuccess } = await doRequest(
    HttpMethod.POST,
    routes.paths.select_frontend_holidays_path,
    {},
    { office: { holiday_ids } }
  );

  return { success: statusIsSuccess, errors: {} };
}
