<script lang="ts">
  import { defineComponent } from "vue";

  import { updateAppointment } from "frontend/api/application/request-appointments";
  import { parseAppointment } from "frontend/parser/parse-appointment";

  import FormInput from "frontend/components/form/FormInput.vue";

  export default defineComponent({
    components: { FormInput },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true,
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const onUpdateModelValue = async (newValue: string) => {
        const result = await updateAppointment({
          id: props.appointmentId,
          internal_office_note: newValue,
        });
        if (result.success)
          emit(
            "update:modelValue",
            parseAppointment(result.entityRaw).internal_office_note
          );
      };

      return {
        onUpdateModelValue,
      };
    },
  });
</script>

<template>
  <div class="">
    <FormInput
      v-bind:model-value="modelValue"
      label="Interne Kurznotiz"
      type="textarea"
      defer-emit
      v-on:update:model-value="onUpdateModelValue"
    />
  </div>
</template>
