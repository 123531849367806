<script lang="ts">
  import { computed, defineComponent, toRefs } from "vue";

  import {
    editProps,
    useEditComponent,
  } from "frontend/uses/settings/use-edit-component";
  import { Reminder } from "frontend/interfaces/settings/reminder";
  import {
    ReminderUnits,
    ReminderUnitsType,
  } from "shared/static/time-units.ts.erb";
  import { translateTimeUnit } from "frontend/utils/translate-enum";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";

  export default defineComponent({
    components: { FormInput, FormSelect, BaseButton },
    props: {
      ...editProps<Reminder>(),
    },
    setup(props) {
      const { entity } = toRefs(props);
      const editComponent = useEditComponent(entity.value);
      const options = computed(() =>
        ReminderUnits.map((e) => {
          const translation = translateTimeUnit(e);
          return {
            id: e,
            label:
              editComponent.entityEditable.value.time_number === 1
                ? translation.singular
                : translation.plural,
          };
        })
      );
      const currentTimeSpan = computed(() => {
        const translation = translateTimeUnit(
          entity.value.time_unit as ReminderUnitsType
        );
        return `${entity.value.time_number} ${
          entity.value.time_number === 1
            ? translation.singular
            : translation.plural
        }`;
      });
      return {
        ...editComponent,
        options,
        currentTimeSpan,
        //libs
        translateTimeUnit,
      };
    },
  });
</script>

<template>
  <div class="edit-reminder">
    <h5>
      {{ currentTimeSpan }} <span class="time-label">vor dem Termin</span>
    </h5>
    <FormInput
      v-model="entityEditable.time_number"
      label="Anzahl"
      type="number"
      v-bind:errors="errorFor('time_number')"
      v-bind:animation-status="statusFor('time_number')"
      v-on="{ autosave: onAutosaveFor('time_number') }"
    />
    <FormSelect
      v-model="entityEditable.time_unit"
      label="Zeiteinheit"
      v-bind:options="options"
      v-bind:errors="errorFor('time_unit')"
      v-bind:animation-status="statusFor('time_unit')"
      v-on="{ autosave: onAutosaveFor('time_unit') }"
    />
    <BaseButton
      class="action"
      v-bind:disabled="isPending"
      v-on:submit="deleteRecord"
    >
      <i class="fe fe-trash"></i>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
  .time-label {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }
</style>
