<script lang="ts" setup>
  import { PropType, toRefs } from "vue";

  import { ScheduleActivation } from "frontend/interfaces/schedule-activation";
  import { ErrorDatabase, useErrors } from "frontend/uses/use-errors";

  import FormSelectOfficeSchedule from "frontend/components/form/FormSelectOfficeSchedule.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";

  const props = defineProps({
    entity: {
      type: Object as PropType<ScheduleActivation>,
      required: true,
    },
    visualModal: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object as PropType<ErrorDatabase>,
      required: true,
    },
    globalErrors: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  });
  const { errorFor } = useErrors(toRefs(props).errors);
  const { entity } = toRefs(props);
</script>

<template>
  <div v-bind:class="{ 'schedule-activation--as-modal': visualModal }">
    <template v-if="globalErrors && globalErrors.length > 0">
      <label
        v-for="errorMsg in globalErrors"
        v-bind:key="errorMsg"
        class="label--error"
      >
        {{ errorMsg }}
      </label>
    </template>
    <FormSelectOfficeSchedule
      v-model="entity.schedule_id"
      label="Zeitplan"
      v-bind:errors="errorFor('schedule_id')"
    />
    <FormDatetime
      v-model="entity.from"
      label="Zeitplan gilt ab"
      v-bind:errors="errorFor('from')"
    ></FormDatetime>
    <FormDatetime
      v-model="entity.to"
      label="Zeitplan gilt bis (optional)"
      v-bind:min-date="entity.from ?? undefined"
      v-bind:errors="errorFor('to')"
    ></FormDatetime>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";
  .schedule-activation--as-modal {
    width: 50vw;
    min-height: 50vh;
  }
  .label--error {
    @include label.label;
    @include label.label--error;
  }
</style>
