import { App } from "vue";

import { Manager } from "shared/utils/context-menu/manager";

export function setupContextMenu(app: App<Element>) {
  // hook into mounting process,
  // catch root component after it was created
  // and with that create the context-menu manager
  const originalMount = app.mount;
  app.mount = (...args) => {
    const component = originalMount(...args);
    new Manager(app, component);
    return component;
  };
}
