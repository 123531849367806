import { Component } from "vue";

import { TmpUnit } from "frontend/interfaces/unit";
import { EventPositionerPosition } from "shared/utils/event-positioner";
import { PrimaryKey } from "frontend/interfaces/primary-key";
import { Attendance as SettingsAttendance } from "frontend/interfaces/settings/attendance";
import { AppointmentType } from "frontend/interfaces/appointment-type";
import { Patient } from "frontend/interfaces/patient";
import { Interval } from "shared/interfaces/interval";
import { ResizingPosition } from "frontend/interfaces/resizing";
import { ConditionalContextMenuEntry } from "shared/utils/context-menu/interfaces";

export interface CalendarConnection {
  triggerRefreshNeeded: () => void;
}

export type CalendarConstants = Readonly<{
  TIMESCALE_STEP_SIZE: number;
  TIMESCALE_VISUAL_INDICATOR_STEP_SIZE?: number; // in minutes not set --> no indicators
  DISPLAY_DURATION_AT_LEAST: number;
  CREATION_DEFAULT_DURATION: number;
  ROUND_DROP_TO_NEAREST: number;
  ROUND_CLICK_TO_NEAREST: number;
  FLOOR_CEIL_STEP_SIZE: number;
}>;

export interface CalendarHeader {
  id: PrimaryKey;
  label: string;
}

export interface CalendarNote {
  id: PrimaryKey;
  label: string;
  color: string;
  widthOffset: number;
  active: boolean;
  startsBefore: boolean;
  endsAfter: boolean;
}

export interface CalendarRow {
  key: string;
  label: string;
  cells: CalendarCell[];
}

export interface CalendarCell {
  key: string;
  layers: CalendarLayer[];
  interval: Interval;
  // something that identifies the column
  columnPivot: unknown;
  onClick?: (location: CalendarRelativeLocation) => void;

  // needed for calulations
  // points to DOM-Element of the cell
  element?: HTMLElement;
  ctxPasteAppointment?: ConditionalContextMenuEntry;
  ctxPasteAttendance?: ConditionalContextMenuEntry;
}

export interface CalendarLayer {
  key: string;
  // NOTE: zIndex should be unique within a cell
  //       you have to change key-generation in
  //       use-day-view-parser.ts if you need
  //       non-unique zIndices!
  zIndex: number;
  entries: CalendarEntry[];
}

export enum CalendarEntryType {
  Unit,
  UnitAbsence,
  Attendance,
  Tmp,
  SettingsAttendance,
  Visual,
}

export interface EventLike {
  stopPropagation: () => void;
}

export interface CalendarEntry {
  key: string;
  type: CalendarEntryType;

  // using functions for this to prevent deep refing of vue
  componentCalendar: () => Component;

  topOffsetPercentage: number;
  heightPercentage: number;
  heightPixels?: number;
  heightCutoffTop?: boolean;
  heightCutoffBottom?: boolean;

  hoverOn?: string;

  onDrop?: (target: CalendarCell, location: CalendarRelativeLocation) => void;
  onResize?: (
    entry: CalendarEntry,
    resizingPosition: ResizingPosition,
    offsetAbsolute: number,
    resizingEnded: boolean,
    targetDate: Date
  ) => void;
  onClick?: (entry: CalendarEntry, nativeEvent: EventLike) => void;
}

export interface CalendarEntryPositionable {
  position: EventPositionerPosition;
}

export interface CalendarEntryUnitAbsence
  extends CalendarEntry,
    CalendarEntryPositionable {
  type: CalendarEntryType.UnitAbsence;

  label?: string;
}

export interface CalendarEntryUnit
  extends CalendarEntry,
    CalendarEntryPositionable {
  type: CalendarEntryType.Unit;

  label: string;
  description?: string;

  timeLabel: string;
  roomLabel?: string;
  boldLabel?: string;

  color?: string;

  disabled: boolean;
  hoverOn: string;

  highlightBackground: boolean;
  showCheckmark: boolean;
  iconNotArrived: boolean;

  ctxAppointmentStatusPatientArrived?: ConditionalContextMenuEntry;
  ctxAppointmentStatusPatientNotArrived?: ConditionalContextMenuEntry;
  ctxAppointmentStatusDone?: ConditionalContextMenuEntry;
  ctxCutAppointment?: ConditionalContextMenuEntry;
  ctxDeleteAppointment?: ConditionalContextMenuEntry;
  ctxAppointmentSuggestions?: ConditionalContextMenuEntry;
  ctxOpenPatientProfile?: ConditionalContextMenuEntry;
  ctxOpenPatientInPVS?: ConditionalContextMenuEntry;

  iconSmartphoneTouch: boolean;
  iconHasPraxisApp: boolean;
  iconHasPrivate: boolean;
}

export interface CalendarEntryAttendance extends CalendarEntry {
  type: CalendarEntryType.Attendance;

  color: string | null;
  tooltipLabel: string | null;
}

export interface CalendarEntryVisual extends CalendarEntry {
  type: CalendarEntryType.Visual;
}

export interface CalendarEntrySettingsAttendance
  extends CalendarEntry,
    CalendarEntryPositionable {
  type: CalendarEntryType.SettingsAttendance;

  label: string;
  personLabel: string;

  color_id: string | null;

  hoverOn: string;

  entity: SettingsAttendance;
  ctxCopyAttendance?: ConditionalContextMenuEntry;
}

export interface CalendarEntryTmp extends CalendarEntry {
  type: CalendarEntryType.Tmp;

  doDestroy: () => void;
  doSubmit: (
    units: TmpUnit[],
    patient: Patient,
    appointmentType: AppointmentType,
    orderTime: number,
    notifyPatient: boolean,
    remindPatient: boolean
  ) => Promise<boolean>;
  doSubmitUnit: () => Promise<boolean>;

  onPersonChange: (newPersonId: PrimaryKey) => void;
  onDateChange: (from: Date, to: Date) => void;

  unit: TmpUnit;
}

export type PopupAppointmentEntry = Pick<
  CalendarEntryTmp,
  | "unit"
  | "key"
  | "doSubmit"
  | "doSubmitUnit"
  | "doDestroy"
  | "onPersonChange"
  | "onDateChange"
>;

export interface Calendar {
  columnHeaders: CalendarHeader[];
  columnNotes: Array<CalendarNote[]>;
  rows: CalendarRow[];

  onCalendarInit?: (connection: CalendarConnection) => void;
}

export interface CalendarRelativeLocation {
  x: number;
  y: number;
}

export interface CalendarUtils {
  relativeDateToCell: (topOffsetAbsolute: number) => Date;
  relativeDateToEntry: (topOffsetAbsolute: number) => Date;
}
