<script lang="ts" setup>
  import { computed, PropType } from "vue";

  import { Errors } from "frontend/uses/use-errors";
  import { StatusValue } from "frontend/utils/animation-status";
  import {
    AllTimeUnitsType,
    AllTimeUnits,
  } from "shared/static/time-units.ts.erb";
  import { translateTimeUnit } from "frontend/utils/translate-enum";

  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import AnimationStatus from "frontend/components/AnimationStatus.vue";

  const props = defineProps({
    amount: {
      type: null as unknown as PropType<number | null>,
      default: null,
      validator: (v: unknown) => v === null || typeof v === "number",
    },
    unit: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    errors: {
      type: Array as PropType<Errors>,
      default: () => [],
    },
    animationStatus: {
      type: Number as PropType<StatusValue | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    timeUnits: {
      type: Array as PropType<readonly AllTimeUnitsType[]>,
      default: () => AllTimeUnits,
    },
  });

  const emit = defineEmits(["update:amount", "update:unit", "autosave"]);

  const onUpdateAmount = (newValue: string | number | null) => {
    const updateEvent = "update:amount";

    if (!newValue) emit(updateEvent, null);
    else {
      if (typeof newValue === "number") emit(updateEvent, Math.round(newValue));
      else emit(updateEvent, parseInt(newValue, 10));
    }
  };

  const optionsUnit = computed(() =>
    props.timeUnits.map((timeUnit) => ({
      id: timeUnit,
      label:
        props.amount && props.amount == 1
          ? translateTimeUnit(timeUnit).singular
          : translateTimeUnit(timeUnit).plural,
    }))
  );
  const onUpdateUnit = (newValue: AllTimeUnitsType | null) => {
    const updateEvent = "update:unit";
    if (!newValue) emit(updateEvent, null);
    else emit(updateEvent, newValue);
  };
</script>

<template>
  <div class="form-relative-time">
    <label class="frt__label">{{ label }}</label>
    <div class="frt__picker-area">
      <FormInput
        class="frt__amount"
        type="number"
        v-bind:model-value="amount"
        v-bind:disabled="disabled"
        defer-emit
        v-on:update:model-value="onUpdateAmount"
        v-on:autosave="$emit('autosave')"
      />
      <FormSelect
        class="frt__unit"
        v-bind:model-value="unit"
        v-bind:options="optionsUnit"
        v-bind:disabled="disabled"
        v-on:update:model-value="onUpdateUnit"
        v-on:autosave="$emit('autosave')"
      />
      <AnimationStatus
        v-if="animationStatus !== null"
        class="frt__animation-status"
        v-bind:animation-status="animationStatus"
      />
    </div>
    <template v-if="errors && errors.length > 0">
      <label
        v-for="error in errors"
        v-bind:key="error"
        class="frt__label--error"
      >
        {{ error }}
      </label>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .form-relative-time {
    padding: 10px 0;
  }

  .frt__label,
  .frt__label--error {
    @include label.label;
  }

  .frt__label--error {
    @include label.label--error;

    display: block;
  }
  .frt__picker-area {
    display: flex;

    align-items: center;

    padding-top: 10px;

    :deep(*) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .frt__amount {
    margin-right: 10px;

    width: 60px;
  }

  .frt__animation-status {
    margin-left: 10px;
  }
</style>
