<script lang="ts">
  import { defineComponent, reactive, toRefs, watchEffect } from "vue";

  import { formatDate, DateFormat } from "shared/utils/date-utils";
  import {
    useShowUnit,
    defineShowUnitProps,
  } from "frontend/uses/use-show-unit";
  import { useAsModalChildProps } from "frontend/uses/simple-modal/use-simple-modal";
  import { DeleteModal } from "frontend/utils/modals/delete-modal";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import UnitStatus from "frontend/components/UnitStatus.vue";
  import UnitInternalOfficeNote from "frontend/components/UnitInternalOfficeNote.vue";
  import ShowLoadedUnit from "frontend/components/ShowLoadedUnit.vue";

  export default defineComponent({
    components: {
      BaseButton,
      UnitStatus,
      UnitInternalOfficeNote,
      ShowLoadedUnit,
    },
    props: {
      ...defineShowUnitProps(),
      ...useAsModalChildProps(),
    },
    emits: [
      "update:modelValue",
      "refresh-needed",
      "changed:unit:status",
      "changed:unit:internalOfficeNote",
      "changed:unit:name",
      "changed:unit:color_id",
    ],
    setup(props, { emit }) {
      const { modelValue } = toRefs(props);
      const { currentUnit } = useShowUnit(modelValue);

      const doDeleteUnit = () => {
        if (!currentUnit.value) return;
        new DeleteModal()
          .setData(
            reactive({
              unit: currentUnit.value,
              notifyPatientOnDeletion: !!currentUnit.value.appointment?.patient,
              isLoading: false,
              reasonForDeletion: null as string | null,
            })
          )
          .onSuccess(() => {
            props.asModalChild?.doClose(true);
            emit("refresh-needed");
          })
          .show();
      };

      watchEffect(() => {
        if (currentUnit.value?.appointment?.status) {
          emit("changed:unit:status", currentUnit.value.appointment.status);
        }

        if (currentUnit.value?.appointment?.internal_office_note != null) {
          emit(
            "changed:unit:internalOfficeNote",
            currentUnit.value.appointment.internal_office_note
          );
        }
      });

      return {
        currentUnit,

        doDeleteUnit,

        // changes from sub-components handled here
        onChangedCurrentUnitName: (value: string | null) => {
          if (!currentUnit.value) return;

          currentUnit.value.name = value;
          emit("changed:unit:name", currentUnit.value.name);
        },
        onChangedCurrentUnitColorId: (
          value: string | null,
          hex: string | null,
          effectiveColorHex: string
        ) => {
          if (!currentUnit.value) return;

          currentUnit.value.color_id = value;
          currentUnit.value.color = hex;
          currentUnit.value.effective_color_hex = effectiveColorHex;
          emit(
            "changed:unit:color_id",
            currentUnit.value.color_id,
            hex,
            effectiveColorHex
          );
        },

        // libs
        formatDate,
        DateFormat,
      };
    },
  });
</script>

<template>
  <div>
    <h2>Termindetails</h2>
    <template v-if="currentUnit">
      <ShowLoadedUnit
        v-bind:current-unit="currentUnit"
        enable-editing
        v-on:changed:current-unit:name="onChangedCurrentUnitName"
        v-on:changed:current-unit:color_id="onChangedCurrentUnitColorId"
      >
        <template v-slot:actions>
          <BaseButton v-on:submit="doDeleteUnit"
            ><i class="fe fe-trash"></i>Termin löschen</BaseButton
          >

          <h6
            v-if="currentUnit.appointment?.status"
            class="show-unit__heading--no-lower-distance"
          >
            Terminstatus
          </h6>
          <UnitStatus
            v-if="currentUnit.appointment?.status"
            v-model="currentUnit.appointment.status"
            v-bind:appointment-id="currentUnit.appointment.id"
          />
          <UnitInternalOfficeNote
            v-if="currentUnit.appointment?.internal_office_note != null"
            v-model="currentUnit.appointment.internal_office_note"
            v-bind:appointment-id="currentUnit.appointment.id"
          />
        </template>
      </ShowLoadedUnit>
    </template>
  </div>
</template>
