<script lang="ts" setup>
  import { computed, onMounted, reactive, ref, toRefs } from "vue";

  import { useFormSelectOptions } from "frontend/uses/use-form-select";
  import { UnitType } from "shared/static/enums.ts.erb";
  import {
    translateUnitType,
    UnitTypeKey,
  } from "frontend/utils/translate-enum";
  import {
    createAbsence,
    previewAbsence,
  } from "frontend/api/application/settings/request-absence";
  import {
    httpPayloadAbsence,
    newAbsence,
    parseAbsence,
  } from "frontend/parser/settings/parse-absence";
  import { Absence } from "frontend/interfaces/settings/absence";
  import { useErrors } from "frontend/uses/use-errors";
  import { useToasts } from "frontend/uses/use-toasts";
  import { ConfirmAbsenceModal } from "frontend/utils/modals/confirm-absence-modal";

  import BaseButton from "frontend/components/base/BaseButton.vue";
  import FormInput from "frontend/components/form/FormInput.vue";
  import FormSelect from "frontend/components/form/FormSelect.vue";
  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";
  import FormDatetime from "frontend/components/form/FormDatetime.vue";
  import BaseHelp from "frontend/components/base/BaseHelp.vue";
  import FormSelectOfficePerson from "frontend/components/form/FormSelectOfficePerson.vue";

  const emit = defineEmits(["refresh-needed"]);

  const { success, error } = useToasts();

  const model = reactive<Absence>(newAbsence());
  const reset = () => {
    Object.assign(model, newAbsence());
    errors.value = {};
    globalErrors.value = [];
  };
  onMounted(reset);

  const { options: reasonOptions } = useFormSelectOptions<UnitTypeKey>(
    toRefs(model).reason,
    ref<UnitTypeKey[]>(Object.keys(UnitType) as UnitTypeKey[]),
    (key) => ({ id: UnitType[key], label: translateUnitType(key).label })
  );

  const usePreview = computed<boolean>(() => !!model.cancel_units);

  const { errors, errorFor } = useErrors(ref({}));
  const globalErrors = ref<string[]>([]);

  const createdAbsence = () => {
    success("Abwesenheit wurde erfolgreich angelegt");
    reset();
    emit("refresh-needed");
  };

  const doSubmitAbsence = async () => {
    globalErrors.value = []; // reset errors

    const requestMethod = usePreview.value ? previewAbsence : createAbsence;

    const result = await requestMethod(httpPayloadAbsence(model));
    if (result.success) {
      if (usePreview.value) {
        await new ConfirmAbsenceModal()
          .setData({ absence: parseAbsence(result.entityRaw) })
          .onSuccess(createdAbsence)
          .show();
      } else {
        createdAbsence();
      }
    } else {
      error("Abwesenheit konnte nicht angelegt werden");
      errors.value = result.errors;
      globalErrors.value.push("Abwesenheit konnte nicht gespeichert werden.");
    }
  };
</script>

<template>
  <div>
    <h3>Abwesenheit hinterlegen</h3>
    <BaseHelp
      >Sollte ein Mitarbeiter z.B. krankheitsbedingt ausfallen, könne Sie die
      Fehlzeiten hier eingeben, damit sie automatisch bei der Terminbuchung
      berücksichtigt werden können.</BaseHelp
    >
    <template v-if="globalErrors && globalErrors.length > 0">
      <label
        v-for="errorMsg in globalErrors"
        v-bind:key="errorMsg"
        class="label--error"
      >
        {{ errorMsg }}
      </label>
    </template>
    <FormSelectOfficePerson
      v-model="model.person_ids"
      label="Mitarbeiter"
      v-bind:errors="errorFor('person_ids')"
    />
    <div class="columns">
      <FormDatetime
        v-model="model.absent_from"
        class="columns__column"
        label="Abwesend ab"
        v-bind:errors="errorFor('absent_from')"
        enable-time
        visual-small
      />
      <FormDatetime
        v-model="model.absent_to"
        class="columns__column"
        label="Abwesend bis"
        v-bind:errors="errorFor('absent_to')"
        enable-time
        visual-small
      />
    </div>
    <FormInput
      v-model="model.name"
      label="Name"
      v-bind:errors="errorFor('name')"
    />
    <FormSelect
      v-model="model.reason"
      label="Grund der Abwesenheit"
      v-bind:options="reasonOptions"
      v-bind:errors="errorFor('reason')"
    />
    <div class="checkboxes">
      <FormCheckbox
        v-model="model.cancel_units"
        label="Gebuchte Termine in diesem Zeitraum löschen"
      />
      <FormCheckbox
        v-if="model.cancel_units"
        v-model="model.notify_patients"
        label="Betroffene Patienten über gelöschte Termine informieren"
      />
    </div>
    <BaseButton
      v-bind:visual-action="!usePreview"
      v-on:submit="doSubmitAbsence"
    >
      <template v-if="usePreview">Vorschau der Abwesenheit</template>
      <template v-else> Abwesenheit erstellen</template>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/label";

  .columns {
    display: flex;
  }

  .columns__column {
    flex: 1;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .checkboxes {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .label--error {
    @include label.label;
    @include label.label--error;
  }
</style>
