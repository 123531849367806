import { HttpPayload } from "frontend/interfaces/http";
import { Office, OfficeRaw } from "frontend/interfaces/office";

export function parseOffice(office: OfficeRaw): Office {
  return { ...office };
}

export function httpPayloadOffice(office: Office): HttpPayload {
  return {
    ...office,
  };
}
