<script lang="ts">
  import { defineComponent } from "vue";

  import image from "shared/assets/zugang-telemedizin-login-button.png";

  export default defineComponent({
    setup() {
      return {
        image,
      };
    },
  });
</script>

<template>
  <div>
    <img style="height: 40px" v-bind:src="image" />
  </div>
</template>

<style scoped>
  img {
    cursor: pointer;
  }
</style>
