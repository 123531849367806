import { computed, Ref, unref, ComputedRef } from "vue";
import { compareAsc, isAfter, isBefore, isSameMonth } from "date-fns";

import { uniqBy } from "shared/utils/array-utils";
import { DateFormat, formatDate } from "shared/utils/date-utils";

export function useMonthRotation(
  rotateables: Ref<Array<Date>>,
  currentDate: Ref<Date | null>,
  updateDate: (newDate: Date) => void
): {
  hasRotation: ComputedRef<boolean>;
  hasPreviousRotation: ComputedRef<boolean>;
  hasNextRotation: ComputedRef<boolean>;
  gotoNextRotation: () => void;
  gotoPreviousRotation: () => void;
} {
  const rotateablesByMonth = computed(() => {
    const targetDateUnref = unref(currentDate);
    return (
      uniqBy(unref(rotateables), (date) =>
        formatDate(date, DateFormat.YearAndMonthOnlyISO)
      )
        // filter out the current selected month
        .filter(
          (date) => !targetDateUnref || !isSameMonth(date, targetDateUnref)
        )
        // sort by date
        .sort(compareAsc)
    );
  });

  const nextRotation = computed(() => {
    return neighborElement(unref(currentDate), unref(rotateablesByMonth), true);
  });
  const previousRotation = computed(() => {
    return neighborElement(
      unref(currentDate),
      unref(rotateablesByMonth),
      false
    );
  });
  const hasNextRotation = computed(() => {
    return !!unref(nextRotation);
  });
  const hasPreviousRotation = computed(() => {
    return !!unref(previousRotation);
  });
  const hasRotation = computed(() => {
    return !!unref(nextRotation) || !!unref(previousRotation);
  });

  const gotoNextRotation = () => {
    const nextRotationUnref = unref(nextRotation);
    if (nextRotationUnref) updateDate(nextRotationUnref);
  };
  const gotoPreviousRotation = () => {
    const previousRotationUnref = unref(previousRotation);
    if (previousRotationUnref) updateDate(previousRotationUnref);
  };

  return {
    hasRotation,
    hasPreviousRotation,
    hasNextRotation,
    gotoNextRotation,
    gotoPreviousRotation,
  };
}

function neighborElement(
  pivot: Date | null,
  elements: Array<Date>,
  forward: boolean
): Date | null {
  if (!pivot && forward && elements.length > 0) return elements[0];
  else if (!pivot) return null;

  let elementsOrdered = elements;
  // reverse the order if we search backwards to give back correct date
  if (!forward) elementsOrdered = elementsOrdered.slice().reverse();

  for (const date of elementsOrdered) {
    if (
      (!forward && isAfter(pivot, date)) ||
      (forward && isBefore(pivot, date))
    )
      return date;
  }
  return null;
}
