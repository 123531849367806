import { computed, PropType, ref, Ref } from "vue";

import { PathParams } from "shared/utils/pathify";
import {
  BaseS,
  FilterDefinition,
} from "frontend/uses/filter/filter-definition";
import { FilterManager } from "frontend/uses/filter/filter-manager";

export const FILTER_EVENT_STATE_CHANGE = "filter:state-change" as const; // should emit complete state after change!

export function filterProps<FilterState, FilterOptions>() {
  return {
    filterState: {
      type: Object as PropType<FilterState>,
      required: true,
    } as const,
    filterOptions: {
      type: Object as PropType<FilterOptions>,
      required: true,
    } as const,
  } as const;
}

export function filterEmits() {
  return [FILTER_EVENT_STATE_CHANGE] as const;
}

// has to be issued directly in setup
export function useFilter<T>(
  collection: Readonly<Ref<T[]>>,
  filterDefinitions: FilterDefinition<T, BaseS>[]
): {
  filteredCollection: Readonly<Ref<T[]>>;
  backendParams: Readonly<Ref<PathParams>>;
  filterContainerBinding: {
    filterManager: FilterManager<T>;
  };
} {
  const backendParams = ref<PathParams>({});
  const filterManager = new FilterManager<T>(filterDefinitions);
  filterManager.subscribeStateChange(({ isBackend }) => {
    if (isBackend) {
      backendParams.value = filterManager.backendQuery();
    }
  });

  return {
    filteredCollection: computed(() =>
      filterManager.sort(filterManager.filter(collection.value))
    ),
    backendParams,
    filterContainerBinding: {
      filterManager,
    },
  };
}
