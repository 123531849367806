<script lang="ts">
  import { computed, defineComponent, PropType, reactive, ref } from "vue";
  import { useRouter } from "vue-router";
  import { compareAsc } from "date-fns";

  import {
    Patient,
    PatientWithAppointments,
  } from "frontend/interfaces/patient";
  import { formatDate, DateFormat, today } from "shared/utils/date-utils";
  import { useSimpleModal } from "frontend/uses/simple-modal/use-simple-modal";
  import {
    destroyPatient,
    removePvsFromPatient,
    updatePatient,
  } from "frontend/api/application/request-patients";
  import {
    duplicatePatient,
    httpPayloadPatient,
    parsePatient,
  } from "frontend/parser/parse-patient";
  import { useToasts } from "frontend/uses/use-toasts";
  import { newClickUnit } from "frontend/parser/parse-unit";
  import { tmpUnitToPopupAppointmentEntry } from "frontend/uses/abstract-view/entries/tmp-units";
  import { PrimaryKey } from "frontend/interfaces/primary-key";
  import { useStore as useOfficeStore } from "frontend/stores/office";

  import FormText from "frontend/components/form/FormText.vue";
  import BaseButton from "frontend/components/base/BaseButton.vue";
  import MutatedEditPatientComponent from "frontend/components/mutated/MutatedEditPatient.vue";
  import PatientMergeComponent from "frontend/components/PatientMerge.vue";
  import PatientAppointmentBox from "frontend/components/PatientAppointmentBox.vue";
  import PopupAppointment from "frontend/components/PopupAppointment.vue";

  const RELOAD_PATIENT = "reload-patient";

  export default defineComponent({
    components: { BaseButton, FormText, PatientAppointmentBox },
    props: {
      patient: {
        type: Object as PropType<PatientWithAppointments | Patient>,
        required: true,
      },
      short: {
        type: Boolean,
        default: false,
      },
      disableEdit: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["update:patient", RELOAD_PATIENT],
    setup(props, { emit }) {
      // setup variables
      const { custom: customModal, confirm: confirmModal } = useSimpleModal();
      const router = useRouter();
      const { error: errorToast, success: successToast } = useToasts();
      const officeStore = useOfficeStore();

      const tmpUnit = newClickUnit(null);
      const entry = tmpUnitToPopupAppointmentEntry(tmpUnit);

      const activeAppointments = computed(() => {
        const patient = props.patient as PatientWithAppointments;
        return patient.appointments
          .filter((appointment) => appointment.begin >= today())
          .sort((a, b) => compareAsc(a.begin, b.begin));
      });

      const pastAppointments = computed(() => {
        const patient = props.patient as PatientWithAppointments;
        return patient.appointments.filter(
          (appointment) => appointment.begin < today()
        );
      });

      // define actions
      const actions = {
        doEditPatient: async () => {
          const patient = ref<Patient>(duplicatePatient(props.patient));

          const componentProps = reactive({
            patient,
            errors: {} as Partial<Record<string, string>>,
            visualModal: true,
          });

          customModal(MutatedEditPatientComponent, {
            hideCancel: false,
            hideConfirm: false,
            confirmLabel: "Patient speichern",
            noAutoClose: true,
            componentProps,
            onClose: async (confirm: boolean, close: () => void) => {
              componentProps.errors = {};

              if (confirm) {
                const result = await updatePatient(
                  httpPayloadPatient(componentProps.patient)
                );

                if (result.errors && Object.keys(result.errors).length > 0) {
                  componentProps.errors = result.errors;
                } else {
                  const resultPatient = parsePatient(result.patient);
                  emit("update:patient", resultPatient);
                  close();
                }
              } else {
                close();
              }
            },
          });
        },
        doDestroyPatient: async () => {
          const { confirm } = await confirmModal(
            `Sind Sie sicher, dass Sie den Patienten "${
              props.patient.display_name
            }" (geb. ${formatDate(
              props.patient.date_of_birth,
              DateFormat.DateOnly
            )}) löschen möchten? Dadurch werden auch alle Termine dieses Patienten unwiderruflich gelöscht. Diese Aktion kann nicht rückgängig gemacht werden.`,
            {}
          );

          if (confirm) {
            const result = await destroyPatient(props.patient.id);

            const { success } = result;

            if (success) {
              successToast("Patient wurde gelöscht");
              router.push({
                name: "calendar",
              });
            } else {
              errorToast("Patient konnte nicht gelöscht werden");
            }
          }
        },
        doMergePatient: async () => {
          const { doClose } = await customModal(PatientMergeComponent, {
            hideCancel: true,
            hideConfirm: true,
            componentProps: reactive({
              mergePatient: props.patient,
            }),
            componentOn: {
              "merged:patient": () => doClose(true),
            },
          });
        },
        doEnterAppointment: async () => {
          const { doClose } = await customModal(PopupAppointment, {
            hideCancel: true,
            hideConfirm: true,
            componentProps: reactive({
              entry: entry,
              patient: props.patient,
            }),
            componentOn: {
              "refresh-needed": () => {
                doClose(true);
                emit(RELOAD_PATIENT);
              },
              "close-modal": () => doClose(true),
            },
          });
        },
      };
      const doRemovePvsPatient = async (id: PrimaryKey) => {
        await removePvsFromPatient(id);
        emit(RELOAD_PATIENT);
      };

      return {
        ...actions,

        patientAppointments: computed(
          () =>
            (
              props.patient as {
                appointments?: PatientWithAppointments["appointments"];
              }
            ).appointments
        ),

        // libs
        formatDate,
        DateFormat,

        activeAppointments,
        pastAppointments,
        doRemovePvsPatient,
        officeStore,
      };
    },
  });
</script>

<template>
  <div>
    <template v-if="short">
      <h5>Patient</h5>
      <FormText label="Name">
        {{ patient.display_name }}
      </FormText>
      <FormText label="Geburtsdatum / Alter">
        {{ formatDate(patient.date_of_birth, DateFormat.DateOnly) }}
        <span class="show-patient__age">({{ patient.human_age }})</span>
      </FormText>
      <RouterLink
        class="show-patient__details-link"
        v-bind:to="{
          name: 'patient-show',
          params: { id: patient.id },
        }"
      >
        Patientendetails
      </RouterLink>
    </template>

    <template v-else>
      <div class="patient__title">
        <h2 class="patientenprofil__title">Patientenprofil</h2>
        <div class="action__button-create-appointment">
          <BaseButton
            visual-full-width
            visual-action
            v-bind:route="{
              name: 'appointment-new',
              query: { patient: patient.id },
            }"
            v-bind:disabled="officeStore.isBlocked"
          >
            Termin anlegen
          </BaseButton>
        </div>
      </div>

      <div class="show-title__name">{{ patient.display_name }}</div>
      <div class="show-title__age">({{ patient.human_age }})</div>

      <div class="profile__wrapper">
        <div class="profile--header">
          <div>Name:</div>
          <div>Geburtsdatum:</div>
          <div>Alter:</div>
          <div>Versichertennummer:</div>
          <div>Versicherung:</div>
          <div>Quelle:</div>
          <div>PVS-id:</div>
        </div>
        <div class="profile__info">
          <div>{{ patient.display_name }}</div>
          <div>
            {{ formatDate(patient.date_of_birth, DateFormat.DateOnly) }}
          </div>
          <div>{{ patient.human_age }}</div>
          <div>{{ patient.kvnr ?? "-" }}</div>
          <div>{{ patient.insurance_name }}</div>
          <div>
            {{ patient.is_praxisapp ? "PraxisApp" : "Online-Terminverwaltung" }}
          </div>
          <div>
            {{ patient.current_pvs_id ?? "N/A" }}
            <template v-if="patient.current_pvs_id">
              <i
                class="fe fe-trash action-icon"
                title="Verknüpfung zum PVS entfernen"
                v-on:click="doRemovePvsPatient(patient.id)"
              ></i>
              <a
                v-if="
                  (patient.current_pvs_features ?? []).indexOf(
                    'open_patient_in_pvs'
                  ) >= 0
                "
                v-bind:href="`monks-pvs-bridge://open_patient/${patient.current_pvs_id}`"
                title="In PVS öffnen"
              >
                <i class="fe fe-note-clinical action-icon"></i>
              </a>
            </template>
            <span
              class="action__button-enter-appointment"
              v-on:click="doEnterAppointment"
              >Bereits vereinbarte Termine übertragen
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="!patient.is_praxisapp && !disableEdit"
        class="profile__buttons"
      >
        <div class="actions__button">
          <BaseButton v-if="!patient.is_praxisapp" v-on:submit="doEditPatient">
            Bearbeiten
          </BaseButton>
        </div>
        <div v-if="!patient.is_praxisapp" class="actions__button">
          <BaseButton v-on:submit="doMergePatient"> Zusammenführen </BaseButton>
        </div>
        <div class="actions__button">
          <BaseButton
            v-if="!patient.is_praxisapp"
            visual-warn
            v-on:submit="doDestroyPatient"
          >
            <i class="fe fe-trash"></i>Löschen
          </BaseButton>
        </div>
      </div>

      <div v-if="patientAppointments">
        <h2 class="appointments__title">Terminhistorie</h2>
        <PatientAppointmentBox
          v-bind:appointments="activeAppointments"
          v-bind:title="'Anstehende Termine'"
          v-bind:note="'Für diesen Patienten stehen keine Termine an.'"
          is-praxis
        />
        <PatientAppointmentBox
          v-bind:appointments="pastAppointments"
          v-bind:title="'Vergangene Termine'"
          v-bind:note="'Für diesen Patienten gibt es keine vergangenen Termine.'"
          is-praxis
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @use "frontend/styles/mixins/link";
  @use "frontend/styles/colors";

  .show-patient__age {
    font-size: 12px;
  }

  .show-patient__details-link {
    @include link.link;

    margin-top: 5px;

    font-size: 12px;
  }

  .show-patient__headline--no-distance {
    margin-bottom: 10px;
  }

  .actions__button {
    display: inline-block;
    padding: 10px;
  }

  .patient__title,
  .appointments__title {
    border-top: 1px solid colors.$color_base-list--separator;
    border-bottom: 1px solid colors.$color_base-list--separator;
    padding: 10px;
  }

  .patient__title {
    display: flex;
    margin: 20px 0;
  }

  .patientenprofil__title {
    margin: 0;
  }

  .show-title__name {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }

  .show-title__age {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .profile__wrapper {
    display: flex;
    margin-bottom: 30px;
  }

  .profile--header {
    text-align: right;
    margin-right: 20px;
    font-weight: bold;

    > div {
      padding: 5px;
    }
  }

  .profile__info div {
    padding: 5px;
  }

  .appointments__title {
    margin-top: 70px;
  }

  .action__button-enter-appointment {
    @include link.link;

    font-size: 12px;
    margin-left: 10px;
  }

  .action__button-create-appointment {
    margin-left: auto;
    width: 220px;
  }

  .action-icon {
    font-size: 20px;
    cursor: pointer;

    margin-left: 10px;
  }
</style>
