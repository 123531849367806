import { v4 as uuid } from "uuid";

import { RoleRaw, Role, RoleNew } from "frontend/interfaces/role";
import { HttpPayload } from "frontend/interfaces/http";

export function parseRole(role: RoleRaw): Role {
  return { ...role };
}

export function httpPayloadRole(role: RoleRaw): HttpPayload {
  return {
    ...role,
  };
}

export function newRole(): RoleNew {
  return {
    id: `MOCK-${uuid()}`,
    name: "",
    unsaved: true,
  };
}
