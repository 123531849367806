<script lang="ts">
  import { defineComponent, toRefs } from "vue";

  import { AppointmentStatus } from "shared/static/enums.ts.erb";
  import { isActive } from "shared/utils/bitwise-enum";
  import { toggleStatusAppointment } from "frontend/api/application/request-appointments";
  import {
    AppointmentStatusControls,
    EnumSetting,
  } from "frontend/utils/appointment-status";

  import FormCheckbox from "frontend/components/form/FormCheckbox.vue";

  export default defineComponent({
    components: { FormCheckbox },
    props: {
      appointmentId: {
        type: String,
        required: true,
      },
      modelValue: {
        type: Number,
        required: true,
      },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
      const { appointmentId } = toRefs(props);
      const doFlipFlag = async (
        setting: EnumSetting,
        newFlagValue: boolean
      ) => {
        const newStatus = await toggleStatusAppointment(appointmentId.value, {
          [setting.enumKey]: newFlagValue,
        });
        if (newStatus >= 0) emit("update:modelValue", newStatus);
      };
      return {
        doFlipFlag,
        //libs
        AppointmentStatusControls,
        AppointmentStatus,
        isActive,
      };
    },
  });
</script>

<template>
  <div class="">
    <div
      v-for="setting in AppointmentStatusControls"
      v-bind:key="setting.enumKey"
    >
      <FormCheckbox
        v-bind:label="setting.label"
        v-bind:model-value="
          isActive(modelValue, AppointmentStatus[setting.enumKey])
        "
        v-on:update:model-value="
          doFlipFlag(
            setting,
            !isActive(modelValue, AppointmentStatus[setting.enumKey])
          )
        "
      />
    </div>
  </div>
</template>
