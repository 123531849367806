import { parseISO } from "date-fns";

import { Suggestion, SuggestionRaw } from "frontend/interfaces/p/suggestion";

export function parseSuggestion(suggestion: SuggestionRaw): Suggestion {
  return {
    ...suggestion,
    begin: parseISO(suggestion.begin),
  };
}
